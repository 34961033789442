export const CONFIG_CTO = {
  ACTIVITY: "Activité",
  ACCOUNT_CUSTOMER: "Client en compte",
  ADMINISTRATION: "Espace Administration",
  APPOINTMENTS: "Rendez-vous",
  BANKS: "Banques",
  CALENDAR: "Planning",
  CENTER: "Centre",
  CENTERS_LIST: "Liste des centres",
  CGV: "Conditions générales de ventes",
  CHANGE_ORGANIZATION: "Changer d'espace CTonline",
  CHANGE_PROFILE: "Changer de profil client",
  CONTACT_DETAILS: "Coordonnées",
  CLOSINGS: "Fermetures",
  CUSTOMERS: "Clients",
  DASHBOARDS: "Dashboard",
  DISCOUNTS: "Réductions",
  DOCUMENTS: "Documents",
  EXPORT: "Export",
  FLEET: "Flotte",
  HELP: "Aide",
  HISTORY: "Historique",
  IMPORT: "Import",
  LINES: "Lignes",
  LOGOUT: "Déconnexion",
  MANAGEMENT: "Gestion",
  MARKETING: "Marketing",
  MY_ACCOUNT: "Mon compte",
  OPERATION: "Prestations",
  OPTIONS: "Options",
  PARAMETERS: "Paramètres",
  PAYMENTS_MODE: "Modes de règlement",
  PUBLIC_PORTAL: "Portail grand public",
  QUALITY: "Qualité",
  REMINDER: "Relances",
  SCHEDULES: "Plages Horaires",
  SHOP: "Boutique",
  SMS: "SMS",
  TOOLS: "Outils",
  TERMS: "Échéances",
  UNPAID_APPOINTMENT: "Rendez-vous non payé",
  USERS: "Utilisateurs",
  VEHICLES: "Véhicules",

  // MENU
  SUPERVISE_MENU: "Pilotage",
  PROFESSION_MENU: "Métier",
  DATA_MENU: "Données",
  PARAMETERS_MENU: "Paramètres",

  // APPOINTMENT OPTIONS
  LOAN_CAR: "Prêt de véhicule",
  TO_LOAD: "À charger",
  TRUCK_TRAILER: "Prêt semi-remorque",
  PARKING: "Parking",
  VALET: "Voiturier",

  // PATHS
  ACTIVITY_PATH: "activity",
  ADMINISTRATION_PATH: "administration",
  APPOINTMENTS_PATH: "appointment",
  BANKS_PATH: "banks",
  CALENDAR_PATH: "calendar",
  CENTER_PATH: "center",
  CERTIFICATES_PATH: "certificates",
  CGV_PATH: "cgv",
  CLOSINGS_PATH: "closings",
  CONNECT_CHECK_PATH: "connect-login",
  CONTACT_DETAILS_PATH: "contact_details",
  CUSTOMERS_PATH: "customers",
  DASHBOARDS_PATH: "dashboards",
  DISCOUNTS_PATH: "discounts",
  EXPORT_PATH: "export",
  FLEET_PATH: "fleet",
  HISTORY_PATH: "history",
  IMPORT_PATH: "import",
  INVOICES_PATH: "invoices",
  LINES_PATH: "lines",
  LOGOUT_PATH: "logout",
  MANAGEMENT_PATH: "management",
  MARKETING_PATH: "marketing",
  ONLINE_PAYMENTS_PATH: "online_payments",
  OPERATION_PATH: "operation",
  OPTIONS_PATH: "options",
  OTHERS_DOCUMENTS_PATH: "others_documents",
  PUBLIC_PORTAL_PATH: "public_portal",
  PAYMENTS_PATH: "payments",
  PARAMETERS_PATH: "parameters",
  REMINDER_PATH: "reminder",
  REPORT_PATH: "report",
  SCHEDULES_PATH: "schedules",
  SEARCH_PATH: "search",
  SMS_PATH: "sms",
  TERMS_PATH: "terms",
  YEARS_PATH: "years",
};
