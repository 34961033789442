import CustomIcon from "../layouts/CustomIcon";

type activitiesIconSize = {
  vl: { width: number; height: number };
  pl: { width: number; height: number };
  l: { width: number; height: number };
};

type Props = {
  activities: string[];
  color?: string;
  size?: "sm" | "lg";
};

const ActivitiesIcons = ({ activities, color, size = "sm" }: Props) => {
  let iconSizes: activitiesIconSize;
  switch (size) {
    case "lg":
      iconSizes = {
        vl: { width: 16, height: 14 },
        pl: { width: 16, height: 14 },
        l: { width: 16, height: 12 },
      };
      break;
    default:
      iconSizes = {
        vl: { width: 12, height: 12 },
        pl: { width: 14, height: 10 },
        l: { width: 13, height: 10 },
      };
      break;
  }

  return (
    <div className="inline-flex justify-center items-center w-fit">
      <div className={`flex-col ${size === "sm" ? "w-2.5" : "w-3.5"}`}>
        {activities.some((activity) => activity === "VL") && (
          <CustomIcon
            name="car"
            color={color}
            width={iconSizes.vl.width}
            height={iconSizes.vl.height}
          />
        )}
        {activities.length < 3 &&
          activities.some((activity) => activity === "MOT") && (
            <CustomIcon
              name="moto"
              color={color}
              width={iconSizes.vl.width}
              height={iconSizes.vl.height}
            />
          )}
        {activities.some((activity) => activity === "PL") && (
          <CustomIcon
            name="truck"
            color={color}
            width={iconSizes.pl.width}
            height={iconSizes.pl.height}
          />
        )}
      </div>
      {activities.length > 2 &&
        activities.some((activity) => activity === "MOT") && (
          <div className={`flex-col -mt-1.5`}>
            <CustomIcon
              name="moto"
              color={color}
              width={size === "sm" ? 16 : 18}
              height={size === "sm" ? 16 : 18}
              rotate={260}
              flip="horizontal"
            />
          </div>
        )}
    </div>
  );
};

export default ActivitiesIcons;
