import axios from "axios";
import { PCA, scopes } from "../msal/msal";
import { persistor } from "../../store/store";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "X-Version": `${process.env.REACT_APP_API_VERSION}`,
  },
});

api.interceptors.request.use(
  async (config) => {
    const accounts = PCA.getAllAccounts();
    if (accounts.length > 0) {
      try {
        // Initialize PCA and handle redirect promises
        await PCA.initialize();
        await PCA.handleRedirectPromise();

        // Acquire token silently
        const tokenResponse = await PCA.acquireTokenSilent({
          scopes: scopes,
          account: accounts[0],
        });

        if (tokenResponse && tokenResponse.accessToken) {
          // Set Authorization header with acquired token
          config.headers["Authorization"] =
            `Bearer ${tokenResponse.accessToken}`;
        } else {
          throw new Error("Token not retrieved");
        }
      } catch (silentError) {
        console.warn(
          "Silent token acquisition failed, attempting popup:",
          silentError,
        );
        try {
          const tokenResponse = await PCA.acquireTokenPopup({
            scopes: scopes,
            account: accounts[0],
          });
          if (tokenResponse && tokenResponse.accessToken) {
            config.headers["Authorization"] =
              `Bearer ${tokenResponse.accessToken}`;
          } else {
            throw new Error("Token not retrieved via popup");
          }
        } catch (popupError) {
          console.error("Popup token acquisition failed:", popupError);
          // Purge and logout as fallback
          PCA.clearCache();
          localStorage.clear();
          await persistor.purge();
          PCA.logoutRedirect({
            authority: process.env.REACT_APP_AZURE_BASE_URL,
            postLogoutRedirectUri: `/`,
          });
          return Promise.reject(popupError);
        }
      }
    }

    // Return the config whether modified or not
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
