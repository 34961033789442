import { useState, useEffect } from "react";
import customTheme from "../../utils/customTheme/customTheme";

export const tailwindBreakpoints = {
  xs: "450px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

// Function to extract breakpoint numeric value
export const getBreakpointValue = (breakpoint: string) => {
  return parseInt(breakpoint.replace("px", ""), 10);
};

// Custom hook to check if the window width is greater than a given breakpoint (default 600), returning true if it is.
const useWindowWidth = (
  breakpoint: number = customTheme.breakpoints.values.sm,
) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > breakpoint);

  useEffect(() => {
    if (window.innerWidth > breakpoint) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }

    const updateMedia = () => {
      if (window.innerWidth > breakpoint) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return isDesktop;
};

export default useWindowWidth;
