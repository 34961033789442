import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { Alert, Badge, Button, Spinner } from "flowbite-react";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faUserPlus } from "@fortawesome/pro-regular-svg-icons";

import { CONFIG_API } from "../../../data/config.API";
import { api } from "../../../utils/api";
import { AppDispatch, RootState } from "../../../store/store";
import {
  APIResponseModel,
  APIResponsesModel,
} from "../../../models/ApiResponseModel";
import { AccountType, UserInfoModel } from "../../../models/UserModel";
import { displayError } from "../../../utils/layout/displayError";
import { CONFIG_CTO } from "../../../data/config.CTO";
import customTheme, {
  generalSpacing,
} from "../../../utils/customTheme/customTheme";
import useWindowWidth from "../../../hooks/layout/useWindowWidth";
import { FilterItem } from "../../common/List/ListFilters";
import { setAlert } from "../../../store/layout/alert-slice";

import ListComponent, {
  ColumnDefinition,
} from "../../common/List/ListComponent";
import ErrorLoadingData from "../../common/ErrorLoadingData";
import LoadingData from "../../common/LoadingData";
import CustomModal from "../../layouts/CustomModal";
import SidePanel from "../../layouts/SidePanel";
import UserForm from "./UserForm";
import OrganizationUsers from "./OrganizationUsers";
import CenterItem from "../../layouts/header/CenterItem";

interface RowData {
  id: number | string;
  lastName: string;
  firstName: string;
  mail: string;
  accountType: {
    render: JSX.Element;
  };
  defaultCenter: {
    render: JSX.Element;
  };
  isCTonlineConnect: {
    render: JSX.Element;
  };
}

interface Props {
  setSelectedUser: React.Dispatch<React.SetStateAction<UserInfoModel | null>>;
}

export const convertAccountType = (
  type: AccountType,
  size: "xs" | "sm",
): JSX.Element => {
  switch (type) {
    case "Admin":
      // return <Chip label="Administrateur" size={size} color="error" sx={{ ".MuiChip-label": { fontSize: fontSize } }} />;
      return (
        <Badge
          className={`bg-orange-50 text-orange-500 font-medium`}
          size={size}
        >
          Administrateur
        </Badge>
      );
    case "User":
      // return <Chip label="Employé" size={size} sx={{ ".MuiChip-label": { fontSize: fontSize } }} />;
      return (
        <Badge className={`bg-gray-100 text-gray-500 font-medium`} size={size}>
          Employé
        </Badge>
      );
    default:
      // return <Chip label={type} size={size} sx={{ ".MuiChip-label": { fontSize: fontSize } }} />;
      return (
        <Badge className={`bg-gray-100 text-gray-500 font-medium`} size={size}>
          {type}
        </Badge>
      );
  }
};

const UsersList = ({ setSelectedUser }: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const organizationCenters = useSelector(
    (store: RootState) => store.CENTER.organizationCenters,
  );
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );
  const isDesktop = useWindowWidth(customTheme.breakpoints.values.lg);

  const [allUsersList, setAllUsersList] = useState<UserInfoModel[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sidePanelContent, setSidePanelContent] = useState<
    "userForm" | "organizationUsers" | null
  >(null);
  const [requestState, setRequestState] = useState<{
    type: "delete" | "remove" | null;
    showModal: boolean;
    loading: boolean;
    items: (string | number)[];
  }>({
    type: null,
    showModal: false,
    loading: false,
    items: [],
  });

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  // Declare filters
  const filterItems: FilterItem[] = [
    {
      id: 1,
      field: "lastName",
      label: "Nom",
      type: "text",
      operator: "contains",
    },
    {
      id: 2,
      field: "firstName",
      label: "Prénom",
      type: "text",
      operator: "contains",
    },
    {
      id: 3,
      field: "mail",
      label: "E-mail",
      type: "text",
      operator: "contains",
    },
  ];

  const fetchCenterUsers = async () => {
    !isLoading && setIsLoading(true);
    error && setError(null);
    try {
      const adminsList = [];
      const organizationAdmin: AxiosResponse<APIResponseModel<UserInfoModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}`,
        );
      const usersList: AxiosResponse<APIResponsesModel<UserInfoModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/${CONFIG_API.ACCOUNT_USER}`,
        );
      if (organizationAdmin.data.success) {
        const adminInfo = organizationAdmin.data.item;
        // Get admin Account Info to have Azure Info with externalUserId
        const adminAccountInfo: AxiosResponse<
          APIResponsesModel<UserInfoModel>
        > = await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/${CONFIG_API.ACCOUNT}/${CONFIG_API.ADMIN}/${CONFIG_API.EXTERNAL_ACCOUNT}`,
        );
        // Find the right admin by its pid in case or several admins returned by API (bug in database)
        const matchedAdmin = adminAccountInfo.data.items.find(
          (admin) => admin.pid === adminInfo.pid,
        );
        adminsList.push({
          ...organizationAdmin.data.item,
          lastName: organizationAdmin.data.item.name,
          firstName: organizationAdmin.data.item.firstName,
          mail: adminInfo.email,
          address1: adminInfo.address1,
          address2: adminInfo.address2,
          city: adminInfo.city,
          postalCode: adminInfo.postalCode,
          phoneNumber: adminInfo.phoneNumber,
          portableNumber: adminInfo.portableNumber,
          accountType: AccountType.Admin,
          login: matchedAdmin ? matchedAdmin.login : "",
          externalUserId: matchedAdmin ? matchedAdmin.externalUserId : "",
        });
      }
      const combinedUsers = [
        ...adminsList,
        ...usersList.data.items.map((user) => ({
          ...user,
          accountType: AccountType.User, // Set "User" accountType because away from API response
        })),
      ];
      if (organizationAdmin.data.success && usersList.data.success) {
        setAllUsersList(combinedUsers);
      } else {
        setError(
          displayError(
            !organizationAdmin.data.success
              ? organizationAdmin.data.messages[0]
              : usersList.data.messages[0],
          ),
        );
      }
    } catch (error) {
      setError("Une erreur est survenue lors du chargement des utilisateurs.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = (row: RowData) => {
    const selectedRow = allUsersList?.find((user) => user.pid === row.id);
    if (selectedRow) {
      setSelectedUser(selectedRow);
    }
  };

  useEffect(() => {
    fetchCenterUsers();
  }, [currentCenter]);

  // Declare columns of the grid (hidden columns only for filtering)
  const tableColumns: ColumnDefinition<RowData>[] = [
    { id: "lastName", label: "Nom", flex: 1.5, type: "text" },
    { id: "firstName", label: "Prénom", flex: 1.2, type: "text" },
    { id: "mail", label: "Adresse mail", flex: 1.5, type: "text" },
    { id: "accountType", label: "Type de compte", flex: 1, type: "component" },
    {
      id: "defaultCenter",
      label: "Centre par défaut",
      type: "component",
      hide: organizationCenters && organizationCenters?.length === 1,
    },
    {
      id: "isCTonlineConnect",
      label: "CTonline Connect",
      flex: 0.8,
      type: "component",
    },
  ];

  const convertCurrentCenter = (currentCenterPid: string) => {
    const currentCenter = organizationCenters?.find(
      (center) => center.pid === currentCenterPid,
    );
    return currentCenter ? (
      <div className="max-w-full">
        <CenterItem
          centerName={currentCenter.name}
          centerColor={currentCenter.centerColor}
          centerActivities={currentCenter.activities}
          isCurrentCenter={false}
          isChangeable={false}
          isItem={false}
        />
      </div>
    ) : (
      <></>
    );
  };

  // Generate table rows
  const mapDataToRows = (data: UserInfoModel[]): RowData[] => {
    return data.map((item) => ({
      id: item.pid,
      lastName: `<strong>${item.lastName}</strong>`,
      firstName: item.firstName,
      mail: item.email,
      accountType: { render: convertAccountType(item.accountType, "xs") },
      defaultCenter: {
        render: item.defaultCenterPid ? (
          convertCurrentCenter(item.defaultCenterPid)
        ) : (
          <></>
        ),
      },
      isCTonlineConnect: {
        render: item.externalUserId ? (
          <FontAwesomeIcon icon={faCircleCheck} size={"lg"} />
        ) : (
          <></>
        ),
      },
    }));
  };

  const deleteUsers = async (usersPid: (string | number)[]) => {
    try {
      // Create an array of delete promises for each user ID
      const deletePromises = usersPid.map((userPid) =>
        api.delete(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.ACCOUNT_USER}/${userPid}`,
        ),
      );

      // Wait for all delete requests to complete
      const deleteResponses = await Promise.all(deletePromises);

      let successCount = 0;
      let errorCount = 0;
      const successfullyDeletedUsers: UserInfoModel[] = [];

      // Process each delete response
      deleteResponses.forEach((deleteResponse, index) => {
        if (deleteResponse.data.success && deleteResponse.data.item) {
          successCount++;
          const currentDeletedUser = allUsersList?.find(
            (user) => user.pid === usersPid[index],
          );
          if (currentDeletedUser) {
            successfullyDeletedUsers.push(currentDeletedUser);
          }
        } else {
          errorCount++;
        }
      });

      // Update users table with all successfully deleted users
      if (successfullyDeletedUsers.length > 0) {
        const updatedUsers = allUsersList?.filter(
          (user) =>
            !successfullyDeletedUsers.some((item) => item.pid === user.pid),
        );
        setAllUsersList(updatedUsers);
      }

      // Determine the appropriate message to dispatch
      if (successCount === usersPid.length) {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message:
              usersPid.length > 1
                ? "Les utilisateurs ont bien été supprimés."
                : "L'utilisateur a bien été supprimé.",
          }),
        );
      } else if (errorCount === usersPid.length) {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "failure",
            message:
              usersPid.length > 1
                ? "Une erreur est survenue lors de la suppression des utilisateurs."
                : "Une erreur est survenue lors de la suppression de l'utilisateur.",
          }),
        );
      } else {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "failure",
            message:
              "Une erreur est survenue lors de la suppression de certains utilisateurs.",
          }),
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "failure",
          message: "Une erreur est survenue lors de la suppression.",
        }),
      );
    } finally {
      // Reset delete confirmation modal and loading state
      setRequestState({
        type: null,
        showModal: false,
        loading: false,
        items: [],
      });
    }
  };

  const removeUsers = async (usersPid: (string | number)[]) => {
    try {
      // Create an array of put promises for each user ID
      const updatePromises = usersPid.map((userPid) => {
        const currentUser = allUsersList.find((user) => user.pid === userPid);
        // Filter others centers to update allowedCentersPids of the user without current center
        const othersUserCenters = currentUser?.allowedCentersPIDs.filter(
          (centerPid) => centerPid !== currentCenter?.pid,
        );
        return api.put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.ACCOUNT_USER}/${userPid}`,
          {
            ...currentUser,
            allowedCentersPIDs: othersUserCenters,
          },
        );
      });

      // Wait for all put requests to complete
      const updateResponses = await Promise.all(updatePromises);

      let successCount = 0;
      let errorCount = 0;
      const successfullyRemovedUsers: UserInfoModel[] = [];

      // Process each put response
      updateResponses.forEach((updateResponse, index) => {
        if (updateResponse.data.success && updateResponse.data.item) {
          successCount++;
          const currentRemovedUser = allUsersList?.find(
            (user) => user.pid === usersPid[index],
          );
          if (currentRemovedUser) {
            successfullyRemovedUsers.push(currentRemovedUser);
          }
        } else {
          errorCount++;
        }
      });

      // Update users table with all successfully removed users
      if (successfullyRemovedUsers.length > 0) {
        const updatedUsers = allUsersList?.filter(
          (user) =>
            !successfullyRemovedUsers.some((item) => item.pid === user.pid),
        );
        setAllUsersList(updatedUsers);
      }

      // Determine the appropriate message to dispatch
      if (successCount === usersPid.length) {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message:
              usersPid.length > 1
                ? "Les utilisateurs ont bien été retirés du centre."
                : "L'utilisateur a bien été retiré du centre.",
          }),
        );
      } else if (errorCount === usersPid.length) {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "failure",
            message:
              usersPid.length > 1
                ? "Une erreur est survenue lors du retrait des utilisateurs."
                : "Une erreur est survenue lors du retrait de l'utilisateur.",
          }),
        );
      } else {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "failure",
            message:
              "Une erreur est survenue lors du retrait de certains utilisateurs.",
          }),
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "failure",
          message: "Une erreur est survenue.",
        }),
      );
    } finally {
      // Reset delete confirmation modal and loading state
      setRequestState({
        type: null,
        showModal: false,
        loading: false,
        items: [],
      });
    }
  };

  return (
    <>
      {error ? (
        <ErrorLoadingData
          errorMessage={error}
          retryFunction={fetchCenterUsers}
        />
      ) : isLoading ? (
        <LoadingData text="Chargement des utilisateurs..." />
      ) : (
        <>
          <CustomModal
            showLogo={false}
            open={requestState.showModal}
            padding={4}
            width={{ xs: "20rem", sm: "42rem" }}
            height="auto"
            textAlign="initial"
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              gap={generalSpacing}
            >
              <Typography fontSize={"20px"}>
                Êtes-vous sûr de vouloir{" "}
                {requestState.type === "delete" ? "supprimer" : "retirer"}{" "}
                {requestState.items.length > 1 ? (
                  <>
                    ces{" "}
                    <Typography
                      component="span"
                      fontWeight="bold"
                      fontSize={"19px"}
                    >
                      {requestState.items.length}
                    </Typography>{" "}
                    utilisateurs
                  </>
                ) : (
                  "cet utilisateur"
                )}{" "}
                {requestState.type === "remove" &&
                  `du centre ${currentCenter?.name}`}{" "}
                ?
              </Typography>
              {requestState.type === "delete" &&
                organizationCenters &&
                organizationCenters.length > 1 && (
                  <Alert color="warning">
                    {requestState.items.length > 1
                      ? "Ces utilisateurs seront supprimés de tous les centres qui leur sont attribués."
                      : "Cet utilisateur sera supprimé de tous les centres qui lui sont attribués."}
                  </Alert>
                )}
              <Stack flexDirection="row" gap={1}>
                <Button
                  className="btn-base"
                  onClick={() => {
                    setRequestState({ ...requestState, loading: true });
                    requestState.type === "delete"
                      ? deleteUsers(requestState.items)
                      : removeUsers(requestState.items);
                  }}
                  disabled={requestState.loading}
                >
                  {requestState.loading ? <Spinner size="sm" /> : "Confirmer"}
                </Button>
                <Button
                  className="btn-base btn-outlined"
                  onClick={() =>
                    setRequestState({ ...requestState, showModal: false })
                  }
                >
                  Annuler
                </Button>
              </Stack>
            </Stack>
          </CustomModal>
          <SidePanel
            showDrawer={Boolean(sidePanelContent)}
            setShowDrawer={() => setSidePanelContent(null)}
            handleCloseDrawer={() => setSidePanelContent(null)}
            title={
              sidePanelContent === "userForm"
                ? "Nouvel utilisateur"
                : "Ajout depuis un autre centre"
            }
          >
            {sidePanelContent === "organizationUsers" ? (
              <OrganizationUsers
                key={Date.now()}
                usersList={allUsersList}
                setAllUsersList={setAllUsersList}
                handleCloseDrawer={() => setSidePanelContent(null)}
              />
            ) : (
              <UserForm
                key={Date.now()}
                setAllUsersList={setAllUsersList}
                userFormContent="userInfo"
                handleCloseDrawer={() => setSidePanelContent(null)}
              />
            )}
          </SidePanel>
          <Stack>
            <Stack
              flexDirection={isDesktop ? "row" : "column"}
              alignItems={isDesktop ? "center" : "center"}
              marginRight={{ xs: "0.5rem", sm: "1rem" }}
              gap={isDesktop ? 0 : generalSpacing}
            >
              <Typography variant="h2" flex={3}>
                {CONFIG_CTO.USERS}
              </Typography>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                alignContent={"center"}
                gap={generalSpacing}
                marginBottom={generalSpacing}
              >
                <Button
                  className="btn-base"
                  onClick={() => {
                    setSidePanelContent("userForm");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    size="lg"
                    className={isDesktop ? "mr-2" : "mr-0"}
                  />
                  {isDesktop && "Ajouter"}
                </Button>
                {organizationCenters && organizationCenters.length > 1 && (
                  <Button
                    className="btn-base"
                    onClick={() => {
                      setSidePanelContent("organizationUsers");
                    }}
                  >
                    {isDesktop ? "Ajouter depuis un autre centre" : "Importer"}
                  </Button>
                )}
              </Stack>
            </Stack>
            <ListComponent
              tableColumns={tableColumns}
              data={mapDataToRows(allUsersList)}
              selectableRow
              selectedActions={(selectedIds: (string | number)[]) => (
                <div className="flex gap-2">
                  {organizationCenters && organizationCenters.length > 1 && (
                    <Button
                      className="btn-base"
                      onClick={() => {
                        setRequestState({
                          type: "remove",
                          showModal: true,
                          loading: false,
                          items: selectedIds,
                        });
                      }}
                    >
                      Retirer du centre
                    </Button>
                  )}
                  <Button
                    className="btn-base btn-danger"
                    onClick={() =>
                      setRequestState({
                        type: "delete",
                        showModal: true,
                        loading: false,
                        items: selectedIds,
                      })
                    }
                  >
                    Supprimer
                  </Button>
                </div>
              )}
              filterItems={filterItems}
              filterModel={filterModel}
              disableColumnSorting={false}
              setFilterModel={setFilterModel}
              onRowClick={handleRowClick}
              paperStyle={{
                maxHeight: "69vh",
              }}
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default UsersList;
