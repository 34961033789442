import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";

import { RootState } from "../../../store/store";
import { UserInfoModel } from "../../../models/UserModel";

import { generalSpacing } from "../../../utils/customTheme/customTheme";

import UserPage from "./UserPage";
import UsersList from "./UsersList";

const Users = () => {
  const [selectedUser, setSelectedUser] = useState<null | UserInfoModel>(null);

  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  useEffect(() => {
    // Reset when changing current center to display <UsersList> if <UserPage> is displayed (to avoid update user who isn't in the current center)
    setSelectedUser(null);
  }, [currentCenter]);

  return (
    <Stack marginTop={{ xs: generalSpacing, xl: 0 }}>
      {selectedUser ? (
        <UserPage user={selectedUser} setSelectedUser={setSelectedUser} />
      ) : (
        <UsersList setSelectedUser={setSelectedUser} />
      )}
    </Stack>
  );
};

export default Users;
