import { createSlice } from "@reduxjs/toolkit";
import { PartnerModel } from "../../models/CenterSettingsModel";
import { AppDispatch } from "../store";
import { CONFIG_API } from "../../data/config.API";
import { api } from "../../utils/api";
import { AxiosResponse } from "axios";
import { APIResponsesModel } from "../../models/ApiResponseModel";

export interface InitialStateModel {
  allPartnersList: PartnerModel[] | undefined;
  error: string | null;
}

const initialState: InitialStateModel = {
  allPartnersList: undefined,
  error: null,
};

// SLICE
export const partnerSlice = createSlice({
  name: "partnerSlice",
  initialState: initialState,
  reducers: {
    setAllPartnersList: (currentSlice, action) => {
      currentSlice.allPartnersList = action.payload;
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload;
    },
  },
});

export const { setAllPartnersList, setError } = partnerSlice.actions;

// ACTIONS
export const fetchAllPartners = () => async (dispatch: AppDispatch) => {
  try {
    await api
      .get(`${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.PARTNER}`)
      .then((response: AxiosResponse<APIResponsesModel<PartnerModel[]>>) => {
        if (response.data.success) {
          dispatch(setAllPartnersList(response.data.items));
        } else {
          dispatch(
            setError(
              "Une erreur est survenue lors de la récupération générale des partenaires.",
            ),
          );
        }
      });
  } catch (error: unknown) {
    if (error instanceof Error) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError("Une erreur s'est produite."));
    }
  }
};
