import * as React from "react";
import { Menu, MenuList, Stack } from "@mui/material";

import BasicMenuItem, { MenuItemModel } from "./BasicMenuItem";
import { Button, ButtonSizes } from "flowbite-react";
import { DynamicStringEnumKeysOf } from "flowbite-react/dist/types/types";

type originPosition = {
  vertical: number | "bottom" | "center" | "top";
  horizontal: number | "left" | "center" | "right";
};

type Props = {
  children: React.ReactNode;
  items: MenuItemModel[];
  className: string;
  buttonSize: DynamicStringEnumKeysOf<ButtonSizes>;
  beforeText?: React.JSX.Element;
  afterText?: React.JSX.Element;
  title?: string;
  anchorOrigin?: originPosition;
  transformOrigin?: originPosition;
  height?: string;
  slotProps?: object;
};

const BasicMenu = ({
  children,
  items,
  beforeText,
  afterText,
  height,
  slotProps,
  title,
  className,
  buttonSize,
  anchorOrigin = { vertical: "bottom", horizontal: "center" },
  transformOrigin = { vertical: "top", horizontal: "center" },
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        title={title}
        size={buttonSize}
        className={className}
        onClick={handleClick}
      >
        {children}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        slotProps={slotProps}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Stack sx={{ backgroundColor: "transparent", height: height }} />
        {beforeText && <Stack mt={"-0.5rem"}>{beforeText}</Stack>}
        <MenuList sx={{ padding: 0, backgroundColor: "white" }}>
          {items.map((item: MenuItemModel) => (
            <BasicMenuItem
              key={items.indexOf(item)}
              icon={item.icon}
              text={item.text}
              navigateTo={item.navigateTo}
              locationState={item.locationState}
              onClick={item.onClick}
            />
          ))}
        </MenuList>
        {afterText && <Stack p={"0.5rem 0.5rem 0"}>{afterText}</Stack>}
      </Menu>
    </>
  );
};

export default BasicMenu;
