import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";
import {
  APIResponseModel,
  APIResponsesModel,
} from "../../models/ApiResponseModel";
import { MessageModel, NewsModel } from "../../models/NewsModel";

export interface InitialStateModel {
  newsList: NewsModel[] | undefined;
  messagesList: MessageModel[] | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: InitialStateModel = {
  newsList: undefined,
  messagesList: undefined,
  loading: true,
  error: null,
};

// SLICE
export const newsSlice = createSlice({
  name: "newsSlice",
  initialState: initialState,
  reducers: {
    setMessages: (currentSlice, action) => {
      currentSlice.messagesList = action.payload;
    },
    updateMessage: (currentSlice, action) => {
      let messageToUpdate = currentSlice.messagesList?.find(
        (element) => element.id === action.payload.id,
      );
      if (messageToUpdate) {
        messageToUpdate = action.payload;
      }
    },
    setNews: (currentSlice, action) => {
      currentSlice.newsList = action.payload;
    },
    setLoading: (currentSlice, action) => {
      currentSlice.loading = action.payload;
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload;
    },
  },
});

export const { setMessages, updateMessage, setNews, setLoading, setError } =
  newsSlice.actions;

// ACTIONS
export const fetchAllNews =
  (organizationPid: string, accountType: string, externalUserId: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      // Fetch the last 3 news
      await api
        .get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.NEWS}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.EXTERNAL_USER_ID}/${externalUserId}?Ordering=Descending&PageSize=3`,
        )
        .then((response: AxiosResponse<APIResponsesModel<NewsModel>>) => {
          dispatch(setNews(response.data.items));
        });
      // Date from a month ago
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      // Fetch the messages of the last month
      await api
        .get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.MESSAGE}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.EXTERNAL_USER_ID}/${externalUserId}?Ordering=Descending&DateFrom=${oneMonthAgo.toISOString()}`,
        )
        .then((response: AxiosResponse<APIResponsesModel<MessageModel>>) => {
          dispatch(setMessages(response.data.items));
        });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const hideMessage =
  (
    organizationPid: string,
    accountType: string,
    externalUserId: string,
    messageID: number,
    isMessageHidden: boolean,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      await api
        .put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.MESSAGE}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.EXTERNAL_USER_ID}/${externalUserId}/${messageID}`,
          { hideMessage: isMessageHidden },
        )
        .then((response: AxiosResponse<APIResponseModel<MessageModel>>) => {
          dispatch(updateMessage(response.data.item));
          dispatch(fetchAllNews(organizationPid, accountType, externalUserId));
        });
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const updateReadStatus =
  (
    organizationPid: string,
    accountType: string,
    externalUserId: string,
    elementID: number,
    type: "news" | "message",
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      if (type === "news") {
        await api.put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.NEWS}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.EXTERNAL_USER_ID}/${externalUserId}/${elementID}`,
        );
      } else if (type === "message") {
        await api.put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.MESSAGE}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.EXTERNAL_USER_ID}/${externalUserId}/${elementID}`,
          { isMessageRead: true },
        );
      }
    } catch (error) {
      dispatch(setError(error));
    }
  };
