import { createTheme } from "@mui/material";
import React from "react";

// Add custom variables
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    "2xl": true;
  }
  interface Palette {
    customBackground: Palette["primary"];
  }
  interface PaletteOptions {
    customBackground?: PaletteOptions["primary"];
  }
  interface TypographyVariants {
    link: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    link?: React.CSSProperties;
  }
}

// Active custom typography variant
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    link: true;
  }
}

export const colors = createTheme({
  palette: {
    primary: { main: "#1C64F2", light: "#e8e3eb", dark: "#1C64F2" },
    secondary: { main: "#3F83F8", light: "#9CA3AF" },
    customBackground: { main: "#f5f5f5", light: "#f8f6fA" },
    error: { main: "#c62828", light: "#ff5f52", dark: "#8e0000" },
    warning: { main: "#F05252", light: "#f08469", dark: "#d13b14" },
    success: { main: "#20998e", light: "#26b7aa", dark: "#197a71" },
  },
});
export const fonts = {
  primary: "Inter",
  secondary: "Inter",
};
export const generalRadius = "5px";
export const generalLetterSpacing = "2px";
export const generalSpacing = "1rem";
export const containerBorder = `0.1rem solid ${colors.palette.primary.light}`;

export const MUIXTheme = {
  datePicker: {
    slotsProps: {
      toolbar: {
        hidden: true,
      },
      actionBar: {
        actions: [],
      },
      day: {
        sx: {
          fontSize: "1rem",
          fontFamily: fonts.secondary,
          "&:hover": {
            backgroundColor: colors.palette.primary.light,
          },
        },
      },
      calendarHeader: {
        sx: {
          ".MuiPickersCalendarHeader-label": {
            fontFamily: fonts.secondary,
            fontSize: "1.1rem",
          },
        },
      },
    },
  },
  timePicker: {
    defaultProps: {
      skipDisabled: true, // Remove before minHour & after maxHour options
      closeOnSelect: true,
      minutesStep: 5,
    },
    slotsProps: {
      toolbar: {
        hidden: true,
      },
      desktopPaper: {
        sx: {
          "& .MuiMenuItem-root": {
            fontFamily: fonts.secondary,
          },
        },
      },
      digitalClockSectionItem: {
        sx: {
          "&:hover": {
            overflowY: "hidden", // Remove moving on hover
          },
          padding: 0,
          margin: "auto",
          textAlign: "center",
          width: "36px",
          height: "36px",
          borderRadius: "50%",
        },
      },
      inputAdornment: { position: "start", sx: { marginRight: 0 } },
      actionBar: {
        actions: [],
      },
    },
  },
  dataGrid: {
    defaultProps: {
      rowHeight: 60,
      disableColumnReorder: true,
      disableRowSelectionOnClick: true,
      disableAggregation: true,
      disableColumnPinning: true,
      disableColumnMenu: true,
    },
    sx: {
      borderRadius: generalRadius,
      border: "none",
      boxShadow: "none",
      fontFamily: fonts.secondary,
      "& .MuiDataGrid-topContainer::after": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader": {
        backgroundColor: colors.palette.customBackground.light,
        letterSpacing: generalLetterSpacing,
        color: colors.palette.secondary.light,
        textTransform: "uppercase",
        fontFamily: fonts.primary,
        fontWeight: 500,
        fontSize: "11px",
        borderBottom: "none",
        height: "2.8rem !important",
      },
      "& .MuiDataGrid-filler, .MuiDataGrid-scrollbarFiller": {
        backgroundColor: colors.palette.customBackground.light,
        borderBottom: "none",
      },
      "& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within":
        {
          outline: "none",
        },
      "& .MuiDataGrid-row": {
        "&:nth-of-type(odd)": {
          backgroundColor: "white",
        },
        "&:nth-of-type(even)": {
          backgroundColor: colors.palette.customBackground.light,
        },
        "&:hover": {
          backgroundColor: `${colors.palette.primary.light} !important`,
        },
      },
      "& .MuiDataGrid-filler": {
        display: "none",
      },
      "& .MuiDataGrid-footerContainer": {
        fontWeight: "800",
        fontSize: "16px",
        padding: "0 1rem",
        display: "flex",
        justifyContent: "end",
        "& .MuiDataGrid-selectedRowCount": {
          display: "none !important",
        },
      },
    },
  },
};
const customTheme = createTheme({
  palette: {
    primary: colors.palette.primary,
    secondary: colors.palette.secondary,
    customBackground: colors.palette.customBackground,
    error: colors.palette.error,
    warning: colors.palette.warning,
    success: colors.palette.success,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      "2xl": 2000,
    },
  },
  typography: {
    fontFamily: [fonts.primary, fonts.secondary, "serif"].join(","),
    link: {
      textDecoration: "underline",
      cursor: "pointer",
    },
    h1: {
      fontSize: "36px",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "30px",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "24px",
      fontWeight: "bold",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: "secondary",
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: fonts.primary,
          fontWeight: "bold",
          textTransform: "none",
          borderRadius: generalRadius,
          height: "40px",
          minWidth: "1rem",
          "&:hover": {
            boxShadow:
              "0 1px 3px 1px rgba(66,64,67,.15), 0 1px 2px 0 rgba(60,64,67,.3)",
          },
        },
        contained: {
          backgroundColor: colors.palette.secondary.main,
          "&:hover": {
            backgroundColor: "rgb(61, 25, 140)",
          },
        },
        outlined: {
          fontFamily: fonts.primary,
          fontWeight: "bold",
          textTransform: "none",
          color: colors.palette.secondary.main,
          height: "40px",
          border: `1px solid ${colors.palette.primary.light}`,
          backgroundColor: "white",
          "&:hover": {
            border: `1px solid ${colors.palette.primary.light}`,
          },
        },
        text: {
          "&:hover": {
            backgroundColor: `rgba(${colors.palette.secondary.main},0.04)`,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          display: "none",
        },
        root: {
          fontFamily: fonts.secondary,
          fontSize: "14px",
        },
        shrink: {
          transform: "translate(14px, -4px)",
          width: "auto",
          backgroundColor: "white !important",
          letterSpacing: generalLetterSpacing,
          textTransform: "uppercase",
          fontFamily: fonts.primary,
          color: "#6b7280 !important",
          fontWeight: 500,
          fontSize: "12px",
          padding: "0 0.2rem 0 0.2rem",
        },
        outlined: {
          color: colors.palette.secondary.light,
          top: "16px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderColor: colors.palette.primary.light,
          border: `1px solid ${colors.palette.primary.light}`,
          outline: "none",
          borderRadius: generalRadius,
          height: "45px",
          marginTop: "1.2rem",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        },
        multiline: {
          marginTop: "0",
          height: "auto",
          backgroundColor: "white",
          padding: "0.7rem",
          fontFamily: fonts.secondary,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "15px",
          margin: "0.1rem 0 0 -0.25rem",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          transform: "translateX(12px)",
          "&.Mui-checked .MuiSwitch-thumb": {
            transform: "translateX(4px)",
          },
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: colors.palette.secondary.main, // Track color when checked
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        thumb: {
          color: "white", // Thumb color when unchecked
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          position: "absolute",
          top: 16,
        },
        track: {
          backgroundColor: colors.palette.primary.light, // Track color when unchecked
          borderRadius: 15,
          width: 30,
          height: 20,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          minWidth: "8rem",
          fontFamily: fonts.primary,
          color: colors.palette.secondary.main,
          fontWeight: "bold",
          fontSize: "14px",
          textTransform: "none",
          maxHeight: "1.1rem",
          borderRadius: generalRadius,
          padding: "20px",
          border: `1px solid ${colors.palette.primary.light}`,
          backgroundColor: "white",
          "&.Mui-selected": {
            color: "white",
            border: `1px solid ${colors.palette.secondary.main}`,
            backgroundColor: colors.palette.secondary.main,
            "&:hover": {
              backgroundColor: colors.palette.secondary.main,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: colors.palette.secondary.main,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          ".MuiAlert-message": {
            fontFamily: fonts.secondary,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "white",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ".MuiChip-label": {
            fontSize: "14px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiAutocomplete-input": {
            paddingTop: "0.5px !important",
          },
        },
        listbox: {
          padding: 0,
        },
        option: {
          fontFamily: fonts.secondary,
          fontSize: "15px",
          "&:hover": {
            backgroundColor: `${colors.palette.primary.light} !important`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: fonts.secondary,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: `${colors.palette.primary.light} !important`,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: generalRadius,
          boxShadow: "none",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-stickyHeader": {
            height: "1rem",
            backgroundColor: colors.palette.customBackground.light,
            letterSpacing: generalLetterSpacing,
            color: colors.palette.secondary.light,
            textTransform: "uppercase",
            fontFamily: fonts.primary,
            fontWeight: 500,
            fontSize: "11px",
            borderBottom: "none",
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          ".MuiTableRow-hover": {
            "&:hover": {
              backgroundColor: `${colors.palette.primary.light} !important`,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: "3.5rem",
          padding: "0.5rem",
          ".MuiTypography-root": {
            fontSize: "0.9rem",
            fontFamily: fonts.secondary,
          },
        },
      },
    },
  },
});

export default customTheme;
