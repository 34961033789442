import { Stack, Typography } from "@mui/material";
import { Spinner } from "flowbite-react";

interface Props {
  text: string;
}

const LoadingData = ({ text }: Props) => {
  return (
    <Stack height={"100vh"}>
      <Stack m="auto" gap={2} alignItems={"center"}>
        <Spinner />
        <Typography variant="h6">{text}</Typography>
      </Stack>
    </Stack>
  );
};

export default LoadingData;
