import { Badge } from "flowbite-react";

interface Props {
  inspectionResult?: number | "current";
  size?: "xs" | "sm";
}

const InspectionResultChip = ({ inspectionResult, size = "xs" }: Props) => {
  return inspectionResult === 0 || !inspectionResult ? (
    <Badge size={size} className={`bg-gray-50 text-gray-500 font-medium`}>
      Inconnu
    </Badge>
  ) : inspectionResult === 1 ? (
    <Badge size={size} className={`bg-green-50 text-green-500 font-medium`}>
      Favorable
    </Badge>
  ) : inspectionResult === "current" ? (
    <Badge size={size} className={`bg-blue-50 text-blue-500 font-medium`}>
      En cours
    </Badge>
  ) : (
    <Badge size={size} className={`bg-red-50 text-red-500 font-medium`}>
      Défavorable
    </Badge>
  );
};

export default InspectionResultChip;
