import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InfoItem from "./InfoItem";

export type InfoItemModel = {
  label: string;
  value: string | JSX.Element | boolean | undefined;
  name?: string;
  fullWidth?: boolean;
  isTitle?: boolean;
  afterText?: string;
  hide?: boolean;
  category?: string;
};

interface Props {
  title: string;
  infoItems: InfoItemModel[];
  afterItemsContent?: JSX.Element;
}

const InfoGrid = ({ title, infoItems, afterItemsContent }: Props) => {
  return (
    <div className="flex flex-col sm:flex-row">
      <div className="flex flex-col gap-4 flex-1">
        <div className="p-4 gap-4 flex flex-col">
          <h3 className="text-2xl font-bold ">{title}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 border border-gray-300 rounded-md p-4">
            {infoItems.map(
              (infoItem) =>
                infoItem.value !== undefined &&
                infoItem.value !== "" &&
                !infoItem.hide && (
                  <div
                    key={infoItem.label}
                    className={`${infoItem.fullWidth ? "col-span-2" : "col-span-1"}`}
                  >
                    {typeof infoItem.value === "boolean" ? (
                      <div className="flex flex-row items-center gap-2">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="sm"
                          className="text-green-500"
                        />
                        <p className="font-medium">
                          {infoItem.label}
                          {infoItem.afterText && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ` ${infoItem.afterText}`,
                              }}
                            />
                          )}
                        </p>
                      </div>
                    ) : infoItem.isTitle ? (
                      <p className="font-bold">{infoItem.value}</p>
                    ) : (
                      <InfoItem
                        title={infoItem.label}
                        afterText={infoItem.afterText}
                      >
                        {infoItem.value}
                      </InfoItem>
                    )}
                  </div>
                ),
            )}

            {afterItemsContent && (
              <div className="col-span-2">{afterItemsContent}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoGrid;
