// Checks if the HTML content has any real text (ignores empty tags, whitespace, and &nbsp;)
export const isTextContentEmpty = (htmlContent: string): boolean => {
  // Replace &nbsp; with spaces, remove all HTML tags, and trim whitespace
  const textContent = htmlContent
    .replace(/&nbsp;/g, " ")
    .replace(/<[^>]*>/g, "")
    .trim();
  // Return true if there is no real text content
  return textContent === "";
};
