import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";

import { APIResponseModel } from "../../../models/ApiResponseModel";
import { api } from "../../../utils/api";
import { CONFIG_API } from "../../../data/config.API";
import { displayError } from "../../../utils/layout/displayError";
import { AppDispatch, RootState } from "../../../store/store";
import { setAlert } from "../../../store/layout/alert-slice";
import { isTextContentEmpty } from "../../../utils/layout/isTextContentEmpty";

import LoadingData from "../../../components/common/LoadingData";
import ErrorLoadingData from "../../../components/common/ErrorLoadingData";
import TextEditor from "../../../components/common/TextEditor";

export interface CGVModel {
  centerPid: string;
  content: string;
  isEnabled: boolean;
}

const CenterCGV = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const [cgvContent, setCGVContent] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string | null>(
    null,
  );

  const fetchContent = async () => {
    setIsLoading(true);
    setFetchErrorMessage(null);
    try {
      const response: AxiosResponse<APIResponseModel<CGVModel>> = await api.get(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/CGV`,
      );
      if (response) {
        if (response.data.success) {
          setCGVContent(response.data.item.content);
        } else {
          setFetchErrorMessage(displayError(response.data.messages[0]));
        }
      }
    } catch (error) {
      setFetchErrorMessage("Erreur lors de la récupération des CGV");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setRequestLoading(true);
    const isContentTextEmpty = cgvContent
      ? isTextContentEmpty(cgvContent)
      : true;
    try {
      const response: AxiosResponse<APIResponseModel<CGVModel>> = await api.put(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/CGV`,
        {
          centerPid: currentCenter?.pid,
          content: !isContentTextEmpty ? cgvContent : null,
          isEnabled: true,
        },
      );
      if (response.data.success) {
        // Checks if content is empty (not only tags), if only tags save
        setCGVContent(!isContentTextEmpty ? cgvContent : null);
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message: "Les modifications ont bien été enregistrées.",
          }),
        );
      } else {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "failure",
            message: displayError(response.data.messages[0]),
          }),
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "failure",
          message: "Une erreur est survenue lors de la modification.",
        }),
      );
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, [currentCenter]);

  return (
    <>
      {fetchErrorMessage ? (
        <ErrorLoadingData
          errorMessage={fetchErrorMessage}
          retryFunction={fetchContent}
        />
      ) : isLoading ? (
        <LoadingData text={"Chargement des CGV"} />
      ) : (
        <div className="w-full flex justify-center items-start p-4">
          <div className="w-[100%] lg:w-[50%] h-auto flex flex-col justify-start items-start gap-6">
            <div className="text-gray-900 text-3xl font-bold font-['Inter'] leading-loose">
              Paramétrage CGV
            </div>
            <div className="w-[80%] text-gray-900 text-md font-medium font-['Inter'] mb-2">
              {
                "Si votre centre dispose de conditions générales, veuillez les indiquer ici. Elles seront automatiquement présentées à vos clients, qui devront les accepter avant d'accéder à votre planning de réservations."
              }
            </div>
            <TextEditor
              editorContent={cgvContent}
              setEditorContent={setCGVContent}
              onSave={handleSave}
              requestLoading={requestLoading}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CenterCGV;
