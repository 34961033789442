import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { UseFormRegister } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

import { RootState } from "../../../store/store";
import { CONFIG_CTO } from "../../../data/config.CTO";
import { CONFIG_API } from "../../../data/config.API";
import { UserInfoModel } from "../../../models/UserModel";
import { colors, generalSpacing } from "../../../utils/customTheme/customTheme";

interface Props {
  user?: UserInfoModel;
  editMode: boolean;
  register?: UseFormRegister<UserInfoModel>;
}

interface UserAccessModel {
  name: keyof UserInfoModel;
  label: string;
  value: boolean;
  category:
    | "supervise"
    | "profession"
    | "data"
    | "parameters"
    | "administration";
  hide?: boolean;
}

const UserAccess = ({ user, editMode, register }: Props) => {
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const userAccess: UserAccessModel[] = [
    {
      name: "hasDashboardAccess",
      label: "Dashboard",
      value: user?.hasDashboardAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasSalesSummaryAccess",
      label: "Gestion - Journal des ventes",
      value: user?.hasSalesSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasCashinSummaryAccess",
      label: "Gestion - Journal des encaissements",
      value: user?.hasCashinSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasBankTransferSummaryAccess",
      label: "Gestion - Bordereau de remise en banque",
      value: user?.hasBankTransferSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasCustomerInvoiceSummaryAccess",
      label: "Gestion - Relevé de facture client",
      value: user?.hasCustomerInvoiceSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasServicesSummaryAccess",
      label: "Gestion - Journal des prestations",
      value: user?.hasServicesSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasActivitySummaryAccess",
      label: "Activité - Synthèse d'activité",
      value: user?.hasActivitySummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasSmsSummaryAccess",
      label: "Activité - Journal des SMS",
      value: user?.hasSmsSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasRelaunchSummaryAccess",
      label: "Activité - Journal des relances",
      value: user?.hasRelaunchSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasMctSummaryAccess",
      label: "Activité - Journal moncontroletechnique.fr",
      value: user?.hasMctSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasAppointmentSummaryAccess",
      label: "Activité - Journal des rendez-vous",
      value: user?.hasAppointmentSummaryAccess ?? false,
      category: "supervise",
    },
    {
      name: "hasRelaunchAccess",
      label: "Relances",
      value: user?.hasRelaunchAccess ?? false,
      category: "profession",
      hide: !currentCenter?.isRelaunchEnabled,
    },
    {
      name: "hasCustomerSettingsAccess",
      label: "Clients - Gestion",
      value: user?.hasCustomerSettingsAccess ?? false,
      category: "data",
    },
    {
      name: "hasPriceSettingsAccess",
      label: "Clients - Tarifs",
      value: user?.hasPriceSettingsAccess ?? false,
      category: "data",
    },
    {
      name: "hasDocumentAccess",
      label: "Documents",
      value: user?.hasDocumentAccess ?? false,
      category: "data",
    },
    {
      name: "hasExportDataAccess",
      label: "Export",
      value: user?.hasExportDataAccess ?? false,
      category: "data",
    },
    {
      name: "hasCenterSettingsAccess",
      label: "Centre (planning, prestations & marketing)",
      value: user?.hasCenterSettingsAccess ?? false,
      category: "parameters",
    },
    {
      name: "hasPlanningSettingsAccess",
      label: "Planning - Fermetures",
      value: user?.hasPlanningSettingsAccess ?? false,
      category: "parameters",
    },
    {
      name: "hasInvoiceAccess",
      label: "Factures Protechnologies",
      value: user?.hasInvoiceAccess ?? false,
      category: "administration",
    },
  ];

  const renderAccessSection = (category: string, menuLabel: string) => {
    // Filter all access from a given category
    const currentAccessList = userAccess
      .filter((access) => access.category === category && !access.hide)
      .map((item) => {
        if (editMode) {
          return (
            <FormControlLabel
              key={item.name}
              label={item.label}
              control={
                <Checkbox
                  {...(register ? register(item.name) : {})}
                  defaultChecked={item.value}
                  onClick={(e) => {
                    if (!editMode) {
                      e.preventDefault(); // Avoid checkbox update in case of readOnly mode
                    }
                  }}
                  onChange={(e) => {
                    if (editMode) {
                      item.value = e.target.checked;
                    }
                  }}
                  disableRipple={!editMode}
                  sx={{
                    cursor: editMode ? "pointer" : "initial",
                  }}
                />
              }
              sx={{
                cursor: editMode ? "pointer" : "initial",
              }}
            />
          );
        } else if (
          !editMode &&
          (item.value || user?.accountType === CONFIG_API.ADMIN)
        ) {
          return (
            <Stack
              key={item.name}
              flexDirection={"row"}
              alignItems={"center"}
              gap={"0.5rem"}
            >
              <FontAwesomeIcon
                icon={faCircleCheck}
                size={"sm"}
                color={colors.palette.success.main}
              />
              <Typography key={item.name}>{item.label}</Typography>
            </Stack>
          );
        } else {
          return null;
        }
      });

    return currentAccessList.length > 0 &&
      !currentAccessList.every((item) => item === null) ? (
      <Stack>
        <p className="text-gray-500 text-left tracking-wide leading-[15px] text-sm font-medium mb-1">
          {menuLabel.toUpperCase()}
        </p>
        {currentAccessList}
      </Stack>
    ) : (
      <></>
    );
  };

  return (
    <Stack
      gap={generalSpacing}
      flexDirection={"column"}
      justifyContent={!editMode ? "center" : "initial"}
    >
      {userAccess.some(
        (access) => access.value || user?.accountType === CONFIG_API.ADMIN,
      ) || editMode ? (
        <>
          {renderAccessSection("supervise", CONFIG_CTO.SUPERVISE_MENU)}
          {renderAccessSection("profession", CONFIG_CTO.PROFESSION_MENU)}
          {renderAccessSection("data", CONFIG_CTO.DATA_MENU)}
          {renderAccessSection("parameters", CONFIG_CTO.PARAMETERS_MENU)}
          {renderAccessSection(
            "administration",
            CONFIG_CTO.ADMINISTRATION.toLocaleUpperCase(),
          )}
        </>
      ) : (
        <Typography>{"Cet utilisateur n'a aucun droit."}</Typography>
      )}
    </Stack>
  );
};

export default UserAccess;
