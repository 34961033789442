import { Badge } from "flowbite-react";
import { cva } from "class-variance-authority";

import { FlowbiteColors } from "../../../models/CenterModel";

import CustomIcon from "../CustomIcon";
import ActivitiesIcons from "../../common/ActivitiesIcons";

interface Props {
  centerName: string;
  centerColor: FlowbiteColors;
  centerActivities: string[];
  isCurrentCenter: boolean;
  isChangeable: boolean;
  isItem?: boolean;
  size?: "sm" | "lg";
}

const CenterItem = ({
  centerName,
  centerColor,
  centerActivities,
  isCurrentCenter,
  isChangeable,
  size = "sm",
  isItem = true,
}: Props) => {
  // Using cva to prevent deleting useless classes by Tailwind
  const backgroundStyles = cva("", {
    variants: {
      color: {
        gray: "bg-gray-50",
        orange: "bg-orange-50",
        red: "bg-red-50",
        green: "bg-green-50",
        yellow: "bg-yellow-50",
        blue: "bg-blue-50",
        teal: "bg-teal-50",
        indigo: "bg-indigo-50",
        purple: "bg-purple-50",
        pink: "bg-pink-50",
      },
    },
  });

  const borderStyles = cva("", {
    variants: {
      color: {
        gray: "border-gray-500",
        orange: "border-orange-500",
        red: "border-red-500",
        green: "border-green-500",
        yellow: "border-yellow-500",
        blue: "border-blue-500",
        teal: "border-teal-500",
        indigo: "border-indigo-500",
        purple: "border-purple-500",
        pink: "border-pink-500",
      },
    },
  });

  const textStyles = cva("", {
    variants: {
      color: {
        gray: "text-gray-500",
        orange: "text-orange-500",
        red: "text-red-500",
        green: "text-green-500",
        yellow: "text-yellow-500",
        blue: "text-blue-500",
        teal: "text-teal-500",
        indigo: "text-indigo-500",
        purple: "text-purple-500",
        pink: "text-pink-500",
      },
    },
  });

  return (
    <div
      className={`w-full  ${isItem ? "h-12" : "h-10"} px-3 py-2.5 ${backgroundStyles({ color: centerColor })} rounded-md ${isCurrentCenter && `border ${borderStyles({ color: centerColor })}`} items-center flex ${isChangeable || isItem ? `cursor-pointer hover:border border-${centerColor}-500` : "cursor-auto"}`}
    >
      <div
        className={`w-full ${textStyles({ color: centerColor })} ${size === "sm" ? "text-sm" : "text-xl"} font-bold font-['Inter'] leading-tight align-middle items-center inline-flex gap-1 ${size === "lg" && "gap-2"} ${centerActivities.length < 3 ? "sm:gap-4" : "sm:gap-1"}`}
      >
        <div
          className={`${size === "sm" && "max-sm:hidden max-sm:gap-0"} inline-flex justify-start items-center`}
        >
          <ActivitiesIcons activities={centerActivities} size={size} />
        </div>
        <div
          className={`${isItem && isCurrentCenter && "w-[64%]"} text-start overflow-hidden text-ellipsis text-nowrap`}
        >
          {centerName}
        </div>
        {isChangeable && <CustomIcon name="chevronOutline" />}
        {isCurrentCenter && isItem && (
          <Badge className="h-5 px-2.5 py-0.5 bg-white rounded-md justify-center items-center inline-flex">
            <div
              className={`text-xs font-medium font-['Inter'] leading-none text-center ${textStyles({ color: centerColor })}`}
            >
              Centre affiché
            </div>
          </Badge>
        )}
      </div>
    </div>
  );
};

export default CenterItem;
