import "./index.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import customTheme from "./utils/customTheme/customTheme";
import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { LicenseInfo } from "@mui/x-license";

import { PCA } from "./utils/msal/msal";
import { persistor, store } from "./store/store";

import App from "./App";
import Layout from "./components/layouts/Layout";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_KEY ?? "");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <ThemeProvider theme={customTheme}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Layout msalInstance={PCA}>
            <App />
          </Layout>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </ThemeProvider>,
);
