import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Button } from "flowbite-react";

import { AppDispatch, persistor } from "../../store/store";
import { resetOrganizationSlice } from "../../store/organization/organization-slice";

const LogoutButton = () => {
  const { instance } = useMsal();

  const dispatch: AppDispatch = useDispatch();

  const handleLogout = async () => {
    try {
      // Clear all data from localStorage
      localStorage.clear();
      // Attempt to purge the persisted state
      await persistor.purge();
      dispatch(resetOrganizationSlice());

      // Logout from Azure Active Directory instance
      await instance.logout({
        authority: process.env.REACT_APP_AZURE_BASE_URL,
        postLogoutRedirectUri: `/`,
      });
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  return (
    <Button className="btn-base m-auto w-auto" onClick={handleLogout}>
      Se déconnecter
    </Button>
  );
};

export default LogoutButton;
