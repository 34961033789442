import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { Alert, Button, Spinner } from "flowbite-react";
import {
  Box,
  FormControl,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

import { APIResponseModel } from "../../../models/ApiResponseModel";
import { api } from "../../../utils/api";
import { CONFIG_API } from "../../../data/config.API";
import { AppDispatch, RootState } from "../../../store/store";
import { generalSpacing } from "../../../utils/customTheme/customTheme";
import { displayError } from "../../../utils/layout/displayError";
import { AccountType } from "../../../models/UserModel";
import { setAlert } from "../../../store/layout/alert-slice";
import generateSignature from "../../../utils/authorization/generateSignature";

import ErrorLoadingData from "../../common/ErrorLoadingData";
import InfoItem from "../../layouts/InfoItem";

interface Props {
  externalUserId: string | null;
  setExternalUserId: React.Dispatch<React.SetStateAction<string | null>>;
  accountType: AccountType;
  login: string;
  userPid: string;
}

interface AzureUserModel {
  organizations: [];
  id: string;
  givenName: string;
  displayName: string;
  surname: string;
  mailNickName: string;
  mail: string;
}

type Inputs = {
  mail: string;
};

const CTonlineConnectForm = ({
  externalUserId,
  accountType,
  login,
  userPid,
  setExternalUserId,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const [azureInfo, setAzureInfo] =
    useState<{ label: string; value: string }[]>();
  const [errorState, setErrorState] = useState<{
    loadingData: string | null;
    action: string | null;
  }>({ loadingData: null, action: null });
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [isEmailAlreadySent, setIsEmailAlreadySent] = useState<boolean>(false);

  const currentOrganization = useSelector(
    (store: RootState) => store.ORGANIZATION.currentOrganization,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData, event) => {
    const action = (event?.nativeEvent as SubmitEvent).submitter?.getAttribute(
      "data-action",
    ) as "link-account" | "create-account";
    if (action === "link-account") {
      linkAccount(formData);
    } else if (action === "create-account") {
      sendingAccountCreatingMail(formData);
    }
  };

  const fetchAzureInfo = async () => {
    setErrorState({ loadingData: null, action: null });
    if (externalUserId) {
      try {
        const response: AxiosResponse<APIResponseModel<AzureUserModel>> =
          await api.get(
            `/${CONFIG_API.CTONLINE_CONNECT}/${CONFIG_API.USER}/${externalUserId}`,
          );
        if (response.data.success) {
          setAzureInfo([
            { label: "Nom", value: response.data.item.givenName },
            { label: "Prénom", value: response.data.item.surname },
            { label: "Email", value: response.data.item.mail },
          ]);
        } else {
          setErrorState({
            ...errorState,
            loadingData: displayError(response.data.messages[0]),
          });
        }
      } catch (error) {
        setErrorState({
          ...errorState,
          loadingData: "Une erreur est survenue.",
        });
      }
    }
  };

  const unlinkAccount = async () => {
    setLoadingAction(true);
    setErrorState({ loadingData: null, action: null });
    try {
      const response: AxiosResponse<APIResponseModel<boolean>> = await api.put(
        `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentOrganization?.pid}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.EXTERNAL_ACCOUNT}`,
        { login: login, externalUserId: externalUserId },
      );
      if (response.data.success) {
        // Remove externalUserId to hide this form and display the link form
        setExternalUserId(null);
      } else {
        setErrorState({
          ...errorState,
          action: displayError(response.data.messages[0]),
        });
      }
    } catch (error) {
      setErrorState({ ...errorState, action: "Le compte n'a pas été délié" });
    } finally {
      setLoadingAction(false);
    }
  };

  const linkAccount: SubmitHandler<Inputs> = async (formData) => {
    setLoadingAction(true);
    setErrorState({ loadingData: null, action: null });
    try {
      const userResponse: AxiosResponse<APIResponseModel<AzureUserModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE_CONNECT}/${CONFIG_API.USER}/${CONFIG_API.MAIL}/${formData.mail}`,
        );
      if (userResponse.data.success) {
        if (userResponse.data.item) {
          const linkResponse: AxiosResponse<APIResponseModel<boolean>> =
            await api.put(
              `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentOrganization?.pid}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.REGISTRER_ACCOUNT}`,
              {
                internalUserPid: userPid,
                externalUserId: userResponse.data.item.id,
              },
            );
          if (linkResponse.data.success && linkResponse.data.item) {
            setExternalUserId(userResponse.data.item.id);
            dispatch(
              setAlert({
                id: "global-alert",
                type: "success",
                message: "Le compte a bien été relié.",
              }),
            );
          } else {
            setErrorState({
              ...errorState,
              action: linkResponse.data.messages
                ? displayError(linkResponse.data.messages[0])
                : "Une erreur est survenue lors de la liaison.",
            });
          }
        } else {
          setErrorState({
            ...errorState,
            action: "Le compte n'a pas été retrouvé.",
          });
        }
      } else {
        setErrorState({
          ...errorState,
          action: displayError(userResponse.data.messages[0]),
        });
      }
    } catch (error) {
      setErrorState({
        ...errorState,
        action: "Une erreur est survenue lors de la liaison.",
      });
    } finally {
      setLoadingAction(false);
    }
  };

  const sendingAccountCreatingMail: SubmitHandler<Inputs> = async (
    formData,
  ) => {
    setLoadingAction(true);
    setErrorState({ loadingData: null, action: null });
    try {
      let numberAccountType = "";
      switch (accountType) {
        case "Admin":
          numberAccountType = "1";
          break;
        case "User":
          numberAccountType = "2";
          break;
        default:
          numberAccountType = "3";
          break;
      }
      const currentTimeStamp: number = Date.now();
      const signature = generateSignature([
        numberAccountType,
        currentOrganization?.code,
        currentOrganization?.isCTonlineLimited,
        false,
        login,
        currentTimeStamp,
      ]);
      const returnUrl = `/link-account?org_code=${currentOrganization?.code}&client_type=${accountType}&return_url=${process.env.REACT_APP_WEB_BASE_URL}&signature=${signature}&user_pid=${userPid}`;
      const response: AxiosResponse<APIResponseModel<boolean>> = await api.post(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentOrganization?.pid}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.INVITE}?returnUrl=${returnUrl}`,
        {
          email: formData.mail,
          internalAccountPid: userPid,
        },
      );
      if (response.data.success) {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message: `L'e-mail de création de compte a bien été ${isEmailAlreadySent ? "r" : ""}envoyé à ${formData.mail}.`,
          }),
        );
        setIsEmailAlreadySent(true);
      } else {
        setErrorState({
          ...errorState,
          action: displayError(response.data.messages[0]),
        });
      }
    } catch (error) {
      setErrorState({ ...errorState, action: "Une erreur est survenue." });
    } finally {
      setLoadingAction(false);
    }
  };

  useEffect(() => {
    fetchAzureInfo();
  }, []);

  return (
    <Stack>
      {externalUserId ? (
        errorState.loadingData ? (
          <Stack margin={"auto"}>
            <ErrorLoadingData
              errorMessage={errorState.loadingData}
              retryFunction={fetchAzureInfo}
              height="92vh"
            />
          </Stack>
        ) : azureInfo ? (
          <Stack gap={0.5}>
            <Typography fontWeight="bold">Informations</Typography>
            <Grid
              container
              gap={generalSpacing}
              padding="0"
              sx={{ width: "100%" }}
            >
              {azureInfo.map((data) => (
                <Grid key={data.label} xs={5} item>
                  <InfoItem title={data.label}>{data.value}</InfoItem>
                </Grid>
              ))}
            </Grid>
            <Stack
              sx={{
                width: "100%",
                position: "sticky",
                paddingY: 1,
                bottom: 0,
                gap: generalSpacing,
                backgroundColor: "white",
              }}
            >
              {errorState.action && (
                <Box>
                  <Alert color="failure">{errorState.action}</Alert>
                </Box>
              )}
              <Stack width="fit-content" flexDirection="row" gap={1}>
                <Button
                  type="submit"
                  className="btn-base"
                  disabled={loadingAction}
                  onClick={unlinkAccount}
                >
                  {loadingAction ? <Spinner size="sm" /> : "Délier le compte"}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack gap={generalSpacing}>
            <Skeleton width="8rem" height="2rem" />
            <Grid container gap={0} padding="0" sx={{ width: "100%" }}>
              <Grid xs={5} item>
                <Skeleton width="9rem" height="3rem" />
              </Grid>
              <Grid xs={5} item>
                <Skeleton width="9rem" height="3rem" />
              </Grid>
              <Grid xs={5} item>
                <Skeleton width="9rem" height="3rem" />
              </Grid>
            </Grid>
            <Stack
              sx={{
                width: "100%",
                position: "sticky",
                paddingY: 1,
                bottom: 0,
                gap: 2,
                backgroundColor: "white",
              }}
            >
              <Skeleton width="9rem" height="4rem" />
            </Stack>
          </Stack>
        )
      ) : (
        <Stack gap={0.5}>
          <Typography fontWeight="bold">
            Aucun compte CTonline Connect lié
          </Typography>
          <FormControl
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%", gap: generalSpacing }}
          >
            <TextField
              {...register("mail", {
                required: "Veuillez renseigner un e-mail.",
                maxLength: {
                  value: 100,
                  message: "L'e-mail est limité à 50 caractères.",
                },
              })}
              name="mail"
              label="E-mail"
              error={Boolean(errors.mail)}
              helperText={errors.mail?.message}
              type="email"
              autoComplete="off"
              aria-autocomplete="none"
              inputProps={{ maxLength: 100, "aria-autocomplete": "none" }}
              sx={{ width: "100%" }}
            />
            <Stack
              sx={{
                width: "100%",
                position: "sticky",
                paddingY: 1,
                bottom: 0,
                gap: generalSpacing,
                backgroundColor: "white",
              }}
            >
              {errorState.action && (
                <Box>
                  <Alert color="failure">{errorState.action}</Alert>
                </Box>
              )}
              <Stack
                width="fit-content"
                flexDirection="row"
                gap={generalSpacing}
              >
                <Button
                  data-action="link-account"
                  type="submit"
                  className="btn-base"
                  disabled={loadingAction}
                >
                  {loadingAction ? <Spinner size="sm" /> : "Lier le compte"}
                </Button>
                <Button
                  data-action="create-account"
                  type="submit"
                  className="btn-base"
                  disabled={loadingAction}
                >
                  {loadingAction ? (
                    <Spinner size="sm" />
                  ) : isEmailAlreadySent ? (
                    `Renvoyer l'e-mail`
                  ) : (
                    "Créer un compte"
                  )}
                </Button>
              </Stack>
            </Stack>
          </FormControl>
        </Stack>
      )}
    </Stack>
  );
};

export default CTonlineConnectForm;
