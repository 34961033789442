import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { api } from "../../utils/api";
import { CustomerModel } from "../../models/CustomerModel";
import { AppDispatch, RootState } from "../store";
import { CONFIG_API } from "../../data/config.API";
import {
  APIResponseModel,
  APIResponsesModel,
} from "../../models/ApiResponseModel";

export interface InitialStateModel {
  customersList: CustomerModel[] | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: InitialStateModel = {
  customersList: [],
  loading: true,
  error: null,
};

// SLICE
export const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    setCustomersList: (
      currentSlice,
      action: PayloadAction<CustomerModel[] | undefined>,
    ) => {
      currentSlice.customersList = action.payload;
    },
    setLoading: (currentSlice, action: PayloadAction<boolean>) => {
      currentSlice.loading = action.payload;
    },
    setError: (currentSlice, action: PayloadAction<string | null>) => {
      currentSlice.error = action.payload;
    },
  },
});

export const { setCustomersList, setLoading, setError } = customerSlice.actions;

// ACTIONS
export const fetchOrganizationAccountCustomers =
  (organizationPid: string, centerPid: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      // If user is client type get and set only him in customersList, otherwise get all organization's customers
      await api
        .get(
          getState().USER.currentUser?.accountType !== CONFIG_API.CLIENT
            ? `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.ACCOUNT_CUSTOMER}`
            : `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.ACCOUNT_CUSTOMER}/${getState().USER.currentUser?.pid}`,
        )
        .then(
          (
            response: AxiosResponse<
              APIResponsesModel<CustomerModel> | APIResponseModel<CustomerModel>
            >,
          ) => {
            if ("items" in response.data && response.data.success) {
              dispatch(setCustomersList(response.data.items));
            } else if ("item" in response.data && response.data.success) {
              dispatch(setCustomersList([response.data.item])); // REMOVE THIS LINE WHEN API BUG FIX !!!! (multiprofile bug, wrong centers)
              // if (response.data.item) {
              //     dispatch(setCustomersList([response.data.item]));
              // } else {
              //     dispatch(setCustomersList([]));
              // }
            } else {
              dispatch(setError("Erreur lors du chargement des clients."));
            }
          },
        );
    } catch (error: unknown) {
      dispatch(setLoading(false));
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Une erreur s'est produite."));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
