import React, { ReactNode } from "react";
import { Stack, SwipeableDrawer, Typography } from "@mui/material";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useZendeskChat from "../../hooks/layout/useZendeskChat";

interface Props {
  title?: string | null;
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseDrawer: () => void;
  children: ReactNode;
  toggleButtonGroup?: ReactNode;
}

const SidePanel = ({
  title,
  showDrawer,
  setShowDrawer,
  handleCloseDrawer,
  children,
  toggleButtonGroup,
}: Props) => {
  // Use the hook to hide/show Zendesk Chat based on the state of the drawer
  useZendeskChat(showDrawer);

  return (
    <SwipeableDrawer
      hideBackdrop={false}
      variant="temporary"
      anchor="right"
      open={showDrawer}
      onClose={handleCloseDrawer}
      onOpen={() => setShowDrawer(true)}
      elevation={5}
      sx={{ width: { xs: "100%", sm: "auto" }, position: "relative" }} // Set width to 100%
      PaperProps={{
        sx: { width: { xs: "100%", sm: "auto" }, border: "none" },
      }}
    >
      <Typography
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: 1000000,
          padding: "1rem 1.5rem",
        }}
      >
        <FontAwesomeIcon
          onClick={handleCloseDrawer}
          icon={faXmark}
          size="lg"
          style={{ cursor: "pointer" }}
        />
      </Typography>
      <Stack
        width={{ xs: "100%", sm: "32rem" }}
        padding="1rem 1.5rem 0"
        bgcolor="white"
        gap={2}
      >
        {toggleButtonGroup}
        <Typography className="title-card" alignItems={"center"}>
          {title}
        </Typography>
        {children}
      </Stack>
    </SwipeableDrawer>
  );
};

export default SidePanel;
