import { AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { AppDispatch, RootState } from "../store";
import { CONFIG_API } from "../../data/config.API";
import { APIResponsesModel } from "../../models/ApiResponseModel";
import { FleetModel } from "../../models/FleetModel";

export interface InitialStateModel {
  vehiclesList: FleetModel[] | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: InitialStateModel = {
  vehiclesList: [],
  loading: true,
  error: null,
};

// SLICE
export const fleetSlice = createSlice({
  name: "fleetSlice",
  initialState,
  reducers: {
    setVehiclesList: (currentSlice, action: PayloadAction<FleetModel[]>) => {
      currentSlice.vehiclesList = action.payload;
    },
    setLoading: (currentSlice, action: PayloadAction<boolean>) => {
      currentSlice.loading = action.payload;
    },
    setError: (currentSlice, action: PayloadAction<string | null>) => {
      currentSlice.error = action.payload;
    },
  },
});

export const { setVehiclesList, setLoading, setError } = fleetSlice.actions;

// ACTIONS
export const fetchVehicles =
  (organizationPid: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      await api
        .get(
          getState().USER.currentUser?.accountType !== CONFIG_API.CLIENT
            ? `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.FLEET}`
            : `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.FLEET}/${getState().USER.currentUser?.pid}`,
        )
        .then((response: AxiosResponse<APIResponsesModel<FleetModel>>) => {
          if (response.data.success && response.data.items) {
            dispatch(setVehiclesList(response.data.items));
          } else {
            dispatch(setError("Erreur lors du chargement des véhicules."));
          }
        });
    } catch (error: unknown) {
      dispatch(setLoading(false));
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Une erreur s'est produite."));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
