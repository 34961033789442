import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../store/store";
import { api } from "../../utils/api";
import { CenterModel } from "../../models/CenterModel";
import { APIResponseModel } from "../../models/ApiResponseModel";
import { CONFIG_API } from "../../data/config.API";
import { displayError } from "../../utils/layout/displayError";
import { updateCurrentCenter } from "../../store/center/center-slice";
import { setAlert } from "../../store/layout/alert-slice";

// Custom hook created to centralize the logic for saving center data.
// It handles API requests, error management, and dispatching Redux actions, ensuring that the same submit logic is reused across
// multiple components without duplicating code. It takes the current center data and a callback to close the drawer, then performs the necessary
// updates and handles success and error states.
export const useSaveCenter = (
  center: CenterModel,
  handleCloseDrawer: () => void,
) => {
  const [requestState, setRequestState] = useState<{
    loading: boolean;
    error: string | null;
  }>({ loading: false, error: null });
  const dispatch: AppDispatch = useDispatch();

  const saveCenter: SubmitHandler<CenterModel> = async (formData) => {
    setRequestState({ loading: true, error: null });
    try {
      const updatedFormData = {
        ...center,
        ...formData,
      };

      const response: AxiosResponse<APIResponseModel<CenterModel>> =
        await api.put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${center.organizationPid}/${CONFIG_API.CENTER}/${center.pid}`,
          updatedFormData,
        );

      if (response.data.success) {
        const centerResponse: AxiosResponse<APIResponseModel<CenterModel>> =
          await api.get(
            `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${center.organizationPid}/${CONFIG_API.CENTER}/${center.pid}`,
            { params: { [`${CONFIG_API.LOAD_DEPENDENCIES}`]: true } },
          );
        if (centerResponse.data.success) {
          const updatedCenter = {
            ...centerResponse.data.item,
            centerColor: center.centerColor,
          };
          dispatch(updateCurrentCenter(updatedCenter, true));
        }
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message: "Les modifications ont bien été enregistrées.",
          }),
        );
        handleCloseDrawer();
      } else {
        setRequestState((prevState) => ({
          ...prevState,
          error: displayError(response.data.messages[0]),
        }));
      }
    } catch (error) {
      setRequestState((prevState) => ({
        ...prevState,
        error: "Une erreur est survenue lors de l'enregistrement.",
      }));
    } finally {
      setRequestState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  return { saveCenter, requestState };
};
