import { useDispatch } from "react-redux";
import { Grid, Stack, Typography } from "@mui/material";

import { AppDispatch } from "../../store/store";
import {
  OrganizationListModel,
  fetchOrganizationData,
} from "../../store/organization/organization-slice";
import { generalRadius } from "../../utils/customTheme/customTheme";

import LogoutButton from "../common/LogoutButton";

type Props = {
  organizationsList: Array<OrganizationListModel>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrganizationSelector = ({ organizationsList, setShowModal }: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const onClick = (organization: OrganizationListModel) => {
    dispatch(fetchOrganizationData(organization.pid));
    setShowModal(false);
  };

  return (
    <Stack p={1} gap={"2rem"}>
      <Typography variant="h6">Veuillez choisir une organisation</Typography>
      <Grid width="fit-content" container gap={"1rem"}>
        {organizationsList.map((organization: OrganizationListModel) => (
          <Grid
            key={organization.pid}
            item
            margin={"auto"}
            onClick={() => onClick(organization)}
          >
            <Stack
              width="12rem"
              height="12rem"
              bgcolor={"rgba(234, 105, 39, 0.7)"}
              border="1px solid rgba(234, 105, 39, 0.7)"
              padding={2}
              borderRadius={generalRadius}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                transition: "all 0.5s ease",
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "rgba(234, 105, 39, 1)",
                  border: "1px solid white",
                },
              }}
            >
              <Typography variant="h6">{organization.name}</Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <LogoutButton />
    </Stack>
  );
};

export default OrganizationSelector;
