export const CONFIG_API = {
  ACCOUNT: "Account",
  ACCOUNT_CUSTOMER: "AccountClient",
  ACCOUNT_USER: "AccountUser",
  ADMIN: "Admin",
  APPOINTMENT: "Appointment",
  CENTER: "Center",
  CLIENT: "Client",
  CLOSING: "Closing",
  CTONLINE: "CTOnline",
  CTONLINE_ADMINISTRATION: "CTonlineAdministration",
  CTONLINE_CONNECT: "CTonlineConnect",
  DOCUMENT: "Document",
  EXTERNAL_ACCOUNT: "ExternalAccount",
  EXTERNAL_USER_ID: "ExternalUserId",
  FLEET: "VehicleFleet",
  INSPECTION: "Inspection",
  INSPECTION_TYPE: "InspectionType",
  INTERNAL_ACCOUNT: "InternalAccount",
  INVITE: "InviteV2",
  LINE: "Line",
  LOAD_DEPENDENCIES: "LoadDependencies",
  MAIL: "mail",
  MAX_RESULTS: "MaxResults",
  MCT_DOCUMENT: "MCTDocument",
  MESSAGE: "Message",
  MESSENGER: "Messenger",
  NEWS: "News",
  ORGANIZATION: "Organization",
  ORGANIZATION_PID: "organizationPid",
  PARTNER: "Partner",
  PROFILE: "Profile",
  TYPES: "Types",
  USER: "User",
  REGISTRER_ACCOUNT: "RegisterAccount",
  REGISTRATION_NUMBER: "RegistrationNumber",
  SCHEDULE: "Schedule",
  SEARCH: "Search",
};
