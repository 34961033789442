import moment from "moment";

interface LimitsModel {
  limDebSit: number;
  limFinSit: number;
}

/**
 * Function to check if a given date falls within specified limits relative to today's date for customer account only.
 * It is used to ensure that appointments can only be scheduled within a valid range of days,
 * starting from `limDebSit` days in the future, and not beyond `limFinSit` days.
 *
 * @param {LimitsModel} limits - An object containing two numerical properties:
 *  - `limDebSit`: Minimum number of days from today for which appointments can be scheduled, updated or deleted.
 *  - `limFinSit`: Maximum number of days from today for which appointments can be scheduled, updated or deleted.
 * @param {string} date - The selected date as a string, which will be checked against the start and end limits.
 *
 * @return {startLimit: boolean, endLimit: boolean} An object containing two boolean properties:
 *  - `startLimit`: Indicates whether the selected date is outside the start limit (true if valid).
 *  - `endLimit`: Indicates whether the selected date is outside the end limit (true if valid).
 */
export const checkLimitDates = (
  limits: LimitsModel,
  date: string,
): { startLimit: boolean; endLimit: boolean } => {
  const { limDebSit, limFinSit } = limits;
  const today = moment().startOf("day"); // Set today's date at the start of the day (to avoid hour/minute issues)
  const selectedDay = moment(date).startOf("day"); // Normalize the selected date to ensure only the day is compared
  const differenceBetweenDates = selectedDay.diff(today, "days"); // Get the difference in days between the two dates

  let isOutsideStartLimit = true;
  let isOutsideEndLimit = true;

  // Check if the selected date is too early (before limDebSit) or too late (after limFinSit)
  if (differenceBetweenDates < limDebSit) {
    isOutsideStartLimit = false;
  }
  if (differenceBetweenDates > limFinSit) {
    isOutsideEndLimit = false;
  }

  return { startLimit: isOutsideStartLimit, endLimit: isOutsideEndLimit };
};
