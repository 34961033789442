import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Alert, Button, Spinner } from "flowbite-react";
import { BryntumCalendar } from "@bryntum/calendar-react";
import { DayResourceView } from "@bryntum/calendar";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/fr";
import moment from "moment";
import { AxiosResponse } from "axios";

import { AppDispatch, RootState } from "../../../store/store";
import useWindowWidth from "../../../hooks/layout/useWindowWidth";
import { api } from "../../../utils/api";
import {
  APIResponseModel,
  APIResponsesModel,
} from "../../../models/ApiResponseModel";
import { AppointmentModel } from "../../../models/AppointmentModel";
import {
  ClosingModel,
  LineModel,
  ScheduleModel,
} from "../../../models/CenterSettingsModel";
import { TimeRangeModel } from "../../../models/CalendarModel";
import { CONFIG_API } from "../../../data/config.API";
import {
  generalSpacing,
  MUIXTheme,
} from "../../../utils/customTheme/customTheme";
import { displayError } from "../../../utils/layout/displayError";
import {
  calculateClosingDates,
  fetchCenterClosings,
  fetchVirtualTimeRanges,
  setCenterClosings,
} from "../../../store/center/center-slice";
import { setAlert } from "../../../store/layout/alert-slice";

import SelectableChip from "../../common/SelectableChip";
import CustomModal from "../../layouts/CustomModal";

type Props = {
  editingRecord?: AppointmentModel;
  data?: ClosingModel;
  centerSchedules?: ScheduleModel[];
  handleCloseDrawer: () => void;
  calendar?: React.RefObject<BryntumCalendar>;
  updateClosingsTable?: (
    isDelete: boolean,
    data: ClosingModel | ClosingModel[],
  ) => void;
  padding?: string;
};

const ClosingForm = ({
  handleCloseDrawer,
  calendar,
  centerSchedules,
  editingRecord,
  data,
  updateClosingsTable,
  padding = "1rem 1.5rem 0",
}: Props) => {
  moment.locale("fr");
  const centerState = useSelector((store: RootState) => store.CENTER);

  const dispatch: AppDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ClosingModel>({
    defaultValues: {
      startingDate: data
        ? data.startingDate
        : moment(editingRecord?.startDate).toISOString(),
      startingHour: data
        ? moment(moment(data?.startingHour, "HH:mm:ss")).toString()
        : editingRecord?.startDate
          ? new Date(editingRecord?.startDate).getHours() !== 0
            ? moment(editingRecord?.startDate).toISOString()
            : moment(
                new Date(
                  new Date().setHours(
                    parseInt(centerState.centerSchedules.minHour ?? "", 10),
                    0,
                    0,
                  ),
                ),
              ).toISOString()
          : moment(
              new Date(
                new Date().setHours(
                  parseInt(centerState.centerSchedules.minHour ?? "", 10),
                  0,
                  0,
                ),
              ),
            ).toISOString(),
      endingDate: moment(
        data ? data.endingDate : editingRecord?.endDate,
      ).toISOString(),
      endingHour: data
        ? moment(moment(data?.endingHour, "HH:mm:ss")).toString()
        : editingRecord?.isDragCreated
          ? moment(editingRecord?.endDate).toISOString()
          : moment(
              new Date(
                new Date().setHours(
                  parseInt(centerState.centerSchedules.maxHour ?? "", 10),
                  0,
                  0,
                ),
              ),
            ).toISOString(),
      closedDays: data
        ? data.closedDays
        : editingRecord && editingRecord?.startDate
          ? [new Date(editingRecord.startDate).getDay()]
          : [],
      lines: data
        ? data.lines
        : editingRecord && editingRecord?.lineId
          ? [editingRecord.lineId]
          : [],
      comments: data ? data.comments : "",
    },
  });

  const startDateInputRef = useRef<HTMLInputElement>(null);
  const startEndInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const isDesktop = useWindowWidth();

  const centerLines = centerState.centerLines?.map((line: LineModel) => {
    return { label: line.label, value: line.lineNumber };
  });

  const allWeekDays = [
    { label: "Dimanche", value: 0 },
    { label: "Lundi", value: 1 },
    { label: "Mardi", value: 2 },
    { label: "Mercredi", value: 3 },
    { label: "Jeudi", value: 4 },
    { label: "Vendredi", value: 5 },
    { label: "Samedi", value: 6 },
  ];
  // Show only center's working days
  const filteredDays = allWeekDays.filter(
    (day) =>
      !centerState.centerSchedules.nonWorkingDays ||
      !centerState.centerSchedules.nonWorkingDays[day.value],
  );

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [showDatePicker, setShowDatePicker] = useState({
    startDate: false,
    endDate: false,
  });
  const [showTimePicker, setShowTimePicker] = useState({
    startTime: false,
    endTime: false,
  });

  const updateVirtualTimeRanges = async (
    response?: APIResponseModel<ClosingModel>,
  ) => {
    const calendarView = calendar?.current?.instance
      .activeView as DayResourceView;
    // Get first and last day of the current ranges displayed by the calendar
    const startWeekDay = calendarView.startDate.toISOString().split("T")[0];
    const endWeekDay = new Date(calendarView.endDate)
      .toISOString()
      .split("T")[0];

    // Create new closings (check by day date) if the date is within the current ranges displayed by the calendar
    let newClosings: TimeRangeModel[] = [];
    if (response) {
      const updatedClosings = centerState.centerClosings
        ? [...centerState.centerClosings]
        : [];
      updatedClosings.push(response.item);
      newClosings = updatedClosings.flatMap((closing) => {
        return closing.lines.flatMap((line) => {
          return calculateClosingDates(
            { ...closing, lines: [line] },
            startWeekDay,
            endWeekDay,
            centerState.centerSchedules.maxHour,
          );
        });
      });
    } else if (centerState.currentCenter) {
      const closingsResponse = (await dispatch(
        fetchCenterClosings(
          centerState.currentCenter?.organizationPid,
          centerState.currentCenter?.pid,
          startWeekDay,
          endWeekDay,
        ),
      )) as APIResponsesModel<ClosingModel> | false;
      if (closingsResponse) {
        newClosings = closingsResponse.items.flatMap((closing) => {
          return closing.lines.flatMap((line) => {
            return calculateClosingDates(
              { ...closing, lines: [line] },
              startWeekDay,
              endWeekDay,
              centerState.centerSchedules.maxHour,
            );
          });
        });
      }
    }

    if (
      centerState.centerTimeRanges &&
      newClosings &&
      centerSchedules &&
      calendar
    ) {
      // Add new closings in the calendar store to display them
      const currentTimeRanges = dispatch(
        fetchVirtualTimeRanges(
          newClosings,
          centerSchedules,
          startWeekDay,
          endWeekDay,
        ),
      );
      const updatedTimeRanges = centerState.centerTimeRanges?.filter(
        (timerange) => timerange.type === "schedule",
      );
      currentTimeRanges?.forEach((timerange) => {
        if (
          !updatedTimeRanges?.some((item) => item.id === timerange.id) &&
          !updatedTimeRanges?.some((item) => item.id === timerange.id)
        ) {
          updatedTimeRanges?.push(timerange);
        }
      });
      calendar.current?.instance.resourceTimeRangeStore.removeAll();
      calendar.current?.instance.resourceTimeRangeStore.add(updatedTimeRanges);
    }
  };

  // Automatically remove focus from the DateTime input when the picker closes
  useEffect(() => {
    setTimeout(() => {
      if (
        !showDatePicker.startDate &&
        !showDatePicker.endDate &&
        startDateInputRef.current &&
        startEndInputRef.current
      ) {
        startDateInputRef.current.blur();
        startEndInputRef.current.blur();
      }
    }, 100);
  }, [showDatePicker]);

  const closingSave: SubmitHandler<ClosingModel> = async (formData) => {
    setLoading(true);
    setError(null);
    const updatedFormData: ClosingModel = {
      ...formData,
      startingDate: moment(formData.startingDate).format("YYYY-MM-DD"),
      startingHour: moment(formData.startingHour).format("HH:mm:ss"),
      endingDate: moment(formData.endingDate).format("YYYY-MM-DD"),
      endingHour: moment(formData.endingHour).format("HH:mm:ss"),
      closedDays: formData.closedDays.sort(), // Sort days in ascending order
      lines: formData.lines.sort(), // Sort lines in ascending order
      visToCust: !!formData.visToCust,
    };

    // Check if the ending date is before the starting date
    if (
      moment(updatedFormData.endingDate, "YYYY-MM-DD").isBefore(
        moment(updatedFormData.startingDate, "YYYY-MM-DD"),
        "day",
      )
    ) {
      setError("La date de fin ne peut pas être avant la date de début.");
      setLoading(false);
      return false;
    }

    // Check if the ending time is before the starting time on the same day
    if (
      moment(updatedFormData.endingHour, "HH:mm:ss").isSameOrBefore(
        moment(updatedFormData.startingHour, "HH:mm:ss"),
      )
    ) {
      setError("L'heure de fin ne peut pas être avant l'heure de début.");
      setLoading(false);
      return false;
    }

    if (!data) {
      // New closing (POST Request)
      const response: AxiosResponse<APIResponseModel<ClosingModel>> =
        await api.post(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${centerState.currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${centerState.currentCenter?.pid}/${CONFIG_API.CLOSING}`,
          updatedFormData,
        );
      if (response.data.success) {
        if (calendar) {
          if (centerState.centerClosings) {
            // In order to allow update this new closing right after its creation directly from calendar
            const updatedClosings = [
              ...centerState.centerClosings,
              response.data.item,
            ];
            dispatch(setCenterClosings(updatedClosings));
          }
          updateVirtualTimeRanges(response.data);
        } else if (updateClosingsTable) {
          // To create closing in table if closing parameters page
          updateClosingsTable(false, response.data.item);
        }
        setLoading(false);
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message: "La fermeture a bien été créée.",
          }),
        );
        handleCloseDrawer();
      } else {
        setLoading(false);
        setError(displayError(response.data.messages[0]));
      }
    } else {
      // Update closing (PUT Request)
      try {
        const response: AxiosResponse<APIResponseModel<ClosingModel>> =
          await api.put(
            `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${centerState.currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${centerState.currentCenter?.pid}/${CONFIG_API.CLOSING}/${data.id}`,
            updatedFormData,
          );
        if (response.data.success) {
          if (calendar) {
            updateVirtualTimeRanges();
          } else if (updateClosingsTable) {
            // To update closing in table of closing parameters page
            updateClosingsTable(false, response.data.item);
          }
          dispatch(
            setAlert({
              id: "global-alert",
              type: "success",
              message: "La fermeture a bien été modifiée.",
            }),
          );
          handleCloseDrawer();
        } else {
          setError(displayError(response.data.messages[0]));
        }
      } catch (error) {
        setError("Une erreur est survenue.");
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteClosing = async (closingData: ClosingModel) => {
    setLoadingDelete(true);
    try {
      await api
        .delete(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${centerState.currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${centerState.currentCenter?.pid}/${CONFIG_API.CLOSING}/${closingData.id}`,
        )
        .then((deleteResponse: AxiosResponse<APIResponseModel<boolean>>) => {
          if (deleteResponse.data.success) {
            handleCloseDrawer();
            if (updateClosingsTable) {
              // Update closings table if closings parameters page
              updateClosingsTable(true, closingData);
            } else if (calendar) {
              // Update closings on the calendar if on the calendar page
              updateVirtualTimeRanges();
            }
            dispatch(
              setAlert({
                id: "global-alert",
                type: "success",
                message: "La fermeture a bien été supprimée.",
              }),
            );
          } else {
            dispatch(
              setAlert({
                id: "global-alert",
                type: "failure",
                message: displayError(deleteResponse.data.messages[0]),
              }),
            );
          }
        });
    } catch (error) {
      dispatch(
        setAlert({
          id: "calendar-alert",
          type: "failure",
          message: "Une erreur est survenue lors de la suppression.",
        }),
      );
    } finally {
      setShowDeleteConfirmation(false);
      setLoadingDelete(false);
    }
  };

  return (
    <>
      <CustomModal
        showLogo={false}
        open={showDeleteConfirmation}
        padding={4}
        width={{ xs: "20rem", sm: "40rem" }}
        height="auto"
        textAlign="initial"
      >
        <Stack justifyContent="center" alignItems="center" gap={4}>
          <Typography className="title-card">
            Êtes-vous sûr de vouloir supprimer cette fermeture ?
          </Typography>
          <Alert color="info">
            <Stack gap="0.5rem">
              <Typography>
                Du {moment(data?.startingDate).format("DD/MM/YYYY")} au{" "}
                {moment(data?.endingDate).format("DD/MM/YYYY")} de{" "}
                {data?.startingHour.slice(0, -3)} à{" "}
                {data?.endingHour.slice(0, -3)}
              </Typography>
              <Stack flexDirection="row" gap="0.5rem">
                {data?.closedDays.map((day) => (
                  <Chip
                    key={day}
                    label={moment().day(day).format("dddd")}
                    size="small"
                    sx={{
                      ".MuiChip-label": { fontSize: "11px" },
                    }}
                  />
                ))}
              </Stack>
              <Stack flexDirection={"row"} gap="0.5rem">
                {data?.lines.map((line) => (
                  <Chip
                    key={line}
                    label={
                      centerLines?.find(
                        (centerLine) => centerLine.value === line,
                      )?.label
                    }
                    size="small"
                    sx={{
                      ".MuiChip-label": { fontSize: "11px" },
                    }}
                  />
                ))}
              </Stack>
            </Stack>
          </Alert>
          <Stack flexDirection="row" gap={1}>
            <Button
              className="btn-base"
              onClick={() => {
                data && deleteClosing(data);
              }}
              disabled={loadingDelete}
            >
              {loadingDelete ? <Spinner size="sm" /> : "Confirmer"}
            </Button>
            <Button
              className="btn-base btn-outlined"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Annuler
            </Button>
          </Stack>
        </Stack>
      </CustomModal>
      <Typography
        className="title-card"
        padding={padding}
        alignItems={"center"}
      >
        {data ? "Modification de fermeture" : "Nouvelle fermeture"}
      </Typography>
      <FormControl
        component="form"
        id="closingForm"
        onSubmit={handleSubmit(closingSave)}
        sx={{ width: "100%", padding: padding, gap: 4 }}
      >
        <Stack gap={generalSpacing}>
          <Typography fontWeight="bold">Créneau</Typography>
          <Stack
            flexDirection={"row"}
            gap={generalSpacing}
            justifyContent={"center"}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                {...register("startingHour", {
                  required: "Veuillez renseigner une heure.",
                })}
                name="startingHour"
                render={({ field }) => {
                  return (
                    <TimePicker
                      {...MUIXTheme.timePicker.defaultProps}
                      value={moment(new Date(field.value))}
                      onChange={field.onChange}
                      open={showTimePicker.startTime}
                      onClose={() => {
                        setShowTimePicker({
                          ...showTimePicker,
                          startTime: false,
                        });
                      }}
                      onOpen={() => {
                        setShowTimePicker({
                          ...showTimePicker,
                          startTime: true,
                        });
                      }}
                      minTime={moment()
                        .hours(
                          parseInt(
                            centerState.centerSchedules.minHour ?? "",
                            10,
                          ),
                        )
                        .minutes(0)}
                      maxTime={moment()
                        .hours(
                          parseInt(
                            centerState.centerSchedules.maxHour ?? "",
                            10,
                          ),
                        )
                        .minutes(0)}
                      slotProps={{
                        ...MUIXTheme.timePicker.slotsProps,
                        inputAdornment: {
                          position: "start",
                          sx: { marginRight: 0 },
                        },
                        textField: {
                          error: Boolean(errors.startingHour),
                          helperText: errors.startingHour?.message,
                          label: "Heure de début",
                          placeholder: "",
                          onClick: () =>
                            setShowTimePicker({
                              ...showTimePicker,
                              startTime: true,
                            }),
                          sx: {
                            "& .MuiInputLabel-root": {
                              top: -3,
                            },
                            "& .MuiOutlinedInput-root": {
                              marginTop: "0",
                            },
                            "& .MuiInputBase-input": {
                              width: { xs: "14rem", md: "20rem" },
                              cursor: "pointer",
                            },
                          },
                        },
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                {...register("endingHour", {
                  required: "Veuillez renseigner une heure.",
                })}
                name="endingHour"
                render={({ field }) => (
                  <TimePicker
                    {...MUIXTheme.timePicker.defaultProps}
                    value={moment(new Date(field.value))}
                    onChange={field.onChange}
                    open={showTimePicker.endTime}
                    onClose={() => {
                      setShowTimePicker({ ...showTimePicker, endTime: false });
                    }}
                    onOpen={() => {
                      setShowTimePicker({ ...showTimePicker, endTime: true });
                    }}
                    minTime={moment()
                      .hours(
                        parseInt(centerState.centerSchedules.minHour ?? "", 10),
                      )
                      .minutes(0)}
                    maxTime={moment()
                      .hours(
                        parseInt(centerState.centerSchedules.maxHour ?? "", 10),
                      )
                      .minutes(55)}
                    slotProps={{
                      ...MUIXTheme.timePicker.slotsProps,
                      inputAdornment: {
                        position: "start",
                        sx: { marginRight: 0 },
                      },
                      textField: {
                        error: Boolean(errors.endingHour),
                        helperText: errors.endingHour?.message,
                        label: "Heure de fin",
                        placeholder: "",
                        onClick: () =>
                          setShowTimePicker({
                            ...showTimePicker,
                            endTime: true,
                          }),
                        sx: {
                          "& .MuiInputLabel-root": {
                            top: -3,
                          },
                          "& .MuiOutlinedInput-root": {
                            marginTop: "0",
                          },
                          "& .MuiInputBase-input": {
                            width: { xs: "14rem", md: "20rem" },
                            cursor: "pointer",
                          },
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>

        <Stack gap={3} width={"100%"}>
          <Typography fontWeight="bold">Plage de dates</Typography>

          <Stack
            flexDirection={isDesktop ? "column" : "row"}
            gap={generalSpacing}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                {...register("startingDate", {
                  required: "Veuillez renseigner une date de début.",
                })}
                name="startingDate"
                render={({ field }) => (
                  <DatePicker
                    value={moment(field.value)}
                    onChange={field.onChange}
                    onClose={() => {
                      setShowDatePicker({
                        ...showDatePicker,
                        startDate: false,
                      });
                    }}
                    onOpen={() => {
                      setShowDatePicker({ ...showDatePicker, startDate: true });
                    }}
                    disablePast
                    disableHighlightToday
                    views={["year", "month", "day"]}
                    format={isDesktop ? "DD MMMM YYYY" : "DD/MM/YY"}
                    open={showDatePicker.startDate}
                    slotProps={{
                      ...MUIXTheme.datePicker.slotsProps,
                      desktopPaper: {
                        sx: {
                          "& .MuiYearCalendar-root": {
                            width: "29rem",
                          },
                          "& .MuiDateCalendar-root": {
                            width: "29rem",
                          },
                          "& .MuiMonthCalendar-root": {
                            margin: "1rem auto",
                          },
                        },
                      },
                      inputAdornment: {
                        position: "start",
                        sx: { marginRight: 0 },
                      },
                      textField: {
                        inputRef: startDateInputRef,
                        label: "Date de début",
                        placeholder: "",
                        error: Boolean(errors.startingDate),
                        helperText: errors.startingDate?.message,
                        onClick: () => {
                          setShowDatePicker({
                            ...showDatePicker,
                            startDate: true,
                          });
                        },
                        sx: {
                          "& .MuiInputLabel-root": {
                            top: -3,
                          },
                          "& .MuiInputBase-input": {
                            width: { xs: "14rem", sm: "20rem" },
                            cursor: "pointer",
                          },
                          "& .MuiOutlinedInput-root": {
                            marginTop: "0",
                          },
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                {...register("endingDate", {
                  required: "Veuillez renseigner une date de fin.",
                })}
                name="endingDate"
                render={({ field }) => (
                  <DatePicker
                    onClose={() => {
                      setShowDatePicker({ ...showDatePicker, endDate: false });
                    }}
                    onOpen={() => {
                      setShowDatePicker({ ...showDatePicker, endDate: true });
                    }}
                    disablePast
                    views={["year", "month", "day"]}
                    format={isDesktop ? "DD MMMM YYYY" : "DD/MM/YY"}
                    open={showDatePicker.endDate}
                    value={moment(field.value)}
                    onChange={field.onChange}
                    inputRef={startEndInputRef}
                    slotProps={{
                      ...MUIXTheme.datePicker.slotsProps,
                      desktopPaper: {
                        sx: {
                          "& .MuiDateCalendar-root": {
                            width: "29rem",
                          },
                          "& .MuiMonthCalendar-root": {
                            margin: "1rem auto",
                          },
                        },
                      },
                      inputAdornment: {
                        position: "start",
                        sx: { marginRight: 0 },
                      },
                      textField: {
                        label: "Date de fin",
                        placeholder: "",
                        error: Boolean(errors.endingDate),
                        helperText: errors.endingDate?.message,
                        onClick: () => {
                          setShowDatePicker({
                            ...showDatePicker,
                            endDate: true,
                          });
                        },
                        sx: {
                          "& .MuiInputLabel-root": {
                            top: -3,
                          },
                          "& .MuiInputBase-input": {
                            width: { xs: "14rem", md: "20rem" },
                            cursor: "pointer",
                          },
                          "& .MuiOutlinedInput-root": {
                            marginTop: "0",
                          },
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>

        <Stack
          gap={2}
          sx={{
            height: "fit-content",
            padding: 2,
            backgroundColor: "#F8F6FA",
            borderRadius: "10px",
          }}
        >
          <Typography fontWeight="bold">{"Jour(s) concerné(s)"}</Typography>
          <Controller
            name="closedDays"
            defaultValue={[]}
            control={control}
            rules={{ required: "Veuillez sélectionner au moins un jour." }}
            render={({ field, fieldState: { error } }) => (
              <>
                <SelectableChip
                  options={filteredDays}
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)}
                  isRequired
                />
                {error && (
                  <Typography
                    fontSize="12px"
                    lineHeight="20px"
                    marginTop={"-0.8rem"}
                    color={"error"}
                  >
                    {error.message}
                  </Typography>
                )}
              </>
            )}
          />
          {centerLines && (
            <>
              <Typography fontWeight="bold">
                {"Ligne(s) concerné(es)"}
              </Typography>
              <Controller
                name="lines"
                defaultValue={centerLines.length > 1 ? [] : [1]}
                control={control}
                rules={{
                  required: "Veuillez sélectionner au moins une ligne.",
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <SelectableChip
                      options={centerLines}
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      isRequired
                    />
                    {error && (
                      <Typography
                        fontSize="12px"
                        lineHeight="20px"
                        marginTop={"-0.8rem"}
                        color={"error"}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </>
          )}

          <Typography fontWeight="bold">{"Commentaire"}</Typography>
          <TextField
            {...register("comments", {
              maxLength: {
                value: 150,
                message: "Le commentaire est limité à 150 caractères.",
              },
            })}
            error={Boolean(errors.comments)}
            helperText={errors.comments?.message}
            name="comments"
            placeholder="Commencez à écrire"
            multiline
            maxRows={3}
            inputProps={{ maxLength: 150 }}
            InputProps={{ sx: { border: "none" } }}
          />
          <FormLabel sx={{ marginBottom: "-1.5rem" }}>
            Commentaire visible pour les clients en compte
          </FormLabel>
          <RadioGroup
            row
            defaultValue={data?.visToCust.toString() ?? "false"}
            {...register("visToCust")}
          >
            <FormControlLabel value="true" control={<Radio />} label="Oui" />
            <FormControlLabel value="false" control={<Radio />} label="Non" />
          </RadioGroup>
        </Stack>

        <Stack
          sx={{
            width: "100%",
            position: "sticky",
            paddingY: 1,
            bottom: 0,
            gap: 2,
            backgroundColor: "white",
          }}
        >
          {error && (
            <Box>
              <Alert color="failure">{error}</Alert>
            </Box>
          )}
          <Stack flexDirection="row" gap={1}>
            <Button
              id="saveClosingFormButton"
              className="btn-base"
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner size="sm" /> : "Confirmer"}
            </Button>
            {data && (
              <Button
                className="btn-base btn-danger"
                onClick={() => {
                  setShowDeleteConfirmation(true);
                }}
              >
                Supprimer
              </Button>
            )}
          </Stack>
        </Stack>
      </FormControl>
    </>
  );
};

export default ClosingForm;
