import { ErrorMessageModel } from "../../models/ApiResponseModel";

export const displayError = (error: ErrorMessageModel): string => {
  /// List of API message types that should be displayed
  const displayedMessageTypes = ["Error", "Information", "Warning"];

  if (error && displayedMessageTypes.some((item) => error.type === item)) {
    return error.message;
  } else {
    return "Une erreur est survenue.";
  }
};
