import { faCalendarCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@mui/material";
import { Button, Spinner } from "flowbite-react";
import type { AxiosResponse } from "axios";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  type Location,
  type NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";

import InspectionResultChip from "../components/common/InspectionResultChip";
import ListComponent, {
  type ColumnDefinition,
} from "../components/common/List/ListComponent";
import ErrorDisplay from "../components/layouts/ErrorDisplay";
import { CONFIG_API } from "../data/config.API";
import { APIResponseModel } from "../models/ApiResponseModel";
import {
  RedirectToCalendarData,
  SearchResponseModel,
  SearchTableModel,
  SearchToAppointmentModel,
} from "../models/SearchResponseModel";

import { setStoredAppointmentData } from "../store/calendar/appointment-slice";
import { setAlert } from "../store/layout/alert-slice";
import { AppDispatch, RootState } from "../store/store";
import { api } from "../utils/api";
import { displayError } from "../utils/layout/displayError";

const Search = () => {
  const dispatch: AppDispatch = useDispatch();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const locationState = location.state as SearchResponseModel[];
  const { currentCenter, organizationCenters } = useSelector(
    (store: RootState) => store.CENTER,
  );

  const [loadingDocuments, setLoadingDocuments] = useState<{
    [key: string]: boolean;
  }>({});

  const getDocument = useCallback(
    async (documentType: "Invoice" | "Inspection", documentId: number) => {
      const loadingKey = `${documentType}-${documentId}`;
      // Push document ID into loadingDocuments array to display spinner
      setLoadingDocuments((prev) => ({ ...prev, [loadingKey]: true }));
      try {
        const response: AxiosResponse<
          APIResponseModel<SearchToAppointmentModel>
        > = await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/Document/${documentType}/${documentId}/Url`,
        );
        if (response?.data.success) {
          if (response.data.item) {
            window.open(`${response.data.item}&inline`);
          } else {
            dispatch(
              setAlert({
                id: "global-alert",
                type: "failure",
                message: "Aucun fichier n'a été trouvé.",
              }),
            );
          }
        } else {
          dispatch(
            setAlert({
              id: "global-alert",
              type: "failure",
              message: displayError(response.data.messages[0]),
            }),
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "failure",
            message: "Une erreur est survenue.",
          }),
        );
      } finally {
        // Remove this document from loadingDocuments array to remove spinner
        setLoadingDocuments((prev) => ({ ...prev, [loadingKey]: false }));
      }
    },
    [currentCenter, dispatch],
  );

  const redirectToCalendar = useCallback(
    (data: RedirectToCalendarData) => {
      const {
        firstName,
        lastName,
        mail,
        phoneNumber,
        vehicle,
        registrationNumber,
        clientPid,
      } = data;
      dispatch(
        setStoredAppointmentData({
          variant: "copy",
          data: {
            firstName,
            lastName,
            mail,
            phoneNumber,
            vehicle,
            registrationNumber,
            clientPid,
          },
          isCenterChanged: false,
        }),
      );
      navigate("/calendar");
    },
    [dispatch, navigate],
  );

  const columns: ColumnDefinition<SearchTableModel>[] = useMemo(
    () => [
      { id: "name", label: "Nom", type: "text", flex: 2 },
      { id: "mail", label: "E-mail", type: "text", flex: 2.5 },
      { id: "phoneNumber", label: "Téléphone", type: "text", flex: 1.2 },
      { id: "vehicle", label: "Véhicule", type: "text", flex: 1.8 },
      { id: "registrationNumber", label: "Immat.", type: "text", flex: 1 },
      {
        id: "centerAgreement",
        label: "Agrément",
        type: "text",
        flex: 1,
        hide: organizationCenters && organizationCenters?.length <= 1,
      },
      {
        id: "nextAppointmentDate",
        label: "Date du RDV",
        type: "text",
        flex: 1,
      },
      {
        id: "lastAppointment",
        label: "Dernier contrôle",
        type: "text",
        flex: 1,
      },
      { id: "lastInspectionType", label: "Nature", type: "chip", flex: 1 },
      {
        id: "lastInspectionResult",
        label: "Résultat",
        type: "component",
        flex: 1.2,
      },
      { id: "validityDate", label: "Date de validité", type: "text", flex: 1 },
      { id: "nextAppointmentType", label: "Type", type: "chip", flex: 1 },
      { id: "invoice", label: "", type: "link", flex: 0.7 },
      { id: "ticket", label: "", type: "link", flex: 0.5 },
      {
        id: "addAppointment",
        label: "",
        type: "component",
        hover: true,
        flex: 0.7,
      },
    ],
    [],
  );

  const mapSearchResponseToRows = useCallback(
    (data: SearchResponseModel[]): SearchTableModel[] => {
      return data.map((item, index) => ({
        id: index,
        firstName: `${item.vehPrenom ?? item.facPrenom}`,
        lastName: `${item.vehNom ?? item.facNom}`,
        name: `<strong>${item.cgPrenom ?? item.vehPrenom} ${item.cgNom ?? item.vehNom}</strong>`,
        mail: item.vehMel ? item.vehMel : item.client ? item.client.mail : "",
        phoneNumber: item.vehPor ? item.vehPor : (item.vehTel ?? ""),
        vehicle: item.vehicle,
        registrationNumber: item.vehImm,
        lastAppointment: item.inspection
          ? moment(new Date(item.inspection.startDate)).format("DD/MM/YYYY")
          : "",
        lastInspectionType: item.inspection ? [item.inspection.conNatCtl] : [],
        lastInspectionResult: {
          render: item.inspection ? (
            <InspectionResultChip
              inspectionResult={item.inspection.inspectionResult}
            />
          ) : (
            <></>
          ),
        },
        centerAgreement: item.inspection
          ? (item.inspection.centerAgreement ?? "")
          : "",
        validityDate: item.datProCtl
          ? moment(new Date(item.datProCtl)).format("DD/MM/YYYY")
          : "",
        nextAppointmentDate: item.rdvDat
          ? moment(new Date(item.rdvDat)).format("DD/MM/YYYY")
          : "",
        nextAppointmentType: item.natProCtl ? [item.natProCtl] : [],
        invoice: item.invoice?.isInvoiceAvailableToDownload
          ? {
              label: loadingDocuments[`Invoice-${item.invoice.id}`] ? (
                <div className="px-4">
                  <Spinner size="sm" />
                </div>
              ) : (
                "Facture"
              ),
              onClick: () => getDocument("Invoice", item.invoice.id),
            }
          : {},
        ticket: item.inspection?.isPvAvailableToDownload
          ? {
              label: loadingDocuments[`Inspection-${item.inspection.id}`] ? (
                <Spinner size="sm" />
              ) : (
                "PV"
              ),
              onClick: () => getDocument("Inspection", item.inspection.id),
            }
          : {},
        addAppointment: {
          render: (
            <Button
              className="btn-base"
              title="Ajouter un RDV"
              onClick={() =>
                redirectToCalendar({
                  firstName: item.cgPrenom ?? item.vehPrenom,
                  lastName: item.cgNom ?? item.vehNom,
                  mail: item.vehMel ?? item.client?.mail ?? "",
                  phoneNumber: item.vehPor ? item.vehPor : (item.vehTel ?? ""),
                  vehicle: item.vehicle,
                  registrationNumber: item.vehImm,
                  clientPid: item.customerPid,
                })
              }
            >
              <FontAwesomeIcon icon={faCalendarCirclePlus} size="lg" />
            </Button>
          ),
        },
        inspection: item.inspection,
        clientPid: item.customerPid,
      }));
    },
    [getDocument, loadingDocuments, redirectToCalendar],
  );

  const rows = useMemo(
    () => (locationState ? mapSearchResponseToRows(locationState) : []),
    [locationState, mapSearchResponseToRows],
  );

  return (
    <Stack
      height="100vh"
      marginLeft={{ xs: "0.5rem", sm: "0" }}
      marginRight={{ xs: "0.5rem", sm: "1rem" }}
    >
      {locationState && rows.length > 0 ? (
        <ListComponent
          data={rows}
          tableColumns={columns}
          filterModel={{ items: [] }}
        />
      ) : (
        <Stack margin="auto" gap={3}>
          <ErrorDisplay displayMessage="Aucune recherche effectuée." />
        </Stack>
      )}
    </Stack>
  );
};

export default Search;
