import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import { Button, Spinner } from "flowbite-react";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

import { AppDispatch, RootState } from "../../../store/store";
import { CONFIG_CTO } from "../../../data/config.CTO";
import { CONFIG_API } from "../../../data/config.API";
import { APIResponsesModel } from "../../../models/ApiResponseModel";
import { ClosingModel, LineModel } from "../../../models/CenterSettingsModel";
import { api } from "../../../utils/api";
import { displayError } from "../../../utils/layout/displayError";
import { fetchCenterSchedules } from "../../../store/center/center-slice";
import { FilterItem } from "../../../components/common/List/ListFilters";
import customTheme, {
  generalSpacing,
} from "../../../utils/customTheme/customTheme";
import { capitalizeString } from "../../../utils/layout/capitalizeString";
import { setAlert } from "../../../store/layout/alert-slice";
import useWindowWidth from "../../../hooks/layout/useWindowWidth";

import ListComponent, {
  ColumnDefinition,
} from "../../../components/common/List/ListComponent";
import ErrorLoadingData from "../../../components/common/ErrorLoadingData";
import LoadingData from "../../../components/common/LoadingData";
import ClosingForm from "../../../components/calendar/EventEditor/ClosingForm";
import SidePanel from "../../../components/layouts/SidePanel";
import PublicHolidaysForm from "./PublicHolidaysForm";
import CustomModal from "../../../components/layouts/CustomModal";

interface RowData {
  id: number;
  date: string;
  startingDate: Date;
  endingDate: Date;
  hour: string;
  closedDays: string[];
  lines: string[];
  comments: string;
}

const ClosingsList = () => {
  document.title = `${CONFIG_CTO.PARAMETERS} ${CONFIG_CTO.CLOSINGS}`;

  const centerState = useSelector((store: RootState) => store.CENTER);

  const dispatch: AppDispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  const isDesktop = useWindowWidth(customTheme.breakpoints.values.lg);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [allClosings, setAllClosings] = useState<ClosingModel[] | undefined>(
    undefined,
  );
  const [sidePanelContent, setSidePanelContent] = useState<
    "closingForm" | "publicHolidays"
  >("closingForm");
  const [closingData, setClosingData] = useState<ClosingModel | undefined>(
    undefined,
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    showModal: boolean;
    itemsToDelete: (number | string)[];
  }>({ showModal: false, itemsToDelete: [] });
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [defaultFilters] = useState<GridFilterModel>({
    // By default, show only current closings
    items: [
      {
        id: 1,
        field: "startingDate",
        operator: ">",
        value: moment(new Date()).format("YYYY-MM-DD"),
      },
      {
        id: 2,
        field: "endingDate",
        operator: "<",
        value: moment(
          new Date().setFullYear(new Date().getFullYear() + 1),
        ).format("YYYY-MM-DD"),
      },
    ],
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  useEffect(() => {
    // To set default filters (in order to show only filtered data by default)
    if (
      defaultFilters.items.length === 0 ||
      (defaultFilters.items.length > 0 &&
        filterModel.items.every(
          (item) => item.value === "" || item.value === undefined,
        ))
    ) {
      setFilterModel(defaultFilters);
    }
  }, [filterModel, defaultFilters]);

  const convertDays = (days: number[], format: "ddd" | "dddd"): string[] => {
    const nonWorkingDays = centerState.centerSchedules.nonWorkingDays;
    return days
      .sort() // Sort days in ascending order (from 0 to 7)
      .filter((day) => !(nonWorkingDays && nonWorkingDays[day])) // Remove nonWorkingDays
      .map(
        (day) => capitalizeString(moment().day(day).format(format)), // Convert into text day
      );
  };

  const findLineLabel = (lines: number[]): string[] => {
    return lines
      .sort()
      .map(
        (line) =>
          centerState.centerLines?.find(
            (centerLine: LineModel) => centerLine.lineNumber === line,
          )?.label ?? "",
      );
  };

  // Declare columns of the grid (hidden columns only for filtering)
  const tableColumns: ColumnDefinition<RowData>[] = [
    { id: "date", label: "Date", flex: 1.5, type: "text" },
    { id: "hour", label: "Heure", flex: 1, type: "text" },
    {
      id: "closedDays",
      label: "Jour(s)",
      type: "chip",
      flex: isDesktop ? 3 : 1,
    },
    { id: "lines", label: "Ligne(s)", type: "chip", flex: isDesktop ? 3 : 1 },
    {
      id: "comments",
      label: "Commentaire",
      type: "text",
      flex: isDesktop ? 2 : 0.5,
    },
    { id: "startingDate", label: "Date de début", type: "date", hide: true },
    { id: "endingDate", label: "Date de fin", type: "date", hide: true },
  ];

  // Declare filters
  const filterItems: FilterItem[] = [
    {
      id: 1,
      field: "startingDate",
      label: "Date de début",
      type: "date",
      operator: ">",
      linkedFilterId: 2,
    },
    {
      id: 2,
      field: "endingDate",
      label: "Date de fin",
      type: "date",
      operator: "<",
      linkedFilterId: 1,
    },
    {
      id: 3,
      field: "closedDays",
      label: "Jour(s)",
      type: "chip",
      operator: "contains",
      options: convertDays([1, 2, 3, 4, 5, 6, 0], "dddd"),
      isDayFormat: true,
    },
    {
      id: 4,
      field: "lines",
      label: "Ligne(s)",
      type: "chip",
      operator: "contains",
      options: centerState.centerLines?.map((line) => line.label) || [],
    },
    {
      id: 5,
      field: "comments",
      label: "Commentaire",
      type: "text",
      operator: "contains",
    },
  ];

  // Generate table rows
  const mapDataToRows = (data: ClosingModel[]): RowData[] => {
    return data.map((item) => ({
      id: item.id,
      startingDate: new Date(item.startingDate),
      endingDate: new Date(item.endingDate),
      date: `<strong> ${moment(item.startingDate).format("DD/MM/YYYY")} </strong>
            ${
              item.startingDate !== item.endingDate
                ? `au <strong>${moment(item.endingDate).format("DD/MM/YYYY")}</strong>`
                : ""
            }`,
      hour: `${item.startingHour.slice(0, -3)} - ${item.endingHour.slice(0, -3)}`,
      closedDays: convertDays(item.closedDays, "ddd"),
      lines: findLineLabel(item.lines),
      comments: item.comments,
    }));
  };

  const fetchAllClosings = useCallback(async () => {
    setError(null);
    try {
      const response: AxiosResponse<APIResponsesModel<ClosingModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${centerState.currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${centerState.currentCenter?.pid}/${CONFIG_API.CLOSING}`,
        );
      if (response.data.success) {
        // Fetch created closings only and not public holiday closings
        setAllClosings(
          response.data.items.filter(
            (closing: ClosingModel) => !closing.isPublicHoliday,
          ),
        );
      } else {
        setError(displayError(response.data.messages[0]));
      }
    } catch (error) {
      setError(
        "Une erreur est survenue lors de la récupération des fermetures.",
      );
    }
  }, [centerState]);

  const handleRowClick = (row: RowData) => {
    setShowDrawer(true);
    if (allClosings && allClosings.length > 0) {
      const selectedClosing = allClosings.find(
        (closing) => closing.id === row.id,
      );
      if (selectedClosing) {
        setClosingData(selectedClosing);
      }
    }
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setTimeout(() => {
      // Timeout to be sure to close the side panel before changing its content (to avoid to see the changing during side panel closing)
      setClosingData(undefined);
      sidePanelContent === "publicHolidays" &&
        setSidePanelContent("closingForm");
    }, 300);
  };

  // Updates the closings table by updating or removing closing entries based on the provided data.
  const updateClosingsTable = (
    isDelete: boolean,
    data: ClosingModel | ClosingModel[],
  ): void => {
    if (isDelete) {
      if (Array.isArray(data)) {
        // In case of deleting multiple items, filter out all deleted closings
        const updatedClosings = allClosings?.filter(
          (closing) => !data.some((item) => item.id === closing.id),
        );
        setAllClosings(updatedClosings);
      } else {
        // In case of deleting a single item, filter out the deleted closing
        const updatedClosings = allClosings?.filter(
          (closing) => closing.id !== data.id,
        );
        setAllClosings(updatedClosings);
      }
    } else {
      if (Array.isArray(data)) {
        // In case of updating multiple items
        let updatedClosings = allClosings?.map((closing) => {
          const updatedClosing = data.find((item) => item.id === closing.id);
          return updatedClosing ? updatedClosing : closing;
        });

        // Add new closings if not updating an existing closing
        data.forEach((item) => {
          if (!updatedClosings?.some((closing) => closing.id === item.id)) {
            updatedClosings = updatedClosings
              ? [...updatedClosings, item]
              : [item];
          }
        });

        setAllClosings(updatedClosings);
      } else {
        // In case of updating a single item
        let isExistingClosing = false;

        // Update the existing closing if found
        let updatedClosings = allClosings?.map((closing) => {
          if (closing.id === data.id) {
            isExistingClosing = true;
            return data; // Update the matching closing with new data
          }
          return closing;
        });

        // Add new closing if not updating an existing closing
        if (!isExistingClosing) {
          updatedClosings = allClosings ? [...allClosings, data] : [data];
        }
        setAllClosings(updatedClosings);
      }
    }
  };

  const deleteClosings = async (closingsData: (number | string)[]) => {
    setLoadingDelete(true);

    try {
      const response: AxiosResponse<
        APIResponsesModel<{ id: number; deleted: boolean }>
      > = await api.delete(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${centerState.currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${centerState.currentCenter?.pid}/${CONFIG_API.CLOSING}`,
        { data: closingsData },
      );
      if (response.data.success) {
        const deletedClosings: ClosingModel[] = [];
        response.data.items.forEach((item) => {
          if (item.deleted) {
            const currentClosing = allClosings?.find(
              (closing) => closing.id === item.id,
            );
            if (currentClosing) {
              deletedClosings.push(currentClosing);
            }
          }
        });

        if (deletedClosings.length > 0) {
          updateClosingsTable(true, deletedClosings);
        }

        if (deletedClosings.length === closingsData.length) {
          dispatch(
            setAlert({
              id: "global-alert",
              type: "success",
              message:
                closingsData.length > 1
                  ? "Les fermetures ont bien été supprimées."
                  : "La fermeture a bien été supprimée.",
            }),
          );
        } else if (deletedClosings.length === 0) {
          dispatch(
            setAlert({
              id: "global-alert",
              type: "failure",
              message: `Une erreur est survenue lors de la suppression ${closingsData.length > 1 ? "des fermetures" : "de la fermeture"}.`,
            }),
          );
        } else {
          dispatch(
            setAlert({
              id: "global-alert",
              type: "failure",
              message:
                "Une erreur est survenue lors de la suppression de certaines fermetures.",
            }),
          );
        }
      } else {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "failure",
            message: displayError(response.data.messages[0]),
          }),
        );
      }
    } catch (error) {
      // Dispatch a general error alert if an exception occurred during the deletion process
      dispatch(
        setAlert({
          id: "global-alert",
          type: "failure",
          message: "Une erreur est survenue.",
        }),
      );
    } finally {
      // Reset delete confirmation modal and loading state
      setDeleteConfirmation({ showModal: false, itemsToDelete: [] });
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    const loadSchedules = async () => {
      if (centerState.currentCenter) {
        const response = await dispatch(
          fetchCenterSchedules(
            centerState.currentCenter.organizationPid,
            centerState.currentCenter.pid,
          ),
        );
        if (!response) {
          setError("schedulesError");
        }
      }
    };
    if (
      Object.values(centerState.centerSchedules).some(
        (value) => value === undefined,
      )
    ) {
      loadSchedules();
      // Check if center schedules are already loaded by checking centerSchedules Redux values (if user hasn't access planning page before for example)
    } else {
      fetchAllClosings();
    }
  }, [fetchAllClosings, centerState.centerSchedules]);

  return error ? (
    <ErrorLoadingData
      errorMessage={
        error === "schedulesError"
          ? "Les plages horaires du centre sont incorrectes."
          : error
      }
      retryMessage={error === "schedulesError" ? "Configurer" : undefined}
      retryFunction={
        error === "schedulesError"
          ? () => {
              navigate(
                `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.SCHEDULES_PATH}`,
              );
            }
          : fetchAllClosings
      }
    />
  ) : allClosings && centerState.centerLines ? (
    <>
      <CustomModal
        showLogo={false}
        open={deleteConfirmation.showModal}
        padding={4}
        width={{ xs: "20rem", sm: "42rem" }}
        height="auto"
        textAlign="initial"
      >
        <Stack justifyContent="center" alignItems="center" gap={4}>
          <Typography fontSize={"20px"}>
            Êtes-vous sûr de vouloir supprimer{" "}
            {deleteConfirmation.itemsToDelete.length > 1 ? (
              <>
                ces{" "}
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize={"19px"}
                >
                  {deleteConfirmation.itemsToDelete.length}
                </Typography>{" "}
                fermetures
              </>
            ) : (
              "cette fermeture"
            )}{" "}
            ?
          </Typography>
          <Stack flexDirection="row" gap={1}>
            <Button
              className="btn-base"
              onClick={() => {
                deleteClosings(deleteConfirmation.itemsToDelete);
              }}
              disabled={loadingDelete}
            >
              {loadingDelete ? <Spinner size="sm" /> : "Confirmer"}
            </Button>
            <Button
              className="btn-base btn-outlined"
              onClick={() =>
                setDeleteConfirmation({
                  ...deleteConfirmation,
                  showModal: false,
                })
              }
            >
              Annuler
            </Button>
          </Stack>
        </Stack>
      </CustomModal>
      <Stack gap={generalSpacing} marginLeft={"1rem"}>
        <Stack
          flexDirection={isDesktop ? "row" : "column"}
          alignItems={isDesktop ? "initial" : "center"}
          marginRight={{ xs: "0.5rem", sm: "1rem" }}
          gap={isDesktop ? 0 : "0.5rem"}
        >
          <Typography variant="h2" flex={3}>
            {CONFIG_CTO.CLOSINGS}
          </Typography>
          <Stack flexDirection={"row"} gap={generalSpacing}>
            <Button
              className="btn-base"
              onClick={() => {
                setShowDrawer(true);
              }}
            >
              <FontAwesomeIcon
                icon={faCalendarCirclePlus}
                size="lg"
                className={isDesktop ? "mr-2" : "mr-0"}
              />
              {isDesktop && "Ajouter"}
            </Button>
            <Button
              className="btn-base"
              onClick={() => {
                setSidePanelContent("publicHolidays");
                setShowDrawer(true);
              }}
            >
              Jours fériés
            </Button>
          </Stack>
        </Stack>
        <ListComponent<RowData>
          data={mapDataToRows(allClosings || [])}
          tableColumns={tableColumns}
          selectableRow
          onRowClick={handleRowClick}
          defaultFilters={defaultFilters}
          selectedActions={(selectedIds: (string | number)[]) => (
            <Button
              className="btn-base btn-danger"
              onClick={() =>
                setDeleteConfirmation({
                  showModal: true,
                  itemsToDelete: selectedIds,
                })
              }
            >
              Supprimer
            </Button>
          )}
          filterModel={filterModel}
          filterItems={filterItems}
          setFilterModel={setFilterModel}
        />
        <SidePanel
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          handleCloseDrawer={handleCloseDrawer}
          title={
            sidePanelContent === "publicHolidays"
              ? "Gestion des jours fériés"
              : null
          }
        >
          {sidePanelContent === "publicHolidays" ? (
            <Stack>
              <PublicHolidaysForm handleCloseDrawer={handleCloseDrawer} />
            </Stack>
          ) : (
            <ClosingForm
              key={Date.now()}
              handleCloseDrawer={handleCloseDrawer}
              data={closingData}
              updateClosingsTable={updateClosingsTable}
              padding={"0"}
            />
          )}
        </SidePanel>
      </Stack>
    </>
  ) : (
    <LoadingData text="Chargement des fermetures..." />
  );
};

export default ClosingsList;
