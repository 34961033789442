import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button } from "flowbite-react";
import { AxiosResponse } from "axios";

import { AppDispatch, RootState } from "../../../store/store";
import { api } from "../../../utils/api";
import { APIResponsesModel } from "../../../models/ApiResponseModel";
import { CONFIG_CTO } from "../../../data/config.CTO";
import { CONFIG_API } from "../../../data/config.API";
import { MctSlotModel } from "../../../models/CenterModel";
import { fetchCenterSchedules } from "../../../store/center/center-slice";
import { displayError } from "../../../utils/layout/displayError";

import InfoGrid, { InfoItemModel } from "../../../components/layouts/InfoGrid";
import SidePanel from "../../../components/layouts/SidePanel";
import PublicPortalForm from "./PublicPortalForm";

const PublicPortal = () => {
  const dispatch: AppDispatch = useDispatch();
  const centerState = useSelector((store: RootState) => store.CENTER);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [mctSlotRequestState, setMctSlotRequestState] = useState<{
    items: MctSlotModel[];
    loading: boolean;
  }>({ items: [], loading: true });
  const [mctSlotsItems, setMctSlotsItems] = useState<MctSlotModel[]>([]);
  const [error, setError] = useState<{
    variant: null | "wrongSlotNumber" | "requestError";
    message: string;
  }>({ variant: null, message: "" });

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const [infoItems, setInfoItems] = useState<InfoItemModel[]>([
    {
      name: "isVisibleOnMCT",
      label: "Visible sur le site grand public",
      value: centerState.currentCenter?.isVisibleOnMCT ?? false,
      fullWidth: true,
    },
    {
      name: "isSendSmsMCT",
      label: "Notification SMS à chaque réservation",
      value: centerState.currentCenter?.isSendSmsMCT ?? false,
      hide:
        !centerState.currentCenter?.isVisibleOnMCT ||
        !centerState.currentCenter?.isSendSmsMCT,
    },
    {
      label: "Téléphone",
      value: centerState.currentCenter?.phoneSmsMonControleTechnique,
      hide:
        !centerState.currentCenter?.isVisibleOnMCT ||
        !centerState.currentCenter?.isSendSmsMCT,
    },
    {
      label: "",
      value: "Affichage des créneaux",
      fullWidth: true,
      isTitle: true,
      hide: !centerState.currentCenter?.isVisibleOnMCT,
    },
  ]);

  const loadSchedules = async () => {
    if (centerState.currentCenter) {
      dispatch(
        fetchCenterSchedules(
          centerState.currentCenter.organizationPid,
          centerState.currentCenter.pid,
        ),
      );
    }
  };

  const fetchMctSlotsList = async () => {
    setError({ variant: null, message: "" });
    try {
      if (
        Object.values(centerState.centerSchedules).some(
          (value) => value === undefined,
        )
      ) {
        loadSchedules();
      }
      const response: AxiosResponse<APIResponsesModel<MctSlotModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${centerState.currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${centerState.currentCenter?.pid}/MCTSlot`,
        );
      if (response.data.success) {
        if (response.data.items.length > 0 && response.data.items.length <= 2) {
          setMctSlotRequestState({
            ...mctSlotRequestState,
            loading: false,
            items: response.data.items,
          });
          setMctSlotsItems(response.data.items);
          // Add mctSlots (filter to replace old items)
          const updatedInfo = infoItems.filter(
            (item) => item.name !== "mctSlot",
          );
          response.data.items.map((mctSlot) => {
            updatedInfo.push({
              name: "mctSlot",
              label:
                response.data.items.length === 1 ? "Journée" : mctSlot.label,
              value: `${mctSlot.startHour.slice(0, 5)} - ${mctSlot.endHour.slice(0, 5)}`,
              hide: !centerState.currentCenter?.isVisibleOnMCT,
            });
          });
          setInfoItems(updatedInfo);
        } else {
          setError({
            variant: "wrongSlotNumber",
            message: "Le nombre de créneaux est incorrect.",
          });
        }
      } else {
        setError({
          variant: "requestError",
          message: displayError(response.data.messages[0]),
        });
      }
    } catch (error) {
      setError({
        variant: "requestError",
        message:
          "Une erreur est survenue lors de la récupération des créneaux.",
      });
    } finally {
      setMctSlotRequestState({ ...mctSlotRequestState, loading: false });
    }
  };

  useEffect(() => {
    fetchMctSlotsList();
  }, [centerState.currentCenter]);

  return (
    <>
      {centerState.currentCenter && (
        <div className="flex flex-col">
          {!mctSlotRequestState.loading && (
            <SidePanel
              showDrawer={showDrawer}
              setShowDrawer={setShowDrawer}
              title={`Modification du ${CONFIG_CTO.PUBLIC_PORTAL.toLocaleLowerCase()}`}
              handleCloseDrawer={handleCloseDrawer}
            >
              <PublicPortalForm
                key={Date.now()}
                center={centerState.currentCenter}
                handleCloseDrawer={handleCloseDrawer}
                setInfoItems={setInfoItems}
                mctSlotRequestState={mctSlotRequestState}
                mctSlotsItems={
                  mctSlotsItems.length > 0 && mctSlotsItems.length <= 2
                    ? [...mctSlotsItems]
                    : [
                        {
                          style: "style1",
                          label: "Matin",
                          startHour: "00:00:00",
                          endHour: "13:00:00",
                        },
                        {
                          style: "style1",
                          label: "Après-midi",
                          startHour: "13:00:00",
                          endHour: "23:59:00",
                        },
                      ]
                }
                setMctSlotsItems={setMctSlotsItems}
                error={error}
              />
            </SidePanel>
          )}
          <div className="flex flex-col gap-4 h-screen mr-4 pl-4 lg:pl-0">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col lg:flex-row flex-2 gap-4 lg:gap-0">
                <div className="flex flex-wrap items-center gap-6 flex-1 lg:mt-0 lg:ml-0">
                  <h2 className="text-3xl font-bold">
                    Paramètres {CONFIG_CTO.PUBLIC_PORTAL.toLocaleLowerCase()}
                  </h2>
                </div>

                {centerState.currentCenter.isVisibleOnMCT &&
                  !mctSlotRequestState.loading && (
                    <div className="flex flex-row gap-4 justify-center lg:justify-start">
                      <Button
                        className="btn-base"
                        onClick={() => {
                          setShowDrawer(true);
                        }}
                      >
                        Modifier
                      </Button>
                    </div>
                  )}
              </div>
            </div>

            <div className="p-4 bg-white border border-gray-300 rounded-md gap-4 max-h-[70vh] 2xl:max-h-[78vh] overflow-y-scroll overflow-x-hidden">
              {centerState.currentCenter?.isVisibleOnMCT ? (
                <InfoGrid
                  title="Paramètres"
                  infoItems={infoItems}
                  afterItemsContent={
                    !mctSlotRequestState.loading && error.variant ? (
                      <Alert color="failure" className="w-fit">
                        <div className="flex flex-col items-center gap-4">
                          {error.message}
                          {error.variant !== "wrongSlotNumber" && (
                            <Button
                              className="btn-base"
                              onClick={fetchMctSlotsList}
                            >
                              Réessayer
                            </Button>
                          )}
                        </div>
                      </Alert>
                    ) : !error.variant ? undefined : (
                      <div className="grid grid-cols-2 gap-4">
                        <div className="h-8 w-64 bg-gray-200 rounded-md animate-pulse"></div>
                        <div className="h-8 w-64 bg-gray-200 rounded-md animate-pulse"></div>
                      </div>
                    )
                  }
                />
              ) : (
                <div className="flex flex-row items-center gap-4">
                  <p>
                    {"Ce centre n'est pas visible sur le site grand public"}
                  </p>
                  <Button
                    className="btn-base"
                    onClick={() => {
                      setShowDrawer(true);
                    }}
                  >
                    Modifier
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PublicPortal;
