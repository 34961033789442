import { Box, Chip, Stack, TextField, Typography } from "@mui/material";
import moment from "moment";

import { AppointmentModel } from "../../../models/AppointmentModel";
import InspectionResultChip from "../../common/InspectionResultChip";
import InfoItem from "../../layouts/InfoItem";

interface Props {
  data: AppointmentModel;
}

const InspectionInfo = ({ data }: Props) => {
  return (
    <Stack gap={3}>
      <Typography fontWeight="bold" sx={{ marginBottom: "-0.5rem" }}>
        Informations Contrôle
      </Typography>

      <Stack>
        <Stack
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={6}
        >
          <InfoItem isNotTypography title="Type du contrôle">
            <Box margin="auto">
              <Chip size="small" label={data.inspection.conNatCtl} />
            </Box>
          </InfoItem>

          <InfoItem isNotTypography title="Résultat du contrôle">
            <Box margin="auto">
              <InspectionResultChip
                inspectionResult={
                  data.isCurrent ? "current" : data.inspection.inspectionResult
                }
              />
            </Box>
          </InfoItem>
        </Stack>

        {(data.inspection.checkInHour || data.inspection.startDate) && (
          <Stack flexDirection={"row"} gap={2}>
            <TextField
              label={
                data.isCurrent ? "Heure de prise en charge" : "Heure de début"
              }
              value={
                data.isCurrent
                  ? data.inspection.checkInHour.slice(0, -3)
                  : moment(data.inspection.startDate ?? "").format("HH:mm")
              }
              inputProps={{ readOnly: true }}
              sx={{
                width: { xs: "18rem", sm: data.isCurrent ? "16rem" : "15rem" },
                margin: data.isCurrent ? "auto" : "initial",
              }}
            />

            {data.inspection.endDate && (
              <TextField
                label="Heure de fin"
                value={moment(data.inspection.endDate ?? "").format("HH:mm")}
                inputProps={{ readOnly: true }}
                sx={{ width: { xs: "18rem", sm: "15rem" } }}
              />
            )}
          </Stack>
        )}

        <Stack flexDirection={"row"} gap={2}>
          <TextField
            label="Marque"
            value={data.inspection.vehicle.brand}
            inputProps={{ readOnly: true }}
            sx={{ width: { xs: "18rem", sm: "10rem" } }}
          />
          <TextField
            label="Modèle"
            value={data.inspection.vehicle.model}
            inputProps={{ readOnly: true }}
            sx={{ width: { xs: "18rem", sm: "10rem" } }}
          />
          <TextField
            label="Immat."
            value={data.inspection.vehicle.registrationNumber ?? ""}
            inputProps={{ readOnly: true }}
            sx={{ width: { xs: "18rem", sm: "10rem" } }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InspectionInfo;
