import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, SwipeableDrawer, Typography } from "@mui/material";
import { Tabs } from "flowbite-react";
import { BryntumCalendar } from "@bryntum/calendar-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import { AppDispatch, RootState } from "../../../store/store";
import { editingRecord } from "../../../utils/calendar/calendarConfig";
import { setError } from "../../../store/calendar/appointment-slice";
import {
  ClosingModel,
  ScheduleModel,
} from "../../../models/CenterSettingsModel";
import { ResourceModel } from "../../../models/CalendarModel";
import useZendeskChat from "../../../hooks/layout/useZendeskChat";

import AppointmentForm from "./AppointmentForm";
import AppointmentInfo from "./AppointmentInfo";
import ClosingForm from "./ClosingForm";

// Define a custom event that will be dispatched when the 'customEditor' style changes to 'flex'.
export const displayChangedEvent = new Event("displayChangedToFlex");

interface Props {
  calendar: React.RefObject<BryntumCalendar>;
  resources: ResourceModel[];
  chosenClosingData?: ClosingModel;
  centerSchedules?: ScheduleModel[];
  keyNumber: number;
  setKeyNumber: React.Dispatch<React.SetStateAction<number>>;
  setChosenClosingData?: React.Dispatch<
    React.SetStateAction<ClosingModel | undefined>
  >;
}

type viewTab = "appointment" | "closing";

const EventEditor = ({
  calendar,
  resources,
  centerSchedules,
  chosenClosingData,
  keyNumber,
  setKeyNumber,
  setChosenClosingData,
}: Props) => {
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);
  const storedAppointmentData = useSelector(
    (store: RootState) => store.APPOINTMENT.storedAppointmentData,
  );
  const dispatch: AppDispatch = useDispatch();

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [toggleButtonAlignment, setToggleButtonAlignment] =
    useState<viewTab>("appointment");

  const tabViews: { label: string; value: viewTab }[] = [
    { label: "Rendez-vous", value: "appointment" },
    { label: "Fermeture", value: "closing" },
  ];

  // Use the hook to hide/show Zendesk Chat based on the state of the drawer
  useZendeskChat(showDrawer);

  const handleTabChange = (tabNumber: number) => {
    const newAlignment: viewTab = tabViews[tabNumber].value;
    setToggleButtonAlignment(newAlignment);
  };

  // To open drawer
  useEffect(() => {
    const customEditor = document.getElementById("customEditor");
    if (!customEditor) return; // If the element doesn't exist, exit the effect.
    // Define an event handler that checks if the element's display property is set to "flex". If it is, the state is updated to display the drawer.
    const handleDisplayChange = () => {
      if (customEditor.style.display === "flex") {
        setShowDrawer(true);
        // Reset error to avoid useless error alert (on appointment Form)
        dispatch(setError(null));
      }
    };
    // Attach the 'displayChangedToFlex' event listener to the 'customEditor' element.
    customEditor.addEventListener("displayChangedToFlex", handleDisplayChange);
    // Cleanup: Detach the event listener when the component is unmounted or before re-running the effect.
    return () => {
      customEditor.removeEventListener(
        "displayChangedToFlex",
        handleDisplayChange,
      );
    };
  }, []);

  const handleCloseDrawer = () => {
    const customEditor = document.getElementById("customEditor");
    if (customEditor) {
      customEditor.style.display = "none";
    }
    // Remove new event in Bryntum Event Store if creating is canceled
    if (!editingRecord.data.pid) {
      calendar.current?.instance.eventStore.remove(editingRecord);
    }
    setKeyNumber(keyNumber + 1); // To reset "appointment/closing" tags when drawer is closed
    setShowDrawer(false);
    setChosenClosingData && setChosenClosingData(undefined);
  };

  useEffect(() => {
    // Disable "selected" effect on current event
    if (!showDrawer) {
      calendar.current?.instance.clearEventSelection();
    }
  }, [showDrawer, calendar]);

  useEffect(() => {
    // If from drag creating event open directly the closing form to create a closing and not an appointment
    if (editingRecord?.data.isDragCreated) {
      setToggleButtonAlignment("closing");
    } else {
      setToggleButtonAlignment("appointment");
    }
  }, [editingRecord]);

  return (
    <Stack id={"customEditor"} sx={{ display: "none" }}>
      <SwipeableDrawer
        hideBackdrop={false}
        variant="temporary"
        anchor="right"
        open={showDrawer}
        onClose={handleCloseDrawer}
        onOpen={() => setShowDrawer(true)}
        elevation={5}
        sx={{ width: { xs: "100%", sm: "auto" }, position: "relative" }}
        PaperProps={{
          sx: { width: { xs: "100%", sm: "auto" }, border: "none" },
        }}
      >
        <Typography
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            display: "flex",
            justifyContent: "flex-end",
            zIndex: 9999,
            padding: "1rem 1.5rem",
          }}
        >
          <FontAwesomeIcon
            onClick={handleCloseDrawer}
            icon={faXmark}
            size="lg"
            style={{ cursor: "pointer" }}
          />
        </Typography>
        <Stack width={{ xs: "100%", sm: "32rem" }} bgcolor="white">
          {/* Disable form choice if user comes from search page or creates a closing with dragging  */}
          {!storedAppointmentData.data &&
            !editingRecord?.data.pid &&
            currentUser?.hasPlanningSettingsAccess &&
            !editingRecord?.data.isDragCreated && (
              <Stack margin="auto" paddingTop={"0.5rem"}>
                <Tabs
                  onActiveTabChange={(tabNumber) => handleTabChange(tabNumber)}
                  variant="fullWidth"
                  className="rounded-lg"
                  theme={{
                    base: "flex flex-row gap-2 font-['Inter']",
                    tablist: {
                      variant: {
                        fullWidth:
                          "grid w-full grid-flow-col divide-x divide-gray-200 m-auto rounded-none text-sm font-medium shadow ring-0",
                      },
                      tabitem: {
                        base: "flex items-center justify-center rounded-t-lg px-4 text-sm font-medium first:ml-0",
                        variant: {
                          fullWidth: {
                            base: "ml-0 flex w-full first:ml-0 rounded-none first:rounded-l-lg last:rounded-r-lg",
                            active: {
                              on: "bg-primary-600 p-4 text-white py-2 px-4",
                              off: "bg-white text-gray-500 hover:bg-gray-50 py-2 px-4",
                            },
                          },
                        },
                      },
                    },
                  }}
                >
                  {tabViews.map((view) => (
                    <Tabs.Item key={view.value} title={view.label} />
                  ))}
                </Tabs>
              </Stack>
            )}
          {toggleButtonAlignment === "closing" ? (
            <ClosingForm
              key={Date.now()}
              calendar={calendar}
              handleCloseDrawer={handleCloseDrawer}
              editingRecord={editingRecord?.data}
              centerSchedules={centerSchedules}
              data={chosenClosingData}
            />
          ) : (
            <>
              {editingRecord?.data && editingRecord?.data.pid && (
                <AppointmentInfo
                  key={editingRecord?.id}
                  data={editingRecord?.data}
                />
              )}
              <AppointmentForm
                key={editingRecord?.data.pid ?? "new"}
                calendar={calendar}
                editingRecord={editingRecord}
                resources={resources}
                handleCloseDrawer={handleCloseDrawer}
                showDrawer={showDrawer}
              />
            </>
          )}
        </Stack>
      </SwipeableDrawer>
    </Stack>
  );
};

export default EventEditor;
