import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import packageJson from "../../../../package.json";
import {
  colors,
  generalRadius,
  generalSpacing,
} from "../../../utils/customTheme/customTheme";

interface ReleaseNoteModel {
  version: string;
  date: string;
  improvements?: string[];
  fixes?: string[];
}

const ReleaseNote = () => {
  const versionsList: ReleaseNoteModel[] = [
    {
      version: "3.2.1",
      date: "04/12/2024",
      fixes: [
        `Effacement du texte saisi dans la zone de recherche une fois celle ci effectuée`,
        `Correction des zones inaccessibles du planning après certaines actions`,
        `Désactivation de la complétion par le navigateur dans le formulaire de rendez-vous`,
        `Correction du problème de création de fermeture quand le planning est vide (dans certaines configurations)`,
      ],
    },
    {
      version: "3.2.0",
      date: "25/11/2024",
      improvements: [
        `Modification de l'apparence d'un rendez-vous honoré`,
        `Ajout des options dans l'info-bulle d'un rendez-vous`,
      ],
    },
    {
      version: "3.1.0",
      date: "19/11/2024",
      improvements: [
        `Ajout de la gestion des CGV pour les comptes clients`,
        `Changement de la couleur des boutons d'action`,
      ],
      fixes: [
        `Correction de la recherche d'un utilisateur dans la cas de l'ajout depuis un autre centre`,
        `Correction des contrôles de cohérence de date et heure sur la saisie des fermetures`,
        `Correction de la prise en compte des centres fermés dans la liste des centres`,
      ],
    },
    {
      version: "3.0.0",
      date: "12/11/2024",
      improvements: [`Refonte graphique du menu et de la barre de navigation`],
      fixes: [
        `Correction de la prise en compte du paramètre "Afficher dans rdv" dans les prestations`,
        `Correction du problème de suppression de la valeur dans le champ immatriculation en modification de rendez-vous`,
      ],
    },
    {
      version: "2.17.0",
      date: "16/10/2024",
      improvements: [
        `Ajout d'un bouton de création de compte CTonline Connect sur les utilisateurs`,
        `Possibilité de couper/coller des rendez vous d'un centre à un autre pour les multicentres`,
      ],
      fixes: [
        `Ajout d'un contrôle de cohérence sur les horaires de fermeture`,
        `Correction de l'accès à la recherche lorsque le panneau d'actualités est ouvert`,
        `Correction de l'accès au bouton "Visionner" dans la liste des factures Protechnologies lorsque le panneau d'actualités est ouvert`,
        `Correction de la possibilité de supprimer un rendez vous en dehors des limites autorisées pour les comptes clients`,
      ],
    },
    {
      version: "2.16.1",
      date: "07/10/2024",
      fixes: [
        `Correction du problème de téléchargement des factures Protechnologies`,
        `Correction du problème de déplacement de rendez vous sur des jours non autorisés avec un compte client`,
      ],
    },
    {
      version: "2.16.0",
      date: "03/10/2024",
      improvements: [
        `Ajout d'un paramètre pour les comptes employés : accès aux factures Protechnologies`,
        `Ajout du numéro d'agrément dans la liste des résultat de recherche pour les multi-centres`,
        `Ouveture du panneau des actualités par défaut à la connexion`,
        `Amélioration de la recherche globale : autocomplétion`,
        `Amélioration de la recherche globale : les mots tappés dans la recherche sont conservés`,
        `Refonte de l'espace administration : Écrans utilisateurs`,
        `Refonte de l'espace administration : Factures Protechnologies`,
        `Refonte des écrans de paramétrage centre`,
      ],
      fixes: [
        `Correction du problème de prestations visibles pour les comptes clients`,
        `Correction du problème d'affichage du planning en vue jour si le jour n'est pas ouvert`,
        `Correction du problème de scroll sur certaines fenêtres`,
        `Correction du problème de prise en compte du paramètre "Envoi de sms de rappel sélectionné par défaut"`,
      ],
    },
    {
      version: "2.15.1",
      date: "18/09/2024",
      fixes: [
        `Correction du problème d'accès aux comptes employés`,
        `Correction du problème d'enregistrement de rendez-vous`,
      ],
    },
    {
      version: "2.15.0",
      date: "17/09/2024",
      improvements: [
        `Ajout de la fonction couper/coller sur les rendez-vous`,
        `Prise en compte du paramétrage centre "Rendez-vous possible de J+... à j+..."`,
        `Affichage du nom du client en première ligne et en gras sur la vignette du rendez-vous`,
      ],
      fixes: [
        `Correction du problème d'accès aux comptes clients professionnels lorsque leur compte CTonline Connect est lié à plusieurs clients dans CTonline.`,
        `Correction du problème du choix des dates dans l'écran des fermetures`,
      ],
    },
    {
      version: "2.14.0",
      date: "12/09/2024",
      improvements: [
        `Ajout d'une note de version pour afficher le contenu des mises à jour de l'application`,
        `Ajout d'un chat en direct depuis l'application`,
        `La modification de tarif sur un rendez-vous n'est plus accessible pour les comptes clients professionnels`,
      ],
      fixes: [
        `Correction du problème d'arrondi dans l'affichage du tarif sur l'écran de modification de rendez-vous`,
        `Correction du problème de copie du numéro de téléphone dans le formulaire de création de rendez-vous depuis la recherche`,
      ],
    },
  ];

  return (
    <Stack sx={{ height: "100%", width: "100%" }} gap={generalSpacing}>
      <Box>
        <Typography className="title-card">Note de version</Typography>
        <Typography variant="subtitle2">
          Version actuelle : <b>{packageJson.version}</b>
        </Typography>
      </Box>

      <Box sx={{ overflowY: "auto" }}>
        <Stack gap={2} paddingBottom={"2rem"} bgcolor={"white"}>
          {versionsList.map((item, index) => (
            <Card
              key={index}
              elevation={0}
              sx={{
                marginX: "2rem",
                backgroundColor: "white",
                bgcolor: colors.palette.primary.light,
              }}
            >
              <CardContent>
                <Stack gap={"0.5rem"}>
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={generalRadius}
                    width={"100%"}
                    padding={"0.5rem"}
                  >
                    <Typography fontWeight={700}>
                      Version {item.version}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontStyle: "italic" }}
                    >
                      {item.date}
                    </Typography>
                  </Stack>

                  {item.improvements && item.improvements.length > 0 && (
                    <Stack textAlign={"start"}>
                      <Typography fontWeight={"700"}>
                        {`Amélioration${item.improvements.length > 1 ? "s" : ""}`}
                      </Typography>
                      <List>
                        {item.improvements.map((improvement, idx) => (
                          <ListItem
                            key={idx}
                            sx={{ marginTop: idx !== 0 ? "-1.3rem" : "0" }}
                          >
                            <ListItemText primary={`- ${improvement}`} />
                          </ListItem>
                        ))}
                      </List>
                    </Stack>
                  )}

                  {item.fixes && item.fixes.length > 0 && (
                    <Stack textAlign={"start"}>
                      <Typography fontWeight={"700"}>
                        {`Correction${item.fixes.length > 1 ? "s" : ""}`}
                      </Typography>
                      <List sx={{ gap: 0 }}>
                        {item.fixes.map((fix, idx) => (
                          <ListItem
                            key={idx}
                            sx={{ marginTop: idx !== 0 ? "-1.3rem" : "0" }}
                          >
                            <ListItemText primary={`- ${fix}`} />
                          </ListItem>
                        ))}
                      </List>
                    </Stack>
                  )}
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export default ReleaseNote;
