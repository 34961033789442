import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageLines } from "@fortawesome/pro-regular-svg-icons";

import { AppDispatch, RootState } from "../../store/store";
import { fetchAllNews } from "../../store/news/news-slice";
import { AllNewsModel, MessageModel } from "../../models/NewsModel";

import NewsCard from "./NewsCard";

interface Props {
  showNews: boolean;
  unreadNewsNumber: number;
  setUnreadNewsNumber: React.Dispatch<React.SetStateAction<number>>;
}

const NewsContainer = ({
  showNews,
  unreadNewsNumber,
  setUnreadNewsNumber,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const currentUserState = useSelector(
    (store: RootState) => store.USER.currentUser,
  );
  const currentOrganizationState = useSelector(
    (store: RootState) => store.ORGANIZATION.currentOrganization,
  );
  const skeletonList = Array.from(Array(6).keys());

  const newsList = useSelector((store: RootState) => store.NEWS.newsList);
  const messagesList = useSelector(
    (store: RootState) => store.NEWS.messagesList,
  );
  const isLoading = useSelector((store: RootState) => store.NEWS.loading);
  const [allNews, setAllNews] = useState<AllNewsModel[]>([]);

  const loadNews = useCallback(() => {
    if (currentUserState && currentOrganizationState) {
      dispatch(
        fetchAllNews(
          currentOrganizationState.pid,
          currentUserState.accountType,
          currentUserState.externalUserId,
        ),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (newsList && messagesList) {
      // Filter all messages based on date and the hideMessage flag
      let messages: MessageModel[] = messagesList.filter(
        (message: MessageModel) => !message.hideMessage,
      );

      // Find the last MCT Appointments Summary message (type "5")
      const lastMCTMessage = messages.find((message) => message.type === 5);

      // Remove all messages of type "5" (MCT Appointments Summary) from the initial messages array
      messages = messages.filter((message: MessageModel) => message.type !== 5);

      // Add the latest message of type "5" (MCT Appointments Summary) to the messages
      lastMCTMessage && messages.push(lastMCTMessage);

      // Merge all arrays
      const combinedNews: AllNewsModel[] = [...newsList, ...messages];

      // Sort the array in reverse chronological order
      combinedNews.sort(
        (a, b) =>
          new Date(b.sendDate).getTime() - new Date(a.sendDate).getTime(),
      );

      setAllNews(combinedNews);
    }
  }, [newsList, messagesList]); // This function will be executed every time newsList or messagesList changes.

  useEffect(() => {
    // Set notification badge if there is at least one unread news
    const unreadNews = allNews.filter((element) => element.readDate === null);
    setUnreadNewsNumber(unreadNews.length);
  }, [allNews, setUnreadNewsNumber]);

  useEffect(() => {
    loadNews();
  }, [loadNews, showNews]);

  return (
    <Stack
      flexDirection={"column"}
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
      mt="1rem"
      gap={1.5}
    >
      <Stack>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <FontAwesomeIcon icon={faMessageLines} />
          Actualités
        </Typography>
      </Stack>
      {!isLoading ? (
        allNews && allNews?.length > 0 ? (
          allNews.map((element) => (
            <NewsCard
              key={element.id}
              element={element}
              unreadNewsNumber={unreadNewsNumber}
              setUnreadNewsNumber={setUnreadNewsNumber}
            />
          ))
        ) : (
          <></>
        )
      ) : (
        <Stack m="auto" gap={2} alignItems={"center"}>
          <Typography>Chargement des actualités...</Typography>
          {skeletonList.map((number) => (
            <Stack
              key={number}
              sx={{
                width: "15rem",
                height: "object-fit",
              }}
            >
              <Skeleton variant="rectangular" width="15rem" height={"10rem"} />
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default NewsContainer;
