import { Dropdown } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { AppDispatch, RootState } from "../../../store/store";
import { CONFIG_CTO } from "../../../data/config.CTO";
import { CenterModel } from "../../../models/CenterModel";
import { updateCurrentCenter } from "../../../store/center/center-slice";

import CenterItem from "./CenterItem";

interface Props {
  center: CenterModel;
  centersList: CenterModel[];
}

const CenterPicker = ({ center, centersList }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  // List of page (pathkey) allowed on limited center in order to not redirect user at the center selection if he's already on one of them
  const isLimitedPathList = [
    `/${CONFIG_CTO.ADMINISTRATION_PATH}`,
    `/${CONFIG_CTO.INVOICES_PATH}`,
    `/${CONFIG_CTO.OTHERS_DOCUMENTS_PATH}`,
    `/${CONFIG_CTO.CERTIFICATES_PATH}`,
    `/${CONFIG_CTO.REPORT_PATH}`,
    `/${CONFIG_CTO.ONLINE_PAYMENTS_PATH}`,
    `/${CONFIG_CTO.REMINDER_PATH}`,
    `/${CONFIG_CTO.IMPORT_PATH}_${CONFIG_CTO.REMINDER_PATH}`,
  ];

  const selectCenter = (center: CenterModel) => {
    dispatch(updateCurrentCenter(center));
    center.isLimited &&
      !isLimitedPathList.includes(location.pathname) &&
      navigate(CONFIG_CTO.INVOICES_PATH);
  };

  return (
    <Dropdown
      label=""
      renderTrigger={() => (
        <div className={`max-w-56 xs:max-w-72 sm:max-w-80 lg:max-w-max`}>
          <CenterItem
            centerName={center.name}
            centerColor={center.centerColor}
            centerActivities={center.activities}
            isCurrentCenter={true}
            isItem={false}
            isChangeable={centersList?.length > 1}
          />
        </div>
      )}
      theme={{
        content: "rounded-lg border-none",
        floating: {
          base: `max-h-[90%] mb-2 outline-none w-auto rounded-lg shadow ${centersList?.length > 1 ? "flex-col" : "hidden"} justify-start items-start overflow-y-scroll`,
          header: "p-4",
          item: {
            base: "flex w-full h-auto my-3 cursor-pointer items-center justify-start text-sm text-gray-700 hover:bg-transparent focus:bg-transparent focus:outline-none",
          },
        },
      }}
    >
      <Dropdown.Header>
        <div className="text-gray-900 mt-1 mb-0 text-lg font-semibold font-['Inter'] leading-relaxed">
          Sélectionner un centre
        </div>
      </Dropdown.Header>
      <div className="p-6 outline-none overflow-y-scroll ">
        <div className="w-full text-gray-500 mb-4 text-sm font-normal font-['Inter'] leading-tight">
          {"Connectez-vous sur l'espace gestion d'un autre centre CTonline"}
        </div>
        {centersList.map((item) => (
          <Dropdown.Item key={item.pid} onClick={() => selectCenter(item)}>
            <CenterItem
              centerName={item.name}
              centerColor={item.centerColor}
              centerActivities={item.activities}
              isCurrentCenter={item.pid === currentCenter?.pid}
              isChangeable={false}
            />
          </Dropdown.Item>
        ))}
      </div>
    </Dropdown>
  );
};

export default CenterPicker;
