import { CONFIG_CTO } from "../data/config.CTO";

import CalendarComponent from "../components/calendar/CalendarComponent";
import { AppDispatch, RootState } from "../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateCurrentCenter } from "../store/center/center-slice";

const Calendar = () => {
  document.title = CONFIG_CTO.CALENDAR;

  const dispatch: AppDispatch = useDispatch();
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  useEffect(() => {
    currentCenter &&
      // Refresh Center Settings (in case of new updates)
      dispatch(updateCurrentCenter(currentCenter));
  }, []);

  return <CalendarComponent />;
};

export default Calendar;
