import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";
import { CONFIG_CTO } from "../data/config.CTO";

const Home = () => {
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  if (!currentCenter?.isLimited) {
    // If organization isn't limited redirect to calendar, else redirect to documents pages
    return <Navigate to={`/calendar`} />;
  } else {
    return <Navigate to={`/${CONFIG_CTO.INVOICES_PATH}`} />;
  }
};

export default Home;
