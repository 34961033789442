import { useState } from "react";
import { Alert, Button, Spinner } from "flowbite-react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { CenterModel } from "../../../models/CenterModel";
import { generalSpacing } from "../../../utils/customTheme/customTheme";
import { useSaveCenter } from "../../../hooks/center/useSaveCenter";

interface Props {
  center: CenterModel;
  handleCloseDrawer: () => void;
}

const AppointmentsParametersForm = ({ center, handleCloseDrawer }: Props) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<CenterModel>({
    defaultValues: {
      lineCount: center.lineCount,
      step: center.step,
      slotLength: center.slotLength,
      isAnnexVehicle: center.isAnnexVehicle,
      isCarChoiceLoanEnabled: center.isCarChoiceLoanEnabled,
      numberLoanCar: center.numberLoanCar,
      limDebSit: center.limDebSit,
      limFinSit: center.limFinSit,
      isValetChoiceEnabled: center.isValetChoiceEnabled,
      isParking: center.isParking,
      isToLoad: center.isToLoad,
      isTruckTrailer: center.isTruckTrailer,
      isMailConfirmationEnabledDefault: center.isMailConfirmationEnabledDefault,
    },
  });

  const [calculatedTime, setCalculatedTime] = useState(
    center.step * center.slotLength,
  );

  // Using the custom hook to manage the form submission logic,
  const { saveCenter, requestState } = useSaveCenter(center, handleCloseDrawer);

  return (
    <Stack gap={generalSpacing}>
      <FormControl
        component="form"
        id="appointmentsParametersForm"
        onSubmit={handleSubmit(saveCenter)}
        sx={{ width: "100%", gap: "0.5rem" }}
      >
        <Typography fontWeight="bold">Généraux</Typography>
        <TextField
          {...register("lineCount", {
            required: "Veuillez renseigner un nombre de lignes.",
          })}
          error={Boolean(errors.lineCount)}
          helperText={errors.lineCount?.message}
          onChange={() => {
            Boolean(errors.name) && clearErrors("lineCount");
          }}
          label="Nombre de lignes"
          type="number"
          inputProps={{
            min: 1,
            max: 99,
          }}
        />

        <Stack flexDirection={"row"} gap={generalSpacing}>
          <TextField
            {...register("step", {
              required: "Veuillez renseigner un nombre de pas.",
            })}
            error={Boolean(errors.step)}
            helperText={errors.step?.message}
            onChange={(e) => {
              Boolean(errors.step) && clearErrors("step");
              setCalculatedTime(Number(e.target.value) * watch("slotLength"));
            }}
            label="Durée du pas"
            type="number"
            fullWidth
            inputProps={{
              min: 1,
              max: 99,
            }}
          />
          <TextField
            {...register("slotLength", {
              required: "Veuillez renseigner une durée de créneau.",
            })}
            error={Boolean(errors.slotLength)}
            helperText={errors.slotLength?.message}
            onChange={(e) => {
              Boolean(errors.slotLength) && clearErrors("slotLength");
              setCalculatedTime(Number(e.target.value) * watch("step"));
            }}
            label="Durée du créneau"
            type="number"
            fullWidth
            inputProps={{
              min: 1,
              max: 99,
            }}
          />
        </Stack>

        <Typography
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          fontWeight={"bold"}
        >
          soit {calculatedTime} minute{calculatedTime > 1 ? "s" : ""}
        </Typography>

        <FormControlLabel
          label={"Gestion des véhicules associés"}
          control={
            <Checkbox
              {...register("isAnnexVehicle")}
              defaultChecked={center.isAnnexVehicle}
            />
          }
        />

        <FormControlLabel
          label={"Gestion des voitures de prêt"}
          control={
            <Checkbox
              {...register("isCarChoiceLoanEnabled")}
              defaultChecked={center.isCarChoiceLoanEnabled}
            />
          }
        />

        {watch("isCarChoiceLoanEnabled") === true && (
          <TextField
            {...register("numberLoanCar", {
              required: "Veuillez renseigner une durée de créneau.",
            })}
            error={Boolean(errors.numberLoanCar)}
            helperText={errors.numberLoanCar?.message}
            onChange={() => {
              Boolean(errors.numberLoanCar) && clearErrors("numberLoanCar");
            }}
            label="Nombre de voitures de prêt"
            type="number"
            fullWidth
            inputProps={{
              min: 1,
            }}
          />
        )}

        <FormControlLabel
          label={"Gestion des voituriers"}
          control={
            <Checkbox
              {...register("isValetChoiceEnabled")}
              defaultChecked={center.isValetChoiceEnabled}
            />
          }
        />
        <FormControlLabel
          label={"Gestion du parking"}
          control={
            <Checkbox
              {...register("isParking")}
              defaultChecked={center.isParking}
            />
          }
        />
        <FormControlLabel
          label={"Gestion des charges"}
          control={
            <Checkbox
              {...register("isToLoad")}
              defaultChecked={center.isToLoad}
            />
          }
        />
        <FormControlLabel
          label={"Gestion des prêts de remorques"}
          control={
            <Checkbox
              {...register("isTruckTrailer")}
              defaultChecked={center.isTruckTrailer}
            />
          }
        />

        <Typography fontWeight="bold" sx={{ marginBottom: "-1rem" }}>
          Comptes clients
        </Typography>
        <Stack flexDirection={"row"} marginY={"1rem"} gap={generalSpacing}>
          <TextField
            {...register("limDebSit", {
              required: "Veuillez renseigner une valeur.",
              max: {
                value: 365,
                message: "La valeur ne peut pas être supérieure à 365.",
              },
            })}
            error={Boolean(errors.limDebSit)}
            helperText={errors.limDebSit?.message}
            onChange={() => {
              Boolean(errors.limDebSit) && clearErrors("limDebSit");
            }}
            label="RDV à partir de J+"
            type="number"
            fullWidth
            inputProps={{
              min: 0,
              max: 365,
            }}
          />
          <TextField
            {...register("limFinSit", {
              required: "Veuillez renseigner une valeur.",
              min: {
                value: 1,
                message: "La valeur ne peut pas être inférieure à 1.",
              },
              max: {
                value: 365,
                message: "La valeur ne peut pas être supérieure à 365.",
              },
            })}
            error={Boolean(errors.limFinSit)}
            helperText={errors.limFinSit?.message}
            onChange={() => {
              Boolean(errors.limFinSit) && clearErrors("limFinSit");
            }}
            label="Jusqu'à J+"
            type="number"
            fullWidth
            inputProps={{
              min: 1,
              max: 365,
            }}
          />
        </Stack>

        <Typography fontWeight="bold">Notifications</Typography>

        <FormControlLabel
          label={"Confirmation de rendez-vous par mail par défaut"}
          control={
            <Checkbox
              {...register("isMailConfirmationEnabledDefault")}
              defaultChecked={center.isMailConfirmationEnabledDefault}
            />
          }
        />

        <Stack
          sx={{
            width: "100%",
            position: "sticky",
            paddingY: generalSpacing,
            bottom: 0,
            gap: 2,
            backgroundColor: "white",
          }}
        >
          {requestState.error && (
            <Box>
              <Alert color="failure">{requestState.error}</Alert>
            </Box>
          )}
          <Stack width="fit-content" flexDirection="row" gap={1}>
            <Button
              type="submit"
              className="btn-base"
              disabled={requestState.loading}
            >
              {requestState.loading ? <Spinner size="sm" /> : "Enregistrer"}
            </Button>
          </Stack>
        </Stack>
      </FormControl>
    </Stack>
  );
};

export default AppointmentsParametersForm;
