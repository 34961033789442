import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Stack, Typography } from "@mui/material";
import { Badge } from "flowbite-react";
import moment from "moment";

import { RootState } from "../../../store/store";
import { AppointmentModel } from "../../../models/AppointmentModel";
import { paymentStatusFormatter } from "../../../utils/calendar/paymentStatusFormatter";

import InfoItem from "../../layouts/InfoItem";

type Props = {
  data: null | AppointmentModel;
};

const AppointmentInfo = ({ data }: Props) => {
  const customersList = useSelector(
    (store: RootState) => store.CUSTOMER.customersList,
  );

  const [isProfesionnalCustomer, setIsProfesionnalCustomer] = useState<
    undefined | boolean
  >(undefined);

  useEffect(() => {
    const currentCustomer = customersList?.find(
      (customer) => customer.clientPid === data?.clientPid,
    );
    if (currentCustomer) {
      setIsProfesionnalCustomer(currentCustomer.isProfessional);
    } else {
      setIsProfesionnalCustomer(false);
    }
  }, [customersList]);

  return (
    <Stack
      sx={{
        gap: 2,
        width: "100%",
        position: "sticky",
        top: 0,
        padding: "1rem 1.5rem 2rem",
        borderBottom: "1px solid #E8E3EB",
        boxShadow: "#E8E3EB 15px 1px 40px 0",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      <Typography className="title-card">
        {data?.isHonored ? "Informations du RDV" : "Modification d'un RDV"}
      </Typography>

      {data && (
        <Grid container gap={2} padding="0" sx={{ width: "100%" }}>
          <Grid xs={5} item>
            <InfoItem title="DATE DE CRÉATION">
              {moment(data.creationDate).format("DD/MM/YYYY - HH:mm")}
            </InfoItem>
          </Grid>
          <Grid xs={5} item>
            {data.modificationDate && (
              <InfoItem title="DATE DE MODIFICATION">
                {moment(data.modificationDate).format("DD/MM/YYYY - HH:mm")}
              </InfoItem>
            )}
          </Grid>
          <Grid xs={5} item>
            <InfoItem title="SOURCE" isNotTypography>
              <Badge
                size="sm"
                className={`w-fit h-5 px-2.5 py-0.5 bg-white rounded-md bg-${data.source?.defaultColorHsl}-50`}
              >
                <div
                  className={`text-sm font-medium font-['Inter'] leading-none text-center text-${data.source?.defaultColorHsl}-500`}
                >
                  {data.source?.name}
                </div>
              </Badge>
            </InfoItem>
          </Grid>
          <Grid xs={5} item>
            <InfoItem title="TYPE DE CLIENT">
              {isProfesionnalCustomer ? "Professionnel" : "Particulier"}
            </InfoItem>
          </Grid>
          {data.reservationNumber && (
            <Grid xs={5} item>
              <InfoItem title="N° DE RÉSERVATION">
                {data.reservationNumber}
              </InfoItem>
            </Grid>
          )}
          <Grid xs={5} item>
            <InfoItem title="PAIEMENT">
              {paymentStatusFormatter(data.paymentStatus)}
            </InfoItem>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default AppointmentInfo;
