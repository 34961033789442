import { ReactNode } from "react";
import { Grow, Modal, Paper, Stack, Typography } from "@mui/material";

import CTonlineLogo from "../../assets/images/ctonline-small-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import {
  generalRadius,
  generalSpacing,
} from "../../utils/customTheme/customTheme";

type Props = {
  open: boolean;
  isCenter?: boolean;
  onClose?: () => void;
  showLogo?: boolean;
  width?: { xs: string | number; sm: string | number };
  height?: string | number;
  gap?: string | number;
  padding?: string | number;
  textAlign?: string;
  children: ReactNode;
};

const CustomModal = ({
  children,
  open,
  onClose,
  isCenter = true,
  showLogo = true,
  gap = 0,
  padding = 0,
  width = { xs: "20rem", sm: "30rem" },
  height = "15rem",
  textAlign = "center",
}: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Stack marginTop="1rem" height={isCenter ? "100%" : "85%"}>
        <Grow in={open}>
          <Paper
            sx={{
              width: width,
              height: height,
              textAlign: textAlign,
              borderRadius: generalRadius,
              gap: gap,
              padding: padding,
              display: "flex",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            {onClose && (
              <Typography
                sx={{
                  color: "secondary.main",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  zIndex: 9999,
                  padding: generalSpacing,
                }}
              >
                <FontAwesomeIcon
                  onClick={onClose}
                  icon={faXmark}
                  size="lg"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Typography>
            )}

            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 2,
              }}
            >
              {showLogo && <img src={CTonlineLogo} width={175} alt="Icône" />}
            </Stack>
            {children}
          </Paper>
        </Grow>
      </Stack>
    </Modal>
  );
};

export default CustomModal;
