import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "flowbite-react";
import { Stack, Typography } from "@mui/material";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import { RootState } from "../../store/store";
import { CONFIG_API } from "../../data/config.API";
import useWindowWidth, {
  getBreakpointValue,
  tailwindBreakpoints,
} from "../../hooks/layout/useWindowWidth";

import AuthenticationPage from "./AuthenticationPage";
import NewsContainer from "../news/NewsContainer";
import AlertMessage from "./AlertMessage";
import NavMenu from "./navigation/NavMenu";
import Header from "./header/Header";
import CGVModal from "./CGVModal";

type Props = {
  children: ReactNode;
  msalInstance: PublicClientApplication;
};

const Layout = ({ children, msalInstance }: Props) => {
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const isDesktop = useWindowWidth(getBreakpointValue(tailwindBreakpoints.lg));
  const [isCGVAccepted, setIsCGVAccepted] = useState<boolean>(true);
  const [showNewsDrawer, setShowNewsDrawer] = useState<boolean>(isDesktop);
  const [showMenuDrawer, setShowMenuDrawer] = useState<{
    isOpen: boolean;
    isFullyOpen: boolean;
  }>({ isOpen: isDesktop, isFullyOpen: !isDesktop });
  const [unreadNewsNumber, setUnreadNewsNumber] = useState<number>(0);

  useEffect(() => {
    if (currentUser?.accountType === CONFIG_API.CLIENT) {
      // Disable news for customer account
      setShowNewsDrawer(false);

      // Timeout to avoid modal flash
      const cgvModalTimeout = setTimeout(() => {
        // Disable access without accepting CGV for customer account (if not accepted before, info stored in sessionStorage)
        const CGVAcceptedStorageValue = sessionStorage.getItem("isCGVAccepted");
        setIsCGVAccepted(
          CGVAcceptedStorageValue ===
            `${currentCenter?.pid}-${currentUser?.externalUserId}`,
        );
      }, 100);

      // Cleanup the timeout if dependencies change or component unmounts
      return () => clearTimeout(cgvModalTimeout);
    }
  }, [currentUser, currentCenter]);

  useEffect(() => {
    // Update based on desktop/mobile
    setShowMenuDrawer({ isOpen: isDesktop, isFullyOpen: !isDesktop });
    if (!isDesktop) {
      setShowNewsDrawer(false);
    }
  }, [isDesktop]);

  // Debounce function to limit how often a function can be called (for opening menu)
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer); // Clear the previous timer to reset the wait period
      // Set a new timer, calling the function after the delay
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Function to handle the opening/closing of the menu with debounce
  const handleMenuToggle = debounce((value: boolean) => {
    if (isDesktop) {
      setShowMenuDrawer((prevState) => ({
        ...prevState,
        isFullyOpen: value,
      }));
    }
  }, 50); // 50ms delay

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticationPage>
        <Stack height="100vh">
          <Header
            unreadNewsNumber={unreadNewsNumber}
            showNewsDrawer={showNewsDrawer}
            showMenuDrawer={showMenuDrawer}
            setShowNewsDrawer={setShowNewsDrawer}
            toggleMenuDrawer={() =>
              setShowMenuDrawer({
                isOpen: !showMenuDrawer.isOpen,
                isFullyOpen: true,
              })
            }
          />
          {currentUser?.accountType !== CONFIG_API.CLIENT && (
            <Drawer
              position={"right"}
              backdrop={false}
              open={showNewsDrawer}
              onClose={() => setShowNewsDrawer(false)}
              theme={{
                root: {
                  base: "fixed z-40 w-10 overflow-y-auto bg-white p-0 transition-transform overflow-x-hidden border-l border-gray-200",
                  position: {
                    right: {
                      on: "right-0 top-0 h-screen w-fit transform-none",
                    },
                  },
                },
              }}
            >
              <Stack
                width={"300px"}
                margin={"auto"}
                className="pt-24"
                sx={{ height: "100vh", overflowY: "auto" }}
              >
                <Typography
                  onClick={() => {
                    setShowNewsDrawer(false);
                  }}
                  sx={{
                    position: "absolute",
                    top: 80,
                    left: 0,
                    marginTop: "1rem",
                    marginLeft: "1rem",
                  }}
                >
                  <FontAwesomeIcon
                    title="Fermer les actualités"
                    icon={faChevronRight}
                    size="lg"
                    style={{ cursor: "pointer" }}
                  />
                </Typography>
                <NewsContainer
                  showNews={showNewsDrawer}
                  unreadNewsNumber={unreadNewsNumber}
                  setUnreadNewsNumber={setUnreadNewsNumber}
                />
              </Stack>
            </Drawer>
          )}
          <Drawer
            backdrop={false}
            open={showMenuDrawer.isOpen}
            onClose={() =>
              setShowMenuDrawer({ isOpen: true, isFullyOpen: false })
            }
            theme={{ root: { backdrop: "z-400" } }}
            className={`w-full h-full overflow-x-hidden ${showMenuDrawer.isFullyOpen ? "lg:w-64" : "w-14"} p-0 flex-col justify-start items-start gap-4 inline-flex transition-all border-r border-gray-200`}
            onMouseEnter={() => handleMenuToggle(true)}
            onMouseLeave={() => handleMenuToggle(false)}
          >
            <NavMenu
              showMenuDrawer={showMenuDrawer}
              toggleMenuDrawer={() =>
                setShowMenuDrawer({ isOpen: false, isFullyOpen: true })
              }
            />
          </Drawer>
          <div
            id="global-container"
            className={`relative overflow-hidden pt-2 pl-2 sm:pt-4 h-[110vh] lg:ml-14 ${showNewsDrawer ? "mr-72 pr-2" : "mr-0"} flex flex-col z-1`}
          >
            {currentUser?.accountType === CONFIG_API.CLIENT &&
              !isCGVAccepted && (
                <CGVModal setIsCGVAccepted={setIsCGVAccepted} />
              )}
            <AlertMessage id="global-alert" />
            {children}
          </div>
        </Stack>
      </AuthenticationPage>
    </MsalProvider>
  );
};

export default Layout;
