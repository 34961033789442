import { useEffect, useRef } from "react";
import { Button } from "flowbite-react";

interface Props {
  editorContent: string | null;
  setEditorContent: React.Dispatch<React.SetStateAction<string | null>>;
  requestLoading: boolean;
  onSave: () => void;
}

const TextEditor = ({
  editorContent,
  requestLoading,
  setEditorContent,
  onSave,
}: Props) => {
  const editorRef = useRef<HTMLDivElement>(null);

  // Fill the editor with initial content or any external changes (e.g., from API)
  useEffect(() => {
    if (
      editorContent &&
      editorRef.current &&
      editorRef.current.innerHTML !== editorContent
    ) {
      editorRef.current.innerHTML = editorContent;
    }
  }, [editorContent]); // Run only when `editorContent` is updated

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();

      // Get pasted content as plain text
      const text = event.clipboardData?.getData("text/plain");

      if (text && editorRef.current) {
        // Insert clean text into the editor
        document.execCommand("insertText", false, text);
      }
    };

    // Attach paste event listener
    const editor = editorRef.current;
    editor?.addEventListener("paste", handlePaste);

    // Clean up the event listener on component unmount
    return () => {
      editor?.removeEventListener("paste", handlePaste);
    };
  }, []);

  // Update state when user modifies the text
  const handleInput = () => {
    if (editorRef.current) {
      setEditorContent(editorRef.current.innerHTML);
    }
  };

  const applyStyle = (command: string, value = "") => {
    if (command === "fontSize") {
      document.execCommand(command, false, value);
    } else {
      document.execCommand(command);
    }
  };

  return (
    <>
      <div className="w-full h-96 border border-gray-200 rounded-lg font-['Inter'] overflow-hidden ">
        <div className="h-10 px-3 bg-gray-50 overflow-hidden relative">
          <div className="flex items-center space-x-1 flex-wrap fixed w-full ">
            <button
              onClick={() => applyStyle("bold")}
              title="Gras"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <strong>G</strong>
            </button>
            <button
              onClick={() => applyStyle("italic")}
              title="Italique"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <em>I</em>
            </button>
            <button
              onClick={() => applyStyle("underline")}
              title="Souligné"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <u>S</u>
            </button>
            <span
              className="w-px h-6 bg-gray-300 mx-2"
              aria-hidden="true"
            ></span>
            <button
              onClick={() => applyStyle("justifyLeft")}
              title="Aligner à gauche"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <span className="sr-only">Aligner à gauche</span>
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                <path
                  d="M4 6h16M4 12h10M4 18h16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={() => applyStyle("justifyCenter")}
              title="Centrer"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <span className="sr-only">Centrer</span>
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                <path
                  d="M4 6h16M7 12h10M4 18h16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={() => applyStyle("justifyRight")}
              title="Aligner droite"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <span className="sr-only">Aligner à droite</span>
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                <path
                  d="M4 6h16M10 12h10M4 18h16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <span
              className="w-px h-6 bg-gray-300 mx-2"
              aria-hidden="true"
            ></span>
            <button
              onClick={() => applyStyle("fontSize", "1")}
              title="Petit"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <span className="text-xs">A</span>
            </button>
            <button
              onClick={() => applyStyle("fontSize", "3")}
              title="Moyen"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <span className="text-sm">A</span>
            </button>
            <button
              onClick={() => applyStyle("fontSize", "5")}
              title="Grand"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <span className="text-lg">A</span>
            </button>
            <button
              onClick={() => applyStyle("fontSize", "6")}
              title="Très grand"
              type="button"
              className="p-1.5 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            >
              <span className="text-xl">A</span>
            </button>
          </div>
        </div>

        <div
          ref={editorRef}
          contentEditable
          suppressContentEditableWarning
          onInput={handleInput}
          className="px-4 py-2 h-[88%] rounded-b-lg focus:outline-none bg-white text-gray-800 overflow-x-hidden"
        >
          {/* Textarea Content */}
        </div>
      </div>

      <Button
        color="blue"
        className="btn-base"
        disabled={requestLoading}
        onClick={onSave}
      >
        Enregistrer
      </Button>
    </>
  );
};

export default TextEditor;
