import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useWindowWidth from "../../hooks/layout/useWindowWidth";

export type MenuItemModel = {
  name?: string;
  icon?: IconProp;
  iconName?: string;
  color?: string;
  text: string;
  onClick?: () => void;
  navigateTo?: string;
  externalLink?: string;
  locationState?: string | number | null;
  condition?: () => boolean | undefined;
};

const BasicMenuItem = ({
  text,
  icon,
  onClick,
  navigateTo,
  externalLink,
  locationState = null,
}: MenuItemModel) => {
  const navigate: NavigateFunction = useNavigate();
  const isDesktop = useWindowWidth();

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo, { state: locationState });
    } else if (onClick) {
      onClick();
    } else if (externalLink) {
      window.open(externalLink, "_blank");
    }
  };

  return (
    <MenuItem onClick={handleClick} dense={!isDesktop}>
      <Stack width={"100%"} direction="row" p="0 0.5rem">
        {icon && (
          <ListItemIcon>
            <FontAwesomeIcon icon={icon} size="lg" />
          </ListItemIcon>
        )}
        <ListItemText>
          <Typography fontWeight={700} fontSize={"0.9rem"}>
            {text}
          </Typography>
        </ListItemText>
      </Stack>
    </MenuItem>
  );
};

export default BasicMenuItem;
