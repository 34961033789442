/**
 * The AlertMessage component is responsible for displaying a single alert message
 * at the center of the screen with no animations.
 * The alert is removed after 2.5 seconds.
 */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "flowbite-react";

import { AppDispatch, RootState } from "../../store/store";
import { AlertModel, clearAlert } from "../../store/layout/alert-slice";

interface Props {
  id: string;
}

const AlertMessage = ({ id }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const alertState = useSelector((store: RootState) =>
    store.ALERT.alerts.find((alert) => alert.id === id),
  );

  const [currentAlert, setCurrentAlert] = useState<null | AlertModel>(null);

  // Updates the current alert when a new alert is available
  useEffect(() => {
    if (alertState && currentAlert?.id !== alertState.id) {
      setCurrentAlert(alertState);
      setTimeout(() => {
        dispatch(clearAlert(alertState.id));
        setCurrentAlert(null);
      }, 2500);
    }
  }, [alertState, currentAlert, dispatch]);

  // If no alert is present, do not render anything
  if (!currentAlert) {
    return null;
  }

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
      <Alert color={currentAlert.type} className="font-bold">
        <span dangerouslySetInnerHTML={{ __html: currentAlert.message }} />
      </Alert>
    </div>
  );
};

export default AlertMessage;
