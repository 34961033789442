import { useCallback, useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Avatar, Dropdown } from "flowbite-react";

import { AppDispatch, RootState, persistor } from "../../store/store";
import { CONFIG_CTO } from "../../data/config.CTO";
import { CONFIG_API } from "../../data/config.API";
import {
  resetOrganizationSlice,
  setCurrentOrganization,
} from "../../store/organization/organization-slice";
import { setCurrentUser, setUsersList } from "../../store/user/user-slice";
import { MenuItemModel } from "../common/BasicMenuItem";
import { setStoredAppointmentData } from "../../store/calendar/appointment-slice";
import { resetCenterSlice, setCenters } from "../../store/center/center-slice";
import { setMessages, setNews } from "../../store/news/news-slice";
import useWindowWidth from "../../hooks/layout/useWindowWidth";
import customTheme from "../../utils/customTheme/customTheme";
import packageJson from "../../../package.json";

import CustomModal from "../layouts/CustomModal";
import ReleaseNote from "../layouts/header/ReleaseNote";
import CustomIcon from "../layouts/CustomIcon";

type Props = {
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileMenu = ({ setShowModal }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const isDesktop = useWindowWidth(customTheme.breakpoints.values.sm);

  const organizationState = useSelector(
    (store: RootState) => store.ORGANIZATION,
  );
  const userState = useSelector((store: RootState) => store.USER);
  const appointmentState = useSelector((store: RootState) => store.APPOINTMENT);

  const [showReleaseModal, setShowReleaseModal] = useState(false);

  // Generate Initials avatar from Azure User Name
  const stringAvatar = (name: string): string => {
    if (!name) {
      // If neither first name or last name : show user icon
      return "";
    }
    const words = name.trim().split(" ").filter(Boolean);
    let initials = "";

    if (words.length >= 2) {
      // Get first initial and last initial for multi-word names
      initials = `${words[0][0]}${words[1][0]}`;
    } else if (words.length === 1) {
      // Get single initial for single-word names
      initials = words[0][0];
    }

    return initials.toUpperCase();
  };

  // LOGOUT
  const handleLogout = useCallback(async () => {
    try {
      setShowModal && setShowModal(true);

      dispatch(resetOrganizationSlice());
      localStorage.clear();

      await persistor.purge();

      await instance.logout({
        authority: process.env.REACT_APP_AZURE_BASE_URL,
        postLogoutRedirectUri: `/`,
      });
    } catch (error) {
      console.error("Error during logout process:", error);
    }
  }, [instance, setShowModal]);

  // Reset CurrentOrganization in store to select an new one (reset profile, center & news states as well)
  const clearCurrentOrganization = useCallback(() => {
    dispatch(setCurrentOrganization(null));
    dispatch(resetCenterSlice());
    dispatch(setUsersList(undefined));
    if (!userState.currentUser?.isSuperAdministrator) {
      // Keep user to show organization form modal when clear user data if SuperAdministrator
      dispatch(setCurrentUser(undefined));
    }
    if (appointmentState.storedAppointmentData) {
      // Reset if an event is stored (by cut, copy or search)
      dispatch(
        setStoredAppointmentData({
          variant: null,
          data: null,
          isCenterChanged: false,
        }),
      );
    }
    dispatch(setCenters(undefined));
    dispatch(setNews(undefined));
    dispatch(setMessages(undefined));
    // Remove stored date & view in the session storage
    sessionStorage.removeItem("calendarActiveView");
    sessionStorage.removeItem("calendarActiveDate");
    // navigate to home page to avoid forbidden page access if the new organization is limited
    navigate("/");
  }, [dispatch, navigate]);

  // Reset CurrentProfile in store to select a new one
  const clearCurrentProfile = useCallback(() => {
    dispatch(setCurrentUser(undefined));
  }, [dispatch]);

  // Define a list of all potential menu items and conditions for adding them
  const potentialMenuItems = useMemo(
    () => [
      {
        name: CONFIG_CTO.CHANGE_ORGANIZATION,
        text: CONFIG_CTO.CHANGE_ORGANIZATION,
        iconName: "garageOutline",
        onClick: clearCurrentOrganization,
        condition: () =>
          (organizationState.userOrganizations &&
            organizationState.userOrganizations?.length > 1) ||
          userState.currentUser?.isSuperAdministrator,
      },
      {
        name: CONFIG_CTO.CHANGE_PROFILE,
        text: CONFIG_CTO.CHANGE_PROFILE,
        iconName: "profileCardOutline",
        onClick: clearCurrentProfile,
        condition: () => userState.usersList && userState.usersList?.length > 1,
      },
      {
        name: CONFIG_CTO.ADMINISTRATION,
        text:
          userState.currentUser?.accountType !== CONFIG_API.ADMIN
            ? "Factures Protechnologies"
            : CONFIG_CTO.ADMINISTRATION,
        iconName:
          userState.currentUser?.accountType !== CONFIG_API.ADMIN
            ? "folderOutline"
            : "pasteOutline",
        onClick: () => navigate(`/${CONFIG_CTO.ADMINISTRATION_PATH}`),
        condition: () =>
          userState.currentUser &&
          (userState.currentUser.accountType === CONFIG_API.ADMIN ||
            userState.currentUser.hasInvoiceAccess),
      },
    ],
    [
      clearCurrentOrganization,
      clearCurrentProfile,
      organizationState.userOrganizations,
      userState.currentUser?.isSuperAdministrator,
      userState.usersList,
    ],
  );

  const menuItems = useMemo(() => {
    const items: MenuItemModel[] = [
      {
        name: CONFIG_CTO.LOGOUT,
        text: CONFIG_CTO.LOGOUT,
        iconName: "arrowRightToBracket",
        onClick: handleLogout,
        color: "text-red-500",
      },
    ];

    // Check each potential menu item to see if its conditions are met
    potentialMenuItems.forEach((potentialItem) => {
      if (potentialItem.condition()) {
        // Additional logic to avoid duplicates could also be added here after rerender
        const isItemAlreadyInMenu = items.some(
          (item) => item.name === potentialItem.name,
        );
        if (!isItemAlreadyInMenu) {
          // Add new item BEFORE logout item
          items.push(potentialItem);
        }
      }
    });

    // Reverse items so that "Logout" is always at the end
    return items.reverse();
  }, [handleLogout, potentialMenuItems]);

  const handleClick = (item: MenuItemModel) => {
    if (item.navigateTo) {
      navigate(item.navigateTo, { state: item.locationState });
    } else if (item.onClick) {
      item.onClick();
    } else if (item.externalLink) {
      window.open(item.externalLink, "_blank");
    }
  };

  return (
    <>
      <CustomModal
        onClose={() => setShowReleaseModal(false)}
        open={showReleaseModal}
        isCenter={!isDesktop}
        height={"100%"}
        width={{ xs: "100%", sm: "40rem" }}
      >
        <ReleaseNote />
      </CustomModal>

      <Dropdown
        renderTrigger={() => (
          <div>
            <Avatar
              className="text-center text-xs font-medium font-['Inter'] leading-3 cursor-pointer"
              rounded
              size="sm"
              theme={{ root: { initials: { text: "text-gray-900" } } }}
              placeholderInitials={stringAvatar(accounts[0].name ?? "")}
            />
          </div>
        )}
        theme={{
          content: "rounded-lg border-none",
          floating: {
            base: `bg-white rounded-lg shadow flex-col justify-start items-start inline-flex outline-none z-[9999]`,
            item: {
              base: "flex w-full cursor-pointer items-center justify-start px-4 py-2 gap-3 outline-none text-sm text-gray-700 hover:bg-gray-100",
            },
            header:
              "h-9 px-4 py-2 justify-start items-center gap-3 inline-flex",
          },
        }}
      >
        <Dropdown.Header>
          <div className="text-gray-700 text-sm font-normal font-['Inter'] leading-tight">
            {accounts[0].username}
          </div>
        </Dropdown.Header>

        {menuItems.map((item) => (
          <Dropdown.Item
            key={item.text}
            icon={() => (
              <CustomIcon name={item.iconName ?? ""} className={item.color} />
            )}
            onClick={() => handleClick(item)}
          >
            <div
              className={`${item.color ? item.color : "text-gray-700"} text-sm font-normal font-['Inter'] leading-tight`}
            >
              {item.text}
            </div>
          </Dropdown.Item>
        ))}

        <div className="h-9 px-4 py-2 justify-start items-center gap-3 inline-flex ">
          <div
            className="text-gray-300 text-sm font-normal font-['Inter'] leading-tight cursor-pointer"
            onClick={() => {
              setShowReleaseModal(true);
            }}
          >
            CTonline v.{packageJson.version}
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default ProfileMenu;
