export const paymentStatusFormatter = (paymentStatus: number): string => {
  switch (paymentStatus) {
    case 0:
      return "Non payé";
    case 1:
      return "Payé";
    case 2:
      return "En attente de paiement";
    case 3:
      return "Partiellement payé";
    case 4:
      return "Payé en ligne";
    default:
      return "Inconnu";
  }
};
