import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { EventStore } from "@bryntum/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faCar,
  faCircleEuro,
  faCircleParking,
  faKey,
  faTrailer,
  faUserTie,
  faWeightHanging,
  faGlobePointer,
} from "@fortawesome/pro-regular-svg-icons";

import { RootState } from "../../../store/store";
import { CONFIG_CTO } from "../../../data/config.CTO";
import FoxyBlackIcon from "../../../assets/images/icons/appointment-options/foxy-black.svg";
import { AppointmentModel } from "../../../models/AppointmentModel";

interface Option {
  label: string;
  icon?: IconDefinition;
  imgSrc?: string;
}

interface Props {
  appointmentsList: AppointmentModel[] | EventStore;
}

const InfoIcons = ({ appointmentsList }: Props) => {
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );
  const customersList = useSelector(
    (store: RootState) => store.CUSTOMER.customersList,
  );

  const [centerOptions, setCenterOptions] = useState<Option[]>([]);

  useEffect(() => {
    const newOptions: Option[] = [];
    // Define conditions to add options
    const conditions = [
      {
        check: customersList && customersList?.length > 0,
        label: CONFIG_CTO.ACCOUNT_CUSTOMER,
        icon: faUserTie,
      },
      {
        check: appointmentsList?.some(
          (appointment) => appointment.paymentStatus === 0,
        ),
        label: CONFIG_CTO.UNPAID_APPOINTMENT,
        icon: faCircleEuro,
      },
      {
        check: currentCenter?.isParking,
        label: CONFIG_CTO.PARKING,
        icon: faCircleParking,
      },
      {
        check: currentCenter?.isCarChoiceLoanEnabled,
        label: CONFIG_CTO.LOAN_CAR,
        icon: faCar,
      },
      {
        check: currentCenter?.isValetChoiceEnabled,
        label: CONFIG_CTO.VALET,
        icon: faKey,
      },
      {
        check: currentCenter?.isTruckTrailer,
        label: CONFIG_CTO.TRUCK_TRAILER,
        icon: faTrailer,
      },
      {
        check: currentCenter?.isToLoad,
        label: CONFIG_CTO.TO_LOAD,
        icon: faWeightHanging,
      },
      {
        check: appointmentsList?.some(
          (appointment) =>
            appointment.source?.name !== "MCT" &&
            appointment.source?.name !== "CTonline",
        ),
        label: "Rendez-vous internet",
        icon: faGlobePointer,
      },
      {
        check: appointmentsList?.some(
          (appointment) => appointment.source?.name === "MCT",
        ),
        label: "Mon Contrôle Technique",
        imgSrc: FoxyBlackIcon,
      },
    ];

    // Iterate over conditions and add to newOptions if the condition is met
    conditions.forEach((condition) => {
      if (condition.check) {
        newOptions.push({
          label: condition.label,
          icon: condition.icon,
          imgSrc: condition.imgSrc,
        });
      }
    });
    setCenterOptions(newOptions);
  }, [currentCenter, customersList]);

  return (
    <Stack padding={2} gap={0}>
      <Typography
        fontWeight="bold"
        textAlign="center"
        color="black"
        marginBottom={"0.2rem"}
      >
        Icônes
      </Typography>
      {centerOptions.map((option) => (
        <Stack
          key={option.label}
          flexDirection={"row"}
          color={"black"}
          gap={option.imgSrc ? 0.4 : 1}
          alignItems={"center"}
        >
          {option.imgSrc ? (
            <img
              src={option.imgSrc}
              alt={option.label}
              style={{ width: "1.8em", marginLeft: "-0.1rem" }}
            />
          ) : (
            option.icon && <FontAwesomeIcon icon={option.icon} size="lg" />
          )}
          <Typography>{option.label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default InfoIcons;
