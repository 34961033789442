import { useSelector } from "react-redux";
import { Stack } from "@mui/material";

import { RootState } from "../../store/store";
import { CONFIG_URL } from "../../data/config.URL";
import useIframeParams, {
  ParamsModel,
} from "../../hooks/authorization/useIframeParams";

import ErrorDisplay from "./ErrorDisplay";

type Props = {
  iframeData: string;
};

const IframeCTO = ({ iframeData }: Props) => {
  const params: ParamsModel = useIframeParams();

  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  return (
    <Stack
      overflow={"hidden"}
      height="100%"
      width={"100%"}
      margin={"0 0 2rem"}
      paddingBottom={"1rem"}
      bgcolor={"white"}
    >
      <iframe
        id="ctframe"
        title="Navigation dans l'application web CTonline"
        sandbox="allow-same-origin allow-scripts allow-downloads allow-modals allow-popups allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-popups-to-escape-sandbox"
        src={`https://${params.organisationCode}${process.env.REACT_APP_CTONLINE_BASE_URL}/${params.organisationCode}/${iframeData}.aspx?Ctr=${currentCenter?.centerId}&${CONFIG_URL.ORGANIZATION_CODE}=${params.organisationCode}&${CONFIG_URL.IS_SUPERADMINISTRATOR}=${params.isProtech}&${CONFIG_URL.IS_CTONLINE_LIMITED}=${params.isLimited}&${CONFIG_URL.USERNAME}=${params.userName}&${CONFIG_URL.LOGINTYPE}=${params.loginType}&${CONFIG_URL.TIMESTAMP}=${params.connexionTimeStamp}&${CONFIG_URL.SIGNATURE}=${params.signature}`}
        className="iframe"
        width="100%"
        height="100%"
      >
        <ErrorDisplay variant="iframeDisplay" displayName={`la page`} />
      </iframe>
    </Stack>
  );
};

export default IframeCTO;
