import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "flowbite-react";

import { RootState } from "../../../store/store";
import InfoGrid, { InfoItemModel } from "../../../components/layouts/InfoGrid";

import SidePanel from "../../../components/layouts/SidePanel";
import AppointmentsParametersForm from "./AppointmentsParametersForm";

const AppointmentsParameters = () => {
  const centerState = useSelector((store: RootState) => store.CENTER);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const appointmentsInfo: InfoItemModel[] = [
    {
      label: "Nombre de lignes",
      value: String(centerState.currentCenter?.lineCount),
      fullWidth: true,
      category: "general",
    },
    {
      label: "Durée du pas",
      value: `${String(centerState.currentCenter?.step)} minute${centerState.currentCenter && centerState.currentCenter?.step > 1 ? "s" : ""}`,
      category: "general",
    },
    {
      label: "Durée du créneau",
      value: `${String(centerState.currentCenter?.slotLength)} pas`,
      afterText:
        centerState.currentCenter &&
        `(soit ${centerState.currentCenter?.slotLength * centerState.currentCenter?.step} minutes)`,
      category: "general",
    },
    {
      label: "",
      value: "Rendez-vous possible",
      isTitle: true,
      fullWidth: true,
      category: "customer",
    },
    {
      label: "À partir de J+",
      value: `${String(centerState.currentCenter?.limDebSit)}`,
      category: "customer",
    },
    {
      label: "Jusqu'à J+",
      value: `${String(centerState.currentCenter?.limFinSit)}`,
      category: "customer",
    },
    {
      name: "isAnnexVehicle",
      label: "Gestion des véhicules associés",
      value: centerState.currentCenter?.isAnnexVehicle ?? false,
      fullWidth: true,
      hide: !centerState.currentCenter?.isAnnexVehicle,
      category: "general",
    },
    {
      name: "isCarChoiceLoanEnabled",
      label: "Gestion des voitures de prêt",
      value: centerState.currentCenter?.isCarChoiceLoanEnabled ?? false,
      hide: !centerState.currentCenter?.isCarChoiceLoanEnabled,
      afterText: `(${String(centerState.currentCenter?.numberLoanCar)} voiture${centerState.currentCenter?.numberLoanCar && centerState.currentCenter?.numberLoanCar > 1 ? "s" : ""})`,
      category: "general",
    },
    {
      name: "isValetChoiceEnabled",
      label: "Gestion des voituriers",
      value: centerState.currentCenter?.isValetChoiceEnabled ?? false,
      fullWidth: true,
      hide: !centerState.currentCenter?.isValetChoiceEnabled,
      category: "general",
    },
    {
      name: "isParking",
      label: "Gestion du parking",
      value: centerState.currentCenter?.isParking ?? false,
      fullWidth: true,
      hide: !centerState.currentCenter?.isParking,
      category: "general",
    },
    {
      name: "isToLoad",
      label: "Gestion des charges",
      value: centerState.currentCenter?.isToLoad ?? false,
      fullWidth: true,
      hide: !centerState.currentCenter?.isToLoad,
      category: "general",
    },
    {
      name: "isTruckTrailer",
      label: "Gestion des prêts de remorques",
      value: centerState.currentCenter?.isTruckTrailer ?? false,
      fullWidth: true,
      hide: !centerState.currentCenter?.isTruckTrailer,
      category: "general",
    },
    {
      name: "isMailConfirmationEnabledDefault",
      label: "Confirmation de rendez-vous par mail par défaut",
      value:
        centerState.currentCenter?.isMailConfirmationEnabledDefault ?? false,
      fullWidth: true,
      hide: !centerState.currentCenter?.isMailConfirmationEnabledDefault,
      category: "notification",
    },
  ];

  return (
    <>
      {centerState.currentCenter && (
        <div className="flex flex-col">
          <SidePanel
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            title="Modification des paramètres rendez-vous"
            handleCloseDrawer={handleCloseDrawer}
          >
            <AppointmentsParametersForm
              key={Date.now()}
              center={centerState.currentCenter}
              handleCloseDrawer={handleCloseDrawer}
            />
          </SidePanel>

          <div className="flex flex-col gap-4 h-screen mr-4 pl-4 lg:pl-0">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col lg:flex-row flex-2 gap-4 lg:gap-0">
                <div className="flex flex-wrap items-center gap-6 flex-1 lg:mt-0 lg:ml-0">
                  <h2 className="text-3xl font-bold">Paramètres rendez-vous</h2>
                </div>

                <div className="flex flex-row gap-4 justify-center lg:justify-start">
                  <Button
                    className="btn-base"
                    onClick={() => {
                      setShowDrawer(true);
                    }}
                  >
                    Modifier
                  </Button>
                </div>
              </div>
            </div>

            <div className="p-4 bg-white border border-gray-300 rounded-md gap-4 max-h-[70vh] 2xl:max-h-[78vh] overflow-y-scroll overflow-x-hidden">
              <InfoGrid
                title="Généraux"
                infoItems={appointmentsInfo.filter(
                  (info) => info.category === "general",
                )}
              />
              <InfoGrid
                title="Comptes Clients"
                infoItems={appointmentsInfo.filter(
                  (info) => info.category === "customer",
                )}
              />
              {appointmentsInfo.some(
                (info) =>
                  info.category === "notification" && info.value !== false,
              ) && (
                <InfoGrid
                  title="Notifications"
                  infoItems={appointmentsInfo.filter(
                    (info) => info.category === "notification",
                  )}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppointmentsParameters;
