import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Chip,
  Paper,
  Stack,
  type SxProps,
  type Theme,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  type GridColDef,
  type GridFilterItem,
  type GridFilterModel,
  type GridRowCountProps,
  gridClasses,
} from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import useWindowWidth from "../../../hooks/layout/useWindowWidth";
import customTheme, {
  MUIXTheme,
  containerBorder,
  generalRadius,
  generalSpacing,
} from "../../../utils/customTheme/customTheme";
import ListFilters, { type FilterItem } from "./ListFilters";

export interface ColumnDefinition<T> {
  id: keyof T;
  label: string;
  type: "text" | "date" | "chip" | "link" | "component";
  width?: number;
  flex?: number;
  hide?: boolean;
  hover?: boolean;
  onClick?: (params: T) => React.MouseEventHandler<T>;
  render?: JSX.Element;
}

// The data array props can be of any type, but each element must have an ID to ensure correct item handling.
interface HasId {
  id: string | number;
}

interface Props<T extends HasId> {
  data: T[];
  tableColumns: ColumnDefinition<T>[];
  selectableRow?: boolean;
  disableColumnSorting?: boolean;
  onRowClick?: (row: T) => void;
  selectedActions?: (selectedIds: (string | number)[]) => JSX.Element;
  defaultFilters?: GridFilterModel;
  filterItems?: FilterItem[];
  filterModel?: GridFilterModel;
  setFilterModel?: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  paperStyle?: SxProps<Theme> | undefined;
}

const ListComponent = <T extends HasId>({
  data,
  tableColumns,
  onRowClick,
  selectedActions,
  filterItems,
  filterModel,
  defaultFilters,
  setFilterModel,
  paperStyle,
  selectableRow = false,
  disableColumnSorting = true,
}: Props<T>) => {
  const isDesktop = useWindowWidth(customTheme.breakpoints.values.lg);
  const [selectedRows, setSelectedRows] = useState<(number | string)[]>([]);

  const columns: GridColDef[] = tableColumns
    .filter((column) => !column.hide)
    .map((column) => ({
      field: String(column.id),
      headerName: column.label,
      flex: column.flex ?? 1,
      width: column.width,
      renderCell: (params) => {
        if (column.hover) {
          return <span className="component-hover">{params.value.render}</span>;
        }
        if (column.type === "chip") {
          return params.value.map((item: string, index: number) => (
            <Chip
              key={`${item}${index}${params.id}`}
              label={item}
              size="small"
              sx={{
                marginRight: index < params.value.length - 1 ? "4px" : "0",
                ".MuiChip-label": { fontSize: "11px" },
              }}
            />
          ));
        }
        if (column.type === "link") {
          if (React.isValidElement(params.value)) {
            return params.value;
          }
          return (
            <Typography
              variant="link"
              onClick={(event) => {
                event.stopPropagation();
                params.value.onClick();
              }}
            >
              {params.value.label}
            </Typography>
          );
        }
        if (column.type === "component") {
          return params.value.render;
        }
        if (
          typeof params.value === "string" &&
          /<\/?[a-z][\s\S]*>/i.test(params.value)
        ) {
          return <span dangerouslySetInnerHTML={{ __html: params.value }} />;
        }
        return <span>{params.value}</span>;
      },
    }));

  // Custom filter function (for date filters logic)
  const customFilterFunction = (filter: GridFilterItem, row: any) => {
    const column = tableColumns.find((column) => column.id === filter.field);
    if (!column) return true; // If column not found, return true (no filter applied)

    if (column.type !== "date") return true; // Skip non-date filters

    const fieldValue = row[filter.field as keyof T];
    const filterValue = filter.value;

    if (!fieldValue || !filterValue) return true; // Skip if either value is not present

    const linkedFilterId = filterItems?.find(
      (filterItem) => filterItem.id === filter.id,
    )?.linkedFilterId;

    const linkedFilterName = filterModel?.items.find(
      (item) => item.id === linkedFilterId,
    )?.field;

    if (filter.operator === ">") {
      return (
        moment(fieldValue as string).isSameOrAfter(
          moment(filterValue),
          "day",
        ) ||
        (linkedFilterName &&
          moment(row[linkedFilterName] as string).isSameOrAfter(
            moment(filterValue),
            "day",
          ))
      );
    } else if (filter.operator === "<") {
      return (
        moment(fieldValue as string).isSameOrBefore(
          moment(filterValue),
          "day",
        ) ||
        (linkedFilterName &&
          moment(row[linkedFilterName ?? ""] as string).isSameOrBefore(
            moment(filterValue),
            "day",
          ))
      );
    }

    return true;
  };

  const filteredData = useMemo(() => {
    return data.filter((row) => {
      return filterModel?.items.every((filter) => {
        return customFilterFunction(filter, row);
      });
    });
  }, [data, filterModel]);

  const handleRowSelectionChange = (newSelection: any) => {
    setSelectedRows(newSelection);
  };

  const handleClearSelection = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    const dataIDs = new Set(data.map((item) => item.id));
    if (
      selectedRows.length > 0 &&
      selectedRows.some((rowId) => !dataIDs.has(rowId))
    ) {
      setSelectedRows([]);
    }
  }, [data]);

  const totalWidth = columns.reduce(
    (acc, column) => acc + (column.width || 100),
    0,
  );

  return (
    <Stack
      height={"100vh"}
      marginBottom="9rem"
      marginLeft={{ xs: "0.5rem", sm: "0" }}
      marginRight={{ xs: "0.5rem", sm: "1rem" }}
      gap={generalSpacing}
    >
      {isDesktop && filterItems && setFilterModel && filterModel && (
        <ListFilters
          filterModel={filterModel}
          columns={columns}
          filterItems={filterItems}
          setFilterModel={setFilterModel}
          defaultFilters={defaultFilters}
        />
      )}
      {selectedRows.length > 0 && (
        <Stack
          margin={{ xs: "0.5rem", lg: "initial" }}
          flexDirection={{ xs: "column", sm: "row" }}
          gap="0.5rem"
        >
          <Stack flexDirection={"row"} gap={"0.6rem"} alignItems={"center"}>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={handleClearSelection}
              size="lg"
              style={{ cursor: "pointer" }}
            />
            <Typography fontWeight={"bold"}>
              {selectedRows.length} élément
              {selectedRows.length > 1 && `s`} séléctionné
              {selectedRows.length > 1 && `s`}
            </Typography>
          </Stack>
          {selectedActions && (
            <Stack width={"fit-content"} margin={{ xs: "auto", sm: "initial" }}>
              {selectedActions(selectedRows)}
            </Stack>
          )}
        </Stack>
      )}
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          border: containerBorder,
          borderRadius: generalRadius,
          overflowY: "hidden",
          overflowX: "auto",
          marginBottom: isDesktop ? "10rem" : "12rem",
          ...paperStyle,
        }}
      >
        <Stack
          sx={{
            width: `${totalWidth}px`,
            minWidth: "100%",
            height: "100%",
          }}
        >
          <DataGridPremium
            {...MUIXTheme.dataGrid.defaultProps}
            rows={filterModel ? filteredData : data}
            columns={columns}
            checkboxSelection={selectableRow}
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={handleRowSelectionChange}
            onRowClick={(params) => {
              if (onRowClick) {
                onRowClick(params.row);
              }
            }}
            getRowId={(row) => row.id}
            filterModel={filterModel}
            disableColumnSorting={disableColumnSorting}
            disableColumnFilter
            disableColumnResize
            disableAutosize
            disableColumnSelector
            slots={{
              footerRowCount: (rows: GridRowCountProps) => (
                <>
                  {rows.visibleRowCount
                    ? `Total lignes : ${rows.visibleRowCount}`
                    : ""}
                </>
              ),
              noRowsOverlay: () => <></>,
              noResultsOverlay: () => <></>,
            }}
            sx={{
              ...MUIXTheme.dataGrid.sx,
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-row": {
                ...MUIXTheme.dataGrid.sx["& .MuiDataGrid-row"],
                cursor: onRowClick ? "pointer" : "initial",
              },
              "& .MuiDataGrid-columnHeader": {
                ...MUIXTheme.dataGrid.sx["& .MuiDataGrid-columnHeader"],
                cursor: disableColumnSorting ? "initial" : "pointer",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
              },
              "& .MuiDataGrid-iconButtonContainer": {
                display: disableColumnSorting ? "none" : "initial",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "normal",
                lineHeight: "normal",
                display: "flex",
                alignItems: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
              },
              "& .MuiDataGrid-footerContainer": {
                ...MUIXTheme.dataGrid.sx["& .MuiDataGrid-footerContainer"],
                justifyContent: { xs: "start", lg: "end" },
              },
              "& .component-hover": {
                opacity: 0,
              },
              [`& .${gridClasses.row}:hover, & .${gridClasses.row}.Mui-hovered`]:
                {
                  "& .component-hover": {
                    opacity: 1,
                  },
                },
            }}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ListComponent;
