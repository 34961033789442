/* eslint-disable */
// @ts-nocheck

import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { Alert, Button, Spinner } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageDots, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/fr";

import { AppDispatch, RootState } from "../../../store/store";
import { CONFIG_CTO } from "../../../data/config.CTO";
import { CONFIG_API } from "../../../data/config.API";
import { APIResponseModel } from "../../../models/ApiResponseModel";
import { ResourceModel } from "../../../models/CalendarModel";
import { AppointmentModel } from "../../../models/AppointmentModel";
import {
  InspectionModel,
  LineModel,
} from "../../../models/CenterSettingsModel";
import { SearchResponseModel } from "../../../models/SearchResponseModel";
import { CustomerModel } from "../../../models/CustomerModel";
import { FleetModel } from "../../../models/FleetModel";
import {
  createCenterAppointment,
  fetchOtherVehicleAppointment,
  updateCenterAppointment,
  setError,
  setStoredAppointmentData,
} from "../../../store/calendar/appointment-slice";
import { checkLimitDates } from "../../../utils/calendar/checkLimitDates";
import {
  MUIXTheme,
  colors,
  fonts,
  generalRadius,
} from "../../../utils/customTheme/customTheme";
import { stringDateFormatter } from "../../../utils/calendar/stringDateFormatter";
import { setAlert } from "../../../store/layout/alert-slice";
import { displayError } from "../../../utils/layout/displayError";
import { api } from "../../../utils/api";

import LoadingAppointmentForm from "./LoadingAppointmentForm";
import CustomModal from "../../layouts/CustomModal";
import SmsForm from "../../services/SmsForm";
import SelectableChip from "../../common/SelectableChip";
import useWindowWidth from "../../../hooks/layout/useWindowWidth";
import InspectionInfo from "./InspectionInfo";

type Props = {
  editingRecord: null | { data: AppointmentModel };
  resources: ResourceModel[];
  showDrawer: boolean;
  handleCloseDrawer: Function;
  calendar?: React.RefObject<BryntumCalendar>;
};

const AppointmentForm = ({
  editingRecord,
  resources,
  showDrawer,
  handleCloseDrawer,
  calendar,
}: Props) => {
  moment.locale("fr");
  const dispatch: AppDispatch = useDispatch();

  const currentOrganizationState = useSelector(
    (store: RootState) => store.ORGANIZATION.currentOrganization,
  );
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );
  const centerSchedules = useSelector(
    (store: RootState) => store.CENTER.centerSchedules,
  );
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);
  const centerLines = useSelector(
    (store: RootState) => store.CENTER.centerLines,
  );
  const customersList = useSelector(
    (store: RootState) => store.CUSTOMER.customersList,
  );
  const vehiclesList = useSelector(
    (store: RootState) => store.FLEET.vehiclesList,
  );
  const appointmentState = useSelector((store: RootState) => store.APPOINTMENT);

  const dateInputRef = useRef<HTMLInputElement>(null);

  const [formData, setFormData] = useState(
    editingRecord ? editingRecord.data : undefined,
  );
  const [showAnnexVehicleSection, setShowAnnexVehicleSection] =
    useState<boolean>(
      Boolean(
        formData?.vehicleList &&
          formData?.vehicleList[1] &&
          formData?.vehicleList[1].inspectionTypeName,
      ),
    );
  const [showAllCustomerFields, setShowAllCustomerFields] =
    useState<boolean>(true);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [showSmsModal, setShowSmsModal] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [totalDurationDisplay, setTotalDurationDisplay] = useState("");

  const isDesktop = useWindowWidth();

  let [isReadOnly, setIsReadonly] = useState<boolean>(
    (formData ? formData.isHonored || formData.isCurrent : false) ||
      appointmentState.createLoading,
  );

  useEffect(() => {
    // Check if the appointment date is on the center limDebSit and limFinSit to set form on readOnly (for customer accounts)
    if (
      editingRecord?.data.pid &&
      currentUser?.accountType === CONFIG_API.CLIENT
    ) {
      let outsideLimits = checkLimitDates(
        {
          limDebSit: currentCenter?.limDebSit,
          limFinSit: currentCenter?.limFinSit,
        },
        editingRecord?.data.startDate,
      );
      if (!outsideLimits.startLimit || !outsideLimits.endLimit) {
        setIsReadonly(true);
      }
    }
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<AppointmentModel>({
    defaultValues: {
      inspectionType: editingRecord?.data.vehicleList[0].inspectionTypeId ?? "",
      options: editingRecord?.data
        ? editingRecord?.data.options
            .filter((option) => option.value === true)
            .map((option) => option.name)
        : [],
      notifications: editingRecord?.data
        ? editingRecord?.data.notifications
            .filter((notification) => notification.value === true)
            .map((notification) => notification.name)
        : [],
    },
    shouldFocusError: false,
  });

  const customersComboItems = customersList?.map((customer: CustomerModel) => ({
    value: customer.clientPid,
    label: `${customer.lastName} ${customer.firstName}`,
    firstName: customer.firstName,
    lastName: customer.lastName,
    mail: customer.mail,
    phoneNumber:
      customer.mobileNumber.length > 0
        ? customer.mobileNumber
        : customer.phoneNumber,
  }));
  const centerInspectionsList = centerLines?.find(
    (line: LineModel) => line.lineNumber === formData?.lineId,
  );
  const inspectionsComboItems = centerInspectionsList?.inspectionTypes.map(
    (inspection: InspectionModel) => ({
      value: inspection.id,
      text: `${inspection.name} (${inspection.duration.slice(0, -3)})`,
    }),
  );

  const [vehicleComboItems, setVehicleComboItems] = useState([]);
  // History (for lastName combo input)
  const [historyState, setHistoryState] = useState({
    loading: false,
    comboItems: [],
    isShowingHistory: false,
    noResult: false,
  });

  // Format Search response to comboItems format
  const formatClientSearch = (responseList: SearchResponseModel[]) => {
    const updatedList: any = [];
    responseList.map((response) => {
      updatedList.push({
        value: response.customerPid,
        lastName: response.vehNom,
        firstName: response.vehPrenom,
        mail: response.vehMel,
        phoneNumber: response.vehPor,
      });
    });
    // Update history comboItems with formatted values
    setHistoryState((prevState) => ({
      ...prevState,
      comboItems: updatedList,
      isShowingHistory: true,
    }));
  };

  // Find customer in history
  const searchClient = async (searchValue: string) => {
    // only when noResult is false to prevent useless request (if there is already no result with the first letter searching)
    if (!historyState.noResult) {
      setHistoryState((prevState) => ({ ...prevState, loading: true }));
      if (searchValue.length > 2) {
        const response = await api.post(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/${CONFIG_API.SEARCH}?searchString=${searchValue}`,
        );
        if (response.data.success) {
          if (response.data.items.length > 0) {
            formatClientSearch(response.data.items);
          } else {
            setHistoryState((prevState) => ({ ...prevState, noResult: true }));
          }
        } else {
          dispatch(
            setAlert({
              id: "global-alert",
              type: "error",
              message: displayError(response.data.messages[0]),
            }),
          );
        }
        // Disable loading
        setHistoryState((prevState) => ({ ...prevState, loading: false }));
      }
    }
  };

  useEffect(() => {
    if (editingRecord) {
      setFormData(editingRecord.data);
      setValue(
        "inspectionType",
        editingRecord.data.vehicleList[0].inspectionTypeId ?? "",
      );
      setValue("vehicle", editingRecord.data.vehicle ?? "");
      setValue("phoneNumber", editingRecord.data.phoneNumber ?? "");
      setShowAllCustomerFields(
        !editingRecord.data.pid || editingRecord.data.isHonored,
      );
      // Get all client's vehicles fleet at event update (if the appointment is one of the client)
      if (
        vehiclesList &&
        editingRecord?.data.clientPid !== currentCenter?.personnalCustomerPid
      ) {
        let customerVehicleList = vehiclesList?.filter(
          (vehicle: FleetModel) =>
            vehicle.clientPid &&
            vehicle.clientPid === editingRecord.data.clientPid,
        );
        if (customerVehicleList) {
          // Use a Set to track already encountered vehicleRegistrationNumbers (to avoid same registrationNumber)
          const seen = new Set();
          // Map through the vehicles and add only unique IDs to the Set
          const uniqueVehicleList = customerVehicleList.reduce(
            (acc, vehicle) => {
              // Check if vehicleRegistrationNumber has not been seen yet
              if (!seen.has(vehicle.vehicleRegistrationNumber)) {
                // Add it to the Set
                seen.add(vehicle.vehicleRegistrationNumber);
                // Push the transformed object to the accumulator
                acc.push({
                  value: vehicle.id,
                  text: vehicle.vehicleRegistrationNumber,
                });
              }
              return acc; // Return the accumulated array
            },
            [],
          );
          // Set the items to generate unique options for Autocomplete registrationNumber inputs
          setVehicleComboItems(uniqueVehicleList);
        }
      }
    }
  }, [editingRecord, vehiclesList]);

  useEffect(() => {
    if (!showDrawer && editingRecord) {
      // Reset data form and errors messages if closing without saving (to get initial Data in the fields)
      setFormData(editingRecord.data);
      setValue(
        "options",
        editingRecord.data?.options
          .filter((option) => option.value === true)
          .map((option) => option.name),
      );
      setValue(
        "notifications",
        editingRecord.data?.notifications
          .filter((notification) => notification.value === true)
          .map((notification) => notification.name),
      );
      setOtherAppointmentDate(false); // To not let the message after the drawer closing
      clearErrors();
    }
  }, [showDrawer, editingRecord]);

  // Automatically remove focus from the DateTime input when the picker closes
  useEffect(() => {
    if (!showDatePicker) {
      setTimeout(() => {
        dateInputRef.current && dateInputRef.current.blur();
      }, 100);
    }
  }, [showDatePicker]);

  // Calculate duration with each vehicleList item to display it in the duration field
  useEffect(() => {
    if (formData?.vehicleList && formData?.vehicleList[0].inspectionTypeId) {
      const totalDuration = formData.vehicleList.reduce((acc, vehicle) => {
        const [hours, minutes] =
          vehicle.inspectionDuration.split(":").map(Number) ?? 0;
        return acc + hours * 60 + minutes; // Converts HH:MM:SS to minutes and accumulates
      }, 0);

      const formattedDuration = `${Math.floor(totalDuration / 60)
        .toString()
        .padStart(2, "0")}:${(totalDuration % 60).toString().padStart(2, "0")}`;
      setTotalDurationDisplay(formattedDuration);
    }
  }, [formData?.vehicleList, showAnnexVehicleSection]);

  const autoCompleteFields = (selectedValue: string) => {
    let selectedData = !historyState.isShowingHistory
      ? customersList?.find((customer) => customer.clientPid === selectedValue)
      : selectedValue;

    if (selectedValue) {
      // Fetch all selected customer's vehicles
      let customerVehicleList = vehiclesList?.filter(
        (vehicle: FleetModel) => vehicle.clientPid === selectedValue,
      );
      if (customerVehicleList?.length > 0) {
        // To transform registration text input in a combo (select) input with customer's vehicles for options
        setVehicleComboItems(
          customerVehicleList.map((vehicle: FleetModel) => ({
            value: vehicle.id,
            text: vehicle.vehicleRegistrationNumber,
          })),
        );
      } else {
        // Remove fleet vehicles for to reset registration field
        vehicleComboItems.length > 0 && setVehicleComboItems([]);
      }
      if (selectedData) {
        // Autocomplete each field if associated event value isn't undefined
        setFormData({
          ...formData,
          lastName: selectedData.lastName,
          firstName: selectedData.firstName,
          mail: selectedData.mail,
          phoneNumber:
            selectedData.mobileNumber && selectedData.mobileNumber.length > 0
              ? selectedData.mobileNumber
              : selectedData.phoneNumber,
          clientPid: selectedData.clientPid,
          registrationNumber: "",
        });
        // Set phoneNumber field to avoid false empty input (because this field is required)
        setValue(
          "phoneNumber",
          selectedData.mobileNumber && selectedData.mobileNumber.length > 0
            ? selectedData.mobileNumber
            : selectedData.phoneNumber,
        );
      }
    }
  };

  // Automatically complete fields customer information if user is customer
  useEffect(() => {
    if (currentUser?.accountType === CONFIG_API.CLIENT && !formData?.lastName) {
      autoCompleteFields(currentUser?.pid);
    }
  }, [currentUser, formData]);

  const updateVehicleList = (index: number, updates) => {
    const vehicleList = formData.vehicleList || [];
    let updatedVehicleList = [...vehicleList];
    if (index < vehicleList.length) {
      updatedVehicleList[index] = { ...vehicleList[index], ...updates };
    } else {
      updatedVehicleList.push(updates);
    }
    setFormData({
      ...formData,
      vehicleList: updatedVehicleList,
      price: updatedVehicleList[0].inspectionPrice,
    });
  };

  const completeInspectionFields = (index: number, selectedValue: number) => {
    let selectedData = centerInspectionsList?.inspectionTypes.find(
      (inspection) => inspection.id === selectedValue,
    );
    if (selectedData) {
      updateVehicleList(index, {
        inspectionTypeId: selectedData.id,
        inspectionTypeName: selectedData.name,
        inspectionDuration: selectedData.duration,
        inspectionPrice: selectedData.price,
      });
    }
  };

  const [otherAppointmentDate, setOtherAppointmentDate] = useState<
    string | false
  >(false);

  const checkIfAlreadyAppointment = (registrationNumber: string) => {
    let appointmentPid = "";
    if (formData?.pid) {
      appointmentPid = formData.pid;
    }
    dispatch(
      fetchOtherVehicleAppointment(
        currentOrganizationState?.pid,
        currentCenter?.pid,
        registrationNumber,
        appointmentPid,
      ),
    ).then((response: AppointmentModel | false) => {
      if (response) {
        setOtherAppointmentDate(stringDateFormatter(response.startDate));
      } else {
        setOtherAppointmentDate(false);
      }
    });
  };

  // Transforms form options to match the format expected by the API
  const updateEventWithOptions = (
    event: AppointmentModel,
    options: [{ name: string; value: boolean }],
  ): AppointmentModel => {
    options.forEach(({ name, value }) => {
      event[name] = value;
    });
    return event;
  };

  const deleteEvent = async (editingRecord) => {
    if (calendar) {
      setLoadingDelete(true);
      // To know if the API has to send notification when deleting appointment (updated on chip click)
      let sendSmsNotification =
        formData.notifications.find((item) => item.name === "isSmsConfirmation")
          ?.value || false;
      let sendMailNotification =
        formData.notifications.find(
          (item) => item.name === "isMailConfirmation",
        )?.value || false;
      const response: AxiosResponse<APIResponseModel<boolean>> =
        await api.delete(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentOrganizationState?.pid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/${CONFIG_API.APPOINTMENT}/${editingRecord?.data.pid}?sendSmsNotification=${sendSmsNotification}&sendMailNotification=${sendMailNotification}`,
        );
      if (response) {
        if (response.data.success) {
          calendar.current?.instance.eventStore.remove(editingRecord);
          handleCloseDrawer();
          dispatch(
            setAlert({
              id: "calendar-alert",
              type: "success",
              message: "Le rendez-vous a bien été supprimé.",
            }),
          );
        } else {
          dispatch(
            setAlert({
              id: "calendar-alert",
              type: "error",
              message: displayError(response.data.messages[0]),
            }),
          );
        }
      } else {
        dispatch(
          setAlert({
            id: "calendar-alert",
            type: "error",
            message:
              "Une erreur est survenue lors de la suppression du rendez-vous.",
          }),
        );
      }
      setShowDeleteConfirmation(false);
      setLoadingDelete(false);
    }
  };

  const eventSave: SubmitHandler<AppointmentModel> = (event) => {
    dispatch(setError(null));

    // Create a shallow copy of formData to prevent direct modification of state
    let updatedFormData: AppointmentModel = { ...formData };

    if (currentUser?.accountType === CONFIG_API.CLIENT) {
      let currentOutsideLimits = checkLimitDates(
        {
          limDebSit: currentCenter?.limDebSit,
          limFinSit: currentCenter?.limFinSit,
        },
        updatedFormData.startDate,
      );

      // Check if the selected date is too early (before limDebSit) or too late (after limFinSit)
      if (!currentOutsideLimits.startLimit || !currentOutsideLimits.endLimit) {
        dispatch(setError(`La date du rendez-vous n'est pas autorisée.`));
        return false; // Stop the function to prevent event creation or update
      }
    }

    // Set minHours if non declared hours
    if (
      updatedFormData.startDate.getHours() <
      Number(centerSchedules.minHour.slice(1, -6))
    ) {
      updatedFormData.startDate.setHours(
        ...centerSchedules.minHour.split(":").map(Number),
      );
    }
    // Conversion Dates to ISOString
    updatedFormData.startDate = updatedFormData.startDate.toLocaleString(
      "fr-FR",
      {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      },
    );
    updatedFormData.endDate = updatedFormData.endDate.toLocaleString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    });

    // Transform form chips selection into appointment object (API format)
    updatedFormData = updateEventWithOptions(
      updatedFormData,
      updatedFormData.options,
    );
    updatedFormData = updateEventWithOptions(
      updatedFormData,
      updatedFormData.notifications,
    );

    // If user is a client, set automatically his clientPID
    if (currentUser?.accountType === CONFIG_API.CLIENT) {
      updatedFormData.clientPid = currentUser.pid;
    }

    // Deep clone vehicleList array to ensure it's writable
    const vehicleListCopy = updatedFormData.vehicleList.map((vehicle) => ({
      ...vehicle,
    }));

    // Push registration number on vehicleList array (API Format)
    if (formData.registrationNumber || formData.registrationNumber === "") {
      vehicleListCopy[0].registrationNumber = formData.registrationNumber;
    }
    if (
      formData.annexRegistrationNumber ||
      formData.annexRegistrationNumber === ""
    ) {
      vehicleListCopy[1].registrationNumber = formData.annexRegistrationNumber;
    }
    updatedFormData = {
      ...updatedFormData,
      vehicleList: vehicleListCopy,
    };

    // Set addToFleet value to true if auto-checked (undefined value), only if there isn't a appointment with stored data
    if (
      vehicleListCopy[0].addToFleet === undefined ||
      vehicleListCopy[0].addToFleet
    ) {
      vehicleListCopy[0].addToFleet = Boolean(
        updatedFormData.clientPid &&
          updatedFormData.clientPid !== currentCenter?.personnalCustomerPid &&
          vehicleListCopy[0].registrationNumber &&
          vehicleComboItems?.every(
            (item) => item.text !== vehicleListCopy[0].registrationNumber,
          ),
      );
    } else {
      vehicleListCopy[0].addToFleet = false;
    }

    // Check and convert decimal price format if number, else return null
    if (typeof updatedFormData.price === "number") {
      updatedFormData.price = Math.round(formData.price * 100) / 100;
    } else {
      updatedFormData.price = null;
    }

    // Check if phone number is at least 7 characters long if one SMS option is active
    if (
      (updatedFormData.isSmsConfirmation || updatedFormData.isSmsReminder) &&
      updatedFormData.phoneNumber?.length < 7
    ) {
      dispatch(setError(""));
      return false;
    }

    // // If event already has a PID, update it else create it
    if (formData.pid) {
      dispatch(
        updateCenterAppointment(
          currentOrganizationState?.pid,
          currentCenter?.pid,
          updatedFormData.pid,
          updatedFormData,
        ),
      ).then((response: APIResponseModel<AppointmentModel>) => {
        if (!appointmentState.createLoading) {
          if (response && response.success) {
            editingRecord?.set({
              ...response.item,
              resourceId: response.item.lineId,
              options: formData?.options,
              notifications: formData?.notifications,
            });
            dispatch(
              setAlert({
                id: "calendar-alert",
                type: "success",
                message: `Le rendez-vous a bien été modifié.`,
              }),
            );
            if (appointmentState.storedAppointmentData.data) {
              dispatch(setStoredAppointmentData({ variant: null, data: null }));
            }
            handleCloseDrawer();
          }
        }
      });
    } else {
      dispatch(
        createCenterAppointment(
          currentOrganizationState?.pid,
          currentCenter?.pid,
          updatedFormData,
        ),
      ).then((response: APIResponseModel<AppointmentModel>) => {
        if (!appointmentState.createLoading) {
          if (response.success && response.item) {
            editingRecord?.set({
              ...response.item,
              resourceId: response.item.lineId,
              options: formData?.options,
              notifications: formData?.notifications,
            });
            dispatch(
              setAlert({
                id: "calendar-alert",
                type: "success",
                message: `Le rendez-vous a bien été créé.`,
              }),
            );
            if (appointmentState.storedAppointmentData.data) {
              dispatch(setStoredAppointmentData({ variant: null, data: null }));
            }
            handleCloseDrawer();
          }
        }
      });
    }
    // Make the record persistable and eligible for syncing
    if (editingRecord) {
      editingRecord.isCreating = false;
    }
  };

  return formData &&
    vehiclesList &&
    inspectionsComboItems &&
    vehicleComboItems ? (
    <>
      <CustomModal
        showLogo={false}
        open={showSmsModal}
        padding={4}
        width={{ xs: "20rem", sm: "40rem" }}
        height="auto"
        textAlign="initial"
      >
        <SmsForm
          recipientPhoneNumber={formData.phoneNumber}
          setShowModal={setShowSmsModal}
        />
      </CustomModal>

      <CustomModal
        showLogo={false}
        open={showDeleteConfirmation}
        padding={4}
        width={{ xs: "20rem", sm: "40rem" }}
        height="auto"
        textAlign="initial"
      >
        <Stack justifyContent="center" alignItems="center" gap={4}>
          <Typography className="title-card">
            Êtes-vous sûr de vouloir supprimer ce rendez-vous ?
          </Typography>
          <Alert color="info">
            <Typography fontWeight={"bold"}>
              {moment(formData.startDate).format("dddd DD MMMM YYYY")} à{" "}
              {moment(formData.startDate).format("HH:mm")}
            </Typography>
            <Typography>{`${formData.vehicleList && formData.vehicleList[0].inspectionTypeName} (${formData.lineName})`}</Typography>
            <Typography>
              {formData.lastName} {formData.firstName}
            </Typography>
            <Typography>{formData.vehicle}</Typography>
          </Alert>
          <Stack flexDirection="row" gap={1}>
            <Button
              className="btn-base"
              onClick={() => {
                deleteEvent(editingRecord);
              }}
              disabled={loadingDelete}
            >
              {loadingDelete ? <Spinner size="sm" /> : "Confirmer"}
            </Button>
            <Button
              className="btn-base btn-outlined"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Annuler
            </Button>
          </Stack>
        </Stack>
      </CustomModal>

      {!editingRecord.data.pid && (
        <Typography
          className="title-card"
          padding="1rem 1.5rem 0"
          alignItems={"center"}
        >
          Nouveau RDV
        </Typography>
      )}

      <FormControl
        component="form"
        id="appointmentForm"
        onSubmit={handleSubmit(eventSave)}
        sx={{ width: "100%", padding: "1rem 1.5rem 0", gap: 4 }}
      >
        <Stack gap={2}>
          <Typography fontWeight="bold" sx={{ marginBottom: "-1rem" }}>
            Intervention
          </Typography>

          {/* PRESTATION & DURATION FIELDS */}
          <Stack flexDirection="row" gap={2} sx={{ width: "100%" }}>
            <Controller
              control={control}
              name="inspectionType"
              rules={{ required: "Veuillez sélectionner une prestation." }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth={!isDesktop}>
                  <InputLabel sx={{ margin: 0 }}>Prestation</InputLabel>
                  <Select
                    {...field}
                    value={
                      formData.vehicleList &&
                      Number(formData.vehicleList[0].inspectionTypeId)
                    }
                    MenuProps={{
                      anchorOrigin: { vertical: "bottom", horizontal: 400 },
                      transformOrigin: { vertical: "top", horizontal: 400 },
                      slotProps: {
                        paper: {
                          sx: {
                            width: { xs: "20.4rem", sm: "29rem" },
                          },
                        },
                      },
                    }}
                    renderValue={() =>
                      formData.vehicleList[0].inspectionTypeName
                    }
                    onChange={(e) => {
                      completeInspectionFields(0, Number(e.target.value));
                      field.onChange(Number(e.target.value));
                    }}
                    inputProps={{
                      readOnly: isReadOnly,
                    }}
                    label="Prestation"
                    sx={{ width: { xs: "100%", sm: "19rem" } }}
                  >
                    {inspectionsComboItems.map((inspection, index) => (
                      <MenuItem
                        key={inspection.value}
                        sx={{
                          fontFamily: fonts.secondary,
                          backgroundColor:
                            index % 2
                              ? "initial"
                              : `${colors.palette.customBackground.light} !important`,
                        }}
                        value={inspection.value}
                      >
                        {inspection.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {error ? (
                    <Typography
                      fontSize="12px"
                      lineHeight="20px"
                      color={"error"}
                      margin={"0.2rem 0.8rem 0"}
                    >
                      {error.message}
                    </Typography>
                  ) : !appointmentState.storedAppointmentData.data &&
                    !editingRecord?.data.pid &&
                    inspectionsComboItems.length < 1 ? (
                    <Typography
                      fontSize="12px"
                      lineHeight="20px"
                      color={"error"}
                      margin={"0.2rem 0.8rem 0"}
                    >
                      Aucune prestation disponible pour cette ligne
                    </Typography>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
            />
            <Stack
              sx={{
                display: "flex",
                borderRadius: generalRadius,
                borderColor: colors.palette.primary.light,
                border: `1px solid ${colors.palette.primary.light}`,
                width: { xs: "5rem", sm: "9rem" },
                height: "45px",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "0.55rem",
                paddingRight: "0.65rem",
                marginTop: "1.2rem",
                pointerEvents: isReadOnly ? "none" : "initial",
              }}
            >
              {isDesktop && (
                <Typography fontFamily={fonts.secondary}>Durée : </Typography>
              )}
              <Stack
                sx={{
                  display: "flex",
                  backgroundColor: colors.palette.customBackground.light,
                  borderRadius: "10px",
                  width: "3.5rem",
                  overflow: "hidden",
                }}
              >
                <Typography margin="auto" fontFamily={fonts.secondary}>
                  {totalDurationDisplay}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/* LINE & DATE FIELDS */}
          <Stack display="flex" flexDirection="row" width="100%">
            <Select
              value={formData.lineId}
              title={formData.lineName}
              onChange={(e) => {
                // Check if the current inspection exists for the selected line, if not, reset the inspection
                let inspectionExists = centerLines
                  .find((line) => line.lineNumber === Number(e.target.value))
                  .inspectionTypes.some(
                    (inspection) =>
                      inspection.id ===
                      formData.vehicleList[0].inspectionTypeId,
                  );
                let updatedVehicleList = [...formData.vehicleList];
                let newPrice = inspectionExists ? formData.price : "";
                if (!inspectionExists) {
                  if (updatedVehicleList.length > 0) {
                    updatedVehicleList[0] = {
                      ...updatedVehicleList[0],
                      inspectionTypeId: "",
                      inspectionTypeName: "",
                      inspectionDuration: "",
                      inspectionPrice: "",
                    };
                    setTotalDurationDisplay("");
                    setValue("inspectionType", "");
                  }
                }
                setFormData({
                  ...formData,
                  lineId: Number(e.target.value),
                  lineName:
                    resources.find(
                      (resource) => resource.id === Number(e.target.value),
                    )?.name ?? "",
                  vehicleList: updatedVehicleList,
                  price: newPrice,
                });
              }}
              inputProps={{
                readOnly: isReadOnly,
              }}
              sx={{
                width: { xs: "6rem", sm: "8rem" },
                backgroundColor: colors.palette.customBackground.light,
                borderRadius: `${generalRadius} 0 0 ${generalRadius}`,
                borderRight: "none",
                cursor: "pointer",
                outline: "none",
                padding: 0,
                marginTop: 0,
              }}
            >
              {resources.map((resource, index) => (
                <MenuItem
                  sx={{
                    fontFamily: fonts.secondary,
                    backgroundColor:
                      index % 2
                        ? "initial"
                        : `${colors.palette.customBackground.light} !important`,
                  }}
                  key={resource.id}
                  value={resource.id}
                >
                  {resource.name}
                </MenuItem>
              ))}
            </Select>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                required
                closeOnSelect
                disablePast
                value={moment(formData.startDate)}
                views={["month", "day"]}
                onClose={() => {
                  setShowDatePicker(false);
                }}
                onOpen={() => setShowDatePicker(true)}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    startDate: moment(formData.startDate)
                      .year(moment(e._d).year())
                      .month(moment(e._d).month())
                      .date(moment(e._d).date())
                      .toDate(),
                  });
                }}
                inputProps={{
                  readOnly: isReadOnly,
                }}
                open={showDatePicker && !isReadOnly}
                format={isDesktop ? "dddd DD MMMM" : "DD/MM/YY"}
                slotProps={{
                  ...MUIXTheme.datePicker.slotsProps,
                  desktopPaper: {
                    sx: {
                      "& .MuiDateCalendar-root": {
                        width: "21.8rem",
                      },
                      "& .MuiMonthCalendar-root": {
                        margin: "1rem auto",
                      },
                    },
                  },
                  inputAdornment: { position: "start", sx: { marginRight: 0 } },
                  textField: {
                    inputRef: dateInputRef,
                    onClick: () => {
                      !isReadOnly && setShowDatePicker(true);
                    },
                    sx: {
                      "& .MuiInputBase-input": {
                        width: { xs: "12rem", sm: "16rem" },
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        pointerEvents: isReadOnly ? "none" : "initial",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "0",
                        marginTop: "0",
                        borderLeft: "none",
                        borderRight: "none",
                      },
                    },
                  },
                }}
              />
              <TimePicker
                {...MUIXTheme.timePicker.defaultProps}
                required
                value={
                  formData.startDate.getHours() !== 0
                    ? moment(formData.startDate)
                    : moment(
                        new Date(
                          new Date().setHours(
                            parseInt(centerSchedules.minHour, 10),
                            0,
                          ),
                        ),
                      )
                }
                // Remove before minHour & after maxHour options
                minTime={moment()
                  .hours(parseInt(centerSchedules.minHour ?? "", 10))
                  .minutes(0)}
                maxTime={moment()
                  .hours(parseInt(centerSchedules.maxHour ?? "", 10))
                  .minutes(0)}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    startDate: moment(formData.startDate)
                      .hour(moment(e._d).hour())
                      .minute(moment(e._d).minute())
                      .toDate(),
                  });
                }}
                open={!isReadOnly && showTimePicker}
                onClose={() => {
                  setShowTimePicker(false);
                }}
                onOpen={() => {
                  setShowTimePicker(true);
                }}
                inputProps={{
                  readOnly: isReadOnly,
                }}
                slotProps={{
                  ...MUIXTheme.timePicker.slotsProps,
                  inputAdornment: {
                    position: "start",
                    sx: { marginRight: 0, display: { xs: "none", sm: "flex" } },
                  },
                  textField: {
                    onClick: () => setShowTimePicker(true),
                    sx: {
                      width: "8rem",
                      "& .MuiOutlinedInput-root": {
                        padding: { xs: "0", sm: "1rem" },
                        marginTop: "0",
                        borderRadius: `0 ${generalRadius} ${generalRadius} 0`,
                      },
                      "& .MuiInputBase-input": {
                        cursor: "pointer",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>

        <Stack>
          <Typography fontWeight="bold">Informations</Typography>

          {showAllCustomerFields ? (
            <>
              {/* NAME FIELDS */}
              <Stack flexDirection={"row"} gap={2}>
                {!isReadOnly &&
                customersList &&
                customersList.length > 0 &&
                customersComboItems ? (
                  <Controller
                    control={control}
                    name="lastName"
                    rules={{
                      required: "Veuillez renseigner un nom.",
                      maxLength: {
                        value: 50,
                        message: "Le nom est limité à 50 caractères.",
                      },
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        freeSolo
                        fullWidth
                        filterOptions={(options, state) => {
                          const filtered = createFilterOptions({
                            matchFrom: "any",
                            stringify: (customer: any) =>
                              `${customer.firstName} ${customer.lastName} ${customer.phoneNumber} ${customer.mail}`,
                          })(options, state);
                          if (
                            currentUser?.accountType !== CONFIG_API.CLIENT &&
                            ((!historyState.isShowingHistory &&
                              field.value &&
                              field.value.length > 2) ||
                              historyState.loading)
                          ) {
                            return [
                              ...filtered,
                              {
                                firstName: "",
                                lastName: "Rechercher dans l'historique",
                                phoneNumber: "",
                                mail: "",
                                isSpecialOption: true,
                              },
                            ];
                          } else {
                            return [...filtered];
                          }
                        }}
                        getOptionLabel={(option: any) => {
                          if (typeof option === "object") {
                            return option
                              ? option.lastName
                              : `${option.firstName} ${option.lastName}`;
                          }
                          return option;
                        }}
                        sx={{ width: "34em" }}
                        options={
                          historyState.isShowingHistory &&
                          currentUser?.accountType !== CONFIG_API.CLIENT
                            ? historyState.comboItems
                            : customersComboItems
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nom"
                            error={Boolean(errors.lastName)}
                            helperText={errors.lastName?.message}
                          />
                        )}
                        renderOption={(props: any, option: any) => {
                          if (
                            option.isSpecialOption &&
                            currentUser?.accountType !== CONFIG_API.CLIENT
                          ) {
                            // Line to search customer in history at the end
                            return (
                              <li
                                {...props}
                                style={{
                                  position: "sticky",
                                  bottom: 0,
                                  backgroundColor: colors.palette.primary.main,
                                  color: "white",
                                  zIndex: 1,
                                }}
                                onClick={(
                                  e: React.MouseEvent<HTMLLIElement>,
                                ) => {
                                  e.stopPropagation(); // Prevent popper closing
                                  if (
                                    formData.lastName &&
                                    formData.lastName.length > 2
                                  ) {
                                    searchClient(formData.lastName);
                                  }
                                }}
                              >
                                <Stack
                                  fontFamily={fonts.secondary}
                                  margin="auto"
                                >
                                  {historyState.loading ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                      }}
                                    >
                                      {option.lastName}
                                    </span>
                                  )}
                                </Stack>
                              </li>
                            );
                          }

                          return (
                            <li
                              {...props}
                              key={props["data-option-index"]}
                              style={{
                                backgroundColor:
                                  props["data-option-index"] % 2
                                    ? "initial"
                                    : colors.palette.customBackground.light,
                              }}
                            >
                              <Stack fontFamily={fonts.secondary}>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontFamily: fonts.secondary,
                                  }}
                                >
                                  {option.firstName} {option.lastName}
                                </span>
                                <div style={{ fontFamily: fonts.secondary }}>
                                  <span
                                    style={{
                                      marginRight: option.mail ? "8px" : "0",
                                      fontFamily: fonts.secondary,
                                    }}
                                  >
                                    {option.mail}
                                  </span>
                                  {option.phoneNumber}
                                </div>
                              </Stack>
                            </li>
                          );
                        }}
                        disableClearable
                        value={formData.lastName ?? ""}
                        onChange={(event, newValue) => {
                          autoCompleteFields(
                            historyState.isShowingHistory
                              ? newValue
                              : newValue?.value,
                          );
                          if (!newValue || !newValue.isSpecialOption) {
                            field.onChange(newValue);
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          if (
                            vehicleComboItems.length > 0 &&
                            newInputValue !== formData.lastName
                          ) {
                            setVehicleComboItems([]);
                          }
                          if (
                            historyState.noResult &&
                            formData.lastName &&
                            newInputValue.length < formData.lastName?.length
                          ) {
                            setHistoryState({
                              ...historyState,
                              noResult: false,
                            });
                          }
                          if (historyState.isShowingHistory) {
                            if (newInputValue.length > 2) {
                              searchClient(formData.lastName);
                            } else if (newInputValue.length < 1) {
                              setHistoryState({
                                ...historyState,
                                isShowingHistory: false,
                              });
                            }
                          }
                          setFormData({ ...formData, lastName: newInputValue });
                          field.onChange(newInputValue);
                        }}
                        onBlur={() => {
                          // Reset isShowingHistory state to have account customer combo items when input is unfocused
                          if (historyState.isShowingHistory) {
                            setHistoryState({
                              ...historyState,
                              isShowingHistory: false,
                            });
                          }
                        }}
                        componentsProps={{
                          paper: {
                            sx: { width: { xs: 380, sm: 465 } },
                          },
                        }}
                        noOptionsText="Aucun client trouvé"
                      />
                    )}
                  />
                ) : (
                  <TextField
                    {...register("lastName", {
                      required: "Veuillez renseigner un nom.",
                      maxLength: {
                        value: 50,
                        message: "Le nom est limité à 50 caractères.",
                      },
                    })}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message}
                    id="lastNameCustomerField"
                    name="lastName"
                    label="Nom"
                    value={formData.lastName ?? ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    autoComplete="off"
                    aria-autocomplete="none"
                    sx={{ width: "34rem" }}
                    inputProps={{
                      maxLength: 50,
                      readOnly: isReadOnly,
                      "aria-autocomplete": "none",
                    }}
                  />
                )}
                <TextField
                  {...register("firstName", {
                    maxLength: {
                      value: 30,
                      message: "Le prénom est limité à 30 caractères.",
                    },
                  })}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                  id="firstNameCustomerField"
                  name="firstName"
                  label="Prénom"
                  value={formData.firstName ?? ""}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  autoComplete="off"
                  aria-autocomplete="none"
                  inputProps={{
                    maxLength: 30,
                    readOnly: isReadOnly,
                    "aria-autocomplete": "none",
                  }}
                  fullWidth
                />
              </Stack>

              {/* MAIL & PHONE FIELDS */}
              <Stack flexDirection={"row"} gap={2}>
                <TextField
                  {...register("mail", {
                    maxLength: {
                      value: 100,
                      message: "L'adresse e-mail est limitée à 100 caractères.",
                    },
                  })}
                  error={Boolean(errors.mail)}
                  helperText={errors.mail?.message}
                  name="mail"
                  label="E-mail"
                  value={formData.mail ?? ""}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  autoComplete="off"
                  aria-autocomplete="none"
                  inputProps={{
                    maxLength: 100,
                    readOnly: isReadOnly,
                    "aria-autocomplete": "none",
                  }}
                  sx={{ width: "34rem" }}
                />
                <TextField
                  {...register("phoneNumber", {
                    required: "Veuillez renseigner un numéro de téléphone.",
                    maxLength: {
                      value: 20,
                      message:
                        "Le numéro de téléphone est limité à 20 caractères.",
                    },
                  })}
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber?.message}
                  name="phoneNumber"
                  label="Téléphone"
                  value={formData.phoneNumber ?? ""}
                  autoComplete="off"
                  aria-autocomplete="none"
                  inputProps={{
                    maxLength: 20,
                    readOnly: isReadOnly,
                    "aria-autocomplete": "none",
                  }}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Stack>
            </>
          ) : (
            // CUSTOMER FULL INFORMATION FIELD (Appointment Update)
            <>
              <TextField
                label="Client"
                value={[
                  formData.lastName + " " + formData.firstName,
                  formData.mail,
                  formData.phoneNumber,
                ]
                  .filter((value) => value != null && value !== "")
                  .join(" - ")}
                onFocus={() => setShowAllCustomerFields(true)}
                sx={{ cursor: "pointer" }}
                contentEditable={false}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    cursor: "pointer",
                  },
                }}
              />
            </>
          )}

          {/* VEHICLE & REGISTRATION FIELDS */}
          <Stack flexDirection={"row"} gap={2}>
            <TextField
              {...register("vehicle", {
                required: "Veuillez renseigner un véhicule.",
                maxLength: {
                  value: 50,
                  message: "Le véhicule est limité à 50 caractères.",
                },
                validate: {
                  // Avoid empty value with space (like : " ")
                  notEmpty: (value) =>
                    value.trim() !== "" || "Le véhicule ne peut pas être vide.",
                },
              })}
              error={Boolean(errors.vehicle)}
              helperText={errors.vehicle?.message}
              name="vehicle"
              label="Véhicule"
              value={formData.vehicle ?? ""}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              autoComplete="off"
              aria-autocomplete="none"
              inputProps={{
                maxLength: 50,
                readOnly: isReadOnly,
                "aria-autocomplete": "none",
              }}
              sx={{ width: { xs: "18rem", sm: "15rem" } }}
            />
            {vehicleComboItems.length > 0 ? (
              <Autocomplete
                id="registrationNumber"
                name="registrationNumber"
                options={vehicleComboItems}
                value={vehicleComboItems.find(
                  (vehicle) =>
                    vehicle.text ===
                      formData.vehicleList[0].registrationNumber ?? "",
                )}
                getOptionLabel={(option) => option.text}
                disableClearable
                onChange={(e, newValue) =>
                  setFormData({
                    ...formData,
                    registrationNumber: newValue ? newValue.text : "",
                  })
                }
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      backgroundColor:
                        props["data-option-index"] % 2
                          ? "initial"
                          : colors.palette.customBackground.light,
                    }}
                  >
                    {option.text}
                  </li>
                )}
                onInputChange={(event, newInputValue) => {
                  setFormData({
                    ...formData,
                    registrationNumber: newInputValue ?? "",
                  });
                }}
                onBlur={() => {
                  // Check if this registration already has an appointment when user unfocus this input
                  if (!formData.registrationNumber && !isReadOnly) {
                    setOtherAppointmentDate(false);
                  } else if (formData.registrationNumber) {
                    checkIfAlreadyAppointment(formData.registrationNumber);
                  }
                }}
                readOnly={isReadOnly}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Immatriculation"
                    name="registrationNumber"
                    error={Boolean(errors.registrationNumber)}
                    helperText={errors.registrationNumber?.message}
                    autoComplete="off"
                    aria-autocomplete="none"
                    inputProps={{
                      ...params.inputProps,
                      maxLength: 20,
                      "aria-autocomplete": "none",
                    }}
                    sx={{
                      width: "13rem",
                    }}
                  />
                )}
                freeSolo
              />
            ) : (
              <TextField
                {...register("registrationNumber")}
                error={Boolean(errors.registrationNumber)}
                helperText={errors.registrationNumber?.message}
                name="registrationNumber"
                value={formData.registrationNumber ?? ""}
                label="Immatriculation"
                autoComplete="off"
                aria-autocomplete="none"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                onBlur={(e) => {
                  // Check if this registration already has an appointment when user unfocus this input
                  if (e.target.value && !isReadOnly) {
                    checkIfAlreadyAppointment(e.target.value);
                  } else {
                    setOtherAppointmentDate(false);
                  }
                }}
                inputProps={{
                  readOnly: isReadOnly,
                  maxLength: 20,
                  "aria-autocomplete": "none",
                }}
                sx={{
                  width: "13rem",
                }}
              />
            )}
          </Stack>
          {otherAppointmentDate && (
            <Box sx={{ margin: "0.5rem 0 -0.5rem" }}>
              <Alert color="warning">
                Il existe déjà un rendez-vous pour ce véhicule le{" "}
                {otherAppointmentDate}
              </Alert>
            </Box>
          )}
          {formData.clientPid &&
            formData.clientPid !== currentCenter?.personnalCustomerPid &&
            formData.registrationNumber &&
            vehicleComboItems?.every(
              (item) => item.text !== formData.registrationNumber,
            ) && (
              <Controller
                name="addToFleet"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={true}
                        size="small"
                        disabled={isReadOnly}
                        onChange={(newValue) => {
                          field.onChange(newValue.target.checked);
                          updateVehicleList(0, {
                            addToFleet: newValue.target.checked,
                          });
                        }}
                      />
                    }
                    label="Ajouter dans la flotte"
                    sx={{
                      marginBottom: "-1rem",
                      marginTop: otherAppointmentDate && "0.5rem",
                    }}
                  />
                )}
              />
            )}

          {/* PRICE, PROMO CODE & COMMAND NUMBER FIELDS */}
          <Stack flexDirection={"row"} gap={2} width="100%">
            <TextField
              {...register("price", {
                min: { value: 0, message: "Prix invalide" },
              })}
              name="price"
              type="number"
              label="Prix"
              error={Boolean(errors.price)}
              helperText={errors.price?.message}
              autoComplete="off"
              aria-autocomplete="none"
              sx={{
                width: { xs: "8rem", sm: "7.3rem" },
              }}
              fullWidth={!isDesktop}
              value={
                formData.price !== undefined
                  ? formData.price
                  : formData.vehicleList && formData.vehicleList[0]
                    ? formData.vehicleList[0].inspectionPrice
                    : ""
              }
              inputProps={{
                "aria-autocomplete": "none",
                readOnly:
                  isReadOnly || currentUser?.accountType === CONFIG_API.CLIENT,
                step: 0.01,
                min: 0,
                max: 999,
              }}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]:
                    e.target.value === "" ? null : Number(e.target.value),
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography sx={{ marginTop: "0.2rem" }}>€</Typography>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              {...register("promoCode", {
                maxLength: {
                  value: 24,
                  message: `Le code promo est limité à 24 caractères.`,
                },
              })}
              error={Boolean(errors.promoCode)}
              helperText={errors.promoCode?.message}
              name="promoCode"
              label={isDesktop ? "Code promo" : "Promo"}
              value={formData.promoCode ?? ""}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              autoComplete="off"
              aria-autocomplete="none"
              inputProps={{
                maxLength: 24,
                readOnly: isReadOnly,
                "aria-autocomplete": "none",
              }}
              sx={{
                width: "6.9rem",
              }}
            />
            <TextField
              {...register("commandNumber", {
                maxLength: {
                  value: 50,
                  message: `Le bon de commande est limité à 50 caractères.`,
                },
              })}
              error={Boolean(errors.commandNumber)}
              helperText={errors.commandNumber?.message}
              name="commandNumber"
              label={isDesktop ? "Bon de commande" : "Bon commande"}
              value={formData.commandNumber ?? ""}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              autoComplete="off"
              aria-autocomplete="none"
              inputProps={{
                maxLength: 50,
                readOnly: isReadOnly,
                "aria-autocomplete": "none",
              }}
              sx={{
                width: { xs: "9rem", sm: "13rem" },
              }}
              fullWidth={!isDesktop}
            />
          </Stack>

          {/* ANNEX VEHICLE SECTION */}
          {currentCenter?.isAnnexVehicle &&
            formData.vehicleList[0].inspectionTypeId && (
              <>
                <Stack sx={{ marginTop: "0.5rem" }}>
                  <FormControlLabel
                    label="Ajouter un véhicule associé"
                    control={
                      <Switch
                        defaultChecked={Boolean(
                          formData.vehicleList &&
                            formData.vehicleList[1] &&
                            formData.vehicleList[1].inspectionTypeName,
                        )}
                        disabled={
                          !(formData.vehicleList && formData.vehicleList[0]) ||
                          isReadOnly
                        }
                        onChange={(e) => {
                          setShowAnnexVehicleSection(!showAnnexVehicleSection);
                          !e.target.checked &&
                            formData.vehicleList.splice(1, 1);
                        }}
                      />
                    }
                  />
                </Stack>

                {showAnnexVehicleSection && (
                  <Stack
                    gap={0}
                    marginTop={1}
                    className="fade-effect"
                    width="100%"
                  >
                    <Typography fontWeight="bold">
                      Informations véhicule associé
                    </Typography>
                    <Stack
                      gap={{ xs: 0, sm: 2 }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <FormControl>
                        <InputLabel>Prestation</InputLabel>
                        <Select
                          name="inspectionTypeAnnexVehicle"
                          value={
                            formData.vehicleList &&
                            formData.vehicleList.length > 1
                              ? formData.vehicleList[1].inspectionTypeId
                              : ""
                          }
                          renderValue={() =>
                            formData.vehicleList[1].inspectionTypeName
                          }
                          onChange={(e) => {
                            completeInspectionFields(1, Number(e.target.value));
                          }}
                          inputProps={{
                            readOnly: isReadOnly,
                          }}
                          label="Prestation"
                          sx={{ width: { xs: "100%", sm: "16.5rem" } }}
                        >
                          {inspectionsComboItems.map((inspection, index) => (
                            <MenuItem
                              key={inspection.value}
                              sx={{
                                fontFamily: fonts.secondary,
                                backgroundColor:
                                  index % 2
                                    ? "initial"
                                    : `${colors.palette.customBackground.light} !important`,
                              }}
                              value={inspection.value}
                            >
                              {inspection.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {formData.vehicleList.length > 1 &&
                        formData.vehicleList[1].inspectionTypeId &&
                        (vehicleComboItems && vehicleComboItems.length > 0 ? (
                          <Autocomplete
                            name="annexRegistrationNumber"
                            options={vehicleComboItems}
                            value={vehicleComboItems.find(
                              (vehicle) =>
                                vehicle.text ===
                                  formData.vehicleList[1].registrationNumber ??
                                "",
                            )}
                            getOptionLabel={(option) => option.text}
                            disableClearable
                            onChange={(e, newValue) =>
                              setFormData({
                                ...formData,
                                annexRegistrationNumber: newValue
                                  ? newValue.text
                                  : "",
                              })
                            }
                            renderOption={(props, option) => (
                              <li
                                {...props}
                                style={{
                                  backgroundColor:
                                    props["data-option-index"] % 2
                                      ? "initial"
                                      : colors.palette.customBackground.light,
                                }}
                              >
                                {option.text}
                              </li>
                            )}
                            onInputChange={(event, newInputValue) => {
                              setFormData({
                                ...formData,
                                annexRegistrationNumber: newInputValue ?? "",
                              });
                            }}
                            readOnly={isReadOnly}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Immatriculation"
                                name="annexRegistrationNumber"
                                error={Boolean(errors.annexRegistrationNumber)}
                                helperText={
                                  errors.annexRegistrationNumber?.message
                                }
                                autoComplete="off"
                                aria-autocomplete="none"
                                inputProps={{
                                  ...params.inputProps,
                                  maxLength: 20,
                                  "aria-autocomplete": "none",
                                }}
                                sx={{
                                  width: "12rem",
                                }}
                              />
                            )}
                            freeSolo
                          />
                        ) : (
                          <TextField
                            {...register("annexRegistrationNumber")}
                            error={Boolean(errors.registrationNumber)}
                            helperText={errors.registrationNumber?.message}
                            name="annexRegistrationNumber"
                            value={
                              formData.vehicleList && formData.vehicleList[1]
                                ? formData.vehicleList[1].registrationNumber
                                : ""
                            }
                            label="Immatriculation"
                            autoComplete="off"
                            aria-autocomplete="none"
                            onChange={(e) => {
                              const updatedVehicleList = [
                                ...formData.vehicleList,
                              ];
                              updatedVehicleList[1] = {
                                ...updatedVehicleList[1],
                                registrationNumber: e.target.value,
                              };
                              setFormData({
                                ...formData,
                                vehicleList: updatedVehicleList,
                              });
                            }}
                            inputProps={{
                              readOnly: isReadOnly,
                              "aria-autocomplete": "none",
                              maxLength: 20,
                            }}
                            sx={{ width: "12rem" }}
                          />
                        ))}
                    </Stack>
                  </Stack>
                )}
              </>
            )}
        </Stack>

        {editingRecord.data.inspection && (
          <InspectionInfo data={editingRecord?.data} />
        )}

        {/* NOTES FIELD */}
        <Stack
          gap={2}
          sx={{
            height: "fit-content",
            padding: 2,
            backgroundColor: colors.palette.customBackground.light,
            borderRadius: "10px",
          }}
        >
          <Stack gap={1}>
            <Typography
              fontWeight="bold"
              color={errors.note ? "error" : "initial"}
            >
              Notes
            </Typography>
            <TextField
              {...register("note", {
                maxLength: {
                  value: 500,
                  message: "Les notes sont limitées à 500 caractères.",
                },
              })}
              error={Boolean(errors.note)}
              helperText={errors.note?.message}
              name="note"
              value={formData.note ?? ""}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              placeholder="Commencez à écrire"
              multiline
              maxRows={3}
              inputProps={{ maxLength: 500, readOnly: isReadOnly }}
              InputProps={{ sx: { border: "none" } }}
            />
          </Stack>

          {/* OPTIONS CHIPS */}
          {(currentCenter?.isCarChoiceLoanEnabled ||
            currentCenter?.isValetChoiceEnabled ||
            currentCenter?.isToLoad ||
            currentCenter?.isTruckTrailer ||
            currentCenter?.isParking) && (
            <Stack gap={1} id="optionsContainerForm">
              <Typography fontWeight="bold">Options</Typography>
              <Controller
                name="options"
                defaultValue={[]}
                control={control}
                render={({ field }) => (
                  <Stack width={{ xs: "13rem", sm: "100%" }}>
                    <SelectableChip
                      direction={isDesktop ? "row" : "column"}
                      options={[
                        ...(currentCenter?.isCarChoiceLoanEnabled
                          ? [
                              currentUser.accountType !== CONFIG_API.CLIENT ||
                              (currentUser.accountType === CONFIG_API.CLIENT &&
                                currentUser?.isCarChoiceLoanEnabled)
                                ? {
                                    label: CONFIG_CTO.LOAN_CAR,
                                    value: "isLoanCar",
                                  }
                                : null,
                            ].filter(Boolean)
                          : []),
                        ...(currentCenter?.isValetChoiceEnabled
                          ? [
                              currentUser.accountType !== CONFIG_API.CLIENT ||
                              (currentUser.accountType === CONFIG_API.CLIENT &&
                                currentUser?.isValetChoiceEnabled)
                                ? { label: CONFIG_CTO.VALET, value: "isValet" }
                                : null,
                            ].filter(Boolean)
                          : []),
                        ...(currentCenter?.isToLoad
                          ? [{ label: CONFIG_CTO.TO_LOAD, value: "isToLoad" }]
                          : []),
                        ...(currentCenter?.isTruckTrailer
                          ? [
                              {
                                label: CONFIG_CTO.TRUCK_TRAILER,
                                value: "isTruckTrailer",
                              },
                            ]
                          : []),
                        ...(currentCenter?.isParking
                          ? [{ label: CONFIG_CTO.PARKING, value: "isParking" }]
                          : []),
                      ]}
                      value={field.value}
                      isReadOnly={isReadOnly}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                        setFormData({
                          ...formData,
                          options: formData.options.map((option) => ({
                            ...option,
                            value: newValue.includes(option.name),
                          })),
                        });
                      }}
                    />
                  </Stack>
                )}
              />
            </Stack>
          )}

          {/* NOTIFICATIONS CHIPS */}
          {(formData.mail ||
            (formData.phoneNumber &&
              currentCenter?.isSmsEnabled &&
              (currentUser.accountType !== CONFIG_API.CLIENT ||
                (currentUser.accountType === CONFIG_API.CLIENT &&
                  (currentUser.isSmsReminderEnabled ||
                    currentUser.isSmsConfirmationEnabled))))) && (
            <Stack gap={1}>
              <Typography fontWeight="bold">Notifications</Typography>
              <Stack
                width="100%"
                direction={{ xs: "column", sm: "row" }}
                gap={{ xs: "1rem", sm: "2rem" }}
              >
                <Controller
                  name="notifications"
                  defaultValue={[]}
                  control={control}
                  render={({ field }) => (
                    <Stack width={{ xs: "13rem", sm: "auto" }}>
                      <SelectableChip
                        direction="column"
                        options={[
                          ...(formData.mail
                            ? [
                                {
                                  label: "E-mail de confirmation",
                                  value: "isMailConfirmation",
                                },
                              ]
                            : []),
                          ...(formData.phoneNumber &&
                          currentCenter?.isSmsEnabled
                            ? [
                                currentUser.accountType !== CONFIG_API.CLIENT ||
                                (currentUser.accountType ===
                                  CONFIG_API.CLIENT &&
                                  currentUser.isSmsConfirmationEnabled)
                                  ? {
                                      label: "SMS de confirmation",
                                      value: "isSmsConfirmation",
                                    }
                                  : null,
                                currentUser.accountType !== CONFIG_API.CLIENT ||
                                (currentUser.accountType ===
                                  CONFIG_API.CLIENT &&
                                  currentUser.isSmsReminderEnabled)
                                  ? {
                                      label: "SMS de rappel à J-1",
                                      value: "isSmsReminder",
                                    }
                                  : null,
                              ].filter(Boolean)
                            : []),
                        ]}
                        autoSelectValues={[
                          ...(formData.mail &&
                          !formData.pid &&
                          currentCenter?.isMailConfirmationEnabledDefault
                            ? ["isMailConfirmation"]
                            : []),
                          ...(formData.phoneNumber &&
                          !formData.pid &&
                          currentCenter?.isSmsEnabled &&
                          currentCenter?.smsOptions &&
                          currentCenter.smsOptions.isConfirmationEnabledDefault
                            ? ["isSmsConfirmation"]
                            : []),
                          ...(formData.phoneNumber &&
                          !formData.pid &&
                          currentCenter?.isSmsEnabled &&
                          currentCenter?.smsOptions &&
                          currentCenter.smsOptions.isReminderEnabledDefault
                            ? ["isSmsReminder"]
                            : []),
                        ]}
                        value={field.value}
                        isReadOnly={isReadOnly}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                          setFormData({
                            ...formData,
                            notifications: formData.notifications.map(
                              (notification) => ({
                                ...notification,
                                value: newValue.includes(notification.name),
                              }),
                            ),
                          });
                        }}
                      />
                    </Stack>
                  )}
                />
                <Stack justifyContent={"center"} margin={"auto"}>
                  {formData.pid &&
                    currentCenter?.isSmsEnabled &&
                    currentUser?.accountType === CONFIG_API.ADMIN && (
                      <Button
                        className="btn-base"
                        onClick={() => setShowSmsModal(true)}
                      >
                        <div className="flex justify-center items-center">
                          <FontAwesomeIcon
                            icon={faMessageDots}
                            className="mr-2"
                          />
                          Envoyer un SMS
                        </div>
                      </Button>
                    )}
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>

        {/* BUTTONS & ERROR MESSAGE */}
        <Stack
          sx={{
            width: "100%",
            position: "sticky",
            paddingY: 2,
            bottom: 0,
            gap: 2,
            backgroundColor: "white",
            zIndex: 5,
          }}
        >
          {appointmentState.error && (
            <Box>
              <Alert color="failure">
                {/* Display a generic message for customer accounts to prevent revealing confidential information */}
                {currentUser?.accountType === CONFIG_API.CLIENT
                  ? `Impossible de ${editingRecord.data.pid ? "modifier" : "créer"} le rendez-vous.`
                  : appointmentState.error}
              </Alert>
            </Box>
          )}
          <Stack width="100%" flexDirection={"row"} gap={{ xs: 0.5, sm: 1 }}>
            {!formData.isHonored && !formData.isCurrent && (
              <Button
                className="btn-base"
                type="submit"
                id="saveAppointmentFormButton"
                disabled={
                  appointmentState.createLoading ||
                  (!appointmentState.storedAppointmentData.data &&
                    !editingRecord?.data.pid &&
                    inspectionsComboItems.length < 1)
                }
              >
                {appointmentState.createLoading ? (
                  <Spinner size="sm" />
                ) : (
                  "Enregistrer"
                )}
              </Button>
            )}
            {editingRecord?.data.pid !== undefined &&
              !appointmentState.storedAppointmentData.data && (
                <>
                  {(currentUser?.accountType !== CONFIG_API.CLIENT ||
                    (currentUser?.accountType === CONFIG_API.CLIENT &&
                      !isReadOnly)) && (
                    <Button
                      className="btn-base"
                      title="Couper"
                      onClick={() => {
                        dispatch(
                          setStoredAppointmentData({
                            variant: "cut",
                            data: editingRecord.data,
                          }),
                        );
                        calendar?.current.instance.copyEvents(
                          [editingRecord],
                          true,
                        );
                        handleCloseDrawer();
                      }}
                    >
                      Couper
                    </Button>
                  )}
                  <Button
                    className="btn-base"
                    title="Copier"
                    onClick={() => {
                      dispatch(
                        setStoredAppointmentData({
                          variant: "copy",
                          data: editingRecord.data,
                        }),
                      );
                      handleCloseDrawer();
                    }}
                  >
                    Copier
                  </Button>
                </>
              )}
            {editingRecord?.data?.pid && !isReadOnly && (
              <Button
                className="btn-base btn-danger"
                onClick={() => {
                  setShowDeleteConfirmation(true);
                }}
              >
                {isDesktop ? "Supprimer" : <FontAwesomeIcon icon={faTrash} />}
              </Button>
            )}
          </Stack>
        </Stack>
      </FormControl>
    </>
  ) : (
    <LoadingAppointmentForm />
  );
};

export default AppointmentForm;
