import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Button, Modal } from "flowbite-react";

import { APIResponseModel } from "../../models/ApiResponseModel";
import { CGVModel } from "../../pages/parameters/center/CenterCGV";
import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";
import { RootState } from "../../store/store";
import { isTextContentEmpty } from "../../utils/layout/isTextContentEmpty";
import useWindowWidth from "../../hooks/layout/useWindowWidth";

import ErrorDisplay from "./ErrorDisplay";

interface Props {
  setIsCGVAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

const CGVModal = ({ setIsCGVAccepted }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const isDesktop = useWindowWidth();

  const [showModal, setShowModal] = useState<boolean>(true);
  const [CGVContent, setCGVContent] = useState<string | null>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);

  const fetchCGV = async () => {
    try {
      const response: AxiosResponse<APIResponseModel<CGVModel>> = await api.get(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/CGV`,
      );
      if (response && response.data.success) {
        // Checks if content is empty or null
        const isContentTextEmpty = response.data.item.content
          ? isTextContentEmpty(response.data.item.content)
          : true;
        if (response.data.item === null || isContentTextEmpty) {
          // Sets CGV as accepted if no valid content is found
          setIsCGVAccepted(true);
        } else {
          setCGVContent(response.data.item.content);
        }
      } else {
        setErrorMessage(
          "Une erreur est survenue lors de la récupération des conditions générales de ventes.",
        );
      }
    } catch (error) {
      setErrorMessage(
        "Une erreur est survenue lors de la récupération des conditions générales de ventes.",
      );
    }
  };

  useEffect(() => {
    fetchCGV();
  }, []);

  return (
    <>
      {(CGVContent || errorMessage) && (
        <Modal
          popup={false}
          show={showModal}
          onClose={() => setShowModal(false)}
          className="font-['Inter']"
          dismissible={false}
          size="7xl"
          theme={{
            root: {
              base: "fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden w-screen m-0",
            },
            content: {
              base: "relative h-full w-full md:p-4 md:h-auto",
              inner:
                "relative w-full h-full flex md:max-h-[90dvh] flex-col md:rounded-lg bg-white shadow",
            },
            header: { close: { base: "hidden", icon: "hidden" } },
            body: { base: "flex-1 overflow-auto p-6" },
          }}
        >
          <Modal.Header>
            Conditions Générales de Vente - {currentCenter?.name}
          </Modal.Header>
          <Modal.Body>
            {errorMessage ? (
              <ErrorDisplay displayMessage={errorMessage} showLogoutButton />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: CGVContent ?? "" }} />
            )}
          </Modal.Body>
          {!errorMessage && (
            <Modal.Footer>
              <Button
                className="btn-base btn-outlined"
                onClick={() => {
                  navigate("/logout");
                }}
              >
                Je refuse {isDesktop && "et je quitte le site"}
              </Button>
              <Button
                className="btn-base"
                onClick={() => {
                  sessionStorage.setItem(
                    "isCGVAccepted",
                    `${currentCenter?.pid}-${currentUser?.externalUserId}`,
                  );
                  setIsCGVAccepted(true);
                  setShowModal(false);
                }}
              >
                {`J'accepte ${isDesktop && "et je continue"}`}
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      )}
    </>
  );
};

export default CGVModal;
