import { Box, FormControl, Stack, TextField, Typography } from "@mui/material";
import { Alert, Button, Spinner } from "flowbite-react";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarSide,
  faMotorcycle,
  faTruck,
} from "@fortawesome/pro-regular-svg-icons";

import { CenterModel } from "../../../models/CenterModel";
import { generalSpacing } from "../../../utils/customTheme/customTheme";
import { useSaveCenter } from "../../../hooks/center/useSaveCenter";

import SelectableChip from "../../../components/common/SelectableChip";

interface Props {
  center: CenterModel;
  handleCloseDrawer: () => void;
}

const CenterForm = ({ center, handleCloseDrawer }: Props) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm<CenterModel>({
    defaultValues: {
      name: center.name,
      mainContact: center.mainContact,
      phone: center.phone,
      mobile: center.mobile,
      email: center.email,
      address1: center.address1,
      address2: center.address2,
      postalCode: center.postalCode,
      city: center.city,
      latitude: center.latitude,
      longitude: center.longitude,
      website: center.website,
      activities: center.activities,
    },
  });

  // Using the custom hook to manage the form submission logic,
  const { saveCenter, requestState } = useSaveCenter(center, handleCloseDrawer);

  return (
    <Stack gap={generalSpacing}>
      <FormControl
        component="form"
        id="centerForm"
        onSubmit={handleSubmit(saveCenter)}
        sx={{ width: "100%", gap: "0.5rem" }}
      >
        <TextField
          {...register("name", { required: "Veuillez renseigner un nom." })}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
          onChange={() => {
            Boolean(errors.name) && clearErrors("name");
          }}
          label="Nom du centre"
          fullWidth
          autoComplete="off"
          aria-autocomplete="none"
          inputProps={{ "aria-autocomplete": "none" }}
        />
        <TextField
          {...register("mainContact")}
          label="Nom du responsable"
          fullWidth
          autoComplete="off"
          aria-autocomplete="none"
          inputProps={{ "aria-autocomplete": "none" }}
        />

        <Stack flexDirection={"row"} gap={generalSpacing}>
          <TextField
            {...register("phone", {
              required: "Veuillez renseigner un numéro de téléphone",
              maxLength: {
                value: 20,
                message: "Le numéro est limité à 20 caractères.",
              },
            })}
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message}
            label="Téléphone"
            fullWidth
            autoComplete="off"
            aria-autocomplete="none"
            inputProps={{ maxLength: 20, "aria-autocomplete": "none" }}
          />
          <TextField
            {...register("mobile", {
              maxLength: {
                value: 20,
                message: "Le numéro est limité à 20 caractères.",
              },
            })}
            error={Boolean(errors.mobile)}
            helperText={errors.mobile?.message}
            label="Portable"
            fullWidth
            autoComplete="off"
            aria-autocomplete="none"
            inputProps={{ maxLength: 20, "aria-autocomplete": "none" }}
          />
        </Stack>

        <TextField
          {...register("email", {
            maxLength: {
              value: 100,
              message: "L'email est limité à 100 caractères.",
            },
          })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          label="Email"
          fullWidth
          autoComplete="off"
          aria-autocomplete="none"
          inputProps={{ maxLength: 50, "aria-autocomplete": "none" }}
        />
        <TextField
          {...register("address1")}
          error={Boolean(errors.address1)}
          helperText={errors.address1?.message}
          label="Adresse 1"
          fullWidth
          autoComplete="off"
          aria-autocomplete="none"
          inputProps={{ "aria-autocomplete": "none" }}
        />
        <TextField
          {...register("address2")}
          error={Boolean(errors.address2)}
          helperText={errors.address2?.message}
          label="Adresse 2"
          fullWidth
          autoComplete="off"
          aria-autocomplete="none"
          inputProps={{ "aria-autocomplete": "none" }}
        />

        <Stack flexDirection={"row"} gap={generalSpacing}>
          <TextField
            {...register("postalCode", {
              maxLength: {
                value: 10,
                message: "Le code postal est limité à 20 caractères.",
              },
            })}
            error={Boolean(errors.postalCode)}
            helperText={errors.postalCode?.message}
            label="Code postal"
            fullWidth
            autoComplete="off"
            aria-autocomplete="none"
            inputProps={{ maxLength: 10, "aria-autocomplete": "none" }}
          />
          <TextField
            {...register("city", { required: "Veuillez renseigner une ville" })}
            error={Boolean(errors.city)}
            helperText={errors.city?.message}
            onChange={() => {
              Boolean(errors.city) && clearErrors("city");
            }}
            label="Ville"
            fullWidth
            autoComplete="off"
            aria-autocomplete="none"
            inputProps={{ "aria-autocomplete": "none" }}
          />
        </Stack>

        <Stack flexDirection={"row"} gap={generalSpacing}>
          <TextField
            {...register("latitude", {
              pattern: {
                value: /^-?\d{1,2}\.\d+$/,
                message:
                  "Veuillez entrer une latitude valide pour la position GPS.",
              },
            })}
            error={Boolean(errors.latitude)}
            helperText={errors.latitude?.message}
            onChange={() => {
              Boolean(errors.latitude) && clearErrors("latitude");
            }}
            label="Latitude"
            fullWidth
            autoComplete="off"
            aria-autocomplete="none"
            inputProps={{ maxLength: 10, "aria-autocomplete": "none" }}
          />

          <TextField
            {...register("longitude", {
              pattern: {
                value: /^-?\d{1,2}\.\d+$/,
                message:
                  "Veuillez entrer une longitude valide pour la position GPS.",
              },
            })}
            error={Boolean(errors.longitude)}
            helperText={errors.longitude?.message}
            onChange={() => {
              Boolean(errors.longitude) && clearErrors("longitude");
            }}
            label="Longitude"
            fullWidth
            autoComplete="off"
            aria-autocomplete="none"
            inputProps={{ "aria-autocomplete": "none" }}
          />
        </Stack>

        <TextField
          {...register("website")}
          error={Boolean(errors.website)}
          helperText={errors.website?.message}
          label="Site web"
          fullWidth
          autoComplete="off"
          aria-autocomplete="none"
          inputProps={{ "aria-autocomplete": "none" }}
        />

        <Stack flexDirection={"column"}>
          <Typography fontWeight="bold" marginBottom={"0.25rem"}>
            Activité
            {watch("activities") && watch("activities").length > 1
              ? "s"
              : ""}{" "}
            du centre
          </Typography>

          <Controller
            name="activities"
            control={control}
            render={({ field }) => (
              <SelectableChip
                value={field.value}
                onChange={(newValue) => field.onChange(newValue)}
                direction="row"
                autoSelectValues={center.activities}
                options={[
                  {
                    label: (
                      <>
                        <FontAwesomeIcon icon={faCarSide} size={"lg"} /> VL
                      </>
                    ),
                    value: "VL",
                  },
                  {
                    label: (
                      <>
                        <FontAwesomeIcon icon={faMotorcycle} size={"lg"} /> L
                      </>
                    ),
                    value: "MOT",
                  },
                  {
                    label: (
                      <>
                        <FontAwesomeIcon icon={faTruck} size={"lg"} /> PL
                      </>
                    ),
                    value: "PL",
                  },
                ]}
              />
            )}
          />
        </Stack>

        <Stack
          sx={{
            width: "100%",
            position: "sticky",
            paddingY: generalSpacing,
            bottom: 0,
            gap: 2,
            backgroundColor: "white",
          }}
        >
          {requestState.error && (
            <Box>
              <Alert color="failure">{requestState.error}</Alert>
            </Box>
          )}
          <Stack width="fit-content" flexDirection="row" gap={1}>
            <Button
              type="submit"
              className="btn-base"
              disabled={requestState.loading}
            >
              {requestState.loading ? <Spinner size="sm" /> : "Enregistrer"}
            </Button>
          </Stack>
        </Stack>
      </FormControl>
    </Stack>
  );
};

export default CenterForm;
