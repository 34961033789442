import { ReactNode } from "react";

interface Props {
  title: string;
  children: ReactNode;
  isNotTypography?: boolean;
  afterText?: string;
}

const InfoItem = ({
  title,
  children,
  afterText,
  isNotTypography = false,
}: Props) => {
  return (
    <div className="flex flex-col gap-1 w-fit">
      <p className="text-gray-500 text-left tracking-wide leading-[15px] text-sm font-medium">
        {title.toUpperCase()}
      </p>
      {isNotTypography ? (
        children
      ) : (
        <p className="text-left leading-5 font-secondary">
          {children}
          {afterText && <span className="font-normal">{` ${afterText}`}</span>}
        </p>
      )}
    </div>
  );
};

export default InfoItem;
