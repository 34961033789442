import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { api } from "../../utils/api";
import { AppDispatch } from "../store";
import { CONFIG_API } from "../../data/config.API";
import { UserModel } from "../../models/UserModel";
import { APIResponsesModel } from "../../models/ApiResponseModel";

export interface InitialStateModel {
  usersList: UserModel[] | undefined;
  currentUser: UserModel | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: InitialStateModel = {
  usersList: undefined,
  currentUser: undefined,
  loading: true,
  error: null,
};

// SLICE
export const userSlice = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    setUsersList: (
      currentSlice,
      action: PayloadAction<UserModel[] | undefined>,
    ) => {
      currentSlice.usersList = action.payload;
    },
    setCurrentUser: (
      currentSlice,
      action: PayloadAction<UserModel | undefined>,
    ) => {
      currentSlice.currentUser = action.payload;
    },
    setLoading: (currentSlice, action: PayloadAction<boolean>) => {
      currentSlice.loading = action.payload;
    },
    setError: (currentSlice, action: PayloadAction<string | null>) => {
      currentSlice.error = action.payload;
    },
  },
});

export const { setCurrentUser, setUsersList, setLoading, setError } =
  userSlice.actions;

// ACTIONS
export const fetchUserData =
  (organizationPid: string, externalUserId: string | undefined) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: AxiosResponse<APIResponsesModel<UserModel>> =
        await api.get(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.ACCOUNT}/${CONFIG_API.EXTERNAL_USER_ID}/${externalUserId}/${CONFIG_API.PROFILE}`,
        );
      if (response.data.items) {
        const profilesNumber = response.data.items.length;
        if (profilesNumber === 0) {
          dispatch(setError("Ce profil est introuvable."));
        } else if (profilesNumber === 1) {
          dispatch(setCurrentUser(response.data.items[0]));
        } else {
          dispatch(setUsersList(response.data.items));
        }
        return response.data;
      } else {
        dispatch(setError("Ce profil est introuvable."));
        return response.data;
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(
          setError(
            "Une erreur est survenue lors de la récupération des données de l'utilisateur.",
          ),
        );
      }
      return false;
    } finally {
      dispatch(setLoading(false));
    }
  };

// Set currentUser based on the profile (selectedProfilePid), in cases where multiple profiles are assigned to a user
export const selectCurrentUser =
  (selectedProfilePid: string, profilesList: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    if (selectedProfilePid) {
      // Find the profile that matches by PID in the user's list of profiles
      dispatch(
        setCurrentUser(
          profilesList.find(
            (profile: UserModel) => profile.pid === selectedProfilePid,
          ),
        ),
      );
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      dispatch(setError("Ce profil est introuvable."));
    }
  };
