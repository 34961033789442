import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "flowbite-react";

import { RootState } from "../../../store/store";

import SidePanel from "../../../components/layouts/SidePanel";
import CenterForm from "./CenterForm";
import InfoGrid, { InfoItemModel } from "../../../components/layouts/InfoGrid";
import CenterItem from "../../../components/layouts/header/CenterItem";

const CenterParameters = () => {
  const centerState = useSelector((store: RootState) => store.CENTER);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const centerInfo: InfoItemModel[] = [
    { label: "Nom", value: centerState.currentCenter?.name },
    {
      label: "Nom du responsable",
      value: centerState.currentCenter?.mainContact,
    },
    {
      label: "Adresse",
      value:
        centerState.currentCenter?.address1 ||
        centerState.currentCenter?.address2 ? (
          <>
            {centerState.currentCenter?.address1}
            <br />
            {centerState.currentCenter?.address2}
          </>
        ) : undefined,
      fullWidth: true,
    },
    { label: "Code postal", value: centerState.currentCenter?.postalCode },
    { label: "Ville", value: centerState.currentCenter?.city },
    {
      label: "Position GPS",
      value:
        centerState.currentCenter?.latitude &&
        centerState.currentCenter?.longitude ? (
          <a
            className="underline cursor-pointer"
            onClick={() => {
              const url = `https://www.google.com/maps?q=${centerState.currentCenter?.latitude},${centerState.currentCenter?.longitude}`;
              window.open(url, "_blank", "noopener,noreferrer");
            }}
          >
            {`${centerState.currentCenter?.latitude} ${centerState.currentCenter?.longitude}`}
          </a>
        ) : undefined,
      fullWidth: true,
    },
    {
      label: "Email",
      value: `${centerState.currentCenter?.email}`,
      fullWidth: true,
    },
    { label: "Téléphone", value: `${centerState.currentCenter?.phone}` },
    { label: "Portable", value: `${centerState.currentCenter?.mobile}` },
    {
      label: "Site Web",
      value: centerState.currentCenter?.website ? (
        <a
          className="underline cursor-pointer"
          onClick={() => {
            const url = centerState.currentCenter?.website;
            if (url) {
              window.open(url, "_blank", "noopener,noreferrer");
            }
          }}
        >
          {centerState.currentCenter?.website}
        </a>
      ) : undefined,
      fullWidth: true,
    },
  ];

  return (
    <>
      {centerState.currentCenter && (
        <div>
          <SidePanel
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            title={"Modification des coordonnées"}
            handleCloseDrawer={handleCloseDrawer}
          >
            <CenterForm
              key={Date.now()}
              center={centerState.currentCenter}
              handleCloseDrawer={handleCloseDrawer}
            />
          </SidePanel>

          <div className="flex flex-col h-screen pl-4 mr-4 lg:p-0 gap-2">
            <div>
              <div className="flex flex-col lg:flex-row flex-[2] gap-2 lg:gap-0">
                <div className="flex flex-row flex-1 flex-wrap items-center gap-6 lg:mt-0 lg:ml-0">
                  <div className="max-w-full mx-auto xl:mx-0">
                    <CenterItem
                      centerName={centerState.currentCenter.name}
                      centerColor={centerState.currentCenter.centerColor}
                      centerActivities={centerState.currentCenter.activities}
                      isCurrentCenter={true}
                      isChangeable={false}
                      isItem={false}
                      size="lg"
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-center gap-2">
                  <div className="flex flex-row gap-2 justify-center lg:justify-start">
                    <Button
                      className="btn-base"
                      onClick={() => setShowDrawer(true)}
                    >
                      Modifier
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 bg-white border border-gray-300 rounded-md gap-4 max-h-[70vh] 2xl:max-h-[78vh] overflow-y-scroll overflow-x-hidden">
              <InfoGrid title="Coordonnées" infoItems={centerInfo} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CenterParameters;
