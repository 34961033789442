import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar } from "flowbite-react";

import { RootState } from "../../../store/store";
import CTonlineLogo from "../../../assets/images/ctonline-logo.svg";
import useWindowWidth, {
  getBreakpointValue,
  tailwindBreakpoints,
} from "../../../hooks/layout/useWindowWidth";
import { CONFIG_API } from "../../../data/config.API";

import CustomIcon from "../CustomIcon";
import CenterPicker from "./CenterPicker";
import ProfileMenu from "../../auth/ProfileMenu";
import SearchBar from "./SearchBar";

export interface HeaderProps {
  showMenuDrawer: { isOpen: boolean; isFullyOpen: boolean };
  showNewsDrawer: boolean;
  toggleMenuDrawer: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      isFullyOpen: boolean;
    }>
  >;
  setShowNewsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  unreadNewsNumber: number;
}

const Header = ({
  showMenuDrawer,
  showNewsDrawer,
  unreadNewsNumber,
  toggleMenuDrawer,
  setShowNewsDrawer,
}: HeaderProps) => {
  const navigate: NavigateFunction = useNavigate();

  const centerState = useSelector((store: RootState) => store.CENTER);
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);

  const isDesktop = useWindowWidth(getBreakpointValue(tailwindBreakpoints.xl));

  const [showSearchBar, setShowSearchBar] = useState(false);

  return (
    <Navbar
      className={`w-full h-24 py-6 pr-0 flex-wrap bg-white z-[50] border-b border-gray-200 `}
      theme={{
        root: {
          inner: {
            base: `max-sm:pr-4 flex items-center justify-between`,
          },
        },
      }}
      fluid
      rounded
    >
      <Navbar.Brand>
        <div className="items-center hidden lg:inline-flex">
          <img
            src={CTonlineLogo}
            alt="Logo CTonline"
            className="cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="cursor-pointer inline-flex lg:hidden">
          <CustomIcon
            name="bars"
            color="#6B7280"
            onClick={() =>
              toggleMenuDrawer({ isOpen: !showMenuDrawer, isFullyOpen: true })
            }
          />
        </div>
      </Navbar.Brand>

      {!centerState.currentCenter?.isLimited &&
        currentUser?.accountType !== CONFIG_API.CLIENT && (
          <SearchBar isHidden={!isDesktop && !showSearchBar} />
        )}

      <div
        className={`inline-flex flex-row items-center gap-2 xs:gap-1 sm:gap-5`}
      >
        {centerState.currentCenter && centerState.organizationCenters && (
          <div className={`${showSearchBar && "max-xl:hidden"}`}>
            <CenterPicker
              center={centerState.currentCenter}
              centersList={centerState.organizationCenters}
            />
          </div>
        )}

        {!centerState.currentCenter?.isLimited &&
          currentUser?.accountType !== CONFIG_API.CLIENT && (
            <>
              <div
                className="visible xl:hidden cursor-pointer "
                onClick={() => setShowSearchBar(!showSearchBar)}
              >
                <CustomIcon
                  name={showSearchBar ? "xOutline" : "searchOutline"}
                  color="#6B7280"
                />
              </div>

              <div
                className="relative cursor-pointer"
                onClick={() => setShowNewsDrawer(!showNewsDrawer)}
              >
                <CustomIcon name="bellActive" color="#6B7280" />
                {unreadNewsNumber > 1 && (
                  <div className="absolute top-0 right-0 rotate-180 border border-white w-2 h-2 rounded-full bg-red-500"></div>
                )}
              </div>
            </>
          )}

        <ProfileMenu />
      </div>
    </Navbar>
  );
};

export default Header;
