import { LocaleHelper } from "@bryntum/calendar";
import "../Scheduler/FrFr.js";

const locale = {
  localeName: "FrFr",
  localeDesc: "Français (France)",
  localeCode: "fr-FR",

  EventEdit: {
    Calendar: "Calendrier",
    "All day": "Jour entier",
    day: "Jour",
    week: "Semaine",
    month: "Mois",
    year: "Année",
    decade: "Décennie",
  },

  EventMenu: {
    duplicateEvent: "Dupliquer l'événement",
    copy: "copier",
  },

  Calendar: {
    toggleSidebar: "Basculer la visibilité de la barre latérale",
    Today: "Aujourd'hui",
    next: (range) => `Suivant ${range}`,
    previous: (range) => `Précédent ${range}`,
    plusMore: (value) => `+${value} de plus`,
    allDay: "Jour entier",
    endsOn: (d) => `Prend fin le ${d}`,
    weekOfYear: ([y, w]) => `Semaine ${w}, ${y}`,
    loadFail:
      "Échec du chargement des données de calendrier. Veuillez contacter votre administrateur système",
  },

  CalendarDrag: {
    holdCtrlForRecurrence:
      "Maintenir la touche CTRL enfoncée pour un événement récurrent",
  },

  CalendarMixin: {
    eventCount: (count) =>
      `${count || "Aucun"} événement${count && count > 1 ? "s" : ""}`,
  },

  EventTip: {
    "Edit event": "Éditer l'événement",
    timeFormat: "LST",
  },

  ModeSelector: {
    includeWeekends: "Inclure les week-ends",
    weekends: "Week-ends",
  },

  AgendaView: {
    Agenda: "Agenda",
  },

  MonthView: {
    Month: "Mois",
    monthUnit: "mois",
  },

  WeekView: {
    weekUnit: "semaine",
  },

  YearView: {
    Year: "Année",
    yearUnit: "année",
    noEvents: "Pas d'événements",
  },

  EventList: {
    List: "Liste",
    Start: "Début",
    Finish: "Fin",
    days: (count) =>
      `${count > 1 ? `${count} ` : ""}jour${count === 1 ? "" : "s"}`,
  },

  DayView: {
    Day: "Jour",
    dayUnit: "jour",
    daysUnit: "jours",
    expandAllDayRow: "Développer la section jour entier",
    collapseAllDayRow: "Réduire la section jour entier",
    timeFormat: "LST",
    timeAxisTimeFormat: "LST",
  },

  DayResourceView: {
    dayResourceView: "Ressources quotidiennes",
  },

  Sidebar: {
    "Filter events": "Filtrer les événements",
  },

  WeekExpander: {
    expandTip: "Cliquez pour développer la ligne",
    collapseTip: "Cliquez pour réduire la ligne",
  },
};

export default LocaleHelper.publishLocale(locale);
