import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Stack, TextField, Typography } from "@mui/material";
import { Alert, Button, Spinner } from "flowbite-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AxiosResponse } from "axios";

import { APIResponseModel } from "../../models/ApiResponseModel";
import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";
import { AppDispatch, RootState } from "../../store/store";
import { displayError } from "../../utils/layout/displayError";
import { setAlert } from "../../store/layout/alert-slice";

type Props = {
  recipientPhoneNumber?: string;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

type Inputs = {
  recipient: string;
  message: string;
};

const SmsForm = ({ recipientPhoneNumber, setShowModal }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [remainingCharacters, setRemainingCharacters] = useState<number>(160);

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setLoading(true);
    try {
      const response: AxiosResponse<
        APIResponseModel<{ receiptId: string; smsContent: string }>
      > = await api.post(
        `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/${CONFIG_API.MESSENGER}/rawSms`,
        { message: formData.message, recipients: [formData.recipient] },
      );
      if (response.data.success) {
        if (setShowModal) {
          setShowModal(false);
        }
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message: "Le SMS a bien été envoyé.",
          }),
        );
      } else {
        setError(displayError(response.data.messages[0]));
      }
    } catch (error) {
      setError("Une erreur est survenue lors de l'envoi du SMS");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormControl
      component="form"
      id="smsForm"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ width: "100%" }}
    >
      <Typography className="title-card" margin="0" textAlign="center">
        Envoyer un SMS
      </Typography>
      <Stack id="appointmentForm" paddingY={2} gap={2}>
        <TextField
          {...register("recipient", {
            required: "Veuillez indiquer un numéro de destinataire.",
            value: recipientPhoneNumber,
            maxLength: {
              value: 20,
              message: "Le numéro de téléphone est limité à 20 caractères.",
            },
          })}
          label="Destinataire"
          error={Boolean(errors.recipient)}
          helperText={errors.recipient ? errors.recipient.message : null}
          inputProps={{ maxLength: 20 }}
          sx={{ width: "12rem" }}
        />
        <Stack>
          <Stack flexDirection="column">
            <TextField
              {...register("message", {
                required: "Veuillez rédiger un message.",
                maxLength: {
                  value: 160,
                  message: "Le message est limité à 160 caractères.",
                },
                onChange: (e) => {
                  setRemainingCharacters(160 - e.target.value.length);
                },
              })}
              placeholder="Message"
              multiline
              rows={4}
              error={Boolean(errors.message)}
              helperText={errors.message ? errors.message.message : null}
              inputProps={{ maxLength: 160 }}
              InputProps={{
                sx: { backgroundColor: "white", borderRadius: "10px" },
              }}
            />
            <Typography
              variant="subtitle2"
              sx={{ color: remainingCharacters <= 0 ? "red" : "black" }}
            >
              {remainingCharacters} caractère{remainingCharacters > 1 && "s"}{" "}
              restant{remainingCharacters > 1 && "s"}
            </Typography>
          </Stack>
        </Stack>
        {error && (
          <Box>
            <Alert color="failure">{error}</Alert>
          </Box>
        )}
        <Stack flexDirection="row" gap={1}>
          <Button
            id="smsSubmit"
            type="submit"
            className="btn-base"
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : "Envoyer"}
          </Button>
          {setShowModal && (
            <Button
              className="btn-base btn-outlined"
              onClick={() => setShowModal(false)}
            >
              Annuler
            </Button>
          )}
        </Stack>
      </Stack>
    </FormControl>
  );
};

export default SmsForm;
