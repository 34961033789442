import { icons } from "../../utils/layout/icons";

export interface CustomIconProps {
  name: keyof typeof icons;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  rotate?: number;
  flip?: "horizontal" | "vertical" | "both";
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const CustomIcon = ({
  name,
  onClick,
  width,
  height,
  flip,
  className = "",
  color = "currentColor",
  rotate = 0,
}: CustomIconProps) => {
  // Function to extract all paths (d attribute) from the SVG string
  const extractPaths = (svg: string): string[] => {
    const pathRegex = /<path[^>]*d="([^"]*)"/g;
    const paths: string[] = [];
    let match;
    // Loop through all matches and push the "d" attribute to the paths array
    while ((match = pathRegex.exec(svg)) !== null) {
      paths.push(match[1]);
    }
    return paths;
  };

  // Function to extract a specific attribute (e.g., width, height, viewBox) from the SVG string
  const extractAttribute = (svg: string, attribute: string): string | null => {
    const regex = new RegExp(`${attribute}="([^"]+)"`);
    const match = svg.match(regex);
    // Return the value of the matched attribute or null if not found
    return match ? match[1] : null;
  };

  const icon = icons[name]; // Get the icon object from the icons collection using the provided name

  const flipTransform =
    flip === "horizontal"
      ? "scaleX(-1)"
      : flip === "vertical"
        ? "scaleY(-1)"
        : flip === "both"
          ? "scale(-1, -1)"
          : "";

  return icon ? (
    <svg
      className={className}
      width={width || extractAttribute(icon.svg, "width") || "20"}
      height={height || extractAttribute(icon.svg, "height") || "20"}
      viewBox={extractAttribute(icon.svg, "viewBox") || "0 0 20 20"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        color,
        transform: `${flipTransform} rotate(${rotate}deg)`,
        transformOrigin: "center",
      }}
      onClick={onClick}
    >
      {/* Loop through each extracted path and render it to "draw" the svg icon with custom parameters */}
      {extractPaths(icon.svg).map((path, index) => (
        <path
          key={index}
          d={path}
          fill="currentColor"
          className={className}
          stroke={extractAttribute(icon.svg, "stroke") ? "currentColor" : ""}
          strokeWidth={extractAttribute(icon.svg, "stroke-width") ?? ""}
        />
      ))}
    </svg>
  ) : (
    <></>
  );
};

export default CustomIcon;
