import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Spinner } from "flowbite-react";
import { Box, FormControl, Stack } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AxiosResponse } from "axios";

import { AppDispatch, RootState } from "../../../store/store";
import { api } from "../../../utils/api";
import { displayError } from "../../../utils/layout/displayError";
import { APIResponsesModel } from "../../../models/ApiResponseModel";
import { setAlert } from "../../../store/layout/alert-slice";
import { CONFIG_API } from "../../../data/config.API";

import SelectableChip from "../../../components/common/SelectableChip";

interface Props {
  handleCloseDrawer: () => void;
}

interface PublicHolidaysModel {
  holiday: number;
  description: string;
  isClosed: boolean;
}

type Inputs = {
  publicHolidays: number[];
};

const PublicHolidaysForm = ({ handleCloseDrawer }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { handleSubmit, control } = useForm<Inputs>();

  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const [errors, setErrors] = useState<{
    loadingError: string | null;
    updateError: string | null;
  }>({ loadingError: null, updateError: null });
  const [loading, setLoading] = useState<boolean>(false);
  const [publicHolidaysList, setPublicHolidaysList] = useState<
    PublicHolidaysModel[] | undefined
  >(undefined);

  const fetchPublicHolidays = async () => {
    try {
      await api
        .get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/holidays`,
        )
        .then(
          (response: AxiosResponse<APIResponsesModel<PublicHolidaysModel>>) => {
            if (response.data.success) {
              setPublicHolidaysList(response.data.items);
            } else {
              setErrors({
                ...errors,
                loadingError: displayError(response.data.messages[0]),
              });
            }
          },
        );
    } catch (error) {
      setErrors({
        ...errors,
        loadingError:
          "Une erreur est survenue lors de la récupération des jours fériés.",
      });
    }
  };

  const publicHolidaysSave: SubmitHandler<Inputs> = async (formData) => {
    // Format formData to API Request format
    const requestBody = publicHolidaysList?.map((holiday) => ({
      holiday: holiday.holiday,
      isClosed: formData.publicHolidays.includes(holiday.holiday),
    }));

    try {
      await api
        .put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/holidays`,
          requestBody,
        )
        .then(
          (response: AxiosResponse<APIResponsesModel<PublicHolidaysModel>>) => {
            if (response.data.success) {
              handleCloseDrawer();
              dispatch(
                setAlert({
                  id: "global-alert",
                  type: "success",
                  message: "Les jours fériés du centre ont bien été modifiés.",
                }),
              );
            } else {
              setErrors({
                ...errors,
                updateError: displayError(response.data.messages[0]),
              });
            }
          },
        );
    } catch (error) {
      setErrors({ ...errors, updateError: "Une erreur est survenue." });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPublicHolidays();
  }, []);

  return (
    <Stack sx={{ width: "100%" }}>
      {errors.loadingError ? (
        <Alert color="failure">{errors.loadingError}</Alert>
      ) : publicHolidaysList ? (
        <FormControl
          component="form"
          onSubmit={handleSubmit(publicHolidaysSave)}
          sx={{
            gap: 2,
          }}
        >
          <Stack
            gap={2}
            sx={{
              width: "100%",
              height: "fit-content",
              margin: "auto",
              padding: 2,
              borderRadius: "10px",
            }}
          >
            <Stack margin="auto" gap={0.5}>
              <Controller
                name="publicHolidays"
                control={control}
                defaultValue={publicHolidaysList
                  ?.filter((item) => item.isClosed)
                  .map((item) => item.holiday)}
                render={({ field }) => (
                  <SelectableChip
                    options={
                      publicHolidaysList?.map((item) => {
                        return {
                          label: item.description,
                          value: item.holiday,
                        };
                      }) ?? []
                    }
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    direction="column"
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              position: "sticky",
              paddingY: 1,
              bottom: 0,
              gap: 2,
              backgroundColor: "white",
            }}
          >
            {errors.updateError && (
              <Box>
                <Alert color="failure">{errors.updateError}</Alert>
              </Box>
            )}
            <Stack width="fit-content" flexDirection="row" gap={1}>
              <Button type="submit" className="btn-base" disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Enregistrer"}
              </Button>
            </Stack>
          </Stack>
        </FormControl>
      ) : (
        <div className="h-screen flex justify-center items-center">
          <Spinner className="mx-auto" />
        </div>
      )}
    </Stack>
  );
};

export default PublicHolidaysForm;
