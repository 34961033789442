import { useEffect } from "react";

declare global {
  interface Window {
    zE?: (...args: any[]) => void;
  }
}

/**
 * Custom hook to show or hide the Zendesk chat widget based on the drawer state.
 * @param isDrawerOpen Boolean indicating if a drawer is open or not.
 */
const useZendeskChat = (isDrawerOpen: boolean) => {
  useEffect(() => {
    const hideZendeskChat = () => {
      if (window.zE) {
        window.zE("webWidget", "hide");
      }
    };

    const showZendeskChat = () => {
      if (window.zE) {
        window.zE("webWidget", "show");
      }
    };

    if (isDrawerOpen) {
      hideZendeskChat(); // Hide chat when drawer is open
    } else {
      showZendeskChat(); // Show chat when drawer is closed
    }
  }, [isDrawerOpen]); // Depend on the drawer's open/close state
};

export default useZendeskChat;
