import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  createFilterOptions,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Alert, Button, Spinner } from "flowbite-react";
import { AxiosResponse } from "axios";

import { AppDispatch, RootState } from "../../../store/store";
import { AccountType, UserInfoModel } from "../../../models/UserModel";
import { APIResponsesModel } from "../../../models/ApiResponseModel";
import { api } from "../../../utils/api";
import { CONFIG_API } from "../../../data/config.API";
import {
  colors,
  fonts,
  generalSpacing,
} from "../../../utils/customTheme/customTheme";
import { displayError } from "../../../utils/layout/displayError";
import { setAlert } from "../../../store/layout/alert-slice";

import ErrorLoadingData from "../../common/ErrorLoadingData";

interface Props {
  usersList: UserInfoModel[];
  setAllUsersList?: React.Dispatch<React.SetStateAction<UserInfoModel[]>>;
  handleCloseDrawer: () => void;
}

const OrganizationUsers = ({
  usersList,
  setAllUsersList,
  handleCloseDrawer,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const [othersUsers, setOthersUsers] = useState<UserInfoModel[]>();
  const [selectedUsers, setSelectedUsers] = useState<UserInfoModel[]>([]);
  const [requestErrors, setRequestErrors] = useState<{
    loadingError: string | null;
    actionError: string | null;
  }>({
    loadingError: null,
    actionError: null,
  });
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const fetchOrganizationUsers = async () => {
    setRequestErrors({ loadingError: null, actionError: null });
    try {
      const response: AxiosResponse<APIResponsesModel<UserInfoModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.ACCOUNT_USER}`,
        );
      if (response.data.success) {
        // Filter only users that aren't already linked to the current center
        const notCurrentCenterUsers = response.data.items.filter(
          (user) => !user.allowedCentersPIDs.includes(currentCenter?.pid ?? ""),
        );
        if (notCurrentCenterUsers) {
          setOthersUsers(notCurrentCenterUsers);
        }
      } else {
        setRequestErrors({
          ...requestErrors,
          loadingError: displayError(response.data.messages[0]),
        });
      }
    } catch (error) {
      setRequestErrors({
        ...requestErrors,
        loadingError: "Une erreur est survenue.",
      });
    }
  };

  const addUsers = async () => {
    setRequestErrors({ ...requestErrors, actionError: null });
    setLoadingAction(true);
    try {
      const updatePromises = selectedUsers.map((user: UserInfoModel) =>
        api.put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.ACCOUNT_USER}/${user.pid}`,
          {
            ...user,
            allowedCentersPIDs: [
              ...user.allowedCentersPIDs,
              currentCenter?.pid,
            ],
          },
        ),
      );
      const updateResponses = await Promise.all(updatePromises);

      let successCount = 0;
      let errorCount = 0;
      const addedUsers: UserInfoModel[] = [];

      // Process each delete response
      updateResponses.forEach((updateResponse) => {
        if (updateResponse.data.success && updateResponse.data.item) {
          successCount++;
          addedUsers.push(updateResponse.data.item);
        } else {
          errorCount++;
        }
      });

      // Update users table with all successfully deleted users
      if (setAllUsersList && addedUsers.length > 0) {
        const mergedUsers = [
          ...usersList,
          ...addedUsers.map((user) => ({
            ...user,
            accountType: AccountType.User,
          })),
        ];
        setAllUsersList(mergedUsers);
      }

      // Determine the appropriate message to dispatch
      if (successCount === selectedUsers.length) {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message:
              selectedUsers.length > 1
                ? "Les utilisateurs ont bien été ajoutés."
                : "L'utilisateur a bien été ajouté.",
          }),
        );
        handleCloseDrawer();
      } else if (errorCount === selectedUsers.length) {
        setRequestErrors({
          ...requestErrors,
          actionError:
            selectedUsers.length > 1
              ? "Une erreur est survenue lors de l'ajout des utilisateurs."
              : "Une erreur est survenue lors de l'ajout de l'utilisateur.",
        });
      } else {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "warning",
            message:
              "Une erreur est survenue lors de l'ajout de certains utilisateurs.",
          }),
        );
        handleCloseDrawer();
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "failure",
          message: "Une erreur est survenue.",
        }),
      );
    } finally {
      setLoadingAction(false);
    }
  };

  useEffect(() => {
    fetchOrganizationUsers();
  }, []);

  return requestErrors.loadingError ? (
    <ErrorLoadingData
      retryFunction={fetchOrganizationUsers}
      errorMessage={requestErrors.loadingError}
    />
  ) : (
    <Stack gap={generalSpacing}>
      <Autocomplete
        onBlur={() => setShowOptions(false)}
        open={showOptions}
        loadingText={
          <div className="flex justify-center items-center">
            <Spinner size="sm" />
          </div>
        }
        loading={!othersUsers}
        options={
          othersUsers?.filter(
            (otherUser) => !selectedUsers.includes(otherUser),
          ) ?? []
        }
        getOptionLabel={() => ""}
        noOptionsText={"Aucun utilisateur trouvé"}
        filterOptions={(options, state) => {
          const filtered = createFilterOptions({
            matchFrom: "any",
            stringify: (item: UserInfoModel) =>
              `${item.lastName} ${item.firstName} ${item.email}`,
          })(options, state);
          return [...filtered];
        }}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            label="Rechercher un utilisateur"
            inputProps={{
              ...params.inputProps,
              "aria-autocomplete": "none",
            }}
            onClick={() => setShowOptions(true)}
          />
        )}
        renderOption={(props: any, option) => {
          return (
            <li
              {...props}
              key={props["data-option-index"]}
              onClick={() => {
                setSelectedUsers([...selectedUsers, option]);
                setShowOptions(false);
              }}
              style={{
                backgroundColor:
                  props["data-option-index"] % 2
                    ? "initial"
                    : colors.palette.customBackground.light,
              }}
            >
              <Stack fontFamily={fonts.secondary}>
                <span
                  style={{ fontWeight: "bold", fontFamily: fonts.secondary }}
                >
                  {option.lastName} {option.firstName}
                </span>
                <div style={{ fontFamily: fonts.secondary }}>
                  <span
                    style={{
                      marginRight: option.email ? "8px" : "0",
                      fontFamily: fonts.secondary,
                    }}
                  >
                    {option.email}
                  </span>
                </div>
              </Stack>
            </li>
          );
        }}
      />
      {selectedUsers && selectedUsers.length > 0 && (
        <>
          <Typography fontWeight={700}>
            {`Utilisateur${selectedUsers.length > 1 ? "s" : ""} à ajouter au centre :`}
          </Typography>
          {selectedUsers?.map((user) => (
            <Alert
              key={user.pid}
              color="info"
              onDismiss={() => {
                const selectedUsersWithout = selectedUsers.filter(
                  (item) => item !== user,
                );
                setSelectedUsers(selectedUsersWithout);
              }}
            >
              <Typography fontFamily={fonts.secondary} fontWeight={700}>
                {user.lastName} {user.firstName}
              </Typography>
              <Typography fontFamily={fonts.secondary}>{user.email}</Typography>
            </Alert>
          ))}
          <Stack
            sx={{
              width: "100%",
              position: "sticky",
              paddingY: 1,
              bottom: 0,
              gap: generalSpacing,
              backgroundColor: "white",
            }}
          >
            <Stack width="fit-content" flexDirection="row" gap={generalSpacing}>
              <Button
                type="submit"
                className="btn-base"
                disabled={loadingAction}
                onClick={addUsers}
              >
                {loadingAction ? <Spinner size="sm" /> : "Ajouter"}
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default OrganizationUsers;
