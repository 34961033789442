export enum AccountType {
  Admin = "Admin",
  User = "User",
  Client = "Client",
}

export interface UserModel {
  pid: string;
  accountType: AccountType;
  login: string;
  lastName: string;
  firstName: string;
  externalUserId: string;
  isSuperAdministrator: boolean;
  isCTonlineLimited: boolean;
  hasDocumentAccess: boolean;
  hasCenterSettingsAccess: boolean;
  hasVehicleFleetAccess: boolean;
  hasCustomerSettingsAccess: boolean;
  hasPriceSettingsAccess: boolean;
  hasDashboardAccess: boolean;
  hasPlanningSettingsAccess: boolean;
  hasActivityAccess: boolean;
  hasManagementAccess: boolean;
  hasExportAccess: boolean;
  hasRelaunchAccess: boolean;
  hasPvAccess: boolean;
  hasInvoiceAccess: boolean;
  defaultCenterPid: string | null;
  isSmsConfirmationEnabled: boolean;
  isSmsReminderEnabled: boolean;
  isValetChoiceEnabled: boolean;
  isCarChoiceLoanEnabled: boolean;
}

export interface UserInfoModel {
  pid: string;
  login: string;
  externalUserId: string;
  passwordHash: string;
  name: string;
  lastName: string;
  firstName: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
  faxNumber: string;
  portableNumber: string;
  email: string;
  webSiteURL: string;
  organizationId: string;
  organizationPid: string;
  hasPlanningSettingsAccess: boolean;
  encodedRights: string;
  hasCenterSettingsAccess: boolean;
  hasDocumentAccess: boolean;
  hasCustomerSettingsAccess: boolean;
  hasDashboardAccess: boolean;
  hasPriceSettingsAccess: boolean;
  hasRelaunchAccess: boolean;
  hasActivitySummaryAccess: boolean;
  hasAppointmentSummaryAccess: boolean;
  hasSalesSummaryAccess: boolean;
  hasCashinSummaryAccess: boolean;
  hasSmsSummaryAccess: boolean;
  hasRelaunchSummaryAccess: boolean;
  hasServicesSummaryAccess: boolean;
  hasBankTransferSummaryAccess: boolean;
  hasMctSummaryAccess: boolean;
  hasCustomerInvoiceSummaryAccess: boolean;
  hasExportDataAccess: boolean;
  hasInvoiceAccess: boolean;
  allowedCentersPIDs: string[];
  defaultCenterPid: string | null;
  accountType: AccountType;
}
