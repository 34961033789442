import { useSelector } from "react-redux";
import { Sidebar, Tooltip } from "flowbite-react";

import { RootState } from "../../../store/store";
import useWindowWidth, {
  getBreakpointValue,
  tailwindBreakpoints,
} from "../../../hooks/layout/useWindowWidth";
import { CONFIG_CTO } from "../../../data/config.CTO";
import { CONFIG_API } from "../../../data/config.API";

import NavList, { NavItemModel } from "./NavList";
import CustomIcon from "../CustomIcon";

interface Props {
  showMenuDrawer: { isOpen: boolean; isFullyOpen: boolean };
  toggleMenuDrawer: () => void;
}

const NavMenu = ({ showMenuDrawer, toggleMenuDrawer }: Props) => {
  const isDesktop = useWindowWidth(getBreakpointValue(tailwindBreakpoints.lg));

  const currentUser = useSelector((store: RootState) => store.USER.currentUser);
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  // Métier
  const professionItems: NavItemModel[] = [
    ...(!currentCenter?.isLimited
      ? [
          {
            label: CONFIG_CTO.CALENDAR,
            icon: "calendarMonthOutline",
            navigateTo: `/${CONFIG_CTO.CALENDAR_PATH}`,
          },
        ]
      : []),
    ...(currentUser?.accountType !== CONFIG_API.CLIENT &&
    currentCenter?.isRelaunchEnabled &&
    currentUser?.hasRelaunchAccess
      ? [
          {
            label: CONFIG_CTO.MARKETING,
            icon: "starOutline",
            submenuItems: [
              {
                label: CONFIG_CTO.REMINDER,
                navigateTo: `/${CONFIG_CTO.REMINDER_PATH}`,
              },
              {
                label: `${CONFIG_CTO.IMPORT} ${CONFIG_CTO.REMINDER}`,
                navigateTo: `/${CONFIG_CTO.IMPORT_PATH}_${CONFIG_CTO.REMINDER_PATH}`,
              },
            ],
          },
        ]
      : []),
    ...(!currentCenter?.isLimited &&
    currentUser?.accountType !== CONFIG_API.CLIENT
      ? [
          {
            label: CONFIG_CTO.QUALITY,
            icon: "badgeCheckOutline",
            externalLink: `https://qualite.ctonline.fr/`,
          },
        ]
      : []),
  ];

  // Pilotage
  const superviseItems: NavItemModel[] = !currentCenter?.isLimited
    ? [
        ...(currentUser?.hasDashboardAccess
          ? [
              {
                label: CONFIG_CTO.DASHBOARDS,
                icon: "gridOutline",
                navigateTo: `/${CONFIG_CTO.DASHBOARDS_PATH}`,
              },
            ]
          : []),
        ...(currentUser?.hasManagementAccess
          ? [
              {
                label: CONFIG_CTO.MANAGEMENT,
                icon: "listOutline",
                navigateTo: `/${CONFIG_CTO.MANAGEMENT_PATH}`,
              },
            ]
          : []),
        ...(currentUser?.hasActivityAccess
          ? [
              {
                label: CONFIG_CTO.ACTIVITY,
                icon: "chartMixed",
                navigateTo: `/${CONFIG_CTO.ACTIVITY_PATH}`,
              },
            ]
          : []),
      ]
    : [];

  // Données
  const dataItems: NavItemModel[] = [
    ...(!currentCenter?.isLimited && currentUser?.hasVehicleFleetAccess
      ? [
          {
            label: CONFIG_CTO.FLEET,
            icon: "carsOutline",
            submenuItems: [
              {
                label: CONFIG_CTO.VEHICLES,
                navigateTo: `/${CONFIG_CTO.FLEET_PATH}`,
              },
              {
                label: `${CONFIG_CTO.HISTORY}`,
                navigateTo: `/${CONFIG_CTO.FLEET_PATH}_${CONFIG_CTO.HISTORY_PATH}`,
              },
            ],
          },
        ]
      : []),
    ...(!currentCenter?.isLimited && currentUser?.hasCustomerSettingsAccess
      ? [
          {
            label: CONFIG_CTO.CUSTOMERS,
            icon: "usersOutline",
            navigateTo: `/${CONFIG_CTO.CUSTOMERS_PATH}`,
          },
        ]
      : []),
    ...(currentUser?.hasDocumentAccess
      ? [
          {
            label: CONFIG_CTO.DOCUMENTS,
            icon: "fileCopyOutline",
            submenuItems: [
              {
                label: `Factures ${currentUser?.accountType !== CONFIG_API.CLIENT ? "Clients" : ""}`,
                navigateTo: `/${CONFIG_CTO.INVOICES_PATH}`,
              },
              ...(currentCenter?.hasOnlinePaymentMCT &&
              currentUser?.accountType !== CONFIG_API.CLIENT
                ? [
                    {
                      label: "RDV payés en ligne",
                      navigateTo: `/${CONFIG_CTO.ONLINE_PAYMENTS_PATH}`,
                    },
                  ]
                : []),
              ...(currentUser?.accountType !== CONFIG_API.CLIENT ||
              (currentUser.accountType === CONFIG_API.CLIENT &&
                currentUser.hasPvAccess)
                ? [
                    {
                      label: "Procès verbaux",
                      navigateTo: `/${CONFIG_CTO.REPORT_PATH}`,
                    },
                  ]
                : []),
              ...(currentUser?.accountType !== CONFIG_API.CLIENT
                ? [
                    {
                      label: "Certificats",
                      navigateTo: `/${CONFIG_CTO.CERTIFICATES_PATH}`,
                    },
                    {
                      label: "Autres",
                      navigateTo: `/${CONFIG_CTO.OTHERS_DOCUMENTS_PATH}`,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(!currentCenter?.isLimited && currentUser?.hasExportAccess
      ? [
          {
            label: CONFIG_CTO.EXPORT,
            icon: "fileExportOutline",
            navigateTo: `/${CONFIG_CTO.EXPORT_PATH}`,
          },
        ]
      : []),
  ];

  // Paramètres
  const parametersItems: NavItemModel[] =
    !currentCenter?.isLimited && currentUser?.accountType !== CONFIG_API.CLIENT
      ? [
          ...(currentUser?.accountType === CONFIG_API.ADMIN
            ? [
                {
                  label: CONFIG_CTO.CENTER,
                  icon: "houseOutline",
                  submenuItems: [
                    {
                      label: CONFIG_CTO.CONTACT_DETAILS,
                      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CONTACT_DETAILS_PATH}`,
                    },
                    {
                      label: CONFIG_CTO.TERMS,
                      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.TERMS_PATH}`,
                    },
                    {
                      label: CONFIG_CTO.PAYMENTS_MODE,
                      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.PAYMENTS_PATH}`,
                    },
                    {
                      label: CONFIG_CTO.BANKS,
                      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.BANKS_PATH}`,
                    },
                    {
                      label: CONFIG_CTO.CGV,
                      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CGV_PATH}`,
                    },
                  ],
                },
              ]
            : []),
          ...(currentUser?.hasCenterSettingsAccess ||
          currentUser?.accountType === CONFIG_API.ADMIN
            ? [
                {
                  label: CONFIG_CTO.CALENDAR,
                  icon: "calendarEditOutline",
                  submenuItems: [
                    ...(currentUser?.accountType === CONFIG_API.ADMIN
                      ? [
                          {
                            label: CONFIG_CTO.APPOINTMENTS,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.APPOINTMENTS_PATH}`,
                          },
                        ]
                      : []),
                    {
                      label: CONFIG_CTO.SCHEDULES,
                      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.SCHEDULES_PATH}`,
                    },
                    {
                      label: CONFIG_CTO.CLOSINGS,
                      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CLOSINGS_PATH}`,
                    },
                    {
                      label: CONFIG_CTO.LINES,
                      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.LINES_PATH}`,
                    },
                  ],
                },
              ]
            : []),
          ...(currentUser?.hasCenterSettingsAccess ||
          currentUser?.accountType === CONFIG_API.ADMIN
            ? [
                {
                  label: CONFIG_CTO.OPERATION,
                  icon: "garageOutline",
                  submenuItems: [
                    ...(currentUser?.accountType === CONFIG_API.ADMIN
                      ? [
                          {
                            label: CONFIG_CTO.MANAGEMENT,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.OPERATION_PATH}`,
                          },
                        ]
                      : []),
                    ...(currentUser?.hasCenterSettingsAccess
                      ? [
                          {
                            label: `${CONFIG_CTO.CENTER}`,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CENTER_PATH}`,
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(currentUser?.hasCenterSettingsAccess ||
          currentUser?.accountType === CONFIG_API.ADMIN
            ? [
                {
                  label: CONFIG_CTO.MARKETING,
                  icon: "starHalfStrokeOutline",
                  submenuItems: [
                    ...(currentUser?.accountType === CONFIG_API.ADMIN
                      ? [
                          {
                            label: CONFIG_CTO.PUBLIC_PORTAL,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.PUBLIC_PORTAL_PATH}`,
                          },
                          {
                            label: CONFIG_CTO.OPTIONS,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.OPTIONS_PATH}`,
                          },
                        ]
                      : []),
                    ...(currentUser?.hasCenterSettingsAccess ||
                    currentUser?.accountType === CONFIG_API.ADMIN
                      ? [
                          {
                            label: CONFIG_CTO.DISCOUNTS,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.DISCOUNTS_PATH}`,
                          },
                          {
                            label: CONFIG_CTO.SMS,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.SMS_PATH}`,
                          },
                          {
                            label: CONFIG_CTO.REMINDER,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.REMINDER_PATH}`,
                          },
                          {
                            label: `${CONFIG_CTO.REMINDER} 4 ans`,
                            navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.REMINDER_PATH}_${CONFIG_CTO.YEARS_PATH}`,
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
        ]
      : [];

  // Others
  const othersItems: NavItemModel[] = [
    {
      label: CONFIG_CTO.HELP,
      icon: "lifeBuoy",
      externalLink: "https://protechnologies.zendesk.com/",
    },
    {
      label: CONFIG_CTO.SHOP,
      icon: "bag",
      externalLink: "https://protechnologies.shop/",
    },
  ];

  return (
    <Sidebar
      theme={{
        root: {
          base: `max-lg:w-full`,
          inner: `z-400 h-screen relative ${showMenuDrawer.isFullyOpen ? "px-3" : "w-14"} pt-24 pb-4 flex-col justify-center align-middle rounded`,
        },
        collapse: {
          button:
            "w-full flex items-center justify-center rounded-lg p-2 font-normal text-gray-800 text-base font-medium font-['Inter'] leading-normal cursor-pointer hover:bg-gray-100",
        },
        item: {
          base: "flex items-center justify-center rounded-lg p-2 font-normal text-gray-800 text-base font-medium font-['Inter'] leading-normal cursor-pointer hover:bg-gray-100",
        },
      }}
    >
      <Sidebar.Items className="pt-2 px-2">
        <Sidebar.ItemGroup
          className={`pb-16 flex-col justify-center align-middle w-full`}
        >
          {professionItems.length > 0 && (
            <NavList
              showMenuDrawer={showMenuDrawer}
              toggleMenuDrawer={toggleMenuDrawer}
              title={CONFIG_CTO.PROFESSION_MENU}
              items={professionItems}
            />
          )}
          {superviseItems.length > 0 && (
            <NavList
              showMenuDrawer={showMenuDrawer}
              toggleMenuDrawer={toggleMenuDrawer}
              title={CONFIG_CTO.SUPERVISE_MENU}
              items={superviseItems}
            />
          )}
          {dataItems.length > 0 && (
            <NavList
              showMenuDrawer={showMenuDrawer}
              toggleMenuDrawer={toggleMenuDrawer}
              title={CONFIG_CTO.DATA_MENU}
              items={dataItems}
            />
          )}
          {parametersItems.length > 0 && showMenuDrawer.isFullyOpen && (
            <NavList
              showMenuDrawer={showMenuDrawer}
              toggleMenuDrawer={toggleMenuDrawer}
              title={CONFIG_CTO.PARAMETERS_MENU}
              items={parametersItems}
            />
          )}
          {othersItems.length > 0 && !isDesktop && (
            <NavList
              showMenuDrawer={showMenuDrawer}
              toggleMenuDrawer={toggleMenuDrawer}
              items={othersItems}
            />
          )}
        </Sidebar.ItemGroup>
      </Sidebar.Items>
      {currentUser?.accountType !== CONFIG_API.CLIENT && isDesktop && (
        <Sidebar.Items>
          <Sidebar.ItemGroup
            theme={{
              base: `fixed flex gap-4
                        ${
                          showMenuDrawer.isFullyOpen
                            ? "pt-1 pb-8 w-56 flex-row justify-center align-middle bottom-0 bg-white"
                            : "w-18 flex-col justify-center align-middle bottom-10 left-4"
                        }`,
            }}
          >
            {othersItems.map((item) => (
              <div key={item.label} className="cursor-pointer">
                <Tooltip
                  className="text-center text-white text-sm font-medium font-['Inter'] leading-tight"
                  content={item.label}
                >
                  <CustomIcon
                    name={item.icon ?? ""}
                    color="#6b7280"
                    onClick={() => {
                      window.open(item.externalLink, "_blank");
                    }}
                  />
                </Tooltip>
              </div>
            ))}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      )}
    </Sidebar>
  );
};

export default NavMenu;
