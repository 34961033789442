import { Skeleton, Stack } from "@mui/material";

const LoadingAppointmentForm = () => {
  return (
    <Stack sx={{ width: "100%", padding: "1rem 1.5rem", gap: 0 }}>
      <Skeleton
        variant="text"
        width="8rem"
        height="3rem"
        sx={{ marginTop: "0.2rem" }}
      />
      <Stack
        flexDirection={"row"}
        gap={2}
        sx={{ minHeight: "4rem !important", maxHeight: "4rem !important" }}
      >
        <Skeleton id="inspectionTypeField" />
        <Skeleton width="15rem" />
      </Stack>
      <Stack
        flexDirection={"row"}
        gap={2}
        sx={{ minHeight: "4rem !important", maxHeight: "4rem !important" }}
      >
        <Skeleton width="100%" />
      </Stack>
      <Skeleton variant="text" width="8rem" height="3rem" />
      <Stack
        flexDirection={"row"}
        gap={2}
        sx={{ minHeight: "4rem !important", maxHeight: "4rem !important" }}
      >
        <Skeleton id="lastNameCustomerField" />
        <Skeleton id="firstNameCustomerField" />
      </Stack>
      <Stack
        flexDirection={"row"}
        gap={2}
        sx={{ minHeight: "4rem !important", maxHeight: "4rem !important" }}
      >
        <Skeleton id="mailCustomerField" />
        <Skeleton id="phoneNumberCustomerField" width="10rem" />
      </Stack>
      <Stack
        flexDirection={"row"}
        gap={2}
        sx={{ minHeight: "4rem !important", maxHeight: "4rem !important" }}
      >
        <Skeleton id="vehicle" />
        <Skeleton id="registrationNumberField" />
      </Stack>
      <Stack
        flexDirection={"row"}
        gap={2}
        sx={{ minHeight: "4rem !important", maxHeight: "4rem !important" }}
      >
        <Skeleton width="4rem" />
        <Skeleton width="12rem" />
        <Skeleton width="12rem" />
      </Stack>
      <Stack gap={2} sx={{ marginTop: "-7rem" }}>
        <Skeleton
          sx={{ borderRadius: "10px", width: "100%", height: "36rem" }}
        />
        <Skeleton width="7rem" height="4rem" sx={{ marginTop: "-7rem" }} />
      </Stack>
    </Stack>
  );
};

export default LoadingAppointmentForm;
