import { PublicClientApplication } from "@azure/msal-browser";

const scopes = [
  process.env.REACT_APP_SCOPE_URL ?? "",
  "openid",
  "offline_access",
];

const PCA = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    knownAuthorities: [process.env.REACT_APP_KNOW_AUTHORITIES ?? ""], // To authorize authority name.
    authority: process.env.REACT_APP_AZURE_BASE_URL,
    redirectUri: `/`,
  },
  cache: {
    cacheLocation: "localStorage", // Register in localStorage to keep user authenticated after closing browser.
  },
});

export { PCA, scopes };
