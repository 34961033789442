/*
 * AuthenticationPage component acts as a "gatekeeper" for the entire application, ensuring that
 * only authenticated and properly authorized users can access the app.
 *
 * It works as a wrapper around the app and performs multiple key checks:
 *
 *  **MSAL Authentication**:
 *    - First, it verifies if the user is authenticated via MSAL (Microsoft Authentication Library).
 *    - If the user is not authenticated, it triggers a login redirect to Azure.
 *    - If the user is authenticated, it proceeds with fetching data related to the user's organization(s), profile(s), and center(s).
 *
 *  **Organization, Profile, and Center Retrieval**:
 *    - After the user is authenticated, the component fetches the user's organization(s), user profile(s), and assigned center(s) from the API.
 *    - Depending on the user's role (e.g., Super Admin or regular user), it displays a modal for selecting an organization or profile if the user  is associated with multiple options.
 *    - Once a `currentOrganization`, `currentUser`, and `currentCenter` are set in the Redux store, the app becomes accessible.
 *
 *  **Loading and Error Handling**:
 *    - While the app is retrieving the necessary data (organization, profile, center), a loading screen with a spinner is displayed to the user.
 *    - If an error occurs at any point during data fetching, an error screen is shown, allowing the user to manually log out.
 *
 *  **Redirection System**:
 *    - The component also manages redirections based on if an Early Access (EA) version is currently available :
 *      - If the selected organization has Early Access rights, the user is redirected to the Early Access environment.
 *      - If the organization does not have Early Access rights, the user is redirected to the standard environment.
 *
 * By ensuring these verifications and conditions are met, this component controls access to the application and only renders the main app once all necessary user data is fetched and validated.
 */
import { useEffect, useState } from "react";
import { Location, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { Spinner } from "flowbite-react";
import { useMsal } from "@azure/msal-react";

import { api } from "../../utils/api";
import { RootState, AppDispatch, persistor } from "../../store/store";
import { APIResponseModel } from "../../models/ApiResponseModel";
import {
  fetchOrganizationData,
  fetchOrganizations,
  setCurrentOrganization,
  setError,
} from "../../store/organization/organization-slice";
import { fetchUserData } from "../../store/user/user-slice";
import { CONFIG_API } from "../../data/config.API";
import { fetchUserCenters } from "../../store/center/center-slice";
import { generalSpacing } from "../../utils/customTheme/customTheme";
import ErrorUnauthorized from "../../assets/images/icons/unauthorized.png";
import CTonlineLogo from "../../assets/images/ctonline-small-logo.png";

import CustomModal from "./CustomModal";
import LogoutButton from "../common/LogoutButton";
import isSuperAdministrator from "../../utils/authorization/isSuperAdministrator";
import OrganizationForm from "./OrganizationForm";
import OrganizationSelector from "./OrganizationSelector";
import ListSelector from "./ListSelector";
import ErrorDisplay from "./ErrorDisplay";

type Props = {
  children: React.ReactNode;
};

const AuthenticationPage = ({ children }: Props) => {
  // MSAL hooks for authentication state
  const { inProgress, accounts, instance } = useMsal();

  // Redux & React hooks
  const dispatch: AppDispatch = useDispatch();
  const location: Location = useLocation();

  // Selectors for accessing Redux state
  const organizationState = useSelector(
    (state: RootState) => state.ORGANIZATION,
  );
  const userState = useSelector((state: RootState) => state.USER);
  const centerState = useSelector((state: RootState) => state.CENTER);

  // State to track if we are already redirecting to prevent loops
  const [isRedirecting, setIsRedirecting] = useState(false);

  // State to handle modals
  const [showOrganizationSelectorModal, setShowOrganizationSelectorModal] =
    useState(false);
  const [showAdminOrganizationModal, setShowAdminOrganizationModal] =
    useState(false);
  const [showProfileSelectorModal, setShowProfileSelectorModal] =
    useState(false);
  const [isLogoutError, setIsLogoutError] = useState(false);

  // Function tu manually trigger logout redirect with full stored data cleaning
  const handleLogoutRedirect = async () => {
    try {
      await localStorage.clear();
      await persistor.purge();
      await instance.logout({
        authority: process.env.REACT_APP_AZURE_BASE_URL,
        postLogoutRedirectUri: `/`,
      });
    } catch (error) {
      if (!isLogoutError) {
        // Display an error screen to stop the application and allow user to logout manually
        dispatch(setError("Une erreur est survenue lors de la déconnexion."));
        setIsLogoutError(true);
      }
    }
  };

  // Function to manually trigger login redirect if user is not logged in
  const handleLoginRedirect = async () => {
    try {
      // Avoid multiple redirects
      if (!isRedirecting) {
        setIsRedirecting(true);
        await instance.loginRedirect(); // Manually trigger the Azure login
      }
    } catch (error) {
      // Display an error screen to stop the application and allow user to logout manually
      dispatch(
        setError("Une erreur est survenue lors de la tentative de connexion."),
      );
    }
  };

  // Function to check if user is SuperAdministrator and open the corresponding modal
  const handleSuperAdminCheck = async () => {
    try {
      const externalUserId = accounts[0]?.idTokenClaims?.sub;
      if (!externalUserId) {
        throw new Error();
      }
      const isSuperAdmin = await isSuperAdministrator(externalUserId);
      if (isSuperAdmin) {
        setShowAdminOrganizationModal(true); // Show modal to enter organization manually
      } else {
        await dispatch(fetchOrganizations(externalUserId)); // Fetch organizations for the user
      }
    } catch (error) {
      // Display an error screen to stop the application and allow user to logout manually
      dispatch(
        setError(
          "Une erreur est survenue lors de la vérification du statut de l'utilisateur.",
        ),
      );
    }
  };

  // Sends a HEAD request to check if an Early Access version is available.
  // Returns true if Early Access is available (status 200), false otherwise (status 404 or error).
  const checkIfEarlyAccessAvailable = async () => {
    try {
      // Attempt to make a HEAD request to check if Early Access is available
      const response = await api.head(
        `${CONFIG_API.CTONLINE_ADMINISTRATION}/SoftwareVersion/IsEarlyAccessAvailable`,
      );

      // If the request returns status 200, Early Access is available
      if (response.status === 200) {
        return true; // Early Access is available
      }

      // If the request returns 404, no Early Access is available
      if (response.status === 404) {
        return false; // Early Access is not available
      }

      // Handle other status codes
      return false; // Default to false if an unexpected status is returned
    } catch (error) {
      return false; // Consider no Early Access available if there is an error
    }
  };

  // Sends a GET request to check if the organization is part of the Early Access selection.
  // Returns true if the organization is a tester (success: true and item: true), false otherwise.
  const checkIsOrganizationEarlyAccess = async (
    organizationPid: string,
  ): Promise<boolean> => {
    try {
      // Make an API GET request to check if the organization is an Early Access tester
      const response = await api.get<APIResponseModel<boolean>>(
        `${CONFIG_API.CTONLINE_ADMINISTRATION}/Organization/${organizationPid}/HasEarlyAccess`,
      );

      // If the API response is successful and we have a valid item, return the item (boolean)
      if (response.data.success) {
        return response.data.item;
      }

      return false; // Default to false if the API returns an unsuccessful response
    } catch (error) {
      return false; // Consider no Early Access if there is an error
    }
  };

  // Handle redirection to login if the user is not logged in
  useEffect(() => {
    // Avoid redirecting if MSAL is in the process of logging in or out
    if (inProgress !== "none" || isRedirecting) {
      return;
    }
    if (accounts.length === 0) {
      // Trigger the login redirect if there are no accounts
      handleLoginRedirect();
    }
  }, [accounts, inProgress, isRedirecting]);

  // Check the user and organization after URL redirection
  useEffect(() => {
    // Get URL parameters for userPid and organizationPid
    const params = new URLSearchParams(window.location.search);
    const redirectedUserPid = params.get("userPid");
    const redirectedOrganizationPid = params.get("organizationPid");

    // Check if the user is logged in via MSAL
    if (instance && accounts.length > 0 && redirectedUserPid) {
      const currentExternalUserId = accounts[0]?.idTokenClaims?.sub;
      // Check if the user in the URL matches the currently logged-in user
      if (redirectedUserPid !== currentExternalUserId) {
        // Ensure MSAL instance is initialized before calling logoutRedirect
        if (instance.getAllAccounts().length > 0) {
          // Force logout if the users do not match
          handleLogoutRedirect();
        }
      } else if (redirectedOrganizationPid) {
        // If an organizationPid is provided in the URL, update the current organization in Redux
        dispatch(fetchOrganizationData(redirectedOrganizationPid));
      }
    }
  }, [accounts, instance, dispatch]);

  // Handle redirection and authentication state for Super Admins, only if currentOrganization is not defined yet
  useEffect(() => {
    if (
      accounts.length > 0 &&
      organizationState.loading &&
      accounts[0].idTokenClaims?.sub !== undefined
    ) {
      handleSuperAdminCheck(); // Check if the user is a SuperAdministrator and fetch organizations
    }
  }, [accounts]);

  // Manage organization selection and reset logic:
  // - Open the organization selection modal if the user has multiple organizations.
  // - Automatically select the organization if there is only one.
  // - Open the admin organization input modal if the user is a Super Administrator.
  // Also handles the reset case when the current organization is cleared.
  useEffect(() => {
    if (!organizationState.currentOrganization) {
      if (
        organizationState.userOrganizations &&
        organizationState.userOrganizations.length > 1
      ) {
        setShowOrganizationSelectorModal(true); // Show modal to select organization
      } else if (
        organizationState.userOrganizations &&
        organizationState.userOrganizations.length === 1
      ) {
        // Automatically select if only one organization is available
        dispatch(
          setCurrentOrganization(organizationState.userOrganizations[0]),
        );
      } else if (userState.currentUser?.isSuperAdministrator) {
        // Show modal to enter organization manually in case of organization reset
        setShowAdminOrganizationModal(true);
      }
    }
  }, [
    organizationState.userOrganizations,
    organizationState.currentOrganization,
  ]);

  // Checks if Early Access is available and if the selected organization has Early Access rights.
  // Depending on the current URL (standard or Early Access), redirects the user accordingly:
  // - If Early Access is available and the organization isn't in the Early Access selection, redirects to the Early Access URL.
  // - If Early Access is not available or the organization isn't in the Early Access selection, redirects to the standard URL.
  // Once redirection logic is complete, fetches user data based on the current organization.
  useEffect(() => {
    // Check if Early Access is available and redirect if necessary
    const checkEarlyAccessAndRedirect = async () => {
      // Get URL parameters to check if redirection has already happened
      const params = new URLSearchParams(window.location.search);
      const isRedirected = params.get("redirected");

      if (organizationState.currentOrganization && accounts.length > 0) {
        // (Ignore the redirection logic if user is trying to logout by access to "/logout" path to logout the right version or if is already redirected)
        if (location.pathname !== "/logout" && !isRedirected) {
          const currentUrl = window.location.origin;
          const standardUrl = process.env.REACT_APP_WEB_BASE_URL;
          const earlyAccessUrl = process.env.REACT_APP_WEB_EARLYACCESS_BASE_URL;

          const isEarlyAccessAvailable = await checkIfEarlyAccessAvailable();
          const hasEarlyAccess = await checkIsOrganizationEarlyAccess(
            organizationState.currentOrganization.pid,
          );

          // If the organization has access to Early Access and user is on the standard URL
          if (
            isEarlyAccessAvailable &&
            hasEarlyAccess &&
            currentUrl === standardUrl &&
            !currentUrl.includes("localhost")
          ) {
            // Redirect to Early Access URL
            window.location.href = `${earlyAccessUrl}${location.pathname}?userPid=${accounts[0].idTokenClaims?.sub}&organizationPid=${organizationState.currentOrganization.pid}&redirected=true`;
            return; // Block further logic if redirected
          }

          // If the organization does not have Early Access and user is on the Early Access URL
          if (
            (!isEarlyAccessAvailable || !hasEarlyAccess) &&
            currentUrl === earlyAccessUrl &&
            !currentUrl.includes("localhost")
          ) {
            const currentOrganizationPid =
              organizationState.currentOrganization.pid;
            // Redirect to standard URL
            window.location.href = `${standardUrl}${location.pathname}?userPid=${accounts[0].idTokenClaims?.sub}&organizationPid=${currentOrganizationPid}&redirected=true`;
            return; // Block further logic if redirected
          }
        }

        // If no redirection needed, continue with the next steps
        // Fetch user data based on the selected organization and Azure externalUserId
        dispatch(
          fetchUserData(
            organizationState.currentOrganization.pid,
            accounts[0]?.idTokenClaims?.sub,
          ),
        );
      }
    };

    checkEarlyAccessAndRedirect(); // Trigger the redirection check and user data fetch
  }, [organizationState.currentOrganization, accounts]);

  // - Open the profile selection modal if the user has multiple profiles to choose from.
  // This logic also handles the reset case when the current user profile is cleared.
  useEffect(() => {
    if (
      !userState.currentUser &&
      userState.usersList &&
      userState.usersList.length > 1
    ) {
      setShowProfileSelectorModal(true); // Show modal to select a profile
    }
  }, [userState.usersList, userState.currentUser]);
  // Fetch centers after user data is loaded
  useEffect(() => {
    if (userState.currentUser && organizationState.currentOrganization) {
      dispatch(
        fetchUserCenters(
          organizationState.currentOrganization.pid,
          userState.currentUser.accountType,
          userState.currentUser.externalUserId,
          userState.currentUser.pid,
        ),
      );
    }
  }, [userState.currentUser, organizationState.currentOrganization]);

  // If a modal needs to be opened (SuperAdministrator, organization selection or profile selection), show modal first
  const isModalOpen =
    showOrganizationSelectorModal ||
    showAdminOrganizationModal ||
    showProfileSelectorModal;
  if (isModalOpen) {
    return (
      <>
        {/* Modal for SuperAdministrator to input organization */}
        <CustomModal
          open={showAdminOrganizationModal}
          height={"auto"}
          padding={2}
        >
          <OrganizationForm setShowModal={setShowAdminOrganizationModal} />
        </CustomModal>

        {/* Modal for selecting organization if user has multiple organizations */}
        <CustomModal
          open={showOrganizationSelectorModal}
          width={{ xs: "fit-content", sm: "fit-content" }}
          height={"auto"}
          padding={4}
        >
          <OrganizationSelector
            organizationsList={organizationState.userOrganizations ?? []}
            setShowModal={setShowOrganizationSelectorModal}
          />
        </CustomModal>

        {/* Modal for selecting profile if user has multiple profiles */}
        <CustomModal
          open={showProfileSelectorModal}
          height={"auto"}
          padding={2}
          gap={4}
        >
          <ListSelector
            variant="profileSelect"
            arrayList={userState.usersList ?? []}
            label="Choisissez un profil client"
            setShowModal={setShowProfileSelectorModal}
          />
          <LogoutButton />
        </CustomModal>
      </>
    );
  }

  // Check if there are errors or empty results after loading is completed
  const hasError =
    organizationState.error || userState.error || centerState.error;
  const isEmptyResults =
    !organizationState.currentOrganization ||
    !userState.currentUser ||
    !centerState.currentCenter;

  // If still loading data or authentication is in progress, but no modal is needed, show loading screen
  const isDataLoading =
    organizationState.loading || userState.loading || centerState.loading;

  // If there are no errors and data is still loading, show the loading screen
  if (inProgress !== "none" || (isDataLoading && !hasError)) {
    return (
      <Stack height={"100vh"}>
        <Stack m="auto" gap={2} alignItems={"center"}>
          <Spinner />
          <Typography>Chargement des données</Typography>
          <LogoutButton />{" "}
          {/* Add a logout button to allow users to disconnect */}
        </Stack>
      </Stack>
    );
  }

  // If an error occurs or results are empty, and loading is complete, show the error screen
  if (
    hasError ||
    (isEmptyResults &&
      !userState.currentUser?.isSuperAdministrator &&
      !isModalOpen)
  ) {
    return (
      <Stack width={"50%"} height={"100vh"} alignItems={"center"} m="auto">
        <Stack m="auto" gap={5}>
          <img
            src={CTonlineLogo}
            width={175}
            alt="Logo CTonline"
            style={{ margin: "auto" }}
          />
          <ErrorDisplay
            displayMessage={
              isLogoutError
                ? "Une erreur est survenue. Veuillez vous reconnecter."
                : "Votre compte n'a pas accès à CTonline"
            }
            showLogoutButton
          />
        </Stack>
      </Stack>
    );
  }

  // If all data is loaded and no selection modal is needed, display the main content or unauthorized message if the organization is limited and the user isn't admin type)
  return (
    <>
      {organizationState.currentOrganization?.isCTonlineLimited &&
      userState.currentUser?.accountType !== CONFIG_API.ADMIN ? (
        <CustomModal open={true} height={"auto"} padding={4}>
          <Stack gap={generalSpacing}>
            <img
              src={ErrorUnauthorized}
              style={{ margin: "auto" }}
              width={80}
              alt={"Accès non autorisé"}
            />
            <Typography>
              {"Vous n'êtes pas autorisé à accéder à CTonline."}
              <br />
              Veuillez vous connecter avec un compte administrateur.
            </Typography>
            <LogoutButton />
          </Stack>
        </CustomModal>
      ) : (
        children
      )}
    </>
  );
};

export default AuthenticationPage;
