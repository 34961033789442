import { Stack } from "@mui/material";
import { Button } from "flowbite-react";

import ErrorDisplay from "../layouts/ErrorDisplay";

interface Props {
  errorMessage: string;
  retryFunction?: () => void;
  retryMessage?: string;
  height?: string;
}

const ErrorLoadingData = ({
  errorMessage,
  retryFunction,
  retryMessage = "Réessayer",
  height = "100vh",
}: Props) => {
  return (
    <Stack height={height} margin="auto">
      <Stack margin={"auto"} gap={2}>
        <ErrorDisplay displayMessage={errorMessage} />
        {retryFunction && (
          <Button className="btn-base m-auto" onClick={() => retryFunction()}>
            {retryMessage}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default ErrorLoadingData;
