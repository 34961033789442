import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Stack, Typography } from "@mui/material";
import { Spinner } from "flowbite-react";
import { persistor } from "../store/store";

import ErrorDisplay from "../components/layouts/ErrorDisplay";
import CustomModal from "../components/layouts/CustomModal";

const Logout = () => {
  const { instance } = useMsal();

  const [error, setError] = useState<boolean>(false);

  const handleLogoutRedirect = async () => {
    try {
      await localStorage.clear();
      await persistor.purge();
      await instance.logout({
        authority: process.env.REACT_APP_AZURE_BASE_URL,
        postLogoutRedirectUri: `/`,
      });
    } catch (error) {
      // Display an error screen to stop the application and allow user to logout manually
      setError(true);
    }
  };

  useEffect(() => {
    handleLogoutRedirect();
  }, []);

  return (
    <Stack height={"100vh"}>
      <CustomModal
        open={true}
        width={{ xs: "60%", sm: "30rem" }}
        showLogo={false}
      >
        {!error ? (
          <Stack m="auto" gap={2} alignItems={"center"}>
            <Spinner />
            <Typography variant="h6">Déconnexion en cours...</Typography>
          </Stack>
        ) : (
          <Stack m="auto" gap={5}>
            <ErrorDisplay
              displayMessage={"Une erreur est survenue."}
              showLogoutButton
            />
          </Stack>
        )}
      </CustomModal>
    </Stack>
  );
};

export default Logout;
