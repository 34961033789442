import { AxiosResponse } from "axios";
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";
import { AppDispatch, RootState } from "../store";
import { CenterModel } from "../../models/CenterModel";
import { TimeRangeModel } from "../../models/CalendarModel";
import {
  ClosingModel,
  LineModel,
  ScheduleModel,
  PartnerModel,
} from "../../models/CenterSettingsModel";
import { APIResponsesModel } from "../../models/ApiResponseModel";
import { AccountType } from "../../models/UserModel";
import { fetchAllPartners } from "../calendar/partner-slice";
import { findMinMaxTimes } from "../../utils/calendar/findMinMaxTimes";
import { generateVisualTimeRanges } from "../../utils/calendar/generateVisualTimeRanges";
import { getColorForIndex } from "../../utils/layout/getColorForIndex";
import { setStoredAppointmentData } from "../calendar/appointment-slice";
import { setAlert } from "../layout/alert-slice";

export interface InitialStateModel {
  organizationCenters: CenterModel[] | undefined;
  currentCenter: CenterModel | undefined;
  centerLines: LineModel[] | undefined;
  centerSchedules: {
    minHour: string | undefined;
    maxHour: string | undefined;
    nonWorkingDays: { [key: number]: boolean } | undefined;
  };
  centerTimeRanges: TimeRangeModel[] | undefined;
  centerClosings: ClosingModel[] | undefined;
  centerPartners: PartnerModel[] | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: InitialStateModel = {
  organizationCenters: undefined,
  currentCenter: undefined,
  centerLines: undefined,
  centerSchedules: {
    minHour: undefined,
    maxHour: undefined,
    nonWorkingDays: undefined,
  },
  centerTimeRanges: undefined,
  centerClosings: undefined,
  centerPartners: undefined,
  loading: true,
  error: null,
};

// SLICE
export const centerSlice = createSlice({
  name: "centerSlice",
  initialState,
  reducers: {
    setCenters: (
      currentSlice,
      action: PayloadAction<CenterModel[] | undefined>,
    ) => {
      currentSlice.organizationCenters = action.payload;
    },
    setCurrentCenter: (currentSlice, action: PayloadAction<CenterModel>) => {
      if (currentSlice.currentCenter) {
        currentSlice.centerLines = undefined;
        currentSlice.centerSchedules = {
          minHour: undefined,
          maxHour: undefined,
          nonWorkingDays: undefined,
        };
        currentSlice.centerTimeRanges = undefined;
        currentSlice.centerPartners = undefined;
      }
      currentSlice.currentCenter = action.payload;
    },
    setCenterLines: (currentSlice, action: PayloadAction<LineModel[]>) => {
      currentSlice.centerLines = action.payload;
    },
    setCenterSchedules: (
      currentSlice,
      action: PayloadAction<{
        schedulesList: ScheduleModel[];
        nonWorkingDays: { [key: number]: boolean };
      }>,
    ) => {
      const globalMinMaxTime = findMinMaxTimes(action.payload.schedulesList);
      currentSlice.centerSchedules.minHour = globalMinMaxTime.minTime;
      currentSlice.centerSchedules.maxHour = globalMinMaxTime.maxTime;
      currentSlice.centerSchedules.nonWorkingDays =
        action.payload.nonWorkingDays;
    },
    setCenterTimeRanges: (
      currentSlice,
      action: PayloadAction<TimeRangeModel[]>,
    ) => {
      const schedulesTimeRanges =
        current(currentSlice).centerTimeRanges?.filter(
          (timerange) => timerange.type === "schedule",
        ) ?? [];
      const updatedTimeRanges = schedulesTimeRanges;
      action.payload.forEach((timerange) => {
        if (
          !currentSlice.centerTimeRanges?.some(
            (item) => item.id === timerange.id,
          ) &&
          !updatedTimeRanges?.some((item) => item.id === timerange.id)
        ) {
          updatedTimeRanges?.push(timerange);
        }
      });
      currentSlice.centerTimeRanges = updatedTimeRanges;
    },
    setCenterClosings: (
      currentSlice,
      action: PayloadAction<ClosingModel[]>,
    ) => {
      currentSlice.centerClosings = action.payload;
    },
    setCenterPartners: (
      currentSlice,
      action: PayloadAction<PartnerModel[]>,
    ) => {
      currentSlice.centerPartners = action.payload;
    },
    setLoading: (currentSlice, action: PayloadAction<boolean>) => {
      currentSlice.loading = action.payload;
    },
    setError: (currentSlice, action: PayloadAction<string | null>) => {
      currentSlice.error = action.payload;
    },
    resetCenterSlice: () => initialState,
  },
});

export const {
  setCenters,
  setCurrentCenter,
  setCenterLines,
  setCenterSchedules,
  setCenterTimeRanges,
  setCenterClosings,
  setCenterPartners,
  setLoading,
  setError,
  resetCenterSlice,
} = centerSlice.actions;

// ACTIONS
export const fetchUserCenters =
  (
    organizationPid: string,
    accountType: AccountType,
    externalUserId: string,
    userPid: string | null,
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      let response: AxiosResponse<APIResponsesModel<CenterModel>>;
      // If userPid isn't defined (SuperAdmin for example), make another Request to get user's centers
      if (userPid) {
        response = await api.get(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${accountType}/${CONFIG_API.EXTERNAL_ACCOUNT}/${externalUserId}/${CONFIG_API.INTERNAL_ACCOUNT}/${userPid}`,
        );
      } else {
        response = await api.get(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${accountType}/${CONFIG_API.EXTERNAL_ACCOUNT}/${externalUserId}`,
        );
      }
      if (response.data.items && response.data.items.length > 0) {
        const updatedCentersList: CenterModel[] = [];
        dispatch(fetchAllPartners());
        // Find all centers attribued assigned to user
        const centersSorted = response.data.items
          .filter((center) => !center.isClosed)
          .sort((a, b) => a.name.localeCompare(b.name));
        centersSorted.forEach((center, index) => {
          const updatedCenter: CenterModel = {
            ...center,
            centerColor:
              response.data.items.filter((item) => !item.isClosed).length > 1
                ? getColorForIndex(index)
                : "gray", // Set unique color to differentiate when multi-centers
          };
          updatedCentersList.push(updatedCenter);
        });
        // Sort user's centers by alphabetically order
        dispatch(setCenters(updatedCentersList));
        // Find if user has a default center informed among its center list and put it as current center, if not set current center with the first item of the list (which isn't limited or closed)
        const userDefaultCenter =
          updatedCentersList.find(
            (center) =>
              center.pid === getState().USER.currentUser?.defaultCenterPid,
          ) ??
          updatedCentersList.find(
            (center) => !center.isLimited && !center.isClosed,
          );
        dispatch(
          updateCurrentCenter(userDefaultCenter ?? updatedCentersList[0]),
        );
      } else {
        dispatch(setError("Aucun centre n'est attribué à l'utilisateur."));
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Une erreur s'est produite."));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateCurrentCenter =
  (center: CenterModel, isACenterUpdate = false) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    // Check if an event is cutten and cancel the cut before changing center
    const storedAppointmentState = getState().APPOINTMENT.storedAppointmentData;
    if (storedAppointmentState.data) {
      // Set isCenterChanged to fix Bryntum paste behavior if user cancel cut action after changed center (from calendar)
      dispatch(
        setStoredAppointmentData({
          variant: storedAppointmentState.variant,
          data: storedAppointmentState.data,
          isCenterChanged: true,
        }),
      );
    }
    if (isACenterUpdate) {
      // To also update currentCenter in the organizationCenters list after updating center info
      const othersCenters = getState().CENTER.organizationCenters?.filter(
        (item) => item.pid !== center.pid,
      );
      othersCenters?.push(center);
      const updatedCenters = othersCenters;
      if (updatedCenters) {
        dispatch(
          setCenters(
            updatedCenters.sort((a, b) => a.name.localeCompare(b.name)),
          ),
        );
      }
    }
    dispatch(setCurrentCenter(center));
    dispatch(fetchCenterLines(center.organizationPid, center.pid));
  };

export const fetchCenterLines =
  (organizationPid: string, centerPid: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      await api
        .get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.LINE}/${CONFIG_API.INSPECTION_TYPE}?inspectionTypeSearchMode=TechnicalInspection`,
        )
        .then((response: AxiosResponse<APIResponsesModel<LineModel>>) => {
          if (response.data.success && response.data.items.length > 0) {
            if (
              getState().USER.currentUser?.accountType === CONFIG_API.CLIENT
            ) {
              // To display only allowed inspections for customers in the center lines
              const updatedLinesForCustomers = response.data.items.map(
                (line) => {
                  const allowedInspections = line.inspectionTypes?.filter(
                    (inspection) => inspection.isAvailableCustomer,
                  );
                  return { ...line, inspectionTypes: allowedInspections };
                },
              );
              dispatch(setCenterLines(updatedLinesForCustomers));
            } else {
              dispatch(setCenterLines(response.data.items));
            }
          } else {
            console.error(
              "Erreur lors de la récupération des prestations du centre.",
            );
          }
        });
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error("Une erreur s'est produite.");
      }
    }
  };

export const fetchCenterSchedules =
  (organizationPid: string, centerPid: string) =>
  async (dispatch: AppDispatch): Promise<ScheduleModel[] | false> => {
    try {
      const response = await api.get(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/OpeningTime`,
      );
      if (response.data.success) {
        const nonWorkingDays: { [key: number]: boolean } = {};
        // Get workings days from schedules
        const presentDays = response.data.items.map(
          (item: ScheduleModel) => item.idtJou,
        );
        for (let day = 0; day <= 6; day++) {
          if (!presentDays.includes(day)) {
            // Add missing days of all week days (to create nonWorkingDays array)
            nonWorkingDays[day] = true;
          }
        }
        dispatch(
          setCenterSchedules({
            schedulesList: response.data.items,
            nonWorkingDays: nonWorkingDays,
          }),
        );
        return response.data.items;
      } else {
        console.error(
          "Erreur lors de la récupération des plages horaires du centre.",
        );
        return false;
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error("Une erreur s'est produite.");
      }
      return false;
    }
  };

/**
 * Fetches and generates visual time ranges for the center's schedule based on the current center configuration.
 * Dispatches the generated time ranges to update the Redux store.
 * @param {ScheduleModel[]} schedules - Array of schedule objects indicating opening times.
 */
export const fetchVirtualTimeRanges =
  (
    closingTimeRanges: TimeRangeModel[],
    centerSchedules: ScheduleModel[],
    dateFrom: string,
    dateTo: string,
  ) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const centerState = getState().CENTER;
    if (
      centerState.currentCenter &&
      centerState.centerLines &&
      centerState.centerTimeRanges
    ) {
      // Get the closings from the state
      const virtualTimeRanges = generateVisualTimeRanges(
        centerState.centerLines,
        centerSchedules,
        centerState.currentCenter.step,
        centerState.currentCenter.slotLength,
        closingTimeRanges,
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(dateTo).format("YYYY-MM-DD"),
      );
      closingTimeRanges.forEach((closing) => {
        virtualTimeRanges.push(closing);
      });
      return virtualTimeRanges;
    }
  };

/**
 * Converts API closing schedule into an array of TimeRangeModel objects for calendar display.
 * Iterates through each day of the specified week, creating closing periods based on the schedule.
 * @param closing - The closing schedule details.
 * @param rangeStartDate - The start date of the range.
 * @param rangeEndDate - The end date of the range.
 * @param userAccount - Optional user account type (important to create some timerange parameters).
 * @returns {TimeRangeModel[]} - Array of closing periods in the Bryntum timerange calendar model.
 */
export const calculateClosingDates = (
  closing: ClosingModel,
  rangeStartDate: string,
  rangeEndDate: string,
  centerMaxHour?: string,
  userAccount?: AccountType,
): TimeRangeModel[] => {
  const closings: TimeRangeModel[] = [];
  // Convert starting and ending dates of closing to Date objects
  const closingStartDate = moment(closing.startingDate).startOf("day");
  const closingEndDate = moment(closing.endingDate).startOf("day");

  // Loop through each day from rangeStartDate to rangeEndDate
  for (
    let date = moment(rangeStartDate);
    date.isSameOrBefore(rangeEndDate);
    date.add(1, "days")
  ) {
    // Check if the current date is within the closing period and is a closed day
    if (
      date.isSameOrAfter(closingStartDate) &&
      date.isSameOrBefore(closingEndDate) &&
      closing.closedDays.includes(date.day())
    ) {
      // Split starting and ending hours into hours and minutes
      const startTime = closing.startingHour.split(":").map(Number);
      const endTime = closing.endingHour.split(":").map(Number);

      // Create Date objects for the start and end times on the current date
      const startDateTime = moment(date)
        .hours(startTime[0])
        .minutes(startTime[1])
        .seconds(0);
      const endDateTime = moment(date)
        .hours(endTime[0])
        .minutes(endTime[1])
        .seconds(0);

      // Check if the closing is starting at or after the end of the planning hours range
      const isClosingAfterMaxHour = moment(startDateTime).isSameOrAfter(
        moment(
          `${moment(endDateTime).format("YYYY-MM-DD")} ${centerMaxHour}`,
          "YYYY-MM-DD HH:mm:ss",
        ),
      );
      // Add a new closing period to the closings array
      closings.push({
        id: `clo${startDateTime
          .toISOString()
          .replace(/-|T|:|\..*|\+.*/g, "")
          .slice(2, 12)}${endDateTime
          .toISOString()
          .replace(/-|T|:|\..*|\+.*/g, "")
          .slice(2, 12)}${closing.lines[0]}`,
        resourceId: closing.lines[0],
        startDate: moment(startDateTime).format("YYYY-MM-DDTHH:mm"),
        endDate: isClosingAfterMaxHour
          ? moment(
              `${moment(endDateTime).format("YYYY-MM-DD")} ${centerMaxHour}`,
              "YYYY-MM-DD HH:mm:ss",
            ).toDate()
          : moment(endDateTime).format("YYYY-MM-DDTHH:mm"), // Set the last planning hour instead if isClosingAfterMaxHour is true
        comments: closing.comments,
        isCommentsVisibleForCustomer: closing.visToCust,
        isAppointmentAllowed:
          userAccount === CONFIG_API.CLIENT || closing.isPublicHoliday
            ? false
            : closing.isAppointmentAllowed,
        type: "closing",
        closingApiId: closing.id,
        cls: `closedTimeRanges ${userAccount !== CONFIG_API.CLIENT && !closing.isPublicHoliday && closing.isAppointmentAllowed ? "appointmentAllowed" : ""}`,
      });
    }
  }
  return closings;
};

export const fetchCenterClosings =
  (
    organizationPid: string,
    centerPid: string,
    dateFrom: string,
    dateTo: string,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await api.get(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/ClosingBetweenDate?startDate=${dateFrom}&endDate=${dateTo}`,
      );
      if (response.data.success) {
        dispatch(setCenterClosings(response.data.items));
        return response.data;
      } else {
        dispatch(
          setAlert({
            id: "calendar-alert",
            type: "failure",
            message: "Erreur lors de la récupération des fermetures du centre.",
          }),
        );
        return false;
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        dispatch(
          setAlert({
            id: "calendar-alert",
            type: "failure",
            message: "Erreur lors de la récupération des fermetures du centre.",
          }),
        );
      }
      return false;
    }
  };

export const fetchCenterPartners =
  (organizationPid: string, centerPid: string) =>
  async (dispatch: AppDispatch) => {
    await api
      .get(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.PARTNER}`,
      )
      .then((response: AxiosResponse<APIResponsesModel<PartnerModel>>) => {
        if (response.data.success) {
          dispatch(setCenterPartners(response.data.items));
        }
      });
  };
