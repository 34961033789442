import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { faCircleXmark } from "@fortawesome/pro-duotone-svg-icons";
import { CSSProperties, useEffect, useState } from "react";

interface ChipOption {
  label: string | JSX.Element;
  value: string | number;
}

interface Props {
  options: ChipOption[];
  value: (string | number)[];
  onChange: (selected: (string | number)[]) => void;
  isRequired?: boolean;
  isReadOnly?: boolean;
  autoSelectValues?: (string | number)[];
  direction?: "row" | "column";
}

export const iconStyle = {
  "--fa-primary-color": "transparent",
  "--fa-secondary-color": "white",
};

const SelectableChip = ({
  options,
  value,
  onChange,
  isRequired = false,
  isReadOnly = false,
  autoSelectValues = [],
  direction = "row",
}: Props) => {
  // State to track manual selections to avoid overriding them with auto-selections
  const [manualSelectionsMade, setManualSelectionsMade] = useState(false);

  useEffect(() => {
    // Only apply auto-selection if no manual selections have been made
    if (!manualSelectionsMade) {
      // Filter auto-select values that are relevant to the current options
      const relevantAutoSelectValues = autoSelectValues.filter((val) =>
        options.some((option) => option.value === val),
      );
      // New set for updated selected options
      const updatedSelectedOptions = new Set(value);
      // Apply auto-selection for relevant values if they are not already selected
      relevantAutoSelectValues.forEach((autoValue) => {
        updatedSelectedOptions.add(autoValue);
      });

      // Automatically select the only option if required and not in readOnly mode
      if (
        options.length === 1 &&
        isRequired &&
        !isReadOnly &&
        !value.includes(options[0].value)
      ) {
        updatedSelectedOptions.add(options[0].value);
      }

      // If the updated selections differ from the current value, apply the change
      if (
        updatedSelectedOptions.size !== value.length ||
        !value.every((val) => updatedSelectedOptions.has(val))
      ) {
        onChange(Array.from(updatedSelectedOptions));
      }
    }
  }, [
    options,
    isRequired,
    isReadOnly,
    autoSelectValues,
    onChange,
    value,
    manualSelectionsMade,
  ]);

  const handleToggle = (selectedValue: string | number) => {
    if (isReadOnly) return;

    setManualSelectionsMade(true); // Mark that a manual selection has been made
    const currentIndex = value.indexOf(selectedValue);
    const newSelectedOptions = [...value];

    if (currentIndex === -1) {
      newSelectedOptions.push(selectedValue);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    onChange(newSelectedOptions);
  };

  return (
    <Stack direction={direction} gap={1} flexWrap={"wrap"}>
      {options.map((option) => {
        const isSelected = value.includes(option.value);
        return (
          <Chip
            key={option.value.toString()}
            label={option.label}
            disabled={isReadOnly}
            clickable={!(options.length === 1 && isRequired)}
            onClick={() => {
              if (!(options.length === 1 && isRequired)) {
                handleToggle(option.value);
              }
            }}
            onDelete={
              !isReadOnly && !(options.length === 1 && isRequired)
                ? () => {
                    handleToggle(option.value);
                  }
                : undefined
            }
            deleteIcon={
              !(options.length === 1 && isRequired) ? (
                <FontAwesomeIcon
                  icon={isSelected ? faCircleXmark : faCirclePlus}
                  style={iconStyle as CSSProperties}
                  className={`rotate-icon ${isSelected ? "rotated" : ""}`}
                />
              ) : undefined
            }
            color={value.includes(option.value) ? "primary" : "default"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: isSelected ? "white" : "black",
              backgroundColor: isSelected ? "primary.main" : "default",
              paddingRight: "0.3rem",
              ".MuiChip-label": {
                fontSize: "14px",
                paddingRight: 1.3,
              },
              ".MuiChip-deleteIcon": {
                fontSize: "0.9rem",
                textAlign: "end",
                color: isSelected ? "white" : "black",
                position: "sticky",
              },
            }}
          />
        );
      })}
    </Stack>
  );
};

export default SelectableChip;
