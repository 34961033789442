import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Modal,
  Badge,
} from "@mui/material";

import { AppDispatch, RootState } from "../../store/store";
import useDateFormat from "../../hooks/common/useDateFormat";
import { AllNewsModel } from "../../models/NewsModel";
import { updateReadStatus } from "../../store/news/news-slice";
import fileLinesIcon from "../../assets/images/icons/file-lines-light.svg";
import fileEuroIcon from "../../assets/images/icons/file-invoice-euro-light.svg";
import circuleUpIcon from "../../assets/images/icons/circle-up-light.svg";
import foxyIcon from "../../assets/images/icons/foxy-logo.svg";

import MessageModal from "./MessageModal";

interface Props {
  element: AllNewsModel;
  unreadNewsNumber: number;
  setUnreadNewsNumber: React.Dispatch<React.SetStateAction<number>>;
}

const NewsCard = ({
  element,
  unreadNewsNumber,
  setUnreadNewsNumber,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUserState = useSelector(
    (store: RootState) => store.USER.currentUser,
  );
  const currentOrganizationState = useSelector(
    (store: RootState) => store.ORGANIZATION.currentOrganization,
  );
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const [isElementRead, setIsElementRead] = useState<boolean>(
    element.readDate ? true : false,
  );
  const convertedSendDate: string = useDateFormat(element.sendDate);
  let messageIcon = fileLinesIcon;
  let iconSize = "16rem";
  let iconTitle = "Icone";

  switch (element.type) {
    case 2:
      messageIcon = circuleUpIcon;
      iconTitle = "Mise à jour";
      break;
    case 3:
      messageIcon = fileEuroIcon;
      iconTitle = "Facture";
      break;
    case 5:
      messageIcon = foxyIcon;
      iconSize = "55rem";
      iconTitle = "Mon contrôle technique";
      break;
    default:
      messageIcon = fileLinesIcon;
      break;
  }

  const onClick = () => {
    if (!element.isMessageRead) {
      setUnreadNewsNumber(unreadNewsNumber - 1);
    }
    setIsElementRead(true);
    if (currentOrganizationState && currentUserState) {
      // NEWS CASE
      if (element.urlLink) {
        if (!element.readDate) {
          dispatch(
            updateReadStatus(
              currentOrganizationState.pid,
              currentUserState.accountType,
              currentUserState.externalUserId,
              element.id,
              "news",
            ),
          );
        }
        window.open(element.urlLink, "_blank", "noopener,noreferrer");
        // MESSAGE CASE
      } else if (element.type) {
        setShowMessageModal(true);
      }
    }
  };

  return (
    <>
      <Modal open={showMessageModal}>
        <Stack>
          <MessageModal
            message={element}
            icon={messageIcon}
            iconSize={iconSize}
            iconTitle={iconTitle}
            setShowModal={setShowMessageModal}
          />
        </Stack>
      </Modal>
      <Badge
        variant="dot"
        invisible={isElementRead}
        sx={{
          "&. MuiBadge-dot": {
            className: "bg-green-500",
          },
        }}
        color="warning"
        badgeContent={""}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card
          raised={!isElementRead}
          sx={{ width: "16.4rem", height: "object-fit", cursor: "pointer" }}
          onClick={onClick}
        >
          {element.imageLink && (
            <CardMedia sx={{ cursor: "pointer" }}>
              <img src={element.imageLink} width="100%" alt="Actualité" />
            </CardMedia>
          )}
          <CardContent>
            <Stack gap={1}>
              <Stack
                flexDirection={element.type ? "row" : "column"}
                sx={{ justifyContent: element.type === 5 ? "center" : null }}
              >
                <Typography fontSize={"0.9rem"} fontWeight={"bold"}>
                  {element.title}
                </Typography>
                {element.type && (
                  <img
                    src={messageIcon}
                    width={iconSize}
                    alt={iconTitle}
                    style={{ marginRight: "0.4rem" }}
                  />
                )}
                {element.type !== 5 ? (
                  <Typography
                    variant="subtitle2"
                    fontSize={"0.75rem"}
                    sx={element.type ? { paddingTop: "0.3rem" } : null}
                  >
                    {convertedSendDate}
                  </Typography>
                ) : null}
              </Stack>
              <Typography fontSize={"0.8rem"}>
                {element.text.length > 70 && element.type !== 5
                  ? `${element.text.substring(0, element.text.lastIndexOf(" ", 70))}...`
                  : element.text}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Badge>
    </>
  );
};

export default NewsCard;
