import React, { CSSProperties, useState } from "react";
import { TextField, Menu, Chip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faXmarkCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircleXmark } from "@fortawesome/pro-duotone-svg-icons";

import SelectableChip, { iconStyle } from "../SelectableChip";
import { colors } from "../../../utils/customTheme/customTheme";

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
  label: string;
  options: string[];
}

const ChipFilter = ({ value, onChange, label, options }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuWidth = 200;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteChip = (chipToDelete: string) => () => {
    onChange(value.filter((chip) => chip !== chipToDelete));
  };

  const handleClearAll = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation(); // To avoid menu to open after click on delete icon
    onChange([]);
  };

  const handleChipChange = (newValue: (string | number)[]) => {
    onChange(newValue.map(String)); // Convert all values to strings
    handleCloseMenu();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        label={label}
        variant="outlined"
        size="small"
        onClick={handleOpenMenu}
        value="" // Clear the value to avoid showing the input text
        InputLabelProps={{
          shrink: value.length > 0, // Move the label up only if there are selected chips
        }}
        sx={{
          "& .MuiInputLabel-formControl": {
            margin: 0,
            cursor: "pointer",
          },
          "& .MuiInputLabel-shrink": {
            backgroundColor: colors.palette.customBackground.light,
            zIndex: 1,
            top: "16px !important",
          },
          "& .MuiFormLabel-root": {
            top: "21px",
          },
        }}
        InputProps={{
          inputProps: {
            style: {
              cursor: "pointer",
            },
          },
          startAdornment: value.map((chip) => (
            <Chip
              key={chip}
              label={chip}
              onDelete={handleDeleteChip(chip)}
              style={{ margin: "2px" }}
              color="primary"
              size="small"
              deleteIcon={
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={iconStyle as CSSProperties}
                />
              }
              sx={{
                zIndex: 2,
                ".MuiChip-deleteIcon": {
                  fontSize: "1rem",
                },
              }}
            />
          )),
          endAdornment:
            value.length > 0 ? (
              <FontAwesomeIcon
                icon={faXmarkCircle}
                onClick={handleClearAll}
                style={{ cursor: "pointer", marginLeft: "8px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronDown}
                size="xs"
                style={{ cursor: "pointer" }}
              />
            ),
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        slotProps={{
          paper: {
            sx: {
              width: menuWidth,
              padding: "1rem",
            },
          },
        }}
      >
        <SelectableChip
          direction="column"
          options={options.map((option) => ({ label: option, value: option }))}
          value={value}
          onChange={handleChipChange}
        />
      </Menu>
    </div>
  );
};

export default ChipFilter;
