import { useSelector } from "react-redux";
import { useState } from "react";
import { Stack } from "@mui/material";
import { Tabs } from "flowbite-react";

import { RootState } from "../store/store";
import { CONFIG_CTO } from "../data/config.CTO";
import { CONFIG_URL } from "../data/config.URL";
import { CONFIG_API } from "../data/config.API";
import useIframeParams, {
  ParamsModel,
} from "../hooks/authorization/useIframeParams";

import ErrorDisplay from "../components/layouts/ErrorDisplay";
import Users from "../components/administration/users/Users";
import InvoicesList from "../components/administration/invoices/InvoicesList";

const Administration = () => {
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);

  const params: ParamsModel = useIframeParams();

  const [toggleButtonAlignment, setToggleButtonAlignment] = useState<string>(
    currentUser?.accountType === CONFIG_API.ADMIN ? "users" : "invoices",
  );

  const viewTabs: { label: string; value: string }[] = [
    { label: CONFIG_CTO.USERS, value: "users" },
    { label: CONFIG_CTO.CENTERS_LIST, value: "v2/GestionCentres" },
    { label: "Factures Protechnologies", value: "invoices" },
  ];

  const handleTabChange = (tabNumber: number) => {
    const newAlignment = viewTabs[tabNumber].value;
    setToggleButtonAlignment(newAlignment);
  };

  return (
    <Stack height={"100vh"}>
      {currentUser?.accountType === CONFIG_API.ADMIN && (
        <div className="m-auto min-h-8">
          <Tabs
            onActiveTabChange={(tabNumber) => handleTabChange(tabNumber)}
            variant="fullWidth"
            className="rounded-lg"
            theme={{
              base: "flex flex-row gap-2 font-['Inter'] mb-1",
              tablist: {
                variant: {
                  fullWidth:
                    "grid w-full grid-flow-col divide-x divide-gray-200 m-auto rounded-none text-sm font-medium shadow ring-0",
                },
                tabitem: {
                  base: "flex items-center justify-center rounded-t-lg px-4 text-sm font-medium first:ml-0",
                  variant: {
                    fullWidth: {
                      base: "ml-0 flex w-full first:ml-0 rounded-none first:rounded-l-lg last:rounded-r-lg",
                      active: {
                        on: "bg-primary-600 p-4 text-white py-2 px-4",
                        off: "bg-white text-gray-500 hover:bg-gray-50 py-2 px-4",
                      },
                    },
                  },
                },
              },
            }}
          >
            {viewTabs.map((view) => (
              <Tabs.Item
                key={view.value}
                active={view.value === toggleButtonAlignment}
                title={view.label}
              />
            ))}
          </Tabs>
        </div>
      )}

      {toggleButtonAlignment === "users" ? (
        <Users />
      ) : toggleButtonAlignment === "invoices" ? (
        <InvoicesList />
      ) : (
        <iframe
          id="ctframe"
          title="Navigation dans l'application web CTonline"
          sandbox="allow-same-origin allow-scripts allow-downloads allow-modals allow-popups allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-popups-to-escape-sandbox"
          src={`https://${params.organisationCode}${process.env.REACT_APP_CTONLINE_BASE_URL}/${params.organisationCode}/${toggleButtonAlignment}.aspx?Ctr=${currentCenter?.centerId}&${CONFIG_URL.ORGANIZATION_CODE}=${params.organisationCode}&${CONFIG_URL.IS_SUPERADMINISTRATOR}=${params.isProtech}&${CONFIG_URL.IS_CTONLINE_LIMITED}=${params.isLimited}&${CONFIG_URL.USERNAME}=${params.userName}&${CONFIG_URL.LOGINTYPE}=${params.loginType}&${CONFIG_URL.TIMESTAMP}=${params.connexionTimeStamp}&${CONFIG_URL.SIGNATURE}=${params.signature}`}
          className="iframe pb-28 overflow-hidden"
          width="100%"
          height="100%"
        >
          <ErrorDisplay
            variant="iframeDisplay"
            displayName={`le panneau d'administration`}
          />
        </iframe>
      )}
    </Stack>
  );
};

export default Administration;
