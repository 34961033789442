import { StringHelper } from "@bryntum/calendar";
import moment from "moment";

import { AppointmentModel } from "../../models/AppointmentModel";
import { ViewModel } from "../../models/CalendarModel";
import { CONFIG_CTO } from "../../data/config.CTO";
import FoxyBlackIcon from "../../assets/images/icons/appointment-options/foxy-black.svg";
import WebPointer from "../../assets/images/icons/appointment-options/pointer-regular.svg";
import "./event-render.css";

export const eventRender = (
  eventData: AppointmentModel,
  activeView: ViewModel,
  fitHours: boolean,
  isDragging: boolean,
  isProfesionnalCustomer: boolean,
) => {
  const eventDuration = eventData.totalDuration
    ? parseInt(eventData.totalDuration.split(":")[0]) * 60 +
      parseInt(eventData.totalDuration.split(":")[1])
    : 0;
  const fullName = `${eventData.lastName ?? ""} ${eventData.firstName ?? ""}`;
  const phoneNumber = eventDuration > 16 ? (eventData.phoneNumber ?? "") : "";
  let fullVehicle = "";

  if (eventData.vehicle) {
    fullVehicle = eventData.vehicle;
  } else if (eventData.vehicleList && eventData.vehicleList.length > 0) {
    fullVehicle =
      (eventData.vehicleList[0].brand ?? "") +
      " " +
      (eventData.vehicleList[0].model ?? "");
  }

  const prestationDiv =
    eventData.vehicleList &&
    eventData.vehicleList[0] &&
    eventData.vehicleList[0].inspectionTypeSymbol
      ? `<div class="chip" title='${eventData.vehicleList[0].inspectionTypeName}' style='background-color: white; color: black'>${eventData.vehicleList[0].inspectionTypeSymbol}</div>`
      : "";

  const vehicleType =
    eventData.vehicleList &&
    eventData.vehicleList[0] &&
    eventData.vehicleList[0].inspectionTypeActivity
      ? `<div class="chip" title='Activité' style='background-color: white; color: black'>${eventData.vehicleList[0].inspectionTypeActivity}</div>`
      : "";

  const annexPrestationDiv =
    eventData.vehicleList &&
    eventData.vehicleList[1] &&
    eventData.vehicleList[1].inspectionTypeSymbol
      ? `<div class="chip" title='${eventData.vehicleList[1].inspectionTypeName} (Véhicule associé)' style='background-color: white; color: black'>${eventData.vehicleList[1].inspectionTypeSymbol}</div>`
      : "";

  const annexVehicleType =
    eventData.vehicleList &&
    eventData.vehicleList[1] &&
    eventData.vehicleList[1].inspectionTypeActivity
      ? `<div class="chip" title='Activité (Véhicule associé)' style='background-color: white; color: black'>${eventData.vehicleList[1].inspectionTypeActivity}</div>`
      : "";

  const options = `
    ${isProfesionnalCustomer ? `<i class="fa-regular fa-user-tie" title=${CONFIG_CTO.ACCOUNT_CUSTOMER.replace(/ /g, "\u00A0")}></i>` : ""}
    ${eventData.paymentStatus === 0 ? `<i class="fa-regular fa-circle-euro" title=${CONFIG_CTO.UNPAID_APPOINTMENT.replace(/ /g, "\u00A0")}></i>` : ""}
    ${eventData.isParking ? `<i class="fa-regular fa-circle-parking" title='${CONFIG_CTO.PARKING}'></i>` : ""}
    ${eventData.isLoanCar ? `<i class="fa-regular fa-car" title='${CONFIG_CTO.LOAN_CAR}'></i>` : ""}
    ${eventData.isValet ? `<i class="fa-regular fa-key" title='${CONFIG_CTO.VALET}'></i>` : ""}
    ${eventData.isTruckTrailer ? `<i class="fa-regular fa-trailer" title='${CONFIG_CTO.TRUCK_TRAILER}'></i>` : ""}
    ${eventData.isToLoad ? `<i class="fa-regular fa-weight-hanging" title='${CONFIG_CTO.TO_LOAD}'></i>` : ""}
    ${eventData.source && eventData.source.name !== "CTonline" ? (eventData.source.name === "MCT" ? `<img src=${FoxyBlackIcon} width="20rem" alt="Icone MCT" title="Mon Contrôle Technique"></i>` : `<img src=${WebPointer} width="13rem" alt="Icone Partenaire" title=${eventData.source.name}></i>`) : ""}
    `;

  const durationClass =
    eventDuration < 16
      ? "short-duration"
      : eventDuration > 30
        ? "long-duration"
        : "medium-duration";

  const viewClass = activeView === "weekResource" ? "week-view" : "day-view";

  const fitHoursClass = fitHours ? `isFitHoursView` : "";

  if (durationClass) {
    return `
    <div class="event-container ${durationClass} ${viewClass} ${fitHoursClass}">
        ${
          eventData.isLocked
            ? `<div class='locked-event'>Réservation en cours...</div>`
            : `
            <div class="event-header">
                <div class="event-chip">
                    <span style="margin-right: 2px">${vehicleType}</span>
                     ${prestationDiv}
                </div>
                
              ${
                durationClass === "medium-duration" &&
                eventData.isCurrent &&
                !fitHours
                  ? `<div class="event-progressBar">
                        <div class="progress-container" title="En cours d'opération">
                        <div class="progress-bar"></div>
                    </div>
                  </div>`
                  : ""
              }
              ${
                durationClass !== "short-duration"
                  ? `<div class="event-options">${options}</div>`
                  : viewClass === "week-view"
                    ? `<div class="fullName"> 
                        <b>${StringHelper.xss`${fullName}`}</b>
                        </div>`
                    : `
                    <div class="event-options">${options}</div>`
              }
                ${
                  eventData.isCurrent &&
                  !fitHours &&
                  durationClass === "short-duration"
                    ? `<div class="event-progressBar">
                        <div class="progress-container" title="En cours d'opération">
                            <div class="progress-bar"></div>
                        </div>
                        <div class="bottom-space"></div>
                    </div>`
                    : ""
                }
            </div>
            
            <div class="event-body">
                <div class="fullName">
                    <b>${StringHelper.xss`${fullName}`}</b>
                </div>
                <div class="fullVehicle">
                    ${StringHelper.xss`${fullVehicle}`}
                </div>
                ${
                  durationClass !== "short-duration"
                    ? `<div class="phoneNumber">
                    ${StringHelper.xss`${phoneNumber}`}
                    </div>`
                    : ""
                }
                ${
                  eventData.vehicleList && eventData.vehicleList.length > 1
                    ? `
                    <div class="annexVehicle">
                        <div class="event-chip">
                            <span style="margin-right: 2px">
                                ${annexVehicleType}
                            </span>
                            ${annexPrestationDiv}
                        </div>
                        <span title="Immatriculation du véhicule associé">${eventData.vehicleList[1].registrationNumber}</span>
                    </div>
                `
                    : ""
                }
            </div>

            <div class="event-footer">
                ${
                  eventData.isCurrent &&
                  !fitHours &&
                  durationClass === "long-duration"
                    ? `<div class="event-progressBar">
                        <div class="progress-container" title="En cours d'opération">
                            <div class="progress-bar"></div>
                        </div>
                    </div>`
                    : ""
                }
            </div>

            ${
              isDragging
                ? `<div class="b-cal-event-footer">${moment(eventData.startDate).format("HH:mm")}</div>`
                : ""
            }`
        }
    </div>`;
  } else {
    return "";
  }
};
