import { ListGroup, Spinner, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { AppDispatch, RootState } from "../../../store/store";
import { api } from "../../../utils/api";
import { CONFIG_CTO } from "../../../data/config.CTO";
import { SearchResponseModel } from "../../../models/SearchResponseModel";
import { CONFIG_API } from "../../../data/config.API";
import { APIResponsesModel } from "../../../models/ApiResponseModel";
import { setAlert } from "../../../store/layout/alert-slice";
import { displayError } from "../../../utils/layout/displayError";

import CustomIcon from "../CustomIcon";

interface Props {
  isHidden: boolean;
}

const SearchBar = ({ isHidden }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<SearchResponseModel[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const highlightText = (text: string, query: string) => {
    if (!query) {
      return text;
    }

    if (text) {
      const index = text.toLowerCase().indexOf(query.toLowerCase());
      if (index === -1) {
        return text;
      }

      return (
        <>
          {text.substring(0, index)}
          <b>{text.substring(index, index + query.length)}</b>
          {text.substring(index + query.length)}
        </>
      );
    }
  };

  const fetchSearch = async () => {
    setSearchLoading(true);
    try {
      if (inputValue.length >= 3) {
        await api
          .post(
            `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/${CONFIG_API.SEARCH}?searchString=${inputValue}`,
          )
          .then(
            (
              response: AxiosResponse<APIResponsesModel<SearchResponseModel>>,
            ) => {
              if (response.data.success) {
                if (response.data.items.length > 0) {
                  setShowOptions(true);
                  setOptions(response.data.items);
                }
              } else {
                dispatch(
                  setAlert({
                    id: "global-alert",
                    type: "failure",
                    message: displayError(response.data.messages[0]),
                  }),
                );
              }
            },
          );
      } else {
        setOptions([]);
        setShowOptions(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "failure",
          message: "Une erreur est survenue",
        }),
      );
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setSearchLoading(false);
    e.preventDefault();
    if (inputValue && inputValue.length >= 3) {
      if (options.length > 0 && !searchLoading) {
        navigate(`/${CONFIG_CTO.SEARCH_PATH}`, { state: options });
        setShowOptions(false);
      }
    } else {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "warning",
          message: "Veuillez renseigner au moins 3 caractères.",
        }),
      );
    }
  };

  // Debounced effect for the input value
  useEffect(() => {
    // Set a timeout to delay the API call
    const delayDebounce = setTimeout(() => {
      if (inputValue.length >= 3) {
        fetchSearch();
      } else {
        setOptions([]);
        setShowOptions(false);
      }
    }, 300); // 300 ms delay

    // Clear timeout if the input value changes before delay ends
    return () => clearTimeout(delayDebounce);
  }, [inputValue]);

  useEffect(() => {
    // Reset input value when navigation to another page
    if (inputValue && location.pathname !== "/search") {
      setInputValue("");
    }
  }, [location]);

  return (
    <form
      className={`${isHidden && "hidden"} ml-auto mr-2 xl:mx-auto relative`}
      onSubmit={(e) => handleSubmit(e)}
    >
      <TextInput
        icon={() =>
          !searchLoading ? (
            <CustomIcon
              name="searchOutline"
              width={14}
              height={14}
              color="#6b7280"
            />
          ) : (
            <Spinner
              size="xs"
              color="gray"
              theme={{ color: { gray: "fill-primary-600" } }}
            />
          )
        }
        rightIcon={() =>
          inputValue.length > 0 ? (
            <CustomIcon
              name="xCircle"
              className="cursor-pointer"
              width={14}
              height={18}
              color="#9CA3AF"
              onClick={() => setInputValue("")}
            />
          ) : (
            <></>
          )
        }
        required
        value={inputValue}
        onChange={(event) => {
          if (event.target.value) {
            setInputValue(event.target.value);
          } else {
            setInputValue("");
            setShowOptions(false);
            setOptions([]);
          }
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowOptions(false);
          }, 200);
        }}
        onClick={() => {
          if (options.length > 1) {
            setShowOptions(true);
          }
        }}
        aria-autocomplete="none"
        autoComplete={"off"}
        className="w-40 xs:w-60 sm:w-96 bg-gray-50 font-['Inter']"
        placeholder="Recherche"
        theme={{
          field: {
            rightIcon: {
              base: "cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3",
            },
            input: {
              colors: {
                gray: "border-gray-300 bg-gray-50 text-gray-900 text-sm font-normal focus:border-blue-600",
              },
            },
          },
        }}
      />

      {showOptions && (
        <ListGroup className="absolute max-h-[50vh] overflow-y-auto flex-col shadow xs:-left-24 overflow-x-hidden w-60 xs:w-80 sm:w-full sm:min-w-64 sm:left-0">
          {options.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                navigate(`/${CONFIG_CTO.SEARCH_PATH}`, { state: [item] });
                setShowOptions(false);
              }}
              className={`w-full sm:min-w-80 p-2 cursor-pointer ${index % 2 ? "bg-white" : "bg-gray-200"}hover:bg-gray-100`}
            >
              <div>
                <span className="font-['Inter']">
                  {highlightText(item.vehNom ?? item.facNom, inputValue)}{" "}
                  {highlightText(item.vehPrenom ?? item.facPrenom, inputValue)}
                </span>
                <div>
                  <span className="font-['Inter']">
                    {highlightText(
                      item.vehMel ? `${item.vehMel} - ` : "",
                      inputValue,
                    )}
                  </span>
                  <span className="font-['Inter']">
                    {highlightText(
                      item.vehPor || item.vehTel || "",
                      inputValue,
                    )}
                  </span>
                </div>
                <div>
                  <span className="font-['Inter']">
                    {highlightText(item.vehImm, inputValue)}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ListGroup>
      )}
    </form>
  );
};

export default SearchBar;
