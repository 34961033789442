import React, { useState, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { Box, Menu, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { Tabs, Button } from "flowbite-react";
import moment from "moment";
import { BryntumCalendar } from "@bryntum/calendar-react";
import {
  faArrowRotateRight,
  faArrowsMaximize,
  faArrowsMinimize,
  faCalendar,
  faCalendarCirclePlus,
  faCalendarLinesPen,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faGear,
  faGripLines,
  faInfoCircle,
  faShopLock,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { AppDispatch, RootState } from "../../../store/store";
import { CONFIG_CTO } from "../../../data/config.CTO";
import { CONFIG_API } from "../../../data/config.API";
import { ViewModel } from "../../../models/CalendarModel";
import { MenuItemModel } from "../../common/BasicMenuItem";
import { setAlert } from "../../../store/layout/alert-slice";
import customTheme, {
  MUIXTheme,
  colors,
  generalRadius,
} from "../../../utils/customTheme/customTheme";

import EventIcon from "../../../assets/images/icons/event.svg";
import BasicMenu from "../../common/BasicMenu";
import useWindowWidth from "../../../hooks/layout/useWindowWidth";
import InfoIcons from "./InfoIcons";

interface Props {
  calendar: React.RefObject<BryntumCalendar>;
  displayedDate: Date | string | undefined;
  fitHours: boolean;
  storedActiveView: ViewModel | null;
  setActiveDate: React.Dispatch<React.SetStateAction<Date>>;
  setActiveView: React.Dispatch<React.SetStateAction<ViewModel>>;
  setFitHours: React.Dispatch<React.SetStateAction<boolean>>;
}

const CalendarToolbar = ({
  calendar,
  displayedDate,
  fitHours,
  storedActiveView,
  setActiveDate,
  setActiveView,
  setFitHours,
}: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const currentUser = useSelector((store: RootState) => store.USER.currentUser);
  const isAppointmentsLoading = useSelector(
    (store: RootState) => store.APPOINTMENT.loading.regularLoading,
  );

  const [toggleButtonAlignment, setToggleButtonAlignment] =
    useState<ViewModel>("weekResource");
  const [showToolbar, setShowToolbar] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isPastForClient, setIsPastForClient] = useState<boolean>(true);

  const isDesktop = useWindowWidth(customTheme.breakpoints.values.lg);

  useEffect(() => {
    // To prevent the client from navigating into the past
    if (currentUser?.accountType === CONFIG_API.CLIENT) {
      // Set the displayed date to midnight (for comparison)
      const todayMidnight = new Date().setHours(0, 0, 0, 0);
      const displayedDateMidnight = displayedDate
        ? new Date(displayedDate).setHours(0, 0, 0, 0)
        : null;
      // If the user is a client and the displayed date is set, update state based on whether the displayed date is today or in the past
      if (displayedDateMidnight !== null) {
        setIsPastForClient(displayedDateMidnight <= todayMidnight);
      }
    } else {
      setIsPastForClient(false);
    }
  }, [currentUser?.accountType, displayedDate]);

  useEffect(() => {
    if (calendar.current) {
      setShowToolbar(true);
    }
  }, [calendar]);

  useEffect(() => {
    // Update Calendar View according to the screen type
    if (!isDesktop) {
      // For small screens, always use the "dayResource" view and ensure fitHours is not enabled
      if (fitHours) {
        setFitHours(false); // Ensuring fitHours is off for small screens view as it's intended for desktop view only
      }
      setActiveView("dayResource");
      setToggleButtonAlignment("dayResource");
    } else {
      // For desktop, check if there is a stored view, otherwise use "weekResource"
      const view = storedActiveView || "weekResource";
      setActiveView(view);
      setToggleButtonAlignment(view);
    }
  }, [isDesktop]);

  const views: { label: string; value: ViewModel }[] = [
    { label: "Quinzaine", value: "twoWeeksResource" },
    { label: "Semaine", value: "weekResource" },
    { label: "Jour", value: "dayResource" },
  ];

  const handleTabChange = (tabNumber: number) => {
    const newAlignment = views[tabNumber].value;
    // Update the calendar view and toggle button to reflect the selected option
    setActiveView(newAlignment);
    setToggleButtonAlignment(newAlignment);
    // Store the selected view in sessionStorage to preserve state across sessions
    sessionStorage.setItem("calendarActiveView", newAlignment);
  };

  // Planning Parameters Options
  const parametersItems: MenuItemModel[] = [
    {
      name: CONFIG_CTO.APPOINTMENTS,
      text: CONFIG_CTO.APPOINTMENTS,
      icon: faCalendarLinesPen,
      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.APPOINTMENTS_PATH}`,
    },
    {
      name: CONFIG_CTO.SCHEDULES,
      text: CONFIG_CTO.SCHEDULES,
      icon: faClock,
      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.SCHEDULES_PATH}`,
    },
    {
      name: CONFIG_CTO.CLOSINGS,
      text: CONFIG_CTO.CLOSINGS,
      icon: faShopLock,
      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CLOSINGS_PATH}`,
    },
    {
      name: CONFIG_CTO.LINES,
      text: CONFIG_CTO.LINES,
      icon: faGripLines,
      navigateTo: `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.LINES_PATH}`,
    },
  ];

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={{
        padding: {
          xs: "0.25rem 0.5rem 0.25rem 0rem",
          lg: "0.25rem 1rem 1.25rem 0",
        },
        width: "auto",
        height: "full",
      }}
    >
      {calendar.current && showToolbar ? (
        <>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            width={{ xs: "100%", lg: "auto" }}
            gap={2}
          >
            <Stack
              flexDirection={{ xs: "row-reverse", lg: "row" }}
              alignItems={"center"}
              flex={{ xs: 1.2, xl: 1 }}
              gap={{ xs: 1, sm: 2 }}
            >
              <Stack
                flexDirection={isDesktop ? "row" : "column"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={0.2}
                margin="auto"
              >
                <Typography
                  key={"monthTitle"}
                  sx={{
                    fontSize: isDesktop ? "1.5rem" : "1.2rem",
                    fontWeight: "700",
                    marginLeft: { xs: "-0.25rem", sm: "0" },
                  }}
                >
                  {moment(calendar.current.instance.date)
                    .format("MMMM YYYY")
                    .charAt(0)
                    .toUpperCase() +
                    moment(calendar.current.instance.date)
                      .format(isDesktop ? "MMMM YYYY" : "MMMM")
                      .slice(1)}
                </Typography>

                {isDesktop && (
                  <Stack
                    flexDirection={"row"}
                    gap={0.2}
                    alignItems={isDesktop ? "alignItems" : "flexStart"}
                  >
                    <Button
                      size="xs"
                      className="btn-text"
                      onClick={() => {
                        if (!isAppointmentsLoading) {
                          calendar.current?.instance.shiftPrevious();
                          calendar.current?.instance.date &&
                            setActiveDate(
                              new Date(calendar.current?.instance.date),
                            );
                        }
                      }}
                      disabled={isAppointmentsLoading || isPastForClient}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        size={isDesktop ? "lg" : "sm"}
                        style={{
                          marginTop: isDesktop ? "0.2rem" : "-0.7rem",
                          cursor: "pointer",
                          color:
                            isAppointmentsLoading || isPastForClient
                              ? "#BDBDB7"
                              : "black",
                        }}
                      />
                    </Button>
                    <Typography
                      key={"weekTitle"}
                      sx={{
                        fontSize: isDesktop ? "1rem" : "0.9rem",
                        fontWeight: "600",
                        paddingTop: isDesktop ? "0.4rem" : "0",
                        marginTop: isDesktop ? "0" : "-0.4rem",
                      }}
                    >
                      {/*To show calendar's week number */}S
                      {moment(calendar.current.instance.date).week()}
                      {/* If 2 weeks view, also show the last day's week number*/}
                      {storedActiveView === "twoWeeksResource" &&
                        `-${moment(calendar.current.instance.date).add(13, "days").week()}`}
                    </Typography>

                    <Button
                      size="xs"
                      className="btn-text"
                      onClick={() => {
                        if (!isAppointmentsLoading) {
                          calendar.current?.instance.shiftNext();
                          calendar.current?.instance.date &&
                            setActiveDate(
                              new Date(calendar.current?.instance.date),
                            );
                        }
                      }}
                      disabled={isAppointmentsLoading}
                    >
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        size={isDesktop ? "lg" : "sm"}
                        style={{
                          marginTop: isDesktop ? "0.2rem" : "-0.7rem",
                          cursor: "pointer",
                          color: isAppointmentsLoading ? "#BDBDB7" : "black",
                        }}
                      />
                    </Button>
                  </Stack>
                )}
              </Stack>

              <Button
                className="btn-base"
                onClick={() => {
                  setActiveDate(new Date());
                  const isTodayANonWorkingDay =
                    calendar.current?.instance.nonWorkingDays[
                      new Date().getDay()
                    ];
                  // If today is a non working day, display a warning message because it will switch to the next opened day
                  if (isTodayANonWorkingDay) {
                    dispatch(
                      setAlert({
                        id: "calendar-alert",
                        type: "warning",
                        message: `Le centre est fermé le ${moment(new Date()).format("dddd")}.`,
                      }),
                    );
                  }
                }}
                disabled={isAppointmentsLoading}
              >
                {isDesktop ? (
                  "Aujourd'hui"
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "1rem",
                    }}
                  >
                    <FontAwesomeIcon icon={faCalendar} size="lg" />
                    <span
                      style={{
                        paddingTop: "6px",
                        position: "absolute",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.6em",
                      }}
                    >
                      {moment().format("DD")}{" "}
                      {/* To display day of the month with two digits (moment() without arguments is equivalent to new Date()) */}
                    </span>
                  </div>
                )}
              </Button>

              <Button
                size="xs"
                className="btn-base btn-outlined"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  setAnchorEl(event.currentTarget)
                }
                disabled={isAppointmentsLoading}
              >
                <img src={EventIcon} alt="Calendrier" />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateCalendar
                    value={moment(calendar.current.instance.date)}
                    onChange={(e) => {
                      if (
                        calendar.current?.instance.nonWorkingDays[e._d.getDay()]
                      ) {
                        // If the selected day is a "non-working" day, switch directly to the next day
                        setActiveDate(
                          new Date(
                            moment(e._d).clone().add(1, "day").format(""),
                          ),
                        );
                      } else {
                        setActiveDate(e._d);
                      }
                      setAnchorEl(null);
                    }}
                    disablePast={currentUser?.accountType === CONFIG_API.CLIENT}
                    views={["month", "day"]}
                    slots={{
                      switchViewIcon: () => (
                        <span className="button">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                      ),
                      leftArrowIcon: () => (
                        <span className="button">
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </span>
                      ),
                      rightArrowIcon: () => (
                        <span className="button">
                          <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                      ),
                    }}
                    slotProps={{
                      ...MUIXTheme.datePicker.slotsProps,
                      previousIconButton: { disableRipple: true },
                      nextIconButton: { disableRipple: true },
                      switchViewButton: { disableRipple: true },
                    }}
                  />
                </LocalizationProvider>
              </Menu>
            </Stack>

            {isDesktop && (
              <Stack flexDirection="row" gap={2} alignItems={"center"}>
                <Tabs
                  onActiveTabChange={(tabNumber) => handleTabChange(tabNumber)}
                  variant="fullWidth"
                  className="rounded-lg"
                  theme={{
                    base: "flex flex-row gap-2 font-['Inter']",
                    tablist: {
                      variant: {
                        fullWidth:
                          "grid w-full grid-flow-col divide-x divide-gray-200 m-auto rounded-none text-sm font-medium shadow ring-0",
                      },
                      tabitem: {
                        base: "flex items-center justify-center rounded-t-lg px-4 text-sm font-medium first:ml-0",
                        variant: {
                          fullWidth: {
                            base: "ml-0 flex w-full first:ml-0 rounded-none first:rounded-l-lg last:rounded-r-lg",
                            active: {
                              on: "bg-primary-600 p-4 text-white py-2 px-4",
                              off: "bg-white text-gray-500 hover:bg-gray-50 py-2 px-4",
                            },
                          },
                        },
                      },
                    },
                  }}
                >
                  {views.map((view, index) => (
                    <Tabs.Item
                      key={view.value}
                      active={
                        views.findIndex(
                          (view) => view.value === toggleButtonAlignment,
                        ) == index
                      }
                      title={view.label}
                    />
                  ))}
                </Tabs>

                <Button
                  size="xs"
                  className=" btn-base btn-outlined animatedIcon"
                  title={fitHours ? "Agrandir la vue" : "Réduire la vue"}
                  onClick={() => {
                    setFitHours(!fitHours);
                    calendar.current?.instance.refresh();
                  }}
                >
                  <FontAwesomeIcon
                    icon={fitHours ? faArrowsMaximize : faArrowsMinimize}
                    className={fitHours ? "minimize" : "maximize"}
                    color="black"
                    size="lg"
                  />
                </Button>
                <Button
                  size="xs"
                  className="btn-base btn-outlined"
                  title={"Actualiser le planning"}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRotateRight}
                    color="black"
                    size="lg"
                  />
                </Button>
              </Stack>
            )}
          </Stack>

          <Stack
            flexDirection="row"
            gap={{ xs: 1, sm: 2 }}
            justifyContent={"flex-end"}
            alignItems={"center"}
            flex={1}
          >
            {isDesktop && (
              <Tooltip
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "white",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                    },
                  },
                }}
                title={
                  <InfoIcons
                    appointmentsList={calendar.current.instance.eventStore}
                  />
                }
              >
                <Box sx={{ display: { xs: "none", xl: "flex" } }}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color={colors.palette.secondary.light}
                    size="lg"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Tooltip>
            )}
            <Button
              className="btn-base"
              onClick={() => {
                calendar.current?.instance.createEvent(
                  new Date(calendar.current?.instance.date),
                );
              }}
              title="Ajouter un rendez-vous"
            >
              <FontAwesomeIcon icon={faCalendarCirclePlus} size="lg" />

              <Box sx={{ display: { xs: "none", xl: "flex" }, marginLeft: 1 }}>
                Ajouter
              </Box>
            </Button>

            {currentUser?.hasCenterSettingsAccess && (
              <BasicMenu
                title="Paramètres du centre"
                items={parametersItems}
                buttonSize="xs"
                className="btn-base btn-outlined"
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <FontAwesomeIcon icon={faGear} size="lg" color="black" />
              </BasicMenu>
            )}
          </Stack>
        </>
      ) : (
        <Stack
          display={{ xs: "none", sm: "flex" }}
          width="100%"
          flexDirection="row"
          alignItems="center"
          gap={"1rem"}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            width="50%"
            gap={"1rem"}
          >
            <Stack width="14rem" flexDirection={"row"} gap={0.5}>
              <Skeleton
                variant="text"
                width="70%"
                height={40}
                sx={{ borderRadius: generalRadius }}
              />
              <Skeleton
                variant="text"
                width="30%"
                height={40}
                sx={{ borderRadius: generalRadius }}
              />
            </Stack>

            <Stack
              width="35rem"
              flexDirection="row"
              alignItems="center"
              gap={"1rem"}
            >
              <Skeleton
                variant="rectangular"
                width="7rem"
                height={40}
                sx={{ borderRadius: generalRadius }}
              />
              <Skeleton
                variant="text"
                width="2.5rem"
                height={40}
                sx={{ borderRadius: generalRadius }}
              />
              <Skeleton
                variant="rectangular"
                width="18rem"
                height={40}
                sx={{ borderRadius: generalRadius }}
              />
              <Skeleton
                variant="text"
                width="2.5rem"
                height={40}
                sx={{ borderRadius: generalRadius }}
              />
            </Stack>
          </Stack>

          <Stack
            flexDirection="row"
            justifyContent={"flex-end"}
            width="50%"
            gap="1rem"
          >
            <Skeleton
              variant="circular"
              width="1.2rem"
              sx={{ margin: "auto 0" }}
            />
            <Skeleton
              variant="rectangular"
              width="7rem"
              height={40}
              sx={{ borderRadius: generalRadius }}
            />
            <Skeleton
              variant="text"
              width="2.5rem"
              height={40}
              sx={{ borderRadius: generalRadius }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CalendarToolbar;
