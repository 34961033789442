import React, { ChangeEvent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { GridFilterItem } from "@mui/x-data-grid-premium";
import { Stack, TextField } from "@mui/material";
import { GridFilterModel, GridColDef } from "@mui/x-data-grid-premium";
import moment from "moment";
import {
  faMagnifyingGlass,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/pro-regular-svg-icons";

import {
  colors,
  generalRadius,
  generalSpacing,
} from "../../../utils/customTheme/customTheme";
import { MUIXTheme } from "../../../utils/customTheme/customTheme";

import ChipFilter from "./ChipFilter";

interface Props {
  columns: GridColDef[];
  filterItems: FilterItem[];
  filterModel: GridFilterModel;
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  defaultFilters?: GridFilterModel;
}

export interface FilterItem {
  id: string | number;
  field: string;
  label: string;
  type: "text" | "number" | "date" | "chip" | "select";
  operator: string;
  options?: string[];
  isDayFormat?: boolean; // To filter the day even if it's not the same value ("lun." and "lundi" for example)
  linkedFilterId?: number; // To filter depending another filter value
}

export const dayMapping = {
  "Lun.": "Lundi",
  "Mar.": "Mardi",
  "Mer.": "Mercredi",
  "Jeu.": "Jeudi",
  "Ven.": "Vendredi",
  "Sam.": "Samedi",
  "Dim.": "Dimanche",
};

// Reverse mapping of full day names to short day names
export const reverseDayMapping = Object.fromEntries(
  Object.entries(dayMapping).map(([k, v]) => [v, k]),
);

const ListFilters = ({
  columns,
  filterItems,
  defaultFilters,
  filterModel,
  setFilterModel,
}: Props) => {
  const [filters, setFilters] = useState<Record<string, string>>(() => {
    if (defaultFilters && defaultFilters.items) {
      // If default filters are defined, set default value for each filter inputs
      return defaultFilters.items.reduce(
        (acc, item) => {
          acc[item.field] = item.value;
          return acc;
        },
        {} as Record<string, string>,
      );
    } else if (filterModel && filterModel.items) {
      // To Keep the values in the filters (in case of current center update for example)
      return filterModel.items.reduce(
        (acc, item) => {
          acc[item.field] = item.value;
          return acc;
        },
        {} as Record<string, string>,
      );
    } else {
      // If there isn't defaut filters, return empty object
      return {};
    }
  });

  const [showDatePicker, setShowDatePicker] = useState<Record<string, boolean>>(
    {},
  );

  // Handles the change in filter input, updates the local filter state, and applies the new filters.
  const handleFilterChange =
    (field: string) =>
    (event: ChangeEvent<HTMLInputElement | { value: unknown }>) => {
      const newFilterValue = event.target.value as string;

      // Update the filters state with the new filter value
      const newFilters = {
        ...filters,
        [field]: newFilterValue,
      };
      setFilters(newFilters);
      applyFilters(newFilters); // Apply updated filters on change
    };

  // Applies the updated filters to the DataGrid by transforming them into GridFilterItem format and updating the filter model.
  const applyFilters = (newFilters: Record<string, string>) => {
    const items: GridFilterItem[] = filterItems.flatMap((filterItem) => {
      let value: string | string[] = newFilters[filterItem.field];
      if (filterItem.isDayFormat && value) {
        value = (value as string)
          .split(",")
          .map((day) => reverseDayMapping[day] || day);
      }
      if (filterItem.type === "date" && value) {
        value = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD"); // Convert the date to a comparable format
      }
      if (Array.isArray(value)) {
        return value.map((v) => ({
          id: filterItem.id,
          field: filterItem.field,
          operator: filterItem.operator,
          value: v,
        }));
      }
      return [
        {
          id: filterItem.id,
          field: filterItem.field,
          operator: filterItem.operator,
          value: value,
        },
      ];
    });
    setFilterModel({ items });
  };

  // Render the appropriate filter component based on filter type
  const renderFilter = (filterItem: FilterItem) => {
    const column = columns.find((col) => col.field === filterItem.field);
    const label = column?.headerName || filterItem.field;

    return (
      <Stack
        key={filterItem.field}
        sx={{
          display: "flex",
          alignItems: "center",
          marginRight: generalSpacing,
        }}
      >
        {filterItem.type === "chip" ? (
          <ChipFilter
            label={label}
            options={filterItem.options || []}
            value={
              filters[filterItem.field]
                ? filters[filterItem.field].split(",")
                : []
            }
            onChange={(newValue) => {
              const value = newValue.sort().join(",");
              handleFilterChange(filterItem.field)({
                target: { value },
              } as ChangeEvent<HTMLInputElement>);
            }}
          />
        ) : filterItem.type === "date" ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={moment(filters[filterItem.field]) || null} // Empty by default
              onChange={(newValue) => {
                if (newValue) {
                  setFilters({
                    ...filters,
                    [filterItem.field]: moment(newValue).toISOString(),
                  });
                  handleFilterChange(filterItem.field)({
                    target: { value: moment(newValue).toISOString() },
                  } as ChangeEvent<HTMLInputElement>);
                }
              }}
              onClose={() => {
                setShowDatePicker({
                  ...showDatePicker,
                  [filterItem.field]: false,
                });
              }}
              onOpen={() => {
                setShowDatePicker({
                  ...showDatePicker,
                  [filterItem.field]: true,
                });
              }}
              disableHighlightToday
              views={["year", "month", "day"]}
              open={showDatePicker[filterItem.field] || false}
              slotProps={{
                ...MUIXTheme.datePicker.slotsProps,
                inputAdornment: { position: "start", sx: { marginRight: 0 } },
                textField: {
                  label: filterItem.label,
                  placeholder: "",
                  InputProps: {
                    onClick: () => {
                      setShowDatePicker({
                        ...showDatePicker,
                        [filterItem.field]: true,
                      });
                    },
                  },
                  sx: {
                    "& .MuiInputBase-input": {
                      cursor: "pointer",
                    },
                    "& .MuiInputLabel-shrink": {
                      backgroundColor: colors.palette.customBackground.light,
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: generalRadius,
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        ) : (
          <TextField
            label={label}
            variant="outlined"
            size="small"
            type={filterItem.type === "number" ? "number" : "text"}
            value={filters[filterItem.field] || ""}
            onChange={handleFilterChange(filterItem.field)}
            InputProps={{
              endAdornment: filters[filterItem.field] ? (
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  onClick={() => {
                    handleFilterChange(filterItem.field)({
                      target: { value: "" },
                    } as ChangeEvent<HTMLInputElement>);
                  }}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                />
              ) : filterItem.type !== "number" ? (
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              ) : (
                <></>
              ),
            }}
            sx={{
              "& .MuiInputLabel-shrink": {
                backgroundColor: colors.palette.customBackground.light,
                top: "16px !important",
              },
              "& .MuiFormLabel-root": {
                top: "21px",
              },
            }}
          />
        )}
      </Stack>
    );
  };

  // Determines if the "Reset Filters" icon should be shown
  const shouldShowResetFilters = (
    defaultFilters: GridFilterModel | undefined,
    filterModel: GridFilterModel,
  ): boolean => {
    if (defaultFilters) {
      // Check if there are defined filters different from the default filters
      const activedFilters = filterModel.items.filter(
        (filter) => filter.value !== "" && filter.value !== undefined,
      );
      return (
        JSON.stringify(activedFilters) !== JSON.stringify(defaultFilters.items)
      ); // convert in JSON to compare arrays easily
    } else {
      // Check if at least one filter is defined (when there are no default filters)
      return filterModel.items.some(
        (filter) => filter.value !== "" && filter.value !== undefined,
      );
    }
  };

  const resetFilters = () => {
    if (defaultFilters) {
      // Reset filters with default filters state
      const updatedFilters: any = [];
      filterItems.forEach((filter) => {
        // Find if there is a matching filter in defaultFilters
        const sameFilter = defaultFilters.items.find(
          (item) => item.field === filter.field,
        );
        if (sameFilter) {
          // If a matching filter is found, set the value from defaultFilters
          updatedFilters[sameFilter.field] = sameFilter.value;
        } else {
          // If no matching filter is found, set an empty string as the value
          updatedFilters[filter.field] = "";
        }
      });
      // Set default values for filters inputs and filtered data
      setFilters(updatedFilters);
      setFilterModel(defaultFilters);
    } else {
      // Reset each filter with empty value
      const updatedFilterModel = filterModel;
      updatedFilterModel.items.map((item) => {
        item.value = "";
      });
      // Set empty values for filters inputs and filtered data
      setFilterModel({ ...filterModel, items: updatedFilterModel.items });
      setFilters({});
    }
  };

  return (
    <Stack flexDirection={"row"} alignItems={"center"} gap={"0.6rem"}>
      {shouldShowResetFilters(defaultFilters, filterModel) && (
        // If one condition of the function is met, show the reset filter icon
        <Stack flexDirection={"row"} gap={"0.6rem"} marginTop="1rem">
          <FontAwesomeIcon
            icon={faXmark}
            onClick={resetFilters}
            size="lg"
            style={{ cursor: "pointer" }}
            title="Réinitialiser les filtres"
          />
        </Stack>
      )}
      {filterItems.map(renderFilter)}
    </Stack>
  );
};

export default ListFilters;
