import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { AppDispatch } from "../../store/store";
import { UserModel } from "../../models/UserModel";
import { selectCurrentUser } from "../../store/user/user-slice";

interface Props<Item extends UserModel> {
  variant: "profileSelect";
  label: string;
  arrayList: Item[];
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ListSelector = ({
  arrayList,
  variant,
  label,
  setShowModal,
}: Props<UserModel>) => {
  const dispatch: AppDispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    if (variant === "profileSelect") {
      dispatch(selectCurrentUser(event.target.value, arrayList));
    }
    setShowModal && setShowModal(false);
  };

  const renderMenuItem = (item: UserModel, variant: string) => {
    // Use a type assertion to inform TypeScript about the type of the item
    if (variant === "profileSelect") {
      return (
        <MenuItem key={(item as UserModel).pid} value={(item as UserModel).pid}>
          {(item as UserModel).login} - {(item as UserModel).lastName}
        </MenuItem>
      );
    }
  };

  return (
    <FormControl sx={{ width: "80%", margin: "-1rem auto 0" }}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={""} onChange={handleChange}>
        {arrayList.map((item) => renderMenuItem(item, variant))}
      </Select>
    </FormControl>
  );
};

export default ListSelector;
