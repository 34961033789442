import { ScheduleModel } from "../../models/CenterSettingsModel";

interface MinMaxTime {
  minTime: string;
  maxTime: string;
}

/**
 * Calculates the earliest (min) and latest (max) times in a given list of time ranges.
 * @param {ScheduleModel[]} schedules  - Array of ScheduleModel objects representing time ranges.
 * @returns {MinMaxTime} - An object representing the overall min and max times.
 */
export const findMinMaxTimes = (schedules: ScheduleModel[]): MinMaxTime => {
  const minMaxTimesByResource: Record<number, MinMaxTime> = {};

  // Initialize global min and max times with the first time range's values
  let globalMinTime = schedules[0].startingTime;
  let globalMaxTime = schedules[0].endingTime;

  // Iterate over each time range
  schedules.forEach((schedule: ScheduleModel) => {
    // Get the current min/max times for the line, if they exist
    const currentScheduleMinMax = minMaxTimesByResource[schedule.line];

    if (!currentScheduleMinMax) {
      // Initialize min/max times for this line if not already present
      minMaxTimesByResource[schedule.line] = {
        minTime: schedule.startingTime,
        maxTime: schedule.endingTime,
      };
    } else {
      // Update min time if the new starting time is earlier
      if (schedule.startingTime < currentScheduleMinMax.minTime) {
        currentScheduleMinMax.minTime = schedule.startingTime;
      }
      // Update max time if the new ending time is later
      if (schedule.endingTime > currentScheduleMinMax.maxTime) {
        currentScheduleMinMax.maxTime = schedule.endingTime;
      }
    }

    // Update global min time if the new starting time is earlier
    if (schedule.startingTime < globalMinTime) {
      globalMinTime = schedule.startingTime;
    }
    // Update global max time if the new ending time is later
    if (schedule.endingTime > globalMaxTime) {
      globalMaxTime = schedule.endingTime;
    }
  });

  // Return the min/max times by line and overall
  return {
    minTime: globalMinTime,
    maxTime: globalMaxTime,
  };
};
