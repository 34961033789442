import { FlowbiteColors } from "../../models/CenterModel";

/**
 * Retrieve a color from a predefined list of Flowbite colors based on the given index.
 * The color list loops infinitely, so any index will map to a color.
 * @param {number} index - The index to determine which color to pick.
 * @returns {CenterColor} The corresponding color from the list.
 */
export const getColorForIndex = (index: number): FlowbiteColors => {
  const colors: FlowbiteColors[] = [
    "orange",
    "red",
    "green",
    "yellow",
    "indigo",
    "pink",
    "purple",
    "teal",
    "blue",
  ];
  return colors[index % colors.length];
};
