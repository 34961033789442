import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Alert, Button, Spinner } from "flowbite-react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AxiosResponse } from "axios";

import { CenterModel, MctSlotModel } from "../../../models/CenterModel";
import {
  generalSpacing,
  MUIXTheme,
} from "../../../utils/customTheme/customTheme";
import { useSaveCenter } from "../../../hooks/center/useSaveCenter";
import { RootState } from "../../../store/store";
import { api } from "../../../utils/api";
import { CONFIG_API } from "../../../data/config.API";
import { APIResponsesModel } from "../../../models/ApiResponseModel";
import { displayError } from "../../../utils/layout/displayError";
import { InfoItemModel } from "../../../components/layouts/InfoGrid";

interface Props {
  center: CenterModel;
  mctSlotRequestState: { items: MctSlotModel[]; loading: boolean };
  mctSlotsItems: MctSlotModel[];
  setMctSlotsItems: React.Dispatch<React.SetStateAction<MctSlotModel[]>>;
  setInfoItems: React.Dispatch<React.SetStateAction<InfoItemModel[]>>;
  error: {
    variant: null | "wrongSlotNumber" | "requestError";
    message: string;
  };
  handleCloseDrawer: () => void;
}

const PublicPortalForm = ({
  center,
  mctSlotsItems,
  mctSlotRequestState,
  setMctSlotsItems,
  setInfoItems,
  error,
  handleCloseDrawer,
}: Props) => {
  const centerState = useSelector((store: RootState) => store.CENTER);

  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors },
  } = useForm<CenterModel>({
    defaultValues: {
      isVisibleOnMCT: center.isVisibleOnMCT,
      isSendSmsMCT: center.isSendSmsMCT,
      phoneSmsMonControleTechnique: center.phoneSmsMonControleTechnique,
      mctSlots: mctSlotsItems.map((slot) => ({
        ...slot,
        startHour: moment(slot.startHour, "HH:mm:ss").toString(),
        endHour: moment(slot.endHour, "HH:mm:ss").toString(),
      })),
    },
  });

  const [currentSlots, setCurrentSlots] =
    useState<MctSlotModel[]>(mctSlotsItems);
  const [mctSlotPutRequestState, setMctSlotPutRequestState] = useState<{
    loading: boolean;
    error: string | null;
  }>({ loading: false, error: null });
  const [timePickerOpenState, setTimePickerOpenState] = useState<
    { startTime: boolean; endTime: boolean }[]
  >([
    {
      startTime: false,
      endTime: false,
    },
    {
      startTime: false,
      endTime: false,
    },
  ]);

  // Using the custom hook to manage the form submission logic,
  const { saveCenter, requestState } = useSaveCenter(center, handleCloseDrawer);

  const updatePublicPortal = async () => {
    setMctSlotPutRequestState({ error: null, loading: true });

    const updatedCurrentSlots: MctSlotModel[] = [];
    currentSlots.forEach((slot, index) => {
      updatedCurrentSlots.push({
        style: "style1",
        startHour: moment(watch(`mctSlots.${index}`).startHour).format(
          "HH:mm:ss",
        ),
        endHour: moment(watch(`mctSlots.${index}`).endHour).format("HH:mm:ss"),
        label: currentSlots.length > 1 ? slot.label : "Journée",
      });
    });

    try {
      // Check if slots are valid
      let isValid: { status: boolean; slotIndex: null | number } = {
        status: true,
        slotIndex: null,
      };
      for (let i = 0; i < updatedCurrentSlots.length; i++) {
        const currentStart = updatedCurrentSlots[i].startHour;
        const currentEnd = updatedCurrentSlots[i].endHour;

        // Check if endHour is before or equal to startHour in the same element
        if (currentEnd < currentStart) {
          isValid = { status: false, slotIndex: i }; // Set isValid to false if the condition is not met
          break; // Exit early if there's an error
        }

        // If this is not the first element, check if the start hour is after the previous end hour
        if (i > 0) {
          const previousEnd = currentSlots[i - 1].endHour;
          if (currentStart < previousEnd) {
            isValid = { status: false, slotIndex: i }; // Set isValid to false if the condition is not met
            break; // Exit early if there's an error
          }
        }
      }

      if (!isValid.status && isValid.slotIndex) {
        setError(`mctSlots.${isValid.slotIndex}.startHour`, {
          type: "validate",
        });
        setMctSlotPutRequestState({
          loading: false,
          error:
            "L'heure de début d'un créneau ne peut pas être avant l'heure de fin du créneau précédent.",
        });
        return;
      }
      const mctSlotResponse: AxiosResponse<APIResponsesModel<MctSlotModel>> =
        await api.post(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${centerState.currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${centerState.currentCenter?.pid}/MCTSlot`,
          updatedCurrentSlots,
        );
      if (mctSlotResponse.data.success) {
        setMctSlotsItems(mctSlotResponse.data.items);
        if (centerState.currentCenter) {
          saveCenter({
            ...centerState.currentCenter,
            isVisibleOnMCT: watch("isVisibleOnMCT"),
            isSendSmsMCT: watch("isSendSmsMCT"),
            phoneSmsMonControleTechnique: getValues(
              "phoneSmsMonControleTechnique",
            ),
          });
          if (!requestState.loading && !requestState.error) {
            setInfoItems([
              {
                name: "isVisibleOnMCT",
                label: "Visible sur le site grand public",
                value: watch("isVisibleOnMCT"),
                fullWidth: true,
              },
              {
                name: "isSendSmsMCT",
                label: "Notification SMS à chaque réservation",
                value: watch("isSendSmsMCT"),
                hide: !watch("isVisibleOnMCT") || !watch("isSendSmsMCT"),
              },
              {
                label: "Téléphone",
                value: getValues("phoneSmsMonControleTechnique"),
                hide: !watch("isVisibleOnMCT") || !watch("isSendSmsMCT"),
              },
              {
                label: "",
                value: "Affichage des créneaux",
                fullWidth: true,
                isTitle: true,
                hide: !watch("isVisibleOnMCT"),
              },
              ...mctSlotResponse.data.items.map((slot) => {
                return {
                  name: "mctSlot",
                  label:
                    mctSlotResponse.data.items.length === 1
                      ? "Journée"
                      : slot.label,
                  value: `${slot.startHour.slice(0, 5)} - ${slot.endHour.slice(0, 5)}`,
                  hide: !centerState.currentCenter?.isVisibleOnMCT,
                };
              }),
            ]);
          }
        }
      } else {
        setMctSlotPutRequestState((prevState) => ({
          ...prevState,
          error: displayError(mctSlotResponse.data.messages[0]),
        }));
      }
    } catch (error) {
      setMctSlotPutRequestState((prevState) => ({
        ...prevState,
        error: "Une erreur est survenue.",
      }));
    } finally {
      setMctSlotPutRequestState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  return (
    <Stack gap={generalSpacing}>
      <FormControl
        component="form"
        id="appointmentsParametersForm"
        onSubmit={handleSubmit(updatePublicPortal)}
        sx={{ width: "100%", gap: "0.5rem" }}
      >
        <FormControlLabel
          label={"Visible sur le site grand public"}
          control={
            <Checkbox
              {...register("isVisibleOnMCT")}
              defaultChecked={center.isVisibleOnMCT}
            />
          }
        />

        {watch("isVisibleOnMCT") && (
          <Stack gap={0}>
            <FormControlLabel
              label={"Notification SMS à chaque réservation"}
              control={
                <Checkbox
                  {...register("isSendSmsMCT")}
                  defaultChecked={center.isSendSmsMCT}
                />
              }
            />

            {watch("isSendSmsMCT") && (
              <TextField
                {...register("phoneSmsMonControleTechnique", {
                  required: "Veuillez renseigner un numéro de téléphone.",
                })}
                error={Boolean(errors.phoneSmsMonControleTechnique)}
                helperText={errors.phoneSmsMonControleTechnique?.message}
                onChange={() => {
                  Boolean(errors.phoneSmsMonControleTechnique) &&
                    clearErrors("phoneSmsMonControleTechnique");
                }}
                label="Téléphone"
              />
            )}

            {!mctSlotRequestState.loading ? (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  gap={generalSpacing}
                  marginTop={generalSpacing}
                >
                  <Typography fontWeight={700}>
                    Affichage des créneaux
                  </Typography>

                  <RadioGroup
                    defaultValue={currentSlots.length > 1 ? 2 : 1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: generalSpacing,
                      marginTop: "-0.5rem",
                    }}
                  >
                    <FormControlLabel
                      label="1 créneau"
                      value={1}
                      control={
                        <Radio
                          onChange={() => {
                            const singleSlot = {
                              style: "style1",
                              label: "Journée",
                              startHour: "00:00:00",
                              endHour: "23:59:00",
                            };
                            setValue(
                              `mctSlots.0.startHour`,
                              moment(
                                singleSlot.startHour,
                                "HH:mm:ss",
                              ).toString(),
                            );
                            setValue(
                              `mctSlots.0.endHour`,
                              moment(singleSlot.endHour, "HH:mm:ss").toString(),
                            );
                            setCurrentSlots([singleSlot]);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="2 créneaux"
                      value={2}
                      control={
                        <Radio
                          onChange={() => {
                            const morningSlot = {
                              style: "style1",
                              label: "Matin",
                              startHour: "00:00:00",
                              endHour: "13:00:00",
                            };
                            const afterNoonSlot = {
                              style: "style1",
                              label: "Après-midi",
                              startHour: "13:00:00",
                              endHour: "23:59:00",
                            };
                            setCurrentSlots([morningSlot, afterNoonSlot]);
                            setValue(
                              `mctSlots.0.startHour`,
                              moment(
                                morningSlot.startHour,
                                "HH:mm:ss",
                              ).toString(),
                            );
                            setValue(
                              `mctSlots.0.endHour`,
                              moment(
                                morningSlot.endHour,
                                "HH:mm:ss",
                              ).toString(),
                            );
                            setValue(
                              `mctSlots.1.startHour`,
                              moment(
                                afterNoonSlot.startHour,
                                "HH:mm:ss",
                              ).toString(),
                            );
                            setValue(
                              `mctSlots.1.endHour`,
                              moment(
                                afterNoonSlot.endHour,
                                "HH:mm:ss",
                              ).toString(),
                            );
                          }}
                        />
                      }
                    />
                  </RadioGroup>

                  {currentSlots.map((mct, index) => (
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      gap={generalSpacing}
                      key={`${mct.label}${index}`}
                    >
                      <Typography width={"7rem"} fontWeight={700}>
                        {currentSlots.length > 1 ? mct.label : "Journée"}
                      </Typography>

                      <Controller
                        control={control}
                        {...register(`mctSlots.${index}.startHour`)}
                        name={`mctSlots.${index}.startHour`}
                        render={({ field }) => (
                          <TimePicker
                            {...MUIXTheme.timePicker.defaultProps}
                            value={moment(field.value)}
                            // Remove before minHour & after maxHour options
                            minTime={moment()
                              .hours(
                                parseInt(
                                  centerState.centerSchedules.minHour ?? "",
                                  10,
                                ),
                              )
                              .minutes(0)}
                            maxTime={moment()
                              .hours(
                                parseInt(
                                  centerState.centerSchedules.maxHour ?? "",
                                  10,
                                ),
                              )
                              .minutes(0)}
                            onChange={field.onChange}
                            open={timePickerOpenState[index].startTime}
                            onOpen={() =>
                              setTimePickerOpenState((prevState) => {
                                const newState = [...prevState];
                                newState[index].startTime = true;
                                return newState;
                              })
                            }
                            onClose={() =>
                              setTimePickerOpenState((prevState) => {
                                const newState = [...prevState];
                                newState[index].startTime = false;
                                return newState;
                              })
                            }
                            slotProps={{
                              ...MUIXTheme.timePicker.slotsProps,
                              inputAdornment: {
                                position: "start",
                                sx: {
                                  marginRight: 0,
                                  display: { xs: "none", sm: "flex" },
                                },
                              },
                              textField: {
                                onClick: () =>
                                  setTimePickerOpenState((prevState) => {
                                    const newState = [...prevState];
                                    newState[index].startTime = true;
                                    return newState;
                                  }),
                                error: Boolean(
                                  errors?.mctSlots?.[index]?.startHour,
                                ),
                                helperText:
                                  errors?.mctSlots?.[index]?.startHour?.message,
                                label: "Début",
                                placeholder: "",
                                sx: {
                                  width: "7rem",
                                  "& .MuiInputLabel-root": {
                                    top: -3,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    marginTop: "0",
                                  },
                                  "& .MuiInputBase-input": {
                                    cursor: "pointer",
                                  },
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        {...register(`mctSlots.${index}.endHour`)}
                        name={`mctSlots.${index}.endHour`}
                        render={({ field }) => (
                          <TimePicker
                            {...MUIXTheme.timePicker.defaultProps}
                            value={moment(field.value)}
                            // Remove before minHour & after maxHour options
                            minTime={moment()
                              .hours(
                                parseInt(
                                  centerState.centerSchedules.minHour ?? "",
                                  10,
                                ),
                              )
                              .minutes(0)}
                            maxTime={moment()
                              .hours(
                                parseInt(
                                  centerState.centerSchedules.maxHour ?? "",
                                  10,
                                ),
                              )
                              .minutes(0)}
                            onChange={field.onChange}
                            open={timePickerOpenState[index].endTime}
                            onOpen={() =>
                              setTimePickerOpenState((prevState) => {
                                const newState = [...prevState];
                                newState[index].endTime = true;
                                return newState;
                              })
                            }
                            onClose={() =>
                              setTimePickerOpenState((prevState) => {
                                const newState = [...prevState];
                                newState[index].endTime = false;
                                return newState;
                              })
                            }
                            slotProps={{
                              ...MUIXTheme.timePicker.slotsProps,
                              inputAdornment: {
                                position: "start",
                                sx: {
                                  marginRight: 0,
                                  display: { xs: "none", sm: "flex" },
                                },
                              },
                              textField: {
                                error: Boolean(
                                  errors?.mctSlots?.[index]?.endHour,
                                ),
                                helperText:
                                  errors?.mctSlots?.[index]?.endHour?.message,
                                label: "Fin",
                                placeholder: "",
                                onClick: () =>
                                  setTimePickerOpenState((prevState) => {
                                    const newState = [...prevState];
                                    newState[index].endTime = true;
                                    return newState;
                                  }),
                                sx: {
                                  width: "7rem",
                                  "& .MuiInputLabel-root": {
                                    top: -3,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    marginTop: "0",
                                  },
                                  "& .MuiInputBase-input": {
                                    cursor: "pointer",
                                  },
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  ))}
                </Stack>
              </LocalizationProvider>
            ) : (
              <Alert color="failure" className="m-4">
                {error.message}
              </Alert>
            )}
          </Stack>
        )}

        <Stack
          sx={{
            width: "100%",
            position: "sticky",
            paddingY: generalSpacing,
            bottom: 0,
            gap: 2,
            backgroundColor: "white",
          }}
        >
          {requestState.error ||
            (mctSlotPutRequestState.error && (
              <Box>
                <Alert color="failure">
                  {requestState.error ?? mctSlotPutRequestState.error}
                </Alert>
              </Box>
            ))}
          <Stack width="fit-content" flexDirection="row" gap={1}>
            <Button
              type="submit"
              className="btn-base"
              disabled={requestState.loading || mctSlotPutRequestState.loading}
            >
              {requestState.loading || mctSlotPutRequestState.loading ? (
                <Spinner size="sm" />
              ) : (
                "Enregistrer"
              )}
            </Button>
          </Stack>
        </Stack>
      </FormControl>
    </Stack>
  );
};

export default PublicPortalForm;
