/* eslint-disable */
// @ts-nocheck
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CalendarListeners, LocaleManager, Model } from "@bryntum/calendar";
import { BryntumCalendarProps } from "@bryntum/calendar-react";
import moment from "moment";
import "../../utils/calendar/localization/Calendar/calendar.locale.FrFr";

import { RootState, AppDispatch } from "../../store/store";
import {
  setStoredAppointmentData,
  updateCenterAppointment,
  setPasteLoading,
  setRegularLoading,
  moveAppointmentFromAnotherCenter,
} from "../../store/calendar/appointment-slice";
import { TimeRangeModel } from "../../models/CalendarModel";
import { displayChangedEvent } from "../../components/calendar/EventEditor/EventEditor";
import { CONFIG_API } from "../../data/config.API";
import { APIResponseModel } from "../../models/ApiResponseModel";
import { AppointmentModel } from "../../models/AppointmentModel";
import { displayError } from "../layout/displayError";
import { setAlert } from "../../store/layout/alert-slice";
import { checkLimitDates } from "./checkLimitDates";

import { eventRender } from "./eventRender";
import EventTooltip from "../../components/calendar/EventTooltip";

export let editingRecord: null | Model<AppointmentModel> = null;

const CalendarConfig = (
  activeDate,
  activeView,
  fitHours,
  storedSlots,
  setChosenClosingData,
  setIsOverlapAlertShown,
): BryntumCalendarProps => {
  const currentOrganization = useSelector(
    (store: RootState) => store.ORGANIZATION.currentOrganization,
  );
  const centerState = useSelector((store: RootState) => store.CENTER);
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);
  const storedAppointmentData = useSelector(
    (store: RootState) => store.APPOINTMENT.storedAppointmentData.data,
  );
  const storedAppointmentVariant = useSelector(
    (store: RootState) => store.APPOINTMENT.storedAppointmentData.variant,
  );
  const customersList = useSelector(
    (store: RootState) => store.CUSTOMER.customersList,
  );

  const [selectedResource, setSelectedResource] = useState<number | undefined>(
    undefined,
  );

  const dispatch: AppDispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  // Set French Localization
  LocaleManager.locale = "FrFr";

  const findClosestSlot = (eventRecord): string | null => {
    const stepTimeRanges = storedSlots.filter(
      (timerange: TimeRangeModel) =>
        timerange.type === "step" &&
        (timerange.resourceId === eventRecord.resourceId || selectedResource) && // selectedResource in case on undefined resource (Bryntum bug)
        moment(timerange.startDate).dayOfYear() ===
          moment(eventRecord.startDate).dayOfYear(),
    );

    // Convert selected hour in moment object
    const selectedMoment = moment(eventRecord.startDate, "HH:mm");

    // Filter step time ranges that start at the same or before selected hour
    const beforeStepTimeRanges = stepTimeRanges.filter((time: TimeRangeModel) =>
      moment(time.startDate).isSameOrBefore(selectedMoment),
    );

    const timeRangesBefore: string[] = beforeStepTimeRanges.map((timerange) =>
      moment(timerange.startDate).format("HH:mm"),
    );

    // Find the closest slot before selected hour
    const closestBeforeTime =
      timeRangesBefore.length > 0
        ? timeRangesBefore.reduce((closest, time) => {
            const timeMoment = moment(time, "HH:mm");
            const closestMoment = moment(closest, "HH:mm");
            return selectedMoment.diff(timeMoment) <
              selectedMoment.diff(closestMoment)
              ? time
              : closest;
          })
        : null;
    if (closestBeforeTime) {
      return closestBeforeTime;
    } else {
      return stepTimeRanges && stepTimeRanges[0]
        ? moment(stepTimeRanges[0].startDate).format("HH:mm")
        : centerState.centerSchedules.minHour;
    }
  };

  const pasteEvent = async (
    calendar: CalendarListeners,
    updatedStoredData: AppointmentModel,
    isCuttenFromAnotherCenter: boolean,
  ) => {
    if (
      isCuttenFromAnotherCenter &&
      (updatedStoredData.isHonored || updatedStoredData.isCurrent)
    ) {
      dispatch(
        setAlert({
          id: "calendar-alert",
          type: "failure",
          message: `Impossible de coller un rendez-vous ${updatedStoredData.isCurrent ? "en cours d'opération" : "honoré"} dans un autre centre.`,
        }),
      );
      return false;
    }
    dispatch(setPasteLoading(true));
    dispatch(setRegularLoading(true));

    try {
      // Find the center from the cutten event is coming
      const previousCenter = centerState.organizationCenters?.find(
        (center) => center.pid === updatedStoredData.centerPid,
      );
      const response = isCuttenFromAnotherCenter
        ? await dispatch(
            moveAppointmentFromAnotherCenter(
              currentOrganization?.pid,
              previousCenter?.pid,
              updatedStoredData.pid,
              updatedStoredData.startDate,
              updatedStoredData.lineId,
              centerState.currentCenter?.pid,
            ),
          )
        : await dispatch(
            updateCenterAppointment(
              currentOrganization?.pid,
              centerState.currentCenter?.pid,
              storedAppointmentData.pid,
              updatedStoredData,
            ),
          );
      if (response.success) {
        const cuttenEvent = calendar.eventStore.find(
          (event) => event.data.pid === response.item.pid,
        );
        if (cuttenEvent) {
          calendar.eventStore.remove(cuttenEvent);
        }
        const updatedElement = {
          ...response.item, // Keep the other properties of the object as they are
          name: `${response.item.lastName} ${response.item.firstName}`,
          resourceId: response.item.lineId,
          source: centerState.centerPartners?.find(
            (partner) =>
              partner.internalCode === (response.item.partnerId ?? "0"),
          ),
          eventColor: "transparent",
          partnerId: response.item.partnerId ?? "0",
          vehicle: response.item.vehicle
            ? response.item.vehicle
            : response.item.vehicleList &&
                response.item.vehicleList.length > 0 &&
                response.item.vehicleList[0].brand &&
                response.item.vehicleList[0].model
              ? `${response.item.vehicleList[0].brand} ${response.item.vehicleList[0].model}`
              : "",
          readOnly:
            response.item.isLocked ||
            (currentUser?.accountType === CONFIG_API.CLIENT &&
              response.item.clientPid !== currentUser.pid),
          options: [
            { name: "isLoanCar", value: response.item.isLoanCar },
            { name: "isToLoad", value: response.item.isToLoad },
            { name: "isTruckTrailer", value: response.item.isTruckTrailer },
            { name: "isParking", value: response.item.isParking },
            { name: "isValet", value: response.item.isValet },
          ],
          notifications: [
            {
              name: "isMailConfirmation",
              value: response.item.isMailConfirmation,
            },
            {
              name: "isSmsConfirmation",
              value: response.item.isSmsConfirmation,
            },
            { name: "isSmsReminder", value: response.item.isSmsReminder },
          ],
        };
        calendar.eventStore.add(updatedElement);
        dispatch(
          setAlert({
            id: "calendar-alert",
            type: "success",
            message: "Le rendez-vous a bien été déplacé.",
          }),
        );
        dispatch(
          setStoredAppointmentData({
            variant: null,
            data: null,
            isCenterChanged: false,
          }),
        );
      } else {
        dispatch(
          setAlert({
            id: "calendar-alert",
            type: "failure",
            message:
              response &&
              response.messages.length > 1 &&
              currentUser?.accountType === CONFIG_API.CLIENT
                ? "Impossible de coller le rendez-vous sur ce créneau."
                : displayError(response.messages[0]),
          }),
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "calendar-alert",
          type: "failure",
          message: "Une erreur est survenue.",
        }),
      );
    } finally {
      dispatch(setPasteLoading(false));
      dispatch(setRegularLoading(false));
    }
  };

  const updateEvent = async (formData, calendar) => {
    //**** Remove this function when Bryntum would have fixed the "resourceRecord undefined" bug.
    // Careful with the async/await and "return false" to cancel the move (dragMoveEnd).
    const response: APIResponseModel<AppointmentModel> = await dispatch(
      updateCenterAppointment(
        currentOrganization?.pid,
        centerState.currentCenter?.pid,
        formData.pid,
        formData,
      ),
    );
    if (response && response.success) {
      dispatch(
        setAlert({
          id: "calendar-alert",
          type: "success",
          message: "Le rendez-vous a bien été déplacé.",
        }),
      );

      // To hide the overlap alert message if none event is overlaping anymore after the drag'n'drop
      const displayedAppointments = calendar.eventStore.allRecords.filter(
        (record) =>
          moment(record.getData("startDate")).isBetween(
            moment(calendar.eventStore.lastDateRange.startDate),
            moment(calendar.eventStore.lastDateRange.endDate),
            "day",
            "[]",
          ) && !record.isHonored,
      );
      if (
        displayedAppointments.every(
          (appointment) => !appointment.getData("intersection"),
        )
      ) {
        setIsOverlapAlertShown(false);
      }
      return response.item;
    } else {
      dispatch(
        setAlert({
          id: "calendar-alert",
          type: "failure",
          message:
            response && currentUser?.accountType !== CONFIG_API.CLIENT
              ? displayError(response.messages[0])
              : "Une erreur est survenue lors du déplacement du rendez-vous.",
        }),
      );
      // Return false in order to cancel the drop (and move back the event to its previous place)
      navigate("/");
      return false;
    }
  };

  return {
    date: activeDate,
    dateFormat: "D MMMM YYYY",
    mode: activeView,
    autoCreate: {
      gesture: "click",
      step: `1 minutes`,
      snapType: "floor",
      startHour: centerState.centerSchedules.minHour,
      duration: "1 seconde", // To not show the event on new event creation
    },
    listeners: {
      beforeAutoCreate({ source, date, resourceRecord }) {
        // If the user is a customer account, check both the minimum and maximum limits for appointment scheduling
        if (currentUser?.accountType === CONFIG_API.CLIENT) {
          const { limDebSit, limFinSit } = centerState.currentCenter || {};

          // Check if the selected date is too early (before limDebSit) or too late (after limFinSit)
          const outsideLimits = checkLimitDates(
            { limDebSit: limDebSit, limFinSit: limFinSit },
            date,
          );

          if (!outsideLimits.startLimit) {
            dispatch(
              setAlert({
                id: "calendar-alert",
                type: "warning",
                message: `Impossible de ${storedAppointmentData ? "coller" : "créer"} un rendez-vous avant le ${moment().add(limDebSit, "days").format("dddd DD MMMM YYYY")}.`,
              }),
            );
            return false; // Stop the function to prevent appointment creation
          }

          if (!outsideLimits.endLimit) {
            dispatch(
              setAlert({
                id: "calendar-alert",
                type: "warning",
                message: `Impossible de ${storedAppointmentData ? "coller" : "créer"} un rendez-vous après le ${moment().add(limFinSit, "days").format("dddd DD MMMM YYYY")}.`,
              }),
            );
            return false; // Stop the function to prevent appointment creation
          }
        }

        // If an event is cutten, paste it if it's possible and return false to avoid a new event creation
        if (storedAppointmentVariant === "cut") {
          const updatedStoredData = { ...storedAppointmentData };
          updatedStoredData.startDate = new Date(date);

          // To set the event on the closest time range and not on the Bryntum increment (wrong slot in case of irregular slot/step)
          const closestTimeRange = findClosestSlot(updatedStoredData);

          const [hours, minutes] = closestTimeRange.split(":").map(Number);
          const regularSlotDate = new Date(updatedStoredData.startDate);
          regularSlotDate.setHours(hours, minutes, 0, 0);

          updatedStoredData.startDate =
            moment(regularSlotDate).format("YYYY-MM-DDTHH:mm");
          updatedStoredData.lineId = resourceRecord.data.id;

          // If event is cutten from another center, delete it from previous center to paste it in the current one
          const isCuttenFromAnotherCenter =
            storedAppointmentData.centerPid !== centerState.currentCenter?.pid;

          pasteEvent(this, updatedStoredData, isCuttenFromAnotherCenter);

          // Remove the "false" event from bryntum store to avoid two same events
          const falseEvents = this.eventStore?.allRecords.filter(
            (event) => !event.pid,
          );
          if (falseEvents && falseEvents.length > 0) {
            falseEvents.map((event) => this.eventStore.remove(event));
          }

          return false;
        }
        const centerTimeRanges = source.resourceTimeRangeStore._storage._values;

        const dayMapping = { SU: 0, MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6 };

        // Find clicked closing (in case of click on closing timerange to allow closing update)
        const chosenClosing = centerTimeRanges?.find(
          (timerange) =>
            timerange.type === "closing" &&
            resourceRecord.data.id === timerange.resourceId &&
            moment(date).isBetween(
              moment(timerange.startDate),
              moment(timerange.endDate),
              null,
              "[)",
            ),
        );

        // Link with matched closing from redux store (to fill the closing form with chosenClosing data to update closing)
        if (chosenClosing && currentUser?.accountType !== CONFIG_API.CLIENT) {
          const chosenClosingData = centerState.centerClosings.find(
            (closing) => closing.id === chosenClosing.getData("closingApiId"),
          );
          if (chosenClosingData) {
            setChosenClosingData(chosenClosingData);
          }
        }

        // Check if the slot is free by filtering out timeRanges where appointments are not allowed on the selected slot for the selected resource
        const isOverlap = centerTimeRanges
          .filter(
            (timerange) =>
              timerange.resourceId === resourceRecord.data.id &&
              (!timerange.isAppointmentAllowed ||
                (timerange.type === "closing" &&
                  !timerange.isAppointmentAllowed)),
          )
          .some((element) => {
            // For timeranges of schedule type, check if the hour slot is free (if the day match the schedule type recurrence rule)
            if (element.type === "schedule") {
              const dayMatches =
                date.getDay() ===
                dayMapping[element.recurrenceRule.match(/BYDAY=([A-Z]{2})/)[1]];
              if (!dayMatches) return false; // If the day doesn't match, no need to check further.

              // Compare only the time part for 'schedule' type.
              const dateMoment = moment(date);
              const startTimeMoment = moment(date).set({
                hour: moment(element.startDate).hour(),
                minute: moment(element.startDate).minute(),
                second: 0,
              });
              const endTimeMoment = moment(date).set({
                hour: moment(element.endDate).hour(),
                minute: moment(element.endDate).minute(),
                second: 0,
              });

              return dateMoment.isBetween(
                startTimeMoment,
                endTimeMoment,
                null,
                "[)",
              );
            } else {
              // For other types, compare if the wanted appointment is between a closing or an external appointment slot
              return moment(date).isBetween(
                moment(element.startDate),
                moment(element.endDate),
                null,
                "[)",
              );
            }
          });

        if (isOverlap) {
          // Prevent new appointment to be created (and prevent the event editor from opening)
          return false;
        } else {
          // To access to the right resource when drag create (some center bug ?)
          setSelectedResource(resourceRecord.data.id);
        }
        return;
      },
      beforeDragMove({ source, eventRecord }) {
        if (currentUser?.accountType === CONFIG_API.CLIENT) {
          const { limDebSit, limFinSit } = centerState.currentCenter || {};

          // Check if the selected date is too early (before limDebSit) or too late (after limFinSit)
          const outsideLimits = checkLimitDates(
            { limDebSit: limDebSit, limFinSit: limFinSit },
            eventRecord.getData("startDate"),
          );

          // Prevent event to be dragged if it is in the limits, honored or current
          if (
            !outsideLimits.startLimit ||
            !outsideLimits.endLimit ||
            eventRecord.getData("isHonored") ||
            eventRecord.getData("isCurrent")
          ) {
            source.deselectEvent(eventRecord); // To deselect the event clicked
            return false;
          }
        }
      },
      beforeEventEdit({ source, eventRecord }) {
        if (storedAppointmentVariant === "cut") {
          // Prevent Appointment Form to open if event is cutten and display a warning alert if click on another appointment
          eventRecord.getData("pid") &&
            dispatch(
              setAlert({
                id: "calendar-alert",
                message: `Veuillez sélectionner un créneau libre pour coller le rendez-vous coupé.`,
                type: "warning",
              }),
            );
          source.deselectEvent(eventRecord); // To deselect the event clicked
          return false;
        }
        // Find closest slot (to set the right slot if there is an irregular slot)
        const closestTimeRange = findClosestSlot(eventRecord);

        // Set the closest slot for the new appointment
        if (closestTimeRange && !eventRecord.data.pid) {
          const splittedClosestTimeRange = closestTimeRange.split(":");
          eventRecord.startDate.setHours(
            splittedClosestTimeRange[0],
            splittedClosestTimeRange[1],
          );
        }

        if (storedAppointmentData) {
          // Set default resource if appointment data stored
          eventRecord.set({
            ...eventRecord.data,
            resourceId: eventRecord.resourceId,
          });
        }
        if (eventRecord && !eventRecord.data.readOnly) {
          // Get the DOM element with the ID 'customEditor'
          const customEditor = document.getElementById("customEditor");
          if (customEditor) {
            // To show custom editor
            customEditor.style.display = "flex";
            // Dispatch the custom event to notify that the style has changed to 'flex'.
            customEditor.dispatchEvent(displayChangedEvent);
          }
        }

        const selectedLine = centerState.centerLines?.find(
          (line: LineModel) =>
            line.lineNumber ===
            (selectedResource ?? eventRecord.data.resourceId),
        );
        // To get stored appointment data only if there is some stored and the event is a creation and not an update
        const isStoredDataUsable = storedAppointmentData && !eventRecord.pid;

        const initialFormData = {
          firstName: isStoredDataUsable
            ? storedAppointmentData.firstName
            : eventRecord
              ? eventRecord.data.firstName
              : "",
          lastName: isStoredDataUsable
            ? storedAppointmentData.lastName
            : eventRecord
              ? eventRecord.data.lastName
              : "",
          mail: isStoredDataUsable
            ? storedAppointmentData.mail
            : eventRecord
              ? eventRecord.data.mail
              : "",
          phoneNumber: isStoredDataUsable
            ? storedAppointmentData.phoneNumber
            : eventRecord
              ? eventRecord.data.phoneNumber
              : "",
          vehicle: isStoredDataUsable
            ? storedAppointmentData.vehicle
            : eventRecord
              ? eventRecord.data.vehicle
              : "",
          lineId:
            selectedLine && selectedLine.lineNumber
              ? selectedLine.lineNumber
              : 1,
          lineName:
            selectedLine && selectedLine.label ? selectedLine.label : "",
          startDate:
            eventRecord && eventRecord.startDate
              ? eventRecord.startDate
              : new Date(),
          registrationNumber: isStoredDataUsable
            ? storedAppointmentData.registrationNumber
            : eventRecord
              ? eventRecord.data.vehicleList &&
                eventRecord.data.vehicleList[0].registrationNumber
              : "",
          options:
            isStoredDataUsable && storedAppointmentData.options
              ? storedAppointmentData.options
              : eventRecord && eventRecord.data.options
                ? eventRecord.data.options
                : [
                    { name: "isLoanCar", value: false },
                    { name: "isToLoad", value: false },
                    { name: "isTruckTrailer", value: false },
                    { name: "isParking", value: false },
                    { name: "isValet", value: false },
                  ],
          vehicleList:
            isStoredDataUsable && storedAppointmentData?.vehicleList
              ? storedAppointmentData?.vehicleList
              : eventRecord &&
                  eventRecord.data.vehicleList &&
                  eventRecord.data.vehicleList.length > 0 &&
                  eventRecord.data.vehicleList[0]
                ? [...eventRecord.data.vehicleList]
                : [
                    {
                      inspectionTypeId:
                        selectedLine?.inspectionTypes?.length > 0 &&
                        selectedLine?.inspectionTypes[0].id
                          ? selectedLine?.inspectionTypes[0].id
                          : "",
                      inspectionTypeName:
                        selectedLine?.inspectionTypes?.length > 0 &&
                        selectedLine?.inspectionTypes[0].name
                          ? selectedLine?.inspectionTypes[0].name
                          : "",
                      inspectionDuration:
                        selectedLine?.inspectionTypes?.length > 0 &&
                        selectedLine?.inspectionTypes[0].duration
                          ? selectedLine?.inspectionTypes[0].duration
                          : "",
                      inspectionPrice:
                        selectedLine?.inspectionTypes?.length > 0 &&
                        selectedLine?.inspectionTypes[0].price
                          ? selectedLine?.inspectionTypes[0].price
                          : "",
                    },
                  ],
          price: isStoredDataUsable
            ? storedAppointmentData.price
            : eventRecord.data.price
              ? eventRecord.data.price
              : eventRecord.data.pid
                ? null
                : selectedLine?.inspectionTypes?.length > 0
                  ? selectedLine?.inspectionTypes[0].price
                  : "",
          notifications:
            eventRecord && eventRecord.data.notifications
              ? eventRecord.data.notifications
              : [
                  { name: "isMailConfirmation", value: false },
                  { name: "isSmsConfirmation", value: false },
                  { name: "isSmsReminder", value: false },
                ],
          isHonored:
            eventRecord && eventRecord.data.isHonored
              ? eventRecord.data.isHonored
              : false,
          clientPid:
            isStoredDataUsable && storedAppointmentData.clientPid
              ? storedAppointmentData?.clientPid
              : eventRecord && eventRecord.data.clientPid
                ? eventRecord.data.clientPid
                : centerState.currentCenter?.personnalCustomerPid,
          partnerId:
            eventRecord && eventRecord.data.partnerId
              ? eventRecord.data.partnerId
              : "0",
          source:
            eventRecord && eventRecord.data.source && eventRecord.data.source,
        };
        eventRecord.set(initialFormData);
        // Reset selectedResource to be sure to have the resource on the next appointment
        selectedResource && setSelectedResource(undefined);

        editingRecord = eventRecord;
        // Prevent default Bryntum editor to display
        return false;
      },
      eventClick({ eventRecord }) {
        this.editEvent(eventRecord);
      },
      dragMoveEnd({ source, eventRecord }) {
        // To set the event on the closest time range and not on the Bryntum increment (wrong slot in case of irregular slot/step)
        const closestTimeRange = findClosestSlot(eventRecord.data);
        const splittedClosestTimeRange = closestTimeRange.split(":");
        const regularSlotDate = moment(eventRecord.startDate)
          .hour(splittedClosestTimeRange[0])
          .minute(splittedClosestTimeRange[1]);

        eventRecord.setStartDate(new Date(regularSlotDate));

        const formData = {
          ...eventRecord.data,
          lineId: source.eventStore
            .find((event) => event.data.pid === eventRecord.getData("pid"))
            .getData("resourceId"), // Find the event to get the updated resource
          startDate: new Date(regularSlotDate).toLocaleString("fr-FR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          }),
          endDate: eventRecord.data.endDate.toLocaleString("fr-FR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          }),
        };

        if (currentUser?.accountType === CONFIG_API.CLIENT) {
          const { limDebSit, limFinSit } = centerState.currentCenter;

          const outsideLimits = checkLimitDates(
            { limDebSit: limDebSit, limFinSit: limFinSit },
            eventRecord.startDate,
          );

          if (!outsideLimits.startLimit) {
            // If the selected date is too early (before limDebSit) or too late (after limFinSit)
            dispatch(
              setAlert({
                id: "calendar-alert",
                type: "failure",
                message: `Impossible de déplacer un rendez-vous avant le ${moment().add(limDebSit, "days").format("dddd DD MMMM YYYY")}.`,
              }),
            );
            navigate("/");
            return false; // Stop the function to prevent appointment moving
          } else if (!outsideLimits.endLimit) {
            // If the selected date is too late (after limFinSit)
            dispatch(
              setAlert({
                id: "calendar-alert",
                type: "failure",
                message: `Impossible de déplacer un rendez-vous après le ${moment().add(limFinSit, "days").format("dddd DD MMMM YYYY")}.`,
              }),
            );
            navigate("/");
            return false; // Stop the function to prevent appointment moving
          }
        }

        //**** Remove this function when Bryntum would have fixed the "resourceRecord undefined" bug.
        // Careful with the async/await and "return false" to cancel the move (dragMoveEnd).
        updateEvent(formData, this).then((response) => {
          if (response) {
            eventRecord.set(response);
            // To remove the select effect on the event after drop
            source.deselectEvent(eventRecord);
          }
        });
      },
      async beforeDragMoveEnd({ drag, view }) {
        // If moving to the CalendarRow (resources or day row), from another view, cancel the drop
        if (view.isCalendarRow && drag.source.view !== view) {
          return false;
        }
      },
      beforeDragCreateEnd({ eventRecord }) {
        // Using drag create event to create new closing directly on the calendar (only allowed to users with hasPlanningSettingsAccess right active)
        if (currentUser?.hasPlanningSettingsAccess && !storedAppointmentData) {
          eventRecord.data = {
            ...eventRecord.data,
            isDragCreated: true,
            resourceId: eventRecord.resourceId,
          };
        } else {
          return false;
        }
      },
    },
    timeRangesFeature: {
      renderer({ timeRange }) {
        if (timeRange.data.type === "step") {
          // Display start time in the empty cell (on hover or always for the "thick-line" if the parameter is active)
          return `<div class="timeRange-startTime ${centerState.currentCenter?.displayHours ? "always-displayed" : ""} ${centerState.currentCenter?.step <= 10 || fitHours ? "short-step" : ""}">
                    <p>${moment(timeRange.data.startDate).format("HH:mm")}</p>
                  </div>`;
        } else if (
          timeRange.data.type === "closing" &&
          timeRange.data.comments &&
          (currentUser?.accountType !== CONFIG_API.CLIENT ||
            timeRange.data.isCommentsVisibleForCustomer)
        ) {
          // Display closing label if it exists and is visible to the customer (for customer account)
          return `<div class="timeRange-comments">
                    <p>${timeRange.data.comments}</p>
                  </div>`;
        }
      },
    },
    eventTooltipFeature: {
      showOn: "hover",
      titleRenderer: () => "",
      closable: false,
      revealEventsInCluster: false,
      tools: {
        edit: false,
        delete: false,
      },
      renderer: ({ eventRecord }) =>
        eventRecord.data.pid ? (
          eventRecord.data.isLocked ? (
            <div className="text-center text-sm">
              Une réservation est en cours sur ce créneau.
            </div>
          ) : (
            <EventTooltip key={eventRecord.id} data={eventRecord.data} />
          )
        ) : (
          <></>
        ),
    },
    eventMenuFeature: {
      disabled: true, // Disable menu on event right click
    },
    scheduleMenuFeature: {
      disabled: true, // Disable menu on empty cell right click
    },
    modeDefaults: {
      timeFormat: "HH:mm",
      increment: `${centerState.currentCenter?.step} min`, // Determines the step to drop an event
      showTime: false, // Remove hour on event top border
      minEventHeight: 0, // To allow autoCreate to not show any event
      showHeaderAvatars: false, // Remove resource avatar
      zoomOnMouseWheel: false, // Disable wheel mouse zoom
      syncCalendarDate: false, // Disable date update on column click
      allDayEvents: {
        dayNameFormat: "dddd",
        eventFilter: () => false,
      },
      eventRenderer: ({ view, eventRecord, renderData }) => {
        // Check if the event has an inspectionCustomColor (if not, the event color will be by default)
        const hasInspectionCustomColor =
          eventRecord.data.vehicleList &&
          eventRecord.data.vehicleList.length > 0 &&
          eventRecord.data.vehicleList[0].inspectionTypeCustomColor;
        const calendar = view.up("calendar");
        // To show startTime on the event only on dragging
        let isDragging = false;
        if (eventRecord === calendar.features.drag.eventRecord) {
          isDragging = true;
        }
        // Green border for the honored appointment
        renderData.bodyStyle.borderLeft = eventRecord.data.isHonored
          ? `5px solid ${hasInspectionCustomColor ? eventRecord.data.vehicleList[0].inspectionTypeCustomColor : "#FDE2B3"}`
          : `none`;
        if (eventRecord.data.intersection) {
          // Red color in case of overlapping appointments (if appointment isn't honored yet)
          renderData.bodyColor = eventRecord.data.isHonored
            ? hasInspectionCustomColor
              ? eventRecord.data.vehicleList[0].inspectionTypeCustomColor
              : "#A8E4FC"
            : "red";
        } else if (
          eventRecord.data?.isDragCreated ||
          eventRecord.data.durationUnit === "hour"
        ) {
          // While dragging to create a closing (durationUnit is the way to know that it is a dragging event before isDragCreated is set)
          renderData.bodyStyle = {
            backgroundColor: "#cdc7d6",
            color: "#cdc7d6",
          };
          renderData.style = {
            backgroundColor: "#cdc7d6",
          };
        } else if (eventRecord.data.pid) {
          // Set event color based on the locked status or custom inspection color (if chosen, otherwise default color)
          renderData.bodyColor = eventRecord.data.isLocked
            ? "#40E0D0"
            : eventRecord.data.isHonored
              ? "#F1F1F5"
              : hasInspectionCustomColor
                ? eventRecord.data.vehicleList[0].inspectionTypeCustomColor
                : "#FDE2B3";
        } else {
          // To hide event before first saved
          renderData.bodyStyle.height = 0; // To hide
        }
        renderData.bodyStyle.color = "black";

        // Check if customer is professional
        const isCustomerProfessional = customersList?.find(
          (customer) => customer.clientPid === eventRecord.data.clientPid,
        )?.isProfessional;

        return eventRender(
          eventRecord.data,
          activeView,
          fitHours,
          isDragging,
          isCustomerProfessional,
        );
      },
    },
    sidebar: null,
    tbar: null,
  };
};

export default CalendarConfig;
