import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "flowbite-react";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import { Stack } from "@mui/material";
import { AxiosResponse } from "axios";
import moment from "moment";

import { AppDispatch, RootState } from "../../../store/store";
import { setAlert } from "../../../store/layout/alert-slice";
import { FilterItem } from "../../common/List/ListFilters";
import {
  APIResponseModel,
  APIResponsesModel,
} from "../../../models/ApiResponseModel";
import {
  DocumentTypeModel,
  MCTInvoiceFile,
  MCTInvoiceModel,
} from "../../../models/DocumentsModel";
import { api } from "../../../utils/api";
import { CONFIG_API } from "../../../data/config.API";
import { displayError } from "../../../utils/layout/displayError";

import LoadingData from "../../common/LoadingData";
import ErrorLoadingData from "../../common/ErrorLoadingData";
import ListComponent, {
  ColumnDefinition,
} from "../../common/List/ListComponent";

interface RowData {
  id: number | string;
  startingDate: Date;
  endingDate: Date;
  displayedDate: string;
  number: string;
  type: string[];
  amount: string;
  link: {
    label?: string | JSX.Element;
    onClick?: () => void;
  };
}

const InvoicesList = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const [invoicesList, setInvoicesList] = useState<MCTInvoiceModel[]>([]);
  const [requestState, setRequestState] = useState<{
    loading: boolean;
    error: null | string;
  }>({ loading: true, error: null });
  const [loadingInvoiceId, setLoadingInvoiceId] = useState<number | null>(null);
  const [defaultFilters] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        field: "startingDate",
        operator: ">",
        value: moment(
          new Date(
            new Date().setFullYear(new Date().getFullYear() - 1),
          ).setHours(0, 0, 0, 0),
        ).format("YYYY-MM-DD"),
      },
      {
        id: 2,
        field: "endingDate",
        operator: "<",
        value: moment(new Date().setHours(23, 59)).format("YYYY-MM-DD"),
      },
    ],
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const filterItems: FilterItem[] = [
    {
      id: 1,
      field: "startingDate",
      label: "Date de début",
      type: "date",
      operator: ">",
    },
    {
      id: 2,
      field: "endingDate",
      label: "Date de fin",
      type: "date",
      operator: "<",
    },
    {
      id: 3,
      field: "number",
      label: "Numéro",
      type: "text",
      operator: "contains",
    },
    {
      id: 4,
      field: "amount",
      label: "Montant",
      type: "number",
      operator: "contains",
    },
    {
      id: 4,
      field: "type",
      label: "Ligne(s)",
      type: "chip",
      operator: "contains",
      options: ["Facture", "Avoir"],
    },
  ];

  useEffect(() => {
    // To set default filters (in order to show only filtered data by default)
    if (
      defaultFilters.items.length === 0 ||
      (defaultFilters.items.length > 0 &&
        filterModel.items.every(
          (item) => item.value === "" || item.value === undefined,
        ))
    ) {
      setFilterModel(defaultFilters);
    }
  }, [filterModel, defaultFilters]);

  const convertInvoiceType = (
    type: number,
  ): DocumentTypeModel[keyof DocumentTypeModel][] => {
    switch (type) {
      case 1:
        return ["Facture"];
      case 2:
        return ["Avoir"];
      case 3:
        return ["Bon de livraison"];
      case 4:
        return ["Bon de réception"];
      case 5:
        return ["Commande"];
      case 6:
        return ["Devis"];
      case 7:
        return ["MCT"];
      default:
        return ["Inconnu"];
    }
  };

  const fetchInvoicesList = async () => {
    setRequestState({ error: null, loading: true });
    try {
      const response: AxiosResponse<APIResponsesModel<MCTInvoiceModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/${CONFIG_API.DOCUMENT}/${CONFIG_API.MCT_DOCUMENT}`,
          {
            params: { [CONFIG_API.TYPES]: ["Invoice", "CreditNote"] } as {
              [key in typeof CONFIG_API.TYPES]: (keyof DocumentTypeModel)[];
            },
            paramsSerializer: (params) => {
              return new URLSearchParams(
                Object.entries(params).reduce((acc, [key, value]) => {
                  if (Array.isArray(value)) {
                    value.forEach((val) => acc.append(key, val));
                  } else {
                    acc.append(key, value as string);
                  }
                  return acc;
                }, new URLSearchParams()),
              ).toString();
            },
          },
        );
      if (response.data.success) {
        setInvoicesList(response.data.items.reverse());
      } else {
        setRequestState((prevState) => ({
          ...prevState,
          error: displayError(response.data.messages[0]),
        }));
      }
    } catch (error) {
      setRequestState((prevState) => ({
        ...prevState,
        error: "Une erreur est survenue lors du chargement des factures.",
      }));
    } finally {
      setRequestState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  // Declare columns of the grid (hidden columns only for filtering)
  const tableColumns: ColumnDefinition<RowData>[] = [
    { id: "startingDate", label: "", type: "date", hide: true },
    { id: "endingDate", label: "", type: "date", hide: true },
    { id: "displayedDate", label: "Date", flex: 1.5, type: "text" },
    { id: "number", label: "Numéro", flex: 1.5, type: "text" },
    { id: "type", label: "Type", flex: 1.5, type: "chip" },
    { id: "amount", label: "Montant", flex: 1.5, type: "text" },
    { id: "link", label: "", flex: 1, type: "link" },
  ];

  const openFile = (content: string, contentType: string) => {
    // Create a Blob with file content
    const blob = new Blob(
      [Uint8Array.from(atob(content), (c) => c.charCodeAt(0))],
      { type: contentType },
    );

    if (blob) {
      // Create URL with Blob
      const url = URL.createObjectURL(blob);

      // Open file in a new tab
      window.open(url);
    } else {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "failure",
          message: "Impossible de visionner cette facture.",
        }),
      );
    }
  };

  const getDocument = async (documentId: number) => {
    setLoadingInvoiceId(documentId);
    try {
      const response: AxiosResponse<APIResponseModel<MCTInvoiceFile>> =
        await api.get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.CENTER}/${currentCenter?.pid}/${CONFIG_API.DOCUMENT}/${CONFIG_API.MCT_DOCUMENT}/${documentId}`,
        );
      if (response.data.success && response.data.item) {
        const { content, contentType } = response.data.item;
        openFile(content, contentType);
      } else {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "failure",
            message:
              response.data.messages.length > 0
                ? displayError(response.data.messages[0])
                : "Impossible de visionner cette facture.",
          }),
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "failure",
          message: "Impossible de visionner cette facture.",
        }),
      );
    } finally {
      setLoadingInvoiceId(null);
    }
  };

  // Generate table rows
  const mapDataToRows = (data: MCTInvoiceModel[]): RowData[] => {
    return data.map((item) => ({
      id: item.id,
      startingDate: new Date(new Date(item.documentDate).setHours(0, 0, 0, 0)),
      endingDate: new Date(new Date(item.documentDate).setHours(23, 59)),
      displayedDate: `<strong>${moment(new Date(item.documentDate)).format("DD/MM/YYYY")}</strong>`,
      number: item.numDoc,
      type: convertInvoiceType(item.type),
      amount: `${item.amount}€`,
      link: {
        label:
          loadingInvoiceId === item.id ? (
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: "1rem",
              }}
            >
              <Spinner size="sm" />
            </Stack>
          ) : (
            "Visionner"
          ),
        onClick: () => {
          getDocument(item.id);
        },
      },
    }));
  };

  useEffect(() => {
    fetchInvoicesList();
  }, [currentCenter]);

  return (
    <>
      {requestState.error ? (
        <ErrorLoadingData
          errorMessage={requestState.error}
          retryFunction={fetchInvoicesList}
        />
      ) : requestState.loading ? (
        <LoadingData text="Chargement des factures..." />
      ) : (
        <ListComponent
          tableColumns={tableColumns}
          data={mapDataToRows(invoicesList)}
          filterItems={filterItems}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          defaultFilters={defaultFilters}
        />
      )}
    </>
  );
};

export default InvoicesList;
