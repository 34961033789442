import { Sidebar } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { AppDispatch, RootState } from "../../../store/store";
import { setStoredAppointmentData } from "../../../store/calendar/appointment-slice";
import useWindowWidth, {
  getBreakpointValue,
  tailwindBreakpoints,
} from "../../../hooks/layout/useWindowWidth";

import CustomIcon from "../CustomIcon";

export type NavItemModel = {
  label: string;
  icon?: string;
  iconColor?: string;
  navigateTo?: string;
  externalLink?: string;
  submenuItems?: NavItemModel[];
};

interface Props {
  title?: string;
  items: NavItemModel[];
  showMenuDrawer: { isOpen: boolean; isFullyOpen: boolean };
  toggleMenuDrawer: () => void;
}

const NavList = ({ title, items, showMenuDrawer, toggleMenuDrawer }: Props) => {
  const location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const isDesktop = useWindowWidth(getBreakpointValue(tailwindBreakpoints.lg));

  const storedAppointmentData = useSelector(
    (store: RootState) => store.APPOINTMENT.storedAppointmentData,
  );

  const handleClick = (menu: NavItemModel) => {
    // If data are stocked in the store (to create appointment from search)
    if (storedAppointmentData.data) {
      dispatch(
        setStoredAppointmentData({
          variant: null,
          data: null,
          isCenterChanged: false,
        }),
      );
    }
    if (menu.navigateTo) {
      navigate(menu.navigateTo);
      // Closing Nav Menu on mobile view
      if (!isDesktop) {
        toggleMenuDrawer();
      }
    } else if (menu.externalLink) {
      window.open(menu.externalLink, "_blank");
    }
  };

  return (
    <>
      {showMenuDrawer.isFullyOpen && (
        <div
          className={`text-gray-400 text-base font-medium font-['Inter'] leading-normal`}
        >
          {title}
        </div>
      )}
      {items.map((item: NavItemModel) =>
        item.submenuItems && item.submenuItems ? (
          showMenuDrawer.isFullyOpen ? (
            // Collapse Items when menu is opened
            <Sidebar.Collapse
              key={item.label}
              open={item.submenuItems.some(
                (menu) => menu.navigateTo === location.pathname,
              )}
              icon={
                showMenuDrawer.isFullyOpen
                  ? () => (
                      <CustomIcon
                        name={item.icon ?? ""}
                        color={item.iconColor ?? "#6b7280"}
                      />
                    )
                  : undefined
              }
              label={showMenuDrawer.isFullyOpen ? item.label : undefined}
            >
              {item.submenuItems.map((submenu) => (
                <Sidebar.Item
                  key={submenu.label}
                  active={submenu.navigateTo === location.pathname}
                  onClick={() => handleClick(submenu)}
                >
                  <span className="text-wrap">{submenu.label}</span>
                </Sidebar.Item>
              ))}
            </Sidebar.Collapse>
          ) : (
            // Collapse Items when menu is closed
            <Sidebar.Item
              key={item.label}
              active={item.submenuItems.some(
                (menu) => menu.navigateTo === location.pathname,
              )}
              icon={
                showMenuDrawer.isFullyOpen
                  ? () => <CustomIcon name={item.icon ?? ""} />
                  : undefined
              }
              onClick={() => handleClick(item)}
            >
              {showMenuDrawer.isFullyOpen ? (
                item.label
              ) : (
                <CustomIcon
                  name={item.icon ?? ""}
                  color={item.iconColor ?? "#6b7280"}
                />
              )}
            </Sidebar.Item>
          )
        ) : (
          // Regular items
          <Sidebar.Item
            key={item.label}
            active={item.navigateTo === location.pathname}
            icon={
              showMenuDrawer.isFullyOpen
                ? () => (
                    <CustomIcon
                      name={item.icon ?? ""}
                      color={item.iconColor ?? "#6b7280"}
                    />
                  )
                : undefined
            }
            onClick={() => handleClick(item)}
          >
            {showMenuDrawer.isFullyOpen ? (
              item.label
            ) : (
              <CustomIcon
                name={item.icon ?? ""}
                color={item.iconColor ?? "#6b7280"}
              />
            )}
          </Sidebar.Item>
        ),
      )}
    </>
  );
};

export default NavList;
