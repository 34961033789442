import { Badge } from "flowbite-react";
import moment from "moment";
import { cva } from "class-variance-authority";

import { AppointmentModel } from "../../models/AppointmentModel";

import InspectionResultChip from "../common/InspectionResultChip";

interface Props {
  data: AppointmentModel;
}

const EventTooltip = ({ data }: Props) => {
  const fullVehicle = `${
    data.inspection && data.inspection.vehicle
      ? (data.inspection.vehicle.brand ?? "") +
        " " +
        (data.inspection.vehicle.model ?? "")
      : (data.vehicle ?? "")
  }`;

  const displayedData = [
    {
      label: "Date de création",
      value: moment(data.creationDate).format("DD/MM/YYYY - HH:mm") ?? "",
    },
    {
      label: "Horaires du rendez-vous",
      value: !data.inspection
        ? `${moment(data.startDate).format("HH:mm")} - ${moment(data.endDate).format("HH:mm")}`
        : "",
    },
    { label: "Durée", value: "" },
    { label: "Nom", value: `${data.firstName} ${data.lastName}` },
    { label: "Téléphone", value: data.phoneNumber },
    { label: "E-mail", value: data.mail },
    { label: "Véhicule", value: fullVehicle !== " " ? fullVehicle : "" },
    {
      label: "Immatriculation",
      value:
        data.inspection && data.inspection.vehicle
          ? data.inspection.vehicle.registrationNumber
          : data.vehicleList
            ? (data.vehicleList[0].registrationNumber ?? "")
            : "",
    },
    {
      label: "Horaires du contrôle",
      value:
        data.inspection && data.inspection.startDate
          ? `${moment(data.inspection.startDate).format("HH:mm")} - ${moment(data.inspection.endDate).format("HH:mm")}`
          : "",
    },
    {
      label: "Heure de prise en charge",
      value:
        data.isCurrent && data.inspection && data.inspection.checkInHour
          ? `${data.inspection.checkInHour.slice(0, -3)}`
          : "",
    },
    {
      label: "Type du contrôle",
      value: data.inspection ? (data.inspection.conNatCtl ?? "") : "",
    },
    {
      label: "Résultat du contrôle",
      value: data.inspection && (
        <InspectionResultChip
          inspectionResult={
            data.isCurrent ? "current" : data.inspection.inspectionResult
          }
        />
      ),
    },
    {
      label: "Type du côntrôle du véhicule associé",
      value:
        data.vehicleList.length > 1
          ? data.vehicleList[1].inspectionTypeName
          : "",
    },
    {
      label: "Immatriculation du véhicule associé",
      value:
        data.vehicleList.length > 1
          ? data.vehicleList[1].registrationNumber
          : "",
    },
    {
      label: "Prix prestation",
      value: data.isHonored
        ? ""
        : data.inspection && data.inspection.invoice
          ? `${data.inspection.invoice.amountPaid}€`
          : data.price
            ? `${data.price}€`
            : "",
    },
    { label: "SMS de confirmation envoyé", value: data.isSmsConfirmation },
    { label: "SMS de rappel activé", value: data.isSmsReminder },
    {
      label: "Prix TTC",
      value:
        data.inspection && data.inspection.invoice
          ? `${data.inspection.invoice.amountTotalTTC}€`
          : "",
    },
    { label: "Commentaire", value: data.note ?? "" },
  ];

  // Using cva to prevent deleting useless classes by Tailwind
  const backgroundStyles = cva("", {
    variants: {
      color: {
        gray: "bg-gray-50",
        orange: "bg-orange-50",
        red: "bg-red-50",
        green: "bg-green-50",
        yellow: "bg-yellow-50",
        blue: "bg-blue-50",
        teal: "bg-teal-50",
        indigo: "bg-indigo-50",
        purple: "bg-purple-50",
        pink: "bg-pink-50",
      },
    },
  });
  const textStyles = cva("", {
    variants: {
      color: {
        gray: "text-gray-500",
        orange: "text-orange-500",
        red: "text-red-500",
        green: "text-green-500",
        yellow: "text-yellow-500",
        blue: "text-blue-500",
        teal: "text-teal-500",
        indigo: "text-indigo-500",
        purple: "text-purple-500",
        pink: "text-pink-500",
      },
    },
  });

  return (
    <div>
      {data.source?.name && (
        <Badge
          className={`w-fit font-['Inter'] h-5 px-2.5 py-0.5 mb-3 mx-auto ${backgroundStyles({ color: data.source?.defaultColorHsl })}`}
        >
          <div
            className={`text-xs font-medium font-['Inter'] leading-none text-center ${textStyles({ color: data.source?.defaultColorHsl })}`}
          >
            {data.source?.name}
          </div>
        </Badge>
      )}
      {displayedData.map(
        (data) =>
          data.value && (
            <div key={data.label}>
              {typeof data.value === "string" ? (
                <div className="text-sm font-['Inter'] overflow-hidden text-ellipsis">
                  <span className="text-sm font-['Inter'] font-bold text-pretty">
                    {data.label}
                  </span>{" "}
                  : {data.value}
                </div>
              ) : typeof data.value === "boolean" ? (
                <div
                  key={data.label}
                  className="text-sm font-['Inter'] flex flex-row items-center gap-0.5"
                >
                  <span className="font-bold">{data.label}</span>
                </div>
              ) : (
                <div
                  key={data.label}
                  className="flex flex-row text-sm font-['Inter'] items-center gap-0.5"
                >
                  <span className="text-sm font-bold font-['Inter']">
                    {data.label} :
                  </span>
                  {data.value}
                </div>
              )}
            </div>
          ),
      )}

      {data.onlineOptions.length > 0 &&
        data.onlineOptions.some(
          (option) => option.name.trim() !== "" || option.price.trim() !== "",
        ) && (
          <>
            <span className="text-sm font-bold font-['Inter']">Options :</span>
            {data.onlineOptions.map((option, index) => (
              <div key={`${option.name}${option.price}${index}"`}>
                <span className="text-sm">- {option.name}</span>
                {option.price && option.price.trim() !== "" && (
                  <span className="text-sm font-['Inter']">
                    {" "}
                    : {option.price}€
                  </span>
                )}
              </div>
            ))}
          </>
        )}
    </div>
  );
};

export default EventTooltip;
