import { useState } from "react";
import { AxiosResponse } from "axios";
import { Button } from "flowbite-react";
import { Stack, TextField, Typography } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../store/store";
import { api } from "../../utils/api";
import { APIResponseModel } from "../../models/ApiResponseModel";
import { CONFIG_API } from "../../data/config.API";
import { fetchOrganizationData } from "../../store/organization/organization-slice";

import LogoutButton from "../common/LogoutButton";

interface IFormInput {
  organizationCode: string;
}

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrganizationForm = ({ setShowModal }: Props) => {
  const { register, handleSubmit } = useForm<IFormInput>();
  const [errorValue, setErrorValue] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    await api
      .get(
        `/${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.CENTER}/${CONFIG_API.ORGANIZATION}/${data.organizationCode}/${CONFIG_API.ORGANIZATION_PID}`,
      )
      .then((response: AxiosResponse<APIResponseModel<string>>) => {
        if (response.data.item) {
          // To close the select organization modal
          dispatch(fetchOrganizationData(response.data.item));
          setShowModal(false);
        } else {
          // To show input error message
          setErrorValue(true);
        }
      });
  };

  return (
    <Stack width="100%">
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Typography variant="h6">
          {"Entrez le code d'une organisation"}
        </Typography>
        <TextField
          {...register("organizationCode")}
          error={errorValue}
          helperText={errorValue ? "Ce code est incorrect." : null}
          autoFocus
          required
          sx={{
            width: "50%",
            m: "auto",
            mb: "1rem",
          }}
        />
        <Stack flexDirection={"column"} gap={2} mx="auto">
          <Button type="submit" className="btn-base w-full m-auto">
            Valider
          </Button>
          <LogoutButton />
        </Stack>
      </form>
    </Stack>
  );
};

export default OrganizationForm;
