import { CSSProperties, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Chip,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Alert, Button, Spinner } from "flowbite-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faCircleXmark } from "@fortawesome/pro-duotone-svg-icons";

import { AppDispatch, RootState } from "../../../store/store";
import { generalSpacing } from "../../../utils/customTheme/customTheme";
import { setAlert } from "../../../store/layout/alert-slice";
import { api } from "../../../utils/api";
import { AccountType, UserInfoModel } from "../../../models/UserModel";
import { CONFIG_API } from "../../../data/config.API";
import { CenterModel } from "../../../models/CenterModel";
import { UserFormModel } from "./UserPage";
import { APIResponseModel } from "../../../models/ApiResponseModel";
import { displayError } from "../../../utils/layout/displayError";
import { iconStyle } from "../../common/SelectableChip";

import CustomModal from "../../layouts/CustomModal";
import UserAccess from "./UserAccess";
import useWindowWidth from "../../../hooks/layout/useWindowWidth";

interface Props {
  userData?: UserInfoModel;
  setSelectedUser?: React.Dispatch<React.SetStateAction<UserInfoModel | null>>;
  setDefaultCenter?: React.Dispatch<
    React.SetStateAction<CenterModel | undefined>
  >;
  setAllUsersList?: React.Dispatch<React.SetStateAction<UserInfoModel[]>>;
  handleCloseDrawer: () => void;
  userFormContent: UserFormModel;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>;
}

const UserForm = ({
  userData,
  setSelectedUser,
  setAllUsersList,
  setDefaultCenter,
  handleCloseDrawer,
  userFormContent,
  setRefreshKey,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const isDesktop = useWindowWidth();

  const organizationCenters = useSelector(
    (store: RootState) => store.CENTER.organizationCenters,
  );
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );

  const [error, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationState, setConfirmationState] = useState<{
    variant: null | "delete" | "remove";
    loading: boolean;
  }>({ variant: null, loading: false });
  const [isDefautCenter, setIsDefautCenter] = useState<boolean>(
    userData?.defaultCenterPid === currentCenter?.pid,
  );
  const [formContent, setFormContent] =
    useState<UserFormModel>(userFormContent);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<UserInfoModel>({
    defaultValues: {
      name: userData?.name ?? userData?.lastName,
      lastName: userData?.lastName,
      firstName: userData?.firstName,
      phoneNumber: userData?.phoneNumber,
      portableNumber: userData?.portableNumber,
      email: userData?.email,
      address1: userData?.address1,
      address2: userData?.address2,
      postalCode: userData?.postalCode,
      city: userData?.city,
    },
  });

  const userSave: SubmitHandler<UserInfoModel> = async (formData) => {
    setErrorMessage(null);
    setLoading(true);
    const organizationPid = organizationCenters
      ? organizationCenters[0].organizationPid
      : "";
    try {
      let updatedFormData =
        userData?.accountType === CONFIG_API.ADMIN
          ? formData
          : // Merge inputs data (formData) with others user data
            {
              ...userData,
              ...formData,
            };

      // Add current center in the list of user allowed centers
      const userCenters = Array.isArray(updatedFormData.allowedCentersPIDs)
        ? [...updatedFormData.allowedCentersPIDs]
        : [];
      if (currentCenter) {
        if (!userCenters.includes(currentCenter.pid)) {
          userCenters.push(currentCenter.pid);
        }
        // If default center chip is checked, update defaultCenterPid with currentCenter pid
        updatedFormData = {
          ...updatedFormData,
          allowedCentersPIDs: userCenters,
          defaultCenterPid: isDefautCenter
            ? currentCenter?.pid
            : updatedFormData.defaultCenterPid !== currentCenter.pid
              ? updatedFormData.defaultCenterPid
              : null,
          hasRelaunchAccess: formData.hasRelaunchAccess ?? false, // To return even if the center hasn't relaunch enabled
        };
        let response: AxiosResponse<APIResponseModel<UserInfoModel>>;
        if (userData) {
          response =
            userData?.accountType === CONFIG_API.ADMIN
              ? await api.put(
                  `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}`,
                  updatedFormData,
                )
              : await api.put(
                  `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.ACCOUNT_USER}/${userData.pid}`,
                  updatedFormData,
                );
        } else {
          response = await api.post(
            `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.ACCOUNT_USER}`,
            updatedFormData,
          );
        }
        if (response && response.data.success) {
          setSelectedUser &&
            setSelectedUser({
              ...response.data.item,
              lastName:
                userData?.accountType === CONFIG_API.ADMIN
                  ? response.data.item.name
                  : response.data.item.lastName,
              accountType:
                userData?.accountType === CONFIG_API.ADMIN
                  ? AccountType.Admin
                  : response.data.item.accountType,
            });
          setRefreshKey && setRefreshKey((prevKey) => prevKey + 1); // To refresh <UserAccess> checkboxes values in <UserPage>
          setDefaultCenter &&
            setDefaultCenter((prevList) =>
              isDefautCenter
                ? currentCenter
                : !response.data.item.defaultCenterPid
                  ? undefined
                  : prevList,
            );
          setAllUsersList &&
            setAllUsersList((prevList) => [
              ...prevList,
              { ...response.data.item, accountType: AccountType.User },
            ]);
          handleCloseDrawer();
          dispatch(
            setAlert({
              id: "global-alert",
              type: "success",
              message: `L'utilisateur a bien été ${userData ? "modifié" : "créé"}.`,
            }),
          );
        } else {
          setErrorMessage(displayError(response.data.messages[0]));
        }
      }
    } catch (error) {
      setErrorMessage(`Une erreur est survenue lors de l'enregistrement.`);
    } finally {
      setLoading(false);
    }
  };

  const userDelete = async (userPid: string) => {
    if (userData) {
      setErrorMessage(null);
      setConfirmationState({ ...confirmationState, loading: true });
      try {
        const organizationPid = organizationCenters
          ? organizationCenters[0].organizationPid
          : "";
        const response: AxiosResponse<APIResponseModel<boolean>> =
          await api.delete(
            `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.ACCOUNT_USER}/${userPid}`,
          );
        if (response.data.success) {
          dispatch(
            setAlert({
              id: "global-alert",
              type: "success",
              message: "L'utilisateur a bien été supprimé.",
            }),
          );
          setSelectedUser && setSelectedUser(null);
        } else {
          setErrorMessage(displayError(response.data.messages[0]));
        }
      } catch (error) {
        setErrorMessage("Une erreur est survenue lors de la suppression.");
      } finally {
        setConfirmationState({ variant: null, loading: false });
      }
    }
  };

  const userRemove = async () => {
    setConfirmationState({ ...confirmationState, loading: true });
    setErrorMessage(null);
    try {
      const othersUserCenters = userData?.allowedCentersPIDs.filter(
        (centerPid) => centerPid !== currentCenter?.pid,
      );
      const response: AxiosResponse<APIResponseModel<UserInfoModel>> =
        await api.put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${currentCenter?.organizationPid}/${CONFIG_API.ACCOUNT_USER}/${userData?.pid}`,
          {
            ...userData,
            allowedCentersPIDs: othersUserCenters,
          },
        );
      if (response.data.success) {
        dispatch(
          setAlert({
            id: "global-alert",
            type: "success",
            message: "L'utilisateur a bien été retiré du centre.",
          }),
        );
        setSelectedUser && setSelectedUser(null);
        handleCloseDrawer();
      } else {
        setErrorMessage(displayError(response.data.messages[0]));
      }
    } catch (error) {
      setErrorMessage("Une erreur est survenue.");
    } finally {
      setConfirmationState({ variant: null, loading: false });
    }
  };

  return (
    <>
      {userData && (
        <CustomModal
          showLogo={false}
          open={Boolean(confirmationState.variant)}
          padding={4}
          width={{ xs: "20rem", sm: "42rem" }}
          height="auto"
          textAlign="initial"
        >
          <Stack justifyContent="center" alignItems="center" gap={4}>
            <Typography className="title-card">
              Êtes-vous sûr de vouloir{" "}
              {confirmationState.variant === "delete" ? "supprimer" : "retirer"}{" "}
              cet utilisateur{" "}
              {confirmationState.variant === "remove" &&
                `du centre ${currentCenter?.name}`}{" "}
              ?
            </Typography>
            {confirmationState.variant === "delete" &&
              organizationCenters &&
              organizationCenters?.length > 1 && (
                <Alert color="warning">
                  Cet utilisateur sera supprimé de tous les centres qui lui sont
                  attribués.
                </Alert>
              )}
            <Stack flexDirection="row" gap={1}>
              <Button
                className="btn-base"
                onClick={() => {
                  confirmationState.variant === "delete"
                    ? userDelete(userData.pid)
                    : userRemove();
                }}
                disabled={confirmationState.loading}
              >
                {confirmationState.loading ? (
                  <Spinner size="sm" />
                ) : (
                  "Confirmer"
                )}
              </Button>
              <Button
                className="btn-base btn-outlined"
                onClick={() =>
                  setConfirmationState({ ...confirmationState, variant: null })
                }
              >
                Annuler
              </Button>
            </Stack>
          </Stack>
        </CustomModal>
      )}
      <Stack gap={generalSpacing}>
        <Typography fontWeight="bold">
          {formContent === "userInfo" ? "Coordonnées" : "Droits d'accès"}
        </Typography>

        <FormControl
          component="form"
          id="userForm"
          onSubmit={handleSubmit(userSave)}
          sx={{ width: "100%", gap: "0.5rem" }}
        >
          {formContent === "userInfo" ? (
            <>
              {userData?.accountType === CONFIG_API.ADMIN ? (
                // Organization's Name (For Admin Account)
                <TextField
                  {...register("name", {
                    required: "Veuillez renseigner un nom.",
                  })}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  onChange={() => {
                    Boolean(errors.name) && clearErrors("name");
                  }}
                  label="Nom"
                  fullWidth
                  autoComplete="off"
                  aria-autocomplete="none"
                  inputProps={{ "aria-autocomplete": "none" }}
                />
              ) : (
                <Stack flexDirection={"row"} gap={generalSpacing}>
                  <TextField
                    {...register("lastName", {
                      required: "Veuillez renseigner un nom.",
                      maxLength: {
                        value: 50,
                        message: "Le nom est limité à 50 caractères.",
                      },
                    })}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message}
                    onChange={() => {
                      Boolean(errors.lastName) && clearErrors("lastName");
                    }}
                    label="Nom"
                    fullWidth
                    autoComplete="off"
                    aria-autocomplete="none"
                    inputProps={{ maxLength: 50, "aria-autocomplete": "none" }}
                  />
                  <TextField
                    {...register("firstName", {
                      required: "Veuillez renseigner un nom.",
                      maxLength: {
                        value: 50,
                        message: "Le nom est limité à 50 caractères.",
                      },
                    })}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName?.message}
                    onChange={() => {
                      Boolean(errors.firstName) && clearErrors("firstName");
                    }}
                    label="Prénom"
                    fullWidth
                    autoComplete="off"
                    aria-autocomplete="none"
                    inputProps={{ maxLength: 50, "aria-autocomplete": "none" }}
                  />
                </Stack>
              )}

              <Stack flexDirection={"row"} gap={generalSpacing}>
                <TextField
                  {...register("phoneNumber", {
                    maxLength: {
                      value: 20,
                      message: "Le numéro est limité à 20 caractères.",
                    },
                  })}
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber?.message}
                  label="Téléphone"
                  fullWidth
                  autoComplete="off"
                  aria-autocomplete="none"
                  inputProps={{ maxLength: 20, "aria-autocomplete": "none" }}
                />
                <TextField
                  {...register("portableNumber", {
                    maxLength: {
                      value: 20,
                      message: "Le numéro est limité à 20 caractères.",
                    },
                  })}
                  error={Boolean(errors.portableNumber)}
                  helperText={errors.portableNumber?.message}
                  label="Portable"
                  fullWidth
                  autoComplete="off"
                  aria-autocomplete="none"
                  inputProps={{ maxLength: 20, "aria-autocomplete": "none" }}
                />
              </Stack>

              <TextField
                {...register("email", {
                  maxLength: {
                    value: 100,
                    message: "L'e-mail est limité à 100 caractères.",
                  },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                label="E-mail"
                fullWidth
                autoComplete="off"
                aria-autocomplete="none"
                inputProps={{ maxLength: 50, "aria-autocomplete": "none" }}
              />
              <TextField
                {...register("address1")}
                error={Boolean(errors.address1)}
                helperText={errors.address1?.message}
                label="Adresse 1"
                fullWidth
                autoComplete="off"
                aria-autocomplete="none"
                inputProps={{ "aria-autocomplete": "none" }}
              />
              <TextField
                {...register("address2")}
                error={Boolean(errors.address2)}
                helperText={errors.address2?.message}
                label="Adresse 2"
                fullWidth
                autoComplete="off"
                aria-autocomplete="none"
                inputProps={{ "aria-autocomplete": "none" }}
              />

              <Stack flexDirection={"row"} gap={generalSpacing}>
                <TextField
                  {...register("postalCode", {
                    maxLength: {
                      value: 10,
                      message: "Le code postal est limité à 20 caractères.",
                    },
                  })}
                  error={Boolean(errors.postalCode)}
                  helperText={errors.postalCode?.message}
                  label="Code postal"
                  fullWidth
                  autoComplete="off"
                  aria-autocomplete="none"
                  inputProps={{ maxLength: 10, "aria-autocomplete": "none" }}
                />
                <TextField
                  {...register("city")}
                  error={Boolean(errors.city)}
                  helperText={errors.city?.message}
                  label="Ville"
                  fullWidth
                  autoComplete="off"
                  aria-autocomplete="none"
                  inputProps={{ "aria-autocomplete": "none" }}
                />
              </Stack>

              {organizationCenters &&
                organizationCenters.length > 1 &&
                userData?.accountType !== CONFIG_API.ADMIN && (
                  <Chip
                    label="Centre par défaut"
                    clickable
                    onClick={() => setIsDefautCenter(!isDefautCenter)}
                    color={isDefautCenter ? "primary" : "default"}
                    sx={{
                      width: "11rem",
                      display: "flex",
                      justifyContent: "space-between",
                      color: isDefautCenter ? "white" : "black",
                      backgroundColor: isDefautCenter
                        ? "primary.main"
                        : "default",
                      marginTop: generalSpacing,
                      paddingRight: "0.3rem",
                      ".MuiChip-label": {
                        fontSize: "14px",
                        paddingRight: 1.3,
                      },
                      ".MuiChip-deleteIcon": {
                        fontSize: "1rem",
                        textAlign: "end",
                        color: isDefautCenter ? "white" : "black",
                        position: "sticky",
                      },
                    }}
                    onDelete={() => {
                      return null;
                    }}
                    deleteIcon={
                      <FontAwesomeIcon
                        icon={isDefautCenter ? faCircleXmark : faCirclePlus}
                        style={iconStyle as CSSProperties}
                        className={`rotate-icon ${isDefautCenter ? "rotated" : ""}`}
                      />
                    }
                  />
                )}
            </>
          ) : (
            <UserAccess user={userData} editMode={true} register={register} />
          )}

          <Stack
            sx={{
              width: "100%",
              position: "sticky",
              paddingY: generalSpacing,
              bottom: 0,
              gap: 2,
              backgroundColor: "white",
            }}
          >
            {error && (
              <Box>
                <Alert color="failure">{error}</Alert>
              </Box>
            )}
            <Stack width="fit-content" flexDirection="row" gap={1}>
              {!userData && (
                <Button
                  className="btn-base"
                  onClick={() => {
                    if (formContent === "userInfo") {
                      if (watch("lastName") === "") {
                        setError("lastName", {
                          message: "Veuillez renseigner un nom.",
                        });
                      } else if (watch("firstName") === "") {
                        setError("firstName", {
                          message: "Veuillez renseigner un prénom.",
                        });
                      } else {
                        setFormContent("userAccess");
                      }
                    } else {
                      setFormContent("userInfo");
                    }
                  }}
                >
                  {formContent === "userInfo" ? "Suivant >" : "< Précédent"}
                </Button>
              )}
              {(userData || (!userData && formContent === "userAccess")) && (
                <>
                  <Button className="btn-base" type="submit" disabled={loading}>
                    {loading ? <Spinner size="sm" /> : "Enregistrer"}
                  </Button>
                  {organizationCenters &&
                    organizationCenters?.length > 1 &&
                    formContent === "userInfo" && (
                      <Button
                        className="btn-base"
                        onClick={() =>
                          setConfirmationState({
                            ...confirmationState,
                            variant: "remove",
                          })
                        }
                      >
                        Retirer {isDesktop ? "du centre" : ""}
                      </Button>
                    )}
                </>
              )}
              {userData &&
                userFormContent !== "userAccess" &&
                userData?.accountType !== CONFIG_API.ADMIN && (
                  <Button
                    className="btn-base btn-danger"
                    onClick={() =>
                      setConfirmationState({
                        ...confirmationState,
                        variant: "delete",
                      })
                    }
                  >
                    {isDesktop ? (
                      "Supprimer"
                    ) : (
                      <FontAwesomeIcon icon={faTrash} />
                    )}
                  </Button>
                )}
            </Stack>
          </Stack>
        </FormControl>
      </Stack>
    </>
  );
};

export default UserForm;
