import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Alert } from "flowbite-react";
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  faArrowLeft,
  faCircleCheck,
  faCircleEllipsis,
  faCircleXmark,
  faEdit,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";

import { RootState } from "../../../store/store";
import customTheme, {
  colors,
  containerBorder,
  generalLetterSpacing,
  generalRadius,
  generalSpacing,
} from "../../../utils/customTheme/customTheme";
import { UserInfoModel } from "../../../models/UserModel";
import { convertAccountType } from "./UsersList";
import { APIResponsesModel } from "../../../models/ApiResponseModel";
import { api } from "../../../utils/api";
import { CONFIG_API } from "../../../data/config.API";
import { CenterModel } from "../../../models/CenterModel";
import { displayError } from "../../../utils/layout/displayError";
import useWindowWidth from "../../../hooks/layout/useWindowWidth";

import CTOConnectLogo from "../../../assets/images/icons/cto-connect.svg";
import InfoItem from "../../layouts/InfoItem";
import SidePanel from "../../layouts/SidePanel";
import CTonlineConnectForm from "./CTonlineConnectForm";
import UserForm from "./UserForm";
import UserAccess from "./UserAccess";
import CenterItem from "../../layouts/header/CenterItem";

interface Props {
  user: UserInfoModel;
  setSelectedUser: React.Dispatch<React.SetStateAction<UserInfoModel | null>>;
}

interface AccessLogModel {
  connexionDate: string;
  userName: string;
  status: string;
  error: string;
  ipAddress: string;
  os: string;
  browser: string;
}

interface UserActivityModel {
  label: string;
  value: string;
  error?: string;
  icon: IconDefinition;
}

export type UserFormModel = "userInfo" | "userAccess";

const UserPage = ({ user, setSelectedUser }: Props) => {
  const organizationCenters = useSelector(
    (store: RootState) => store.CENTER.organizationCenters,
  );

  const isDesktop = useWindowWidth();

  const [defaultCenter, setDefaultCenter] = useState<CenterModel | undefined>(
    () => {
      const matchedCenter = organizationCenters?.find(
        (center) => center.pid === user.defaultCenterPid,
      );
      if (matchedCenter) {
        return matchedCenter;
      } else {
        return undefined;
      }
    },
  );
  const [externalUserId, setExternalUserId] = useState<string | null>(
    user.externalUserId,
  );
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [sidePanelContent, setSidePanelContent] = useState<
    "CTonlineConnectForm" | "UserEditForm" | null
  >(null);
  const [userFormContent, setUserFormContent] =
    useState<UserFormModel>("userInfo");
  const [refreshKey, setRefreshKey] = useState(0);
  const [accessLogState, setAccessLogState] = useState<{
    loading: boolean;
    error: string | null;
  }>({ loading: false, error: null });
  const [userActivity, setUserActivity] = useState<UserActivityModel[]>();

  const fetchAccessLog = async () => {
    setAccessLogState({ loading: true, error: null });
    try {
      const response: AxiosResponse<APIResponsesModel<AccessLogModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE_CONNECT}/${CONFIG_API.USER}/${user.externalUserId}/accesslog`,
          { params: { [`${CONFIG_API.MAX_RESULTS}`]: 5 } },
        );
      if (response.data.success) {
        setAccessLogState({ ...accessLogState, loading: false });
        if (response.data.items.length > 0) {
          const lastFiveConnections: UserActivityModel[] = response.data.items
            .filter((item, index) => index <= 4)
            .map((item) => {
              return {
                label: item.connexionDate
                  ? moment(item.connexionDate).format(`DD/MM/YYYY - HH:mm`)
                  : "-",
                value: `${item.os && item.browser ? `${item.os} - ${item.browser}` : ""}`,
                icon: item.status ? faCircleCheck : faCircleXmark,
                error: item.error,
              };
            });
          setUserActivity(lastFiveConnections);
        } else {
          setUserActivity([
            {
              label: "",
              value: "Aucune connexion au cours des <b>30</b> derniers jours.",
              icon: faCircleEllipsis,
            },
          ]);
        }
      } else {
        setAccessLogState({
          ...accessLogState,
          loading: false,
          error: displayError(response.data.messages[0]),
        });
      }
    } catch (error) {
      setAccessLogState({
        ...accessLogState,
        loading: false,
        error: "Une erreur est survenue au chargement du journal.",
      });
    }
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setUserFormContent("userInfo");
  };

  useEffect(() => {
    externalUserId && !showDrawer && fetchAccessLog();
  }, [externalUserId]);

  const userInfo = [
    { label: "Nom", value: user.lastName },
    { label: "Prénom", value: user.firstName },
    { label: "Email", value: user.email },
    { label: "Téléphone", value: user.phoneNumber },
    { label: "Portable", value: user.portableNumber },
    { label: "Adresse", value: user.address1 },
    { label: "Code postal", value: user.postalCode },
    { label: "Ville", value: user.city },
  ];

  return (
    <>
      <SidePanel
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        title={
          sidePanelContent === "CTonlineConnectForm"
            ? "Compte CTonline Connect"
            : `Modification ${user.accountType === CONFIG_API.ADMIN ? "de l'administrateur" : "d'un utilisateur"} `
        }
        handleCloseDrawer={handleCloseDrawer}
      >
        {sidePanelContent === "CTonlineConnectForm" ? (
          <CTonlineConnectForm
            key={Date.now()}
            externalUserId={externalUserId}
            setExternalUserId={setExternalUserId}
            accountType={user.accountType ?? "User"}
            login={user.login}
            userPid={user.pid}
          />
        ) : (
          <UserForm
            key={Date.now()}
            userData={user}
            handleCloseDrawer={handleCloseDrawer}
            setSelectedUser={setSelectedUser}
            setDefaultCenter={setDefaultCenter}
            userFormContent={userFormContent}
            setRefreshKey={setRefreshKey}
          />
        )}
      </SidePanel>
      <Stack
        gap={generalSpacing}
        marginRight={generalSpacing}
        height="100vh"
        paddingLeft={{ xs: "1rem", lg: "initial" }}
      >
        <Stack
          flexDirection="row"
          alignItems={"center"}
          gap={1}
          marginTop={{ xs: generalSpacing, sm: 0 }}
          sx={{ cursor: "pointer" }}
          width={"fit-content"}
          onClick={() => {
            setSelectedUser(null);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <Typography fontWeight={700}>Retour aux utilisateurs</Typography>
        </Stack>

        <Stack gap={generalSpacing} width={"100%"}>
          <Stack
            flex={2}
            gap={{ xs: 2, lg: 0 }}
            flexDirection={{ xs: "column", lg: "row" }}
          >
            <Stack
              flexDirection={"row"}
              alignItems="center"
              gap={1.5}
              flex={1}
              flexWrap={"wrap"}
              margin={{ xs: "auto", lg: "initial" }}
            >
              <Typography variant="h2">
                {user.firstName} {user.lastName}
              </Typography>
              {convertAccountType(user.accountType ?? "User", "sm")}
              {externalUserId && (
                <img
                  src={CTOConnectLogo}
                  width={"30px"}
                  height={"40rem"}
                  alt="Logo CTonline Connect"
                  title="Cet utilisateur est relié à CTonline Connect"
                />
              )}
            </Stack>

            <Stack
              flexDirection={"row"}
              gap={1}
              justifyContent={{ xs: "center", lg: "initial" }}
            >
              <Button
                className="btn-base"
                onClick={() => {
                  setShowDrawer(true);
                  setSidePanelContent("CTonlineConnectForm");
                }}
              >
                CTonline Connect
              </Button>
              <Button
                className="btn-base"
                onClick={() => {
                  setShowDrawer(true);
                  setSidePanelContent("UserEditForm");
                }}
              >
                Modifier
              </Button>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          padding={{ xs: `0 0 5rem`, sm: generalSpacing }}
          borderRadius={generalRadius}
          border={containerBorder}
          bgcolor={"white"}
          gap={"1rem"}
          sx={{
            maxHeight: { xs: "70vh", "2xl": "78vh" },
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Stack flexDirection={{ xs: "column", sm: "row" }}>
            <Stack gap={"1rem"} flex={1}>
              <Stack padding={generalSpacing} gap={generalSpacing}>
                <Typography variant="h3" fontWeight={700}>
                  Coordonnées
                </Typography>
                <Grid
                  container
                  gap={2}
                  borderRadius={generalRadius}
                  border={containerBorder}
                  padding={generalSpacing}
                >
                  {userInfo.map(
                    (data) =>
                      data.value && (
                        <Grid key={data.label} xs={5} item>
                          <InfoItem title={data.label}>{data.value}</InfoItem>
                        </Grid>
                      ),
                  )}
                </Grid>
              </Stack>

              {organizationCenters && organizationCenters.length > 1 && (
                <Stack padding={generalSpacing} gap={generalSpacing}>
                  <Typography variant="h3" fontWeight={700}>
                    Paramètres
                  </Typography>
                  <Stack
                    borderRadius={generalRadius}
                    border={containerBorder}
                    padding={generalSpacing}
                  >
                    <Typography
                      color="#816796"
                      textAlign="left"
                      letterSpacing={generalLetterSpacing}
                      lineHeight={"15px"}
                      fontSize={"0.8rem"}
                      fontWeight={"500"}
                    >
                      CENTRE PAR DÉFAUT
                    </Typography>
                    {defaultCenter ? (
                      <div className="w-fit mt-1">
                        <CenterItem
                          centerName={defaultCenter.name}
                          centerColor={defaultCenter.centerColor}
                          centerActivities={defaultCenter.activities}
                          isCurrentCenter={false}
                          isChangeable={false}
                          isItem={false}
                        />
                      </div>
                    ) : (
                      <Typography>
                        {
                          "Cet utilisateur n'a aucun centre défini par défautrzererer."
                        }
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              )}

              {externalUserId && (
                <Stack padding={generalSpacing} gap={generalSpacing}>
                  <Typography variant="h3" fontWeight={700}>
                    Journal des connexions
                  </Typography>

                  {accessLogState.error ? (
                    <Alert color="failure" className="w-fit">
                      <Stack
                        width={"fit-content"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        gap={generalSpacing}
                      >
                        {accessLogState.error}
                        <Button className="btn-base" onClick={fetchAccessLog}>
                          Réessayer
                        </Button>
                      </Stack>
                    </Alert>
                  ) : userActivity ? (
                    <Stepper
                      orientation="vertical"
                      sx={{ width: { xs: "100%", sm: "50%" } }}
                      connector={
                        <Box
                          sx={{
                            borderLeft: `2px dashed ${customTheme.palette.primary.light}`,
                            minHeight: "30px",
                            marginLeft: "20px",
                          }}
                        />
                      }
                    >
                      {userActivity.map((activity, index) => (
                        <Step
                          key={activity.label + index}
                          active={false}
                          sx={{
                            "&.MuiStepConnector-vertical": {
                              marginLeft: "10rem",
                              width: "2rem",
                            },
                          }}
                        >
                          <StepLabel
                            StepIconComponent={() => (
                              <Tooltip
                                title={
                                  activity.error
                                    ? "La tentative de connexion a échoué."
                                    : activity.error === ""
                                      ? "Connexion réussie."
                                      : undefined
                                }
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    border: containerBorder,
                                    fontSize: "1.2rem",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={activity.icon}
                                    color={
                                      activity.error
                                        ? colors.palette.error.main
                                        : activity.error === ""
                                          ? colors.palette.success.main
                                          : "initial"
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography color="initial">
                                {activity.label}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: activity.value,
                                  }}
                                />
                              </Typography>
                            </Stack>
                          </StepLabel>
                          <StepContent>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: activity.value,
                              }}
                            />
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  ) : (
                    <Stack width={"50%"} gap={"2rem"}>
                      {[1, 2, 3, 4, 5].map((index) => (
                        <Stack
                          key={index}
                          flexDirection={"row"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Skeleton
                            variant="circular"
                            width={"3rem"}
                            height={"3rem"}
                          />
                          <Stack>
                            <Skeleton width="8rem" height="1.5rem" />
                            <Skeleton width="10rem" height="1.5rem" />
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>

            <Stack
              padding={generalSpacing}
              gap={generalSpacing}
              flex={1}
              height={"auto"}
            >
              <Typography variant="h3" fontWeight={700}>
                {"Droits d'accès"}
              </Typography>
              <Stack
                flexDirection={
                  user.accountType !== CONFIG_API.ADMIN ? "row-reverse" : "row"
                }
                justifyContent={"space-between"}
                borderRadius={generalRadius}
                border={containerBorder}
                padding={generalSpacing}
                gap={generalSpacing}
              >
                {user.accountType !== CONFIG_API.ADMIN && (
                  <Button
                    className="btn-base"
                    onClick={() => {
                      setUserFormContent("userAccess");
                      setShowDrawer(true);
                      setSidePanelContent("UserEditForm");
                    }}
                  >
                    {isDesktop ? "Modifier" : <FontAwesomeIcon icon={faEdit} />}
                  </Button>
                )}
                <UserAccess
                  user={user}
                  editMode={false}
                  key={refreshKey} // To refresh checkboxes values after update in <UserForm>
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default UserPage;
