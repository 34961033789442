import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import "@fortawesome/fontawesome-pro/css/all.min.css";

import { RootState } from "./store/store";
import { CONFIG_CTO } from "./data/config.CTO";
import { CONFIG_API } from "./data/config.API";

import Home from "./pages/Home";
import Calendar from "./pages/Calendar";
import ErrorPage from "./pages/ErrorPage";
import Administration from "./pages/Administration";
import IframeCTO from "./components/layouts/IframeCTO";
import Search from "./pages/Search";
import Logout from "./pages/Logout";
import CenterParameters from "./pages/parameters/center/CenterParameters";
import AppointmentsParameters from "./pages/parameters/calendar/AppointmentsParameters";
import PublicPortal from "./pages/parameters/marketing/PublicPortal";
import CenterCGV from "./pages/parameters/center/CenterCGV";
import ClosingsList from "./pages/parameters/calendar/ClosingsList";

function App() {
  const currentCenter = useSelector(
    (store: RootState) => store.CENTER.currentCenter,
  );
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);

  return (
    <>
      <CssBaseline />
      <Routes>
        {/* HOMEPAGE */}
        <Route path="/" element={<Home />} />

        {/* ADMINISTRATION DASHBOARD */}
        <Route
          path={`/${CONFIG_CTO.ADMINISTRATION_PATH}`}
          element={
            currentUser?.accountType === CONFIG_API.ADMIN ||
            currentUser?.hasInvoiceAccess ? (
              <Administration />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* SUPERVISE MENU */}
        <Route
          path={`/${CONFIG_CTO.DASHBOARDS_PATH}`}
          element={
            !currentCenter?.isLimited && currentUser?.hasDashboardAccess ? (
              <IframeCTO iframeData="PL2ForTDBTabDeBor" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`/${CONFIG_CTO.MANAGEMENT_PATH}`}
          element={
            !currentCenter?.isLimited && currentUser?.hasManagementAccess ? (
              <IframeCTO iframeData="PL2ForTDBGes" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`/${CONFIG_CTO.ACTIVITY_PATH}`}
          element={
            !currentCenter?.isLimited && currentUser?.hasActivityAccess ? (
              <IframeCTO iframeData="PL2ForTDBAct" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* PROFESSION MENU */}
        <Route
          path={`/${CONFIG_CTO.CALENDAR_PATH}`}
          element={
            !currentCenter?.isLimited ? (
              <Calendar />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* Marketing submenu */}
        <Route
          path={`/${CONFIG_CTO.REMINDER_PATH}`}
          element={
            currentUser?.accountType !== CONFIG_API.CLIENT &&
            currentCenter?.isRelaunchEnabled &&
            currentUser?.hasRelaunchAccess ? (
              <IframeCTO iframeData="PL2ForOutRel" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`/${CONFIG_CTO.IMPORT_PATH}_${CONFIG_CTO.REMINDER_PATH}`}
          element={
            currentUser?.accountType !== CONFIG_API.CLIENT &&
            currentCenter?.isRelaunchEnabled &&
            currentUser?.hasRelaunchAccess ? (
              <IframeCTO iframeData="PL2ForOutImport" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* DATA MENU */}

        {/* Customers */}
        <Route
          path={`/${CONFIG_CTO.CUSTOMERS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCustomerSettingsAccess ? (
              <IframeCTO iframeData="v2/GestionClients" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* Documents submenu */}
        <Route
          path={`/${CONFIG_CTO.INVOICES_PATH}`}
          element={
            currentUser?.hasDocumentAccess ? (
              <IframeCTO iframeData="PL2ForDocFacCustomer" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`/${CONFIG_CTO.ONLINE_PAYMENTS_PATH}`}
          element={
            currentCenter?.hasOnlinePaymentMCT &&
            currentUser?.accountType !== CONFIG_API.CLIENT &&
            currentUser?.hasDocumentAccess ? (
              <IframeCTO iframeData="PL2ForDocMct" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`/${CONFIG_CTO.REPORT_PATH}`}
          element={
            (currentUser?.accountType !== CONFIG_API.CLIENT &&
              currentUser?.hasDocumentAccess) ||
            (currentUser?.accountType === CONFIG_API.CLIENT &&
              currentUser?.hasPvAccess) ? (
              <IframeCTO iframeData="PL2ForDocPv" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`/${CONFIG_CTO.CERTIFICATES_PATH}`}
          element={
            currentUser?.accountType !== CONFIG_API.CLIENT &&
            currentUser?.hasDocumentAccess ? (
              <IframeCTO iframeData="PL2ForDocLog" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`/${CONFIG_CTO.OTHERS_DOCUMENTS_PATH}`}
          element={
            currentUser?.accountType !== CONFIG_API.CLIENT &&
            currentUser?.hasDocumentAccess ? (
              <IframeCTO iframeData="PL2ForDocAut" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* Export */}
        <Route
          path={`/${CONFIG_CTO.EXPORT_PATH}`}
          element={
            currentUser?.accountType !== CONFIG_API.CLIENT &&
            currentUser?.hasDocumentAccess ? (
              <IframeCTO iframeData="PL2ForTDBExp" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* Fleet submenu */}
        <Route
          path={`/${CONFIG_CTO.FLEET_PATH}`}
          element={
            !currentUser?.hasVehicleFleetAccess || currentCenter?.isLimited ? (
              <ErrorPage variant="forbidden" />
            ) : (
              <IframeCTO iframeData="v2/Vehicules" />
            )
          }
        />
        <Route
          path={`/${CONFIG_CTO.FLEET_PATH}_${CONFIG_CTO.HISTORY_PATH}`}
          element={
            !currentUser?.hasVehicleFleetAccess || currentCenter?.isLimited ? (
              <ErrorPage variant="forbidden" />
            ) : (
              <IframeCTO iframeData="v2/HistoriqueVehicules" />
            )
          }
        />

        {/* PARAMETERS MENU */}
        {/* Center Parameters submenu */}
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CONTACT_DETAILS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.accountType === CONFIG_API.ADMIN ? (
              <CenterParameters />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.TERMS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.accountType === CONFIG_API.ADMIN ? (
              <IframeCTO iframeData="v2/GestionEcheances" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.PAYMENTS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.accountType === CONFIG_API.ADMIN ? (
              <IframeCTO iframeData="v2/GestionReglements" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.BANKS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.accountType === CONFIG_API.ADMIN ? (
              <IframeCTO iframeData="v2/GestionBanques" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CGV_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <CenterCGV />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* Planning Parameters submenu */}
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.APPOINTMENTS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <AppointmentsParameters />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.SCHEDULES_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <IframeCTO iframeData="v2/PlagesHoraires" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CLOSINGS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <ClosingsList />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.LINES_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <IframeCTO iframeData="v2/Lignes" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* Operations Parameters submenu */}
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.OPERATION_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.accountType === CONFIG_API.ADMIN ? (
              <IframeCTO iframeData="v2/GestionPrestations" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.CENTER_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <IframeCTO iframeData="v2/Prestations" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* Marketing Parameters submenu */}
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.PUBLIC_PORTAL_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.accountType === CONFIG_API.ADMIN ? (
              <PublicPortal />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.OPTIONS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.accountType === CONFIG_API.ADMIN ? (
              <IframeCTO iframeData="v2/GestionOptions" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.DISCOUNTS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <IframeCTO iframeData="v2/Reductions" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.SMS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <IframeCTO iframeData="v2/Sms" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.REMINDER_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <IframeCTO iframeData="v2/Relances" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route
          path={`${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.REMINDER_PATH}_${CONFIG_CTO.YEARS_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.hasCenterSettingsAccess ? (
              <IframeCTO iframeData="v2/Relances3A" />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />

        {/* OTHERS PAGES */}
        <Route
          path={`/${CONFIG_CTO.SEARCH_PATH}`}
          element={
            !currentCenter?.isLimited &&
            currentUser?.accountType !== CONFIG_API.CLIENT ? (
              <Search />
            ) : (
              <ErrorPage variant="forbidden" />
            )
          }
        />
        <Route path={`/${CONFIG_CTO.LOGOUT_PATH}`} element={<Logout />} />
        <Route path={`/${CONFIG_CTO.CONNECT_CHECK_PATH}`} element={<></>} />
        <Route path="*" element={<ErrorPage variant={"unknown"} />} />
      </Routes>
    </>
  );
}

export default App;
