import { createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { api } from "../../utils/api";
import { AppDispatch, RootState } from "../store";
import { CONFIG_API } from "../../data/config.API";
import { AppointmentModel } from "../../models/AppointmentModel";
import {
  APIResponseModel,
  APIResponsesModel,
} from "../../models/ApiResponseModel";
import { displayError } from "../../utils/layout/displayError";
import { PartnerModel } from "../../models/CenterSettingsModel";

export interface InitialStateModel {
  storedAppointmentData: {
    variant: "cut" | "copy" | null;
    data: AppointmentModel | null;
    isCenterChanged: boolean;
  };
  loading: {
    firstLoading: boolean;
    regularLoading: boolean;
    pasteLoading: boolean;
  };
  createLoading: boolean;
  error: string | null;
}

const initialState: InitialStateModel = {
  storedAppointmentData: { variant: null, data: null, isCenterChanged: false },
  loading: { firstLoading: true, regularLoading: true, pasteLoading: false },
  createLoading: false,
  error: null,
};

// SLICE
export const appointmentSlice = createSlice({
  name: "appointmentSlice",
  initialState: initialState,
  reducers: {
    setStoredAppointmentData: (currentSlice, action) => {
      currentSlice.storedAppointmentData = action.payload;
    },
    setFirstLoading: (currentSlice, action) => {
      currentSlice.loading = {
        ...currentSlice.loading,
        firstLoading: action.payload,
      };
    },
    setRegularLoading: (currentSlice, action) => {
      currentSlice.loading = {
        ...currentSlice.loading,
        regularLoading: action.payload,
      };
    },
    setPasteLoading: (currentSlice, action) => {
      currentSlice.loading = {
        ...currentSlice.loading,
        pasteLoading: action.payload,
      };
    },
    setCreateLoading: (currentSlice, action) => {
      currentSlice.createLoading = action.payload;
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload;
    },
  },
});

export const {
  setStoredAppointmentData,
  setRegularLoading,
  setFirstLoading,
  setCreateLoading,
  setPasteLoading,
  setError,
} = appointmentSlice.actions;

export const createCenterAppointment =
  (organizationPid: string, centerPid: string, formData: AppointmentModel) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setCreateLoading(true));
    const centerPartners: PartnerModel[] | undefined =
      getState().CENTER.centerPartners;
    try {
      // Format formData to API request format
      const updatedFormData = {
        startDate: formData.startDate,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        vehicleList: formData.vehicleList,
        clientPid: formData.clientPid,
        lineId: formData.lineId,
        firstName: formData.firstName,
        isSmsConfirmation: formData.isSmsConfirmation,
        isSmsReminder: formData.isSmsReminder,
        isMailConfirmation: formData.isMailConfirmation,
        mail: formData.mail,
        isParking: formData.isParking,
        isValet: formData.isValet,
        isLoanCar: formData.isLoanCar,
        isTruckTrailer: formData.isTruckTrailer,
        isToLoad: formData.isToLoad,
        note: formData.note,
        commandNumber: formData.commandNumber,
        price: formData.price,
        partnerId: formData.partnerId,
        vehicle: formData.vehicle,
        promoCode: formData.promoCode,
      };
      const response: AxiosResponse<APIResponseModel<AppointmentModel>> =
        await api.post(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.APPOINTMENT}`,
          updatedFormData,
        );
      if (response.data.success) {
        const updatedNewAppointment = {
          ...response.data.item,
          name: `${response.data.item.lastName} ${response.data.item.firstName}`,
          resourceId: response.data.item.lineId,
          source: centerPartners?.find(
            (partner) => partner.internalCode === response.data.item.partnerId,
          ),
          duration:
            response.data.item.totalDuration
              .split(":")
              .map(Number)
              .reduce((acc, time, i) => acc + time * [3600, 60, 1][i], 0) /
            86400,
          durationUnit: "day",
        };
        return { success: true, item: updatedNewAppointment, messages: [] };
      } else {
        dispatch(setError(displayError(response.data.messages[0])));
        return { success: false, item: null, messages: response.data.messages };
      }
    } catch (error: unknown) {
      dispatch(setError("Une erreur est survenue."));
      return {
        success: false,
        item: null,
        messages: [{ type: "Error", message: "Une erreur est survenue." }],
      };
    } finally {
      dispatch(setCreateLoading(false));
    }
  };

export const updateCenterAppointment =
  (
    organizationPid: string,
    centerPid: string,
    appointmentPid: string,
    formData: AppointmentModel,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setCreateLoading(true));
    try {
      const updatedFormData = {
        startDate: formData.startDate,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        vehicleList: formData.vehicleList,
        clientPid: formData.clientPid,
        lineId: formData.lineId,
        firstName: formData.firstName,
        isSmsConfirmation: formData.isSmsConfirmation,
        isSmsReminder: formData.isSmsReminder,
        isMailConfirmation: formData.isMailConfirmation,
        mail: formData.mail,
        isParking: formData.isParking,
        isValet: formData.isValet,
        isLoanCar: formData.isLoanCar,
        isTruckTrailer: formData.isTruckTrailer,
        isToLoad: formData.isToLoad,
        note: formData.note,
        commandNumber: formData.commandNumber,
        price: formData.price,
        partnerId: formData.partnerId,
        vehicle: formData.vehicle,
        promoCode: formData.promoCode,
        isHonored: formData.isHonored,
      };
      const response: AxiosResponse<APIResponseModel<AppointmentModel>> =
        await api.put(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.APPOINTMENT}/${appointmentPid}`,
          updatedFormData,
        );
      if (!response.data.success) {
        dispatch(setError(displayError(response.data.messages[0])));
      }
      return response.data;
    } catch (error: unknown) {
      dispatch(setError("Une erreur est survenue."));
      return {
        success: false,
        item: null,
        messages: [{ type: "Error", message: "Une erreur est survenue." }],
      };
    } finally {
      dispatch(setCreateLoading(false));
    }
  };

export const moveAppointmentFromAnotherCenter =
  (
    organizationPid: string,
    previousCenterPid: string,
    appointmentPid: string,
    newStartDate: string,
    newLineId: number,
    newCenterPid: string,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse<APIResponseModel<AppointmentModel>> =
        await api.put(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${previousCenterPid}/${CONFIG_API.APPOINTMENT}/${appointmentPid}/move?${"lineId"}=${newLineId}&${"startDate"}=${newStartDate}&${"newCenterPid"}=${newCenterPid}`,
        );
      return response.data;
    } catch (error: unknown) {
      dispatch(setError("Une erreur est survenue."));
      return {
        success: false,
        item: null,
        messages: [{ type: "Error", message: "Une erreur est survenue." }],
      };
    } finally {
      dispatch(setCreateLoading(false));
    }
  };

export const fetchOtherVehicleAppointment =
  (
    organizationPid: string,
    centerPid: string,
    registrationNumber: string,
    appointmentPid: string,
  ) =>
  async () => {
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

    const response: AxiosResponse<APIResponsesModel<AppointmentModel>> =
      await api.get(
        `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.APPOINTMENT}/${CONFIG_API.REGISTRATION_NUMBER}?registrationNumber=${registrationNumber}&dateFrom=${twoMonthsAgo.toISOString().split("T")[0]}&dateTo=${new Date().toISOString().split("T")[0]}`,
      );

    if (
      response.data.items.length > 0 &&
      response.data.items.some(
        (item: AppointmentModel) =>
          !item.isHonored && item.pid !== appointmentPid,
      )
    ) {
      const unhonoredAppointments: AppointmentModel[] =
        response.data.items.filter((item: AppointmentModel) => !item.isHonored);
      return unhonoredAppointments[0];
    } else {
      return false;
    }
  };
