import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FlowbiteStateColors } from "flowbite-react";

export interface AlertModel {
  id: string;
  type: keyof FlowbiteStateColors;
  message: string;
}

interface AlertState {
  alerts: AlertModel[];
}

const initialState: AlertState = {
  alerts: [],
};

export const alertSlice = createSlice({
  name: "alertSlice",
  initialState,
  reducers: {
    setAlert: (currentSlice, action: PayloadAction<AlertModel>) => {
      currentSlice.alerts.push(action.payload);
    },
    clearAlert: (state, action) => {
      // Filter alerts to remove the one with specific id
      state.alerts = state.alerts.filter(
        (alert) => alert.id !== action.payload,
      );
    },
  },
});

export const { setAlert, clearAlert } = alertSlice.actions;
