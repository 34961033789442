import { NavigateFunction, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState, useCallback } from "react";
import { Box, Stack } from "@mui/material";
import { Alert, Button, Spinner } from "flowbite-react";
import { BryntumCalendar } from "@bryntum/calendar-react";
import moment from "moment";
import { AxiosResponse } from "axios";
import "@bryntum/calendar/calendar.material.css";

import { AppDispatch, RootState } from "../../store/store";
import { capitalizeString } from "../../utils/layout/capitalizeString";
import {
  setError,
  setFirstLoading,
  setRegularLoading,
  setStoredAppointmentData,
} from "../../store/calendar/appointment-slice";
import {
  calculateClosingDates,
  fetchCenterClosings,
  fetchCenterPartners,
  fetchCenterSchedules,
  fetchVirtualTimeRanges,
  setCenterTimeRanges,
} from "../../store/center/center-slice";
import { fetchOrganizationAccountCustomers } from "../../store/center/customer-slice";
import { fetchVehicles } from "../../store/fleet/fleet-slice";
import {
  AppointmentModel,
  InspectionModel,
} from "../../models/AppointmentModel";
import {
  ClosingModel,
  LineModel,
  ScheduleModel,
} from "../../models/CenterSettingsModel";
import {
  ResourceModel,
  TimeRangeModel,
  ViewModel,
} from "../../models/CalendarModel";
import { api } from "../../utils/api";
import { CONFIG_CTO } from "../../data/config.CTO";
import { CONFIG_API } from "../../data/config.API";
import { APIResponsesModel } from "../../models/ApiResponseModel";
import { generateTimeRangesSchedules } from "../../utils/calendar/generateTimeRangesSchedules";
import { setAlert } from "../../store/layout/alert-slice";
import customTheme from "../../utils/customTheme/customTheme";
import useWindowWidth from "../../hooks/layout/useWindowWidth";

import CalendarConfig from "../../utils/calendar/calendarConfig";
import EventEditor from "./EventEditor/EventEditor";
import LoadingData from "../common/LoadingData";
import CalendarToolbar from "./CalendarHeader/CalendarToolbar";
import AlertMessage from "../layouts/AlertMessage";
import ErrorLoadingData from "../common/ErrorLoadingData";

declare global {
  interface Window {
    handleNextDayClick?: () => void;
    handlePreviousDayClick?: () => void;
  }
}

const CalendarComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const calendarRef = useRef<BryntumCalendar>(null);

  const appointmentState = useSelector((store: RootState) => store.APPOINTMENT);
  const currentOrganization = useSelector(
    (store: RootState) => store.ORGANIZATION.currentOrganization,
  );
  const centerState = useSelector((store: RootState) => store.CENTER);
  const currentUser = useSelector((store: RootState) => store.USER.currentUser);
  const allPartnersList = useSelector(
    (store: RootState) => store.PARTNER.allPartnersList,
  );

  const isDesktop = useWindowWidth(customTheme.breakpoints.values.lg);
  // Get the stored view in sessionStorage if there is one
  const storedActiveView = sessionStorage.getItem(
    "calendarActiveView",
  ) as ViewModel | null;

  const [isError, setIsError] = useState<
    null | "appointmentError" | "schedulesError"
  >(null);
  const [resources, setResources] = useState<ResourceModel[] | undefined>(
    undefined,
  );
  const [activeDate, setActiveDate] = useState<Date>(() => {
    // Set the stored view in sessionStorage if there is one, otherwise set the current date
    const storedDate = sessionStorage.getItem("calendarActiveDate");
    return storedDate ? moment(storedDate, "DD/MM/YYYY").toDate() : new Date();
  });
  const [activeView, setActiveView] = useState<ViewModel>(
    storedActiveView
      ? storedActiveView
      : isDesktop
        ? "weekResource"
        : "dayResource",
  );
  const [fitHours, setFitHours] = useState<boolean>(false);
  const [displayedDate, setDisplayedDate] = useState<Date | string | undefined>(
    new Date(),
  );
  const [dateRange, setDateRange] = useState<
    { startDate: Date; endDate: Date } | undefined
  >(undefined);
  const [chosenClosingData, setChosenClosingData] = useState<
    ClosingModel | undefined
  >();
  const [centerSchedules, setCenterSchedules] = useState<
    ScheduleModel[] | undefined
  >(undefined);
  const [isOverlapAlertShown, setIsOverlapAlertShown] =
    useState<boolean>(false);
  const [storedSlots, setStoredSlots] = useState<TimeRangeModel[]>([]);
  const [keyNumber, setKeyNumber] = useState<number>(0);

  const fetchDateRangeClosings = async (
    organizationPid: string,
    centerPid: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    const response = (await dispatch(
      fetchCenterClosings(organizationPid, centerPid, dateFrom, dateTo),
    )) as APIResponsesModel<ClosingModel> | false;
    if (response) {
      const newClosings: TimeRangeModel[] = response.items.flatMap(
        (closing) => {
          return closing.lines.flatMap((line) => {
            return calculateClosingDates(
              { ...closing, lines: [line] },
              dateFrom,
              dateTo,
              centerState.centerSchedules.maxHour,
              currentUser?.accountType,
            );
          });
        },
      );
      if (newClosings && centerSchedules) {
        const currentTimeRanges = dispatch(
          fetchVirtualTimeRanges(
            newClosings,
            centerSchedules,
            dateFrom,
            dateTo,
          ),
        );
        const updatedTimeRanges = centerState.centerTimeRanges?.filter(
          (timerange) => timerange.type === "schedule",
        );
        currentTimeRanges?.forEach((timerange) => {
          // To avoid duplicate timerange or negative timerange duration (if starting hour is after ending hour)
          if (
            !updatedTimeRanges?.some((item) => item.id === timerange.id) &&
            !updatedTimeRanges?.some((item) => item.id === timerange.id) &&
            moment(timerange.endDate) > moment(timerange.startDate)
          ) {
            updatedTimeRanges?.push(timerange);
          }
        });

        if (updatedTimeRanges) {
          setStoredSlots(
            updatedTimeRanges.filter((timerange) => timerange.type === "step"),
          );
          if (currentUser?.accountType !== CONFIG_API.CLIENT) {
            // Remove timeranges from the store (in case of external update and to not stored too much timeranges)
            calendarRef.current?.instance.resourceTimeRangeStore.allRecords.forEach(
              (record) => {
                calendarRef.current?.instance.resourceTimeRangeStore.remove(
                  record,
                );
              },
            );
          }
          calendarRef.current?.instance.resourceTimeRangeStore.add(
            updatedTimeRanges,
          );
        }
      }
    }
  };

  const getAppointments = useCallback(() => {
    setIsError(null);
    !appointmentState.loading.firstLoading && dispatch(setFirstLoading(true));
    if (
      currentOrganization &&
      centerState.currentCenter &&
      centerState.centerLines
    ) {
      dispatch(
        fetchCenterPartners(
          currentOrganization.pid,
          centerState.currentCenter.pid,
        ),
      );
      setResources(
        centerState.centerLines.map((element: LineModel) => ({
          id: element.lineNumber,
          name: element.label,
        })),
      );
      let daysToAdd = 6;
      if (storedActiveView === "dayResource") {
        daysToAdd = 0;
      } else if (storedActiveView === "twoWeeksResource") {
        daysToAdd = 13;
      }
      const formattedActiveDate = moment(activeDate).format("YYYY-MM-DD");
      const endOfTheWeek = moment(activeDate)
        .add(daysToAdd, "days")
        .format("YYYY-MM-DD");

      fetchDateRangeClosings(
        currentOrganization?.pid,
        centerState.currentCenter?.pid,
        formattedActiveDate,
        endOfTheWeek,
      );
      const successAppointmentLoading = fetchCenterAppointments(
        currentOrganization?.pid,
        centerState.currentCenter.pid,
        false,
        formattedActiveDate,
        endOfTheWeek,
      );

      dispatch(
        fetchOrganizationAccountCustomers(
          currentOrganization?.pid,
          centerState.currentCenter?.pid,
        ),
      );
      dispatch(fetchVehicles(currentOrganization?.pid));

      if (!successAppointmentLoading) {
        setIsError("appointmentError");
      } else if (currentUser?.accountType !== CONFIG_API.CLIENT) {
        fetchCenterAppointments(
          currentOrganization?.pid,
          centerState.currentCenter.pid,
          false,
          moment(activeDate).subtract(daysToAdd, "days").format("YYYY-MM-DD"),
          moment(activeDate).add(daysToAdd, "days").format("YYYY-MM-DD"),
        );
      }
      dispatch(setFirstLoading(false));
    }
  }, [
    setIsError,
    dispatch,
    currentOrganization,
    centerState.currentCenter,
    centerState.centerLines,
    centerState.centerTimeRanges,
  ]);

  const getSchedulesTimeRanges = useCallback(
    async (schedules: ScheduleModel[]) => {
      if (currentOrganization && centerState.currentCenter) {
        const { minHour, maxHour, nonWorkingDays } =
          centerState.centerSchedules;
        if (schedules && minHour && maxHour && nonWorkingDays) {
          const schedulesTimeRanges = generateTimeRangesSchedules(
            schedules,
            centerState.centerLines,
            minHour,
            maxHour,
            nonWorkingDays,
          );
          if (centerState.centerTimeRanges) {
            const updatedTimeRanges: TimeRangeModel[] = [];
            schedulesTimeRanges?.forEach((timeRange: TimeRangeModel) => {
              if (
                !centerState.centerTimeRanges?.some(
                  (item) => item.id === timeRange.id,
                )
              ) {
                updatedTimeRanges?.push(timeRange);
              }
              dispatch(setCenterTimeRanges(updatedTimeRanges));
            });
          } else {
            dispatch(setCenterTimeRanges(schedulesTimeRanges));
          }
        }
      }
    },
    [currentOrganization, centerState, dispatch],
  );

  useEffect(() => {
    const loadAppointments = (
      isBackgroundRequest: boolean,
      startDate: string,
      endDate: string,
    ) => {
      try {
        if (currentOrganization && centerState.currentCenter) {
          const success = fetchCenterAppointments(
            currentOrganization?.pid,
            centerState.currentCenter?.pid,
            isBackgroundRequest,
            startDate,
            endDate,
          );
          return success;
        }
      } catch (error) {
        dispatch(
          setAlert({
            id: "calendar-alert",
            type: "failure",
            message:
              "Une erreur est survenue lors du chargement des rendez-vous.",
          }),
        );
      }
    };
    const loadClosings = async (startDate: string, endDate: string) => {
      try {
        if (currentOrganization && centerState.currentCenter) {
          await fetchDateRangeClosings(
            currentOrganization?.pid,
            centerState.currentCenter?.pid,
            startDate,
            endDate,
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            id: "calendar-alert",
            type: "failure",
            message:
              "Une erreur est survenue lors du chargement des fermetures.",
          }),
        );
      }
    };

    // Initialize a delay before loading events to allow the state to stabilize (to avoid useless requests due to Bryntum date update sync)
    const delayDebounceFn = setTimeout(async () => {
      if (dateRange) {
        // Store the date in sessionStorage to preserve state across sessions
        sessionStorage.setItem(
          "calendarActiveDate",
          moment(dateRange.startDate).format("DD/MM/YYYY"),
        );

        // To format a date into an ISO string without considering timezone (Contrary to toISOString())
        const formatDateISO = (date: moment.Moment) =>
          date.format("YYYY-MM-DD");

        // Function to get formatted strings for both start and end dates
        const getFormattedDateRange = (
          startDate: moment.Moment,
          endDate: moment.Moment,
        ) => ({
          startDate: formatDateISO(startDate),
          endDate: formatDateISO(endDate),
        });

        let daysToAdd = 7;
        if (storedActiveView === "dayResource") {
          daysToAdd = 0;
        } else if (storedActiveView === "twoWeeksResource") {
          daysToAdd = 14;
        }

        // Calculate date ranges
        const startDateCurrent = moment(dateRange.startDate);
        const endDateCurrent = moment(dateRange.endDate).subtract(1, "days");

        // Get formatted date ranges
        const formattedCurrent = getFormattedDateRange(
          startDateCurrent,
          endDateCurrent,
        );
        const formattedLarge = getFormattedDateRange(
          moment(dateRange.startDate).subtract(daysToAdd, "days"),
          moment(dateRange.endDate).add(daysToAdd, "days"),
        );

        loadClosings(
          formatDateISO(startDateCurrent),
          formatDateISO(endDateCurrent),
        );

        // Load current week's appointments
        loadAppointments(
          false,
          formattedCurrent.startDate,
          formattedCurrent.endDate,
        )?.then((success) => {
          if (success && currentUser?.accountType !== CONFIG_API.CLIENT) {
            // After loading the current week's appointments, load previous and next weeks/days in background
            loadAppointments(
              true,
              formattedLarge.startDate,
              formattedLarge.endDate,
            );
          }
        });
      }
    }, 200);

    // Cleanup function to cancel the timeout if the effect is cleaned up or if calendarConfig changes before the delay elapses
    return () => clearTimeout(delayDebounceFn);
  }, [dateRange]);

  const fetchCenterAppointments = async (
    organizationPid: string,
    centerPid: string,
    isBackgroundRequest: boolean,
    dateFrom?: string,
    dateTo?: string,
  ) => {
    dispatch(setError(null));
    dispatch(setRegularLoading(true));

    try {
      const response: AxiosResponse<APIResponsesModel<AppointmentModel>> =
        await api.get(
          currentUser?.accountType !== CONFIG_API.CLIENT
            ? `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.APPOINTMENT}?dateFrom=${dateFrom}&dateTo=${dateTo}`
            : `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.APPOINTMENT}/Customer/${currentUser.pid}?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        );
      if (response.data.success && response.data.items) {
        const eventStore = calendarRef.current?.instance.eventStore;
        const updatedData: AppointmentModel[] = [];
        const existingIdsMap = new Map<number | string, number>();

        const appointmentsList =
          currentUser?.accountType !== CONFIG_API.CLIENT
            ? response.data.items
            : response.data.items.filter(
                (item: AppointmentModel) => item.clientPid === currentUser?.pid,
              );

        if (
          currentUser?.accountType === CONFIG_API.CLIENT &&
          !isBackgroundRequest
        ) {
          const othersAppointments: TimeRangeModel[] = [];

          response.data.items
            .filter(
              (item: AppointmentModel) => item.clientPid !== currentUser?.pid,
            )
            .forEach((appointment) => {
              othersAppointments.push({
                id: `app${appointment.startDate.replace(/-|T|:|\..*|\+.*/g, "").slice(6, 12)}${appointment.endDate?.replace(/-|T|:|\..*|\+.*/g, "").slice(6, 12)}${appointment.lineId}${appointment.pid.slice(24, 36)}`,
                resourceId: appointment.lineId ?? 1,
                startDate: appointment.startDate,
                endDate: appointment.endDate,
                isAppointmentAllowed: false,
                type: "appointment",
                cls: "closedTimeRanges",
              });
            });

          // Reset all appointment timeranges before update with the new ones
          calendarRef.current?.instance.resourceTimeRangeStore.allRecords.forEach(
            (item) => {
              if (item.getData("type") === "appointment") {
                calendarRef.current?.instance.resourceTimeRangeStore.remove(
                  item,
                );
              }
            },
          );
          calendarRef.current?.instance.resourceTimeRangeStore.add(
            othersAppointments,
          );
        }

        // Transform each appointment into Bryntum event format
        appointmentsList.forEach((element) => {
          const updatedElement = {
            ...element, // Keep the other properties of the object as they are
            name: `${element.lastName} ${element.firstName}`,
            resourceId: element.lineId,
            source: allPartnersList?.find(
              (partner) => partner.internalCode === (element.partnerId ?? "0"),
            ),
            eventColor: "transparent",
            partnerId: element.partnerId ?? "0",
            vehicle: element.vehicle
              ? element.vehicle
              : element.vehicleList &&
                  element.vehicleList.length > 0 &&
                  element.vehicleList[0].brand &&
                  element.vehicleList[0].model
                ? `${element.vehicleList[0].brand} ${element.vehicleList[0].model}`
                : "",
            readOnly:
              element.isLocked ||
              (currentUser?.accountType === CONFIG_API.CLIENT &&
                element.clientPid !== currentUser.pid),
            options: [
              { name: "isLoanCar", value: element.isLoanCar },
              { name: "isToLoad", value: element.isToLoad },
              { name: "isTruckTrailer", value: element.isTruckTrailer },
              { name: "isParking", value: element.isParking },
              { name: "isValet", value: element.isValet },
            ],
            notifications: [
              { name: "isMailConfirmation", value: element.isMailConfirmation },
              { name: "isSmsConfirmation", value: element.isSmsConfirmation },
              { name: "isSmsReminder", value: element.isSmsReminder },
            ],
          };

          // Skip adding the appointment if its PID already exists.
          if (!existingIdsMap.has(element.pid)) {
            // Add the appointment and map its ID to its index in updatedData
            existingIdsMap.set(element.pid, updatedData.length);
            updatedData.push(updatedElement);
          }
        });

        // Add all new appointments which didn't exists in the current event store
        updatedData.forEach((appointment) => {
          const alreadyExists = eventStore?.allRecords.some(
            (event) => event.getData("pid") === appointment.pid,
          );
          if (!alreadyExists) {
            eventStore?.add(appointment);
          }
        });

        // Fetch all current inspections in this center
        const currentInspectionsList: AxiosResponse<
          APIResponsesModel<InspectionModel>
        > = await api.get(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${centerPid}/${CONFIG_API.INSPECTION}/current`,
        );

        // Create a list of PIDs for the current inspection's appointments
        const currentAppointmentsSet = new Set(
          currentInspectionsList.data.items.map(
            (item: InspectionModel) => item.appointmentPid,
          ),
        );

        if (isBackgroundRequest) {
          eventStore?.allRecords.map((event) => {
            if (
              !moment(event.getData("startDate")).isBetween(
                moment(dateFrom),
                moment(dateTo),
                "day",
                "[]",
              )
            ) {
              // Removing stored appointments that aren't in the loaded range anymore (to avoid too much useless items in the eventStore)
              eventStore?.remove(event);
            } else {
              // Removing stored appointment that aren't in the response anymore (if delete from another session for example)
              const existingAppointment = updatedData.some(
                (appointment) => appointment.pid === event.getData("pid"),
              );
              if (!existingAppointment) {
                eventStore?.remove(event);
              }
            }
          });
        } else {
          updatedData.forEach((appointment) => {
            const storedEvent = eventStore?.allRecords.find(
              (event) => appointment.pid === event.getData("pid"),
            );
            // Update stored event if updated since the first get (remove the old data to add the new data instead)
            if (
              storedEvent &&
              (storedEvent.getData("modificationDate") !==
                appointment.modificationDate ||
                storedEvent.getData("isHonored") !== appointment.isHonored)
            ) {
              eventStore?.remove(storedEvent);
              eventStore?.add(appointment);
            }
            // Check if the appointment is in the current inspection list and set the param if so (or remove it if it's not current anymore)
            if (currentAppointmentsSet.has(appointment.pid)) {
              storedEvent?.set({ ...storedEvent, isCurrent: true });
            } else if (storedEvent?.getData("isCurrent")) {
              storedEvent?.set({ ...storedEvent, isCurrent: false });
            }
          });

          // If one of the not honored displayed appointments has intersection show the overlap alert message, else hide it if it was shown
          if (
            updatedData.some(
              (appointment) =>
                appointment.intersection && !appointment.isHonored,
            )
          ) {
            setIsOverlapAlertShown(true);
          } else {
            setIsOverlapAlertShown(false);
          }
        }
        return true;
      } else {
        dispatch(
          setAlert({
            id: "calendar-alert",
            type: "failure",
            message:
              "Une erreur est survenue lors du chargement des rendez-vous.",
          }),
        );
        return false;
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "calendar-alert",
          type: "failure",
          message:
            "Une erreur est survenue lors du chargement des rendez-vous.",
        }),
      );
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Une erreur est survenue."));
      }
      return false;
    } finally {
      if (appointmentState.loading.firstLoading) {
        dispatch(setFirstLoading(false));
      }
      dispatch(setRegularLoading(false));
    }
  };

  useEffect(() => {
    // ONLY FOR MOBILE VIEW : To switch days on HTML chevrons icons click
    const handleNextDay = () => {
      calendarRef.current?.instance.shiftNext();
      calendarRef.current?.instance.date &&
        setActiveDate(new Date(calendarRef.current?.instance.date));
    };

    const handlePreviousDay = () => {
      calendarRef.current?.instance.shiftPrevious();
      calendarRef.current?.instance.date &&
        setActiveDate(new Date(calendarRef.current?.instance.date));
    };

    window.handleNextDayClick = handleNextDay;
    window.handlePreviousDayClick = handlePreviousDay;
  }, []);

  useEffect(() => {
    const loadSchedules = async () => {
      if (currentOrganization && centerState.currentCenter) {
        const response = await dispatch(
          fetchCenterSchedules(
            currentOrganization.pid,
            centerState.currentCenter.pid,
          ),
        );
        if (response) {
          setCenterSchedules(response);
        } else {
          setIsError("schedulesError");
        }
      }
    };
    if (
      Object.values(centerState.centerSchedules).some(
        (value) => value === undefined,
      )
    ) {
      loadSchedules();
    } else if (centerSchedules && centerState.centerLines) {
      getSchedulesTimeRanges(centerSchedules);
    }
  }, [dispatch, centerSchedules, centerState.centerLines]);

  useEffect(() => {
    if (centerState.centerTimeRanges && centerSchedules) {
      getAppointments();
    }
  }, [centerState.centerTimeRanges, centerSchedules]);

  useEffect(() => {
    if (centerSchedules) {
      // Reset centerSchedules when changing current center to avoid schedules conflict
      setCenterSchedules(undefined);
    }
  }, [centerState.currentCenter]);

  const config = CalendarConfig(
    activeDate,
    activeView,
    fitHours,
    storedSlots,
    setChosenClosingData,
    setIsOverlapAlertShown,
  );

  return (
    <>
      {!isError &&
      (appointmentState.loading.firstLoading ||
        !centerState.centerSchedules.minHour) ? (
        <LoadingData text={"Chargement des rendez-vous..."} />
      ) : isError ? (
        <ErrorLoadingData
          errorMessage={
            isError === "schedulesError"
              ? "Les plages horaires du centre sont incorrectes."
              : "Erreur lors du chargement des rendez-vous."
          }
          retryFunction={
            isError === "schedulesError" && currentUser?.hasCenterSettingsAccess
              ? () => {
                  navigate(
                    `/${CONFIG_CTO.PARAMETERS_PATH}/${CONFIG_CTO.SCHEDULES_PATH}`,
                  );
                }
              : isError === "appointmentError"
                ? getAppointments
                : undefined
          }
          retryMessage={
            isError === "schedulesError" && currentUser?.hasCenterSettingsAccess
              ? "Configurer"
              : undefined
          }
        />
      ) : (
        resources &&
        resources?.length > 0 &&
        centerState.centerSchedules.minHour &&
        activeDate && (
          <>
            <AlertMessage id="calendar-alert" />
            <CalendarToolbar
              calendar={calendarRef}
              setActiveDate={setActiveDate}
              setActiveView={setActiveView}
              storedActiveView={storedActiveView}
              displayedDate={displayedDate}
              fitHours={fitHours}
              setFitHours={setFitHours}
            />
            {appointmentState.storedAppointmentData.data && (
              <Alert
                color="info"
                className="flex-row justify-center items-center font-bold mx-0 my-0.5 lg:-mt-1.5 lg:mr-4 lg:mb-0.5 lg:ml-0"
              >
                <Stack
                  alignItems={"center"}
                  flexDirection={"row"}
                  gap={"0.5rem"}
                >
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={"5px"}
                  >
                    {`Sélectionnez un créneau : ${capitalizeString(appointmentState.storedAppointmentData.data.firstName?.toLocaleLowerCase() ?? "")} ${capitalizeString(appointmentState.storedAppointmentData.data.lastName?.toLocaleLowerCase() ?? "")}`}
                    {isDesktop &&
                      appointmentState.storedAppointmentData.variant &&
                      appointmentState.storedAppointmentData.data.centerPid !==
                        centerState.currentCenter?.pid && (
                        <Box fontWeight={"initial"}>
                          {appointmentState.storedAppointmentData.variant ===
                          "cut"
                            ? `(Coupé depuis ${centerState.organizationCenters?.find((center) => center.pid === appointmentState.storedAppointmentData.data?.centerPid)?.name})`
                            : ""}
                        </Box>
                      )}
                  </Stack>
                  <Button
                    disabled={appointmentState.loading.pasteLoading}
                    onClick={async () => {
                      if (
                        calendarRef.current &&
                        appointmentState.storedAppointmentData.data
                      ) {
                        appointmentState.storedAppointmentData.variant ===
                          "cut" &&
                          calendarRef.current.instance.pasteEvents(
                            appointmentState.storedAppointmentData
                              .isCenterChanged
                              ? new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() + 10,
                                  ),
                                )
                              : new Date(
                                  new Date(
                                    appointmentState.storedAppointmentData.data.startDate,
                                  ),
                                ),
                          );
                        calendarRef.current.instance.clearEventSelection();
                        dispatch(
                          setStoredAppointmentData({
                            variant: null,
                            data: null,
                            isCenterChanged: false,
                          }),
                        );
                      }
                    }}
                    className="btn-base btn-outlined"
                  >
                    {appointmentState.loading.pasteLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      "Annuler"
                    )}
                  </Button>
                </Stack>
              </Alert>
            )}
            {isOverlapAlertShown && (
              <Alert
                color="failure"
                className="flex-row justify-center font-bold mx-0 my-0.5 lg:-mt-1.5 lg:mr-4 lg:mb-0.5 lg:ml-0"
              >
                Attention, des rendez-vous se superposent
              </Alert>
            )}
            <BryntumCalendar
              {...config}
              id={"b-calendar"}
              ref={calendarRef}
              resources={resources}
              dragFeature={{
                resizable: false,
                creatable:
                  currentUser?.hasPlanningSettingsAccess &&
                  !appointmentState.storedAppointmentData.data,
                footer: null,
              }}
              nonWorkingDays={centerState.centerSchedules.nonWorkingDays}
              onDateRangeChange={({ old: oldRange, new: newRange }) => {
                // To avoid double appointments loading
                // With Bryntum 6.0, using dateRangeRequested instead ("changed" property to check if range is different from last request))
                if (oldRange.startDate) {
                  setDateRange({
                    startDate: newRange.startDate,
                    endDate: newRange.endDate,
                  });
                }
              }}
              onDateChange={({ oldDate, date }) => {
                if (
                  !moment(date).isBetween(
                    moment(dateRange?.startDate),
                    moment(dateRange?.endDate),
                  )
                ) {
                  setDisplayedDate(calendarRef.current?.instance.date);
                }
                // Switch automatically day to not display not working day
                if (
                  activeView === "dayResource" &&
                  calendarRef.current?.instance.nonWorkingDays[date.getDay()]
                ) {
                  if (oldDate < date) {
                    calendarRef.current?.instance.shiftNext();
                  } else {
                    // Double shiftPrevious to avoid staying on the same day
                    calendarRef.current?.instance.shiftPrevious();
                    calendarRef.current?.instance.shiftPrevious();
                  }
                }
              }}
              modes={{
                dayResource: {
                  type: "dayresource",
                  title: "JOUR",
                  fitHours: fitHours,
                  hourHeight: 200,
                  range: "1d",
                  date: (() => {
                    const nextDate = moment(activeDate); // Start with the active date
                    const nonWorkingDays =
                      calendarRef.current?.instance.nonWorkingDays || {}; // Get the non-working days
                    // Loop while the current day is a non-working day
                    while (nonWorkingDays[nextDate.day()]) {
                      nextDate.add(1, "day"); // Add 1 day
                    }
                    return nextDate.toDate(); // Return the next working day
                  })(),
                  hideNonWorkingDays: true,
                  dayStartTime: centerState.centerSchedules.minHour,
                  dayEndTime: centerState.centerSchedules.maxHour,
                  dayHeaderRenderer: (headerDomConfig) => {
                    const previousDayIcon = {
                      className: `previousDayIcon`,
                      html: `<i 
                                             class="fa-regular fa-chevron-left"
                                             onclick="window.handlePreviousDayClick()" 
                                             </i>`,
                    };
                    const nextDayIcon = {
                      className: `nextDayIcon`,
                      html: `
                                        <i 
                                        class="fa-regular fa-chevron-right"
                                        onclick="window.handleNextDayClick()"
                                        >
                                        </i>`,
                    };
                    headerDomConfig.children.unshift(previousDayIcon);
                    headerDomConfig.children.push(nextDayIcon);
                    return ``; // String returned
                  },
                },
                weekResource: {
                  type: "dayresource",
                  fitHours: fitHours,
                  hourHeight: 125,
                  title: "1 SEMAINE",
                  range: "7d",
                  hideNonWorkingDays: true,
                  startDate: activeDate,
                  dayStartTime: centerState.centerSchedules.minHour,
                  dayEndTime: centerState.centerSchedules.maxHour,
                },
                twoWeeksResource: {
                  type: "dayresource",
                  fitHours: fitHours,
                  hourHeight: 125,
                  title: "2 SEMAINES",
                  hideNonWorkingDays: true,
                  range: "14d",
                  startDate: activeDate,
                  dayStartTime: centerState.centerSchedules.minHour,
                  dayEndTime: centerState.centerSchedules.maxHour,
                },
                agenda: null,
                day: null,
                year: null,
                month: null,
                week: null,
              }}
            />
            <EventEditor
              key={keyNumber}
              keyNumber={keyNumber}
              setKeyNumber={setKeyNumber}
              calendar={calendarRef}
              resources={resources}
              centerSchedules={centerSchedules}
              chosenClosingData={chosenClosingData}
              setChosenClosingData={setChosenClosingData}
            />
          </>
        )
      )}
    </>
  );
};

export default CalendarComponent;
