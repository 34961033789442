import moment from "moment";
import { TimeRangeModel } from "../../models/CalendarModel";
import { LineModel, ScheduleModel } from "../../models/CenterSettingsModel";
import { findMinMaxTimes } from "./findMinMaxTimes";

/**
 * Generates calendar visual time ranges for each line based on the provided schedules (dotted or thick lines).
 * The time ranges are generated according to the specified step and slot length,
 * and they take into account non-working days.
 * @param {LineModel[]} lines - Array of LineModel objects representing different lines.
 * @param {ScheduleModel[]} schedules - Array of ScheduleModel objects representing the schedules.
 * @param {number} step - The step interval in minutes for generating time ranges.
 * @param {number} slotLength - The length of each slot in terms of the number of steps.
 * @param {TimeRangeModel[]} closings - Array of closing time ranges for the given lines.
 * @param {string} startDate - The start date of the period to generate time ranges for.
 * @param {string} endDate - The end date of the period to generate time ranges for.
 * @returns An array of TimeRangeModel objects representing the generated visual time ranges.
 */
export const generateVisualTimeRanges = (
  lines: LineModel[],
  schedules: ScheduleModel[],
  step: number,
  slotLength: number,
  closings: TimeRangeModel[],
  startDate: string,
  endDate: string,
): TimeRangeModel[] => {
  const timeRanges: TimeRangeModel[] = [];
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  // Iterate over each day in the period
  for (
    let day = startMoment.clone();
    day.isSameOrBefore(endMoment, "day");
    day.add(1, "days")
  ) {
    const dayOfWeek = day.day(); // 0 (Sunday) to 6 (Saturday)

    // Check if the day is a working day
    const isWorkingDay = schedules.some(
      (schedule) => schedule.idtJou === dayOfWeek,
    );
    if (!isWorkingDay) {
      continue; // Skip non-working days
    }

    // Iterate over each line
    lines.forEach((line: LineModel) => {
      const currentSchedule = schedules.find(
        (schedule) =>
          schedule.idtJou === dayOfWeek && schedule.line === line.lineNumber,
      );
      if (currentSchedule) {
        // Find line's min and max time for each day
        const lineMinMaxTime = findMinMaxTimes([currentSchedule]);

        // Set the start and end time for the current day
        let currentMoment = moment(
          `${day.format("YYYY-MM-DD")}T${lineMinMaxTime.minTime}`,
        );
        const lineEndMoment = moment(
          `${day.format("YYYY-MM-DD")}T${lineMinMaxTime.maxTime}`,
        );
        let counter = 0;

        // Generation of TimeRanges for this line
        while (currentMoment.isBefore(lineEndMoment)) {
          // Check if currentMoment is at the end of a closing period
          const closing = getClosingAtTime(
            currentMoment,
            closings,
            line.lineNumber,
            step,
          );
          if (closing) {
            // Move to the end time of the closing
            currentMoment = moment(closing.endDate);
            // Reset counter to start fresh
            counter = 0;
          }

          if (currentMoment.isBefore(lineEndMoment)) {
            // Ensure we don't go beyond the end time of the line
            if (counter % slotLength === 0) {
              timeRanges.push(
                createTimeRange(
                  line.lineNumber,
                  currentMoment,
                  step,
                  `thickLine-timeRange`,
                ),
              );
            } else {
              timeRanges.push(
                createTimeRange(
                  line.lineNumber,
                  currentMoment,
                  step,
                  `dottedLine-timeRange`,
                ),
              );
            }

            currentMoment.add(step, "minutes");
            counter++;
          } else {
            break; // Exit the loop if we have reached the end time of the line
          }
        }
      }
    });
  }

  return timeRanges;
};

// Helper function to check if the current time is within or just after the end of a closing period for a specific resource
const getClosingAtTime = (
  time: moment.Moment,
  closings: TimeRangeModel[],
  resourceId: number,
  step: number,
): TimeRangeModel | undefined => {
  return closings.find(
    (closing) =>
      closing.resourceId === resourceId &&
      (moment(closing.endDate).isSame(time, "minute") ||
        (moment(closing.endDate).isBefore(time, "minute") &&
          time.isBefore(moment(closing.endDate).add(step, "minutes")))),
  );
};

/**
 * Function to create a time range with precise dates.
 * @param {number} resourceId - The ID of the resource/line.
 * @param {moment.Moment} startTime - The start time of the range.
 * @param {number} duration - The duration in minutes.
 * @param {string} className - The class name for styling.
 * @returns {TimeRangeModel} - The generated time range.
 */
const createTimeRange = (
  resourceId: number,
  startTime: moment.Moment,
  duration: number,
  className: string,
): TimeRangeModel => {
  // Calculate endDate from startTime and duration
  const endDate = moment(startTime).add(duration, "minutes");

  return {
    id: `${className.slice(0, 3)}${startTime.format("YYYYMMDDHHmmss")}${endDate.format("YYYYMMDDHHmmss")}${resourceId}`,
    resourceId: resourceId,
    startDate: startTime.toISOString(), // Use the precise date and time
    endDate: endDate.toISOString(), // Use the precise date and time
    isAppointmentAllowed: true,
    type: "step",
    cls: className,
  };
};
