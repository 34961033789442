import { TimeRangeModel } from "../../models/CalendarModel";
import { LineModel, ScheduleModel } from "../../models/CenterSettingsModel";

/**
 * Generates TimeRangeModel objects indicating when each line is closed ensuring coverage for non-scheduled times within working days.
 * @param {ScheduleModel[]} schedules - Array of schedule objects indicating opening times.
 * @param {LineModel[]} centerLines - Array of all center lines in order to defined each line schedules.
 * @param {string} minTime - The minimum time of the day (e.g., "08:00").
 * @param {string} maxTime - The maximum time of the day (e.g., "18:00").
 * @param {Object.<number, boolean>} nonWorkingDays - An object where keys are days of the week (0-6) and values are true if the day is non-working.
 * @returns {TimeRangeModel[]} - Array of TimeRangeModel objects representing closed time ranges for the calendar.
 */
export const generateTimeRangesSchedules = (
  schedules: ScheduleModel[],
  centerLines: LineModel[] | undefined,
  minTime: string,
  maxTime: string,
  nonWorkingDays: { [key: number]: boolean },
): TimeRangeModel[] => {
  // Create an array of days to match index (SU = 0 (for Sunday), SA = 6 (for Saturday))
  const daysOfWeek = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
  const timeRanges: TimeRangeModel[] = [];

  // Collect unique line IDs from schedules to avoid duplicates
  let allCenterLines = new Set<number>();
  centerLines?.forEach((line: LineModel) => {
    allCenterLines.add(line.lineNumber);
  });

  // Convert the Set of unique line IDs to an Array for easier iteration
  allCenterLines = new Set(Array.from(allCenterLines));

  // Check every day-line combination
  daysOfWeek.forEach((day, index) => {
    // Timerange creation logic only for working days (not includes in nonWorkingDays)
    if (!(index in nonWorkingDays)) {
      allCenterLines.forEach((line) => {
        // Check if there is a schedule for the current day (index) and current line
        const isScheduled = schedules.some(
          (schedule: ScheduleModel) =>
            schedule.idtJou === index && schedule.line === line,
        );
        if (!isScheduled) {
          // Add a time range for the entire day if the line is not scheduled for that day (for all day long closed lines)
          const newTimeRange: TimeRangeModel = {
            id: `schday${index}${minTime.replace(/:/g, "")}${maxTime.replace(/:/g, "")}${line}`,
            resourceId: line,
            startDate: `1970-01-01T${minTime}`,
            endDate: `1970-01-01T${maxTime}`,
            recurrenceRule: `FREQ=WEEKLY;BYDAY=${day}`,
            isAppointmentAllowed: false,
            type: "schedule",
            cls: "closedTimeRanges",
          };
          timeRanges.push(newTimeRange);
        } else {
          // Handle normal opening hours time ranges
          schedules
            .filter(
              (schedule: ScheduleModel) =>
                schedule.idtJou === index && schedule.line === line,
            )
            .forEach((schedule) => {
              // Before opening
              if (schedule.startingTime !== minTime) {
                timeRanges.push({
                  id: `schbef${index}${minTime.replace(/:/g, "")}${schedule.startingTime.replace(/:/g, "")}${line}`,
                  resourceId: line,
                  startDate: `1970-01-01T${minTime}`,
                  endDate: `1970-01-01T${schedule.startingTime}`,
                  recurrenceRule: `FREQ=WEEKLY;BYDAY=${day}`,
                  isAppointmentAllowed: false,
                  type: "schedule",
                  cls: "closedTimeRanges",
                });
              }

              // After closing
              if (schedule.endingTime !== maxTime) {
                timeRanges.push({
                  id: `schaft${index}${schedule.endingTime.replace(/:/g, "")}${maxTime.replace(/:/g, "")}${line}`,
                  resourceId: line,
                  startDate: `1970-01-01T${schedule.endingTime}`,
                  endDate: `1970-01-01T${maxTime}`,
                  recurrenceRule: `FREQ=WEEKLY;BYDAY=${day}`,
                  isAppointmentAllowed: false,
                  type: "schedule",
                  cls: "closedTimeRanges",
                });
              }
            });
        }
      });
    }
  });

  // To be sure to not have the same Timerange (filtered by ID)
  const uniqueIds = new Set();

  const updatedTimeRanges = timeRanges.filter((timeRange: TimeRangeModel) => {
    uniqueIds.add(timeRange.id);
    return true;
  });

  // Return generetaed schedules timeranges
  return updatedTimeRanges;
};
