import { Stack } from "@mui/material";

import ErrorDisplay from "../components/layouts/ErrorDisplay";

type Props = {
  variant: "error" | "forbidden" | "unknown" | "iframeDisplay" | undefined;
};

const ErrorPage = ({ variant }: Props) => {
  return (
    <Stack height={"100vh"}>
      <ErrorDisplay variant={variant} />
    </Stack>
  );
};

export default ErrorPage;
